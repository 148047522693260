/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { Dropdown, DropdownButton } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { clickFunc } from "../../../../../../../../common/Functions";
import { logoutActions } from "../../../../../../../../store/actions";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import "../../BankruptcyDisclosure.scss";

const actionOptions = {
  BANKRUPTCY: "Bankruptcy",
  COMPROMISE: "Compromise",
  DECLARATION: "Declaration",
  LIQUIDATION: "Liquidation",
  RECEIVERSHIP: "Receivership",
  OTHER: "Other",
  DENIED: "Denied",
  REVOKED: "Revoked",
};

const dateOptions = {
  EXACT: "Exact date",
  EXPLANATION: "Explanation",
};

const BankruptcyDisclosureActionDetails = ({
  updateCCBForm,
  nextFormId,
  backFormId,
  updateTempBankruptcyDRP,
  tempBankruptcyDRP,
  isOrg,
}) => {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("");
  const [actionTypeError, setActionTypeError] = useState(false);
  const [actionDate, setActionDate] = useState("");
  const [actionDateError, setActionDateError] = useState(false);
  const [actionDateErrorText, setActionDateErrorText] = useState("");
  const [actionOption, setActionOption] = useState("");
  const [actionOptionError, setActionOptionError] = useState(false);
  const [actionDateExplanation, setActionDateExplanation] = useState("");
  const [actionDateExplanationError, setActionDateExplanationError] =
    useState(false);
  const [actionTypeOtherText, setActionTypeOtherText] = useState("");
  const [actionTypeOtherTextError, setActionTypeOtherTextError] =
    useState(false);

  const [actionBankruptcyOption, setActionBankruptcyOption] = useState("");
  const [actionBankruptcyOptionError, setActionBankruptcyOptionError] =
    useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [currentFormChanged, hasCurrentFormChanged] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  const handleToggleBackModal = (value) => {
    if (currentFormChanged) {
      toggleShowBackModal(value);
    } else {
      handleBack();
    }
  };
  const handleNext = () => {
    if (actionType !== actionOptions.COMPROMISE) {
      for (const [key, value] of Object.entries(tempBankruptcyDRP)) {
        if (key.slice(0, 4) === "comp") {
          delete tempBankruptcyDRP[key];
        }
      }
    }
    const tmpDRP = {
      ...tempBankruptcyDRP,
      actionType: actionType,
      actionTypeOtherText: actionTypeOtherText,
      actionDate: actionDate,
      actionOption: actionOption,
      actionDateExplanation: actionDateExplanation,
      actionBankruptcyOption: actionBankruptcyOption,
    };
    updateTempBankruptcyDRP(tmpDRP);
    updateCCBForm(nextFormId);
    hasCurrentFormChanged(false);
  };
  const handleBack = () => {
    updateCCBForm(backFormId);
  };
  const handleChange = ({ target: { id, value } }) => {
    hasCurrentFormChanged(true);
    switch (id) {
      case "actionDateId":
        setActionDate(value);

        break;
      case "actionTypeOtherText":
        setActionTypeOtherText(value);

        break;
      case "actionDateExplanation":
        setActionDateExplanation(value);

        break;
      default:
        break;
    }
  };
  const handleActionTypeChange = ({ target: { value } }) => {
    setActionType(value);
    if (value !== actionOptions.BANKRUPTCY) {
      setActionBankruptcyOption("");
      setActionBankruptcyOptionError(false);
    }
    if (value !== actionOptions.OTHER) {
      setActionTypeOtherText("");
      setActionTypeOtherTextError(false);
    }
    hasCurrentFormChanged(true);
  };

  const handleActionDateOptionChange = ({ target: { value } }) => {
    setActionOption(value);
    if (value === dateOptions.EXACT) {
      setActionDateExplanation("");
      setActionDateExplanationError(false);
    }
    hasCurrentFormChanged(true);
  };

  const handleBankruptcyOptionChange = ({ target: { value } }) => {
    setActionBankruptcyOption(value);

    hasCurrentFormChanged(true);
  };

  const onBlur = ({ target: { id, value } }) => {
    switch (id) {
      case "actionDateExplanation":
        setActionDateExplanationError(value.trim().length < 2);
        break;
      case "actionTypeOtherText":
        setActionTypeOtherTextError(value.trim().length < 2);
        break;
      case "bankOpt":
        setActionBankruptcyOptionError(actionBankruptcyOption.trim() === "");
        if (actionBankruptcyOption === "") {
          document.getElementById("bankOpt").style.border =
            "0.1rem solid #e21224";
        } else {
          document.getElementById("bankOpt").style.border =
            "0.1rem solid #6b748e";
        }
        break;
      default:
        break;
    }

    setFocusedState(false);
  };

  const validationDate = (date, trueDate) => {
    return String(date) === String(trueDate);
  };

  const isInvalidDate = (value) => {
    const todaysDate = moment();
    const inputValue = moment(value);
    const dateValidation = moment(value).format("MM/DD/YYYY");

    return (
      !inputValue.isValid() ||
      value.includes("_") ||
      value === "" ||
      value.slice(-4) === "0000" ||
      !validationDate(value, dateValidation) ||
      inputValue.isAfter(todaysDate) ||
      todaysDate.diff(inputValue, "year") >= 100 ||
      value.slice(-4, -1) === "000"
    );
  };

  const onBlurDateInput = ({ target: { value } }) => {
    const todaysDate = moment();
    const inputValue = moment(value);
    const dateValidation = moment(value).format("MM/DD/YYYY");

    let hasError = false;
    let errorText = "";
    if (
      !inputValue.isValid() ||
      value.includes("_") ||
      value === "" ||
      value.slice(-4) === "0000" ||
      !validationDate(value, dateValidation)
    ) {
      errorText = "Please enter a valid date";
      hasError = true;
    } else if (inputValue.isAfter(todaysDate)) {
      errorText = "Date cannot be a future date";
      hasError = true;
    } else if (
      todaysDate.diff(inputValue, "year") >= 100 ||
      value.slice(-4, -1) === "000"
    ) {
      errorText = "Date cannot be more than 100 years ago";
      hasError = true;
    }

    setFocusedState(false);
    setActionDateError(hasError);
    setActionDateErrorText(errorText);
  };

  const allFilled = () => {
    return (
      !isInvalidDate(actionDate) &&
      ((actionOption === dateOptions.EXPLANATION &&
        actionDateExplanation.trim().length > 1) ||
        actionOption === dateOptions.EXACT) &&
      actionType !== "" &&
      (actionType !== actionOptions.BANKRUPTCY ||
        (actionType === actionOptions.BANKRUPTCY &&
          actionBankruptcyOption !== "")) &&
      (actionType !== actionOptions.OTHER ||
        (actionType === actionOptions.OTHER &&
          actionTypeOtherText.trim().length > 1))
    );
  };
  const noErrors = () => {
    return (
      !actionTypeError &&
      !actionDateError &&
      !actionOptionError &&
      !actionDateExplanationError &&
      !actionBankruptcyOptionError &&
      (actionType !== actionOptions.OTHER ||
        (actionType === actionOptions.OTHER && !actionTypeOtherTextError))
    );
  };

  useEffect(() => {
    setIsFormComplete(allFilled() && noErrors());
  });
  useEffect(() => {
    if (
      tempBankruptcyDRP?.actionType &&
      tempBankruptcyDRP?.actionDate &&
      tempBankruptcyDRP?.actionOption
    ) {
      setActionType(tempBankruptcyDRP?.actionType);
      setActionTypeOtherText(tempBankruptcyDRP?.actionTypeOtherText);
      setActionDate(tempBankruptcyDRP?.actionDate);
      setActionOption(tempBankruptcyDRP?.actionOption);
      setActionDateExplanation(tempBankruptcyDRP?.actionDateExplanation);
      setActionBankruptcyOption(tempBankruptcyDRP?.actionBankruptcyOption);
      setIsFormComplete(true);
      hasCurrentFormChanged(true);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
      //}
    } else {
      hasCurrentFormChanged(false);
      if (isOrg) {
        dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
      } else {
        dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
      }
    }
  }, []);
  useEffect(() => {
    if (isOrg) {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    } else {
      if (currentFormChanged || !noErrors()) {
        dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
      } else {
        dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
      }
    }
  }, [currentFormChanged, noErrors(), dispatch]);
  return (
    <div className="bankruptcy-form-wrapper">
      <br />
      <div className="row">
        <div className="col-md-12">
          <label id="actionTypeLabelId" className="field-label">
            Action Type (select appropriate item) *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="actionTypeBankruptcy"
                type="radio"
                id="actionTypeBankruptcy"
                className="custom-control-input"
                value={actionOptions.BANKRUPTCY}
                checked={actionType === actionOptions.BANKRUPTCY}
                onChange={handleActionTypeChange}
              />
              <label
                id="actionTypeBankruptcyLabelId"
                htmlFor="actionTypeBankruptcy"
                className="custom-control-label"
              >
                {actionOptions.BANKRUPTCY}
              </label>
            </div>
            {actionType === actionOptions.BANKRUPTCY && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <DropdownButton
                      className={
                        actionBankruptcyOptionError
                          ? "is-invalid"
                          : actionBankruptcyOption === ""
                          ? "action-placeholder"
                          : ""
                      }
                      id="bankOpt"
                      type="default"
                      variant="secondary"
                      value={actionBankruptcyOption}
                      title={
                        actionBankruptcyOption !== ""
                          ? actionBankruptcyOption
                          : "Select one"
                      }
                      selected={false}
                      autoComplete="off"
                      onSelect={(key, event) => {
                        event.target.id = "bankOpt";
                        event.target.value = key;
                        handleBankruptcyOptionChange(event);
                      }}
                      onBlur={onBlur}
                      onClick={clickFunc}
                      //onFocus={onFocusState}
                    >
                      <Dropdown.Item eventKey={"Chapter 7"} id="bankOptionCh7">
                        Chapter 7
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={"Chapter 11"}
                        id="bankOptionCh11"
                      >
                        Chapter 11
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={"Chapter 13"}
                        id="bankOptionCh13"
                      >
                        Chapter 13
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"Other"} id="bankOptionOther">
                        Other
                      </Dropdown.Item>
                    </DropdownButton>
                    <div
                      id={"actionBankruptcyOptionError"}
                      className="invalid-feedback"
                      style={{ marginLeft: "25px", paddingBottom: "20px" }}
                    >
                      Please choose an answer
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="custom-control custom-radio">
              <input
                name="actionTypeCompromise"
                type="radio"
                id="actionTypeCompromise"
                className="custom-control-input"
                value={actionOptions.COMPROMISE}
                checked={actionType === actionOptions.COMPROMISE}
                onChange={handleActionTypeChange}
              />
              <label
                id="actionTypeCompromiseLabelId"
                htmlFor="actionTypeCompromise"
                className="custom-control-label"
              >
                {actionOptions.COMPROMISE}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="actionTypeDeclaration"
                type="radio"
                id="actionTypeDeclaration"
                className="custom-control-input"
                value={actionOptions.DECLARATION}
                checked={actionType === actionOptions.DECLARATION}
                onChange={handleActionTypeChange}
              />
              <label
                id="actionTypeDeclarationLabelId"
                htmlFor="actionTypeDeclaration"
                className="custom-control-label"
              >
                {actionOptions.DECLARATION}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="actionTypeLiquidation"
                type="radio"
                id="actionTypeLiquidation"
                className="custom-control-input"
                value={actionOptions.LIQUIDATION}
                checked={actionType === actionOptions.LIQUIDATION}
                onChange={handleActionTypeChange}
              />
              <label
                id="actionTypeLiquidationLabelId"
                htmlFor="actionTypeLiquidation"
                className="custom-control-label"
              >
                {actionOptions.LIQUIDATION}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="actionTypeReceivership"
                type="radio"
                id="actionTypeReceivership"
                className="custom-control-input"
                value={actionOptions.RECEIVERSHIP}
                checked={actionType === actionOptions.RECEIVERSHIP}
                onChange={handleActionTypeChange}
              />
              <label
                id="actionTypeReceivershipLabelId"
                htmlFor="actionTypeReceivership"
                className="custom-control-label"
              >
                {actionOptions.RECEIVERSHIP}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="actionTypeOther"
                type="radio"
                id="actionTypeOther"
                className="custom-control-input"
                value={actionOptions.OTHER}
                checked={actionType === actionOptions.OTHER}
                onChange={handleActionTypeChange}
              />
              <label
                id="actionTypeOtherLabelId"
                htmlFor="actionTypeOther"
                className="custom-control-label"
              >
                {actionOptions.OTHER}
              </label>
            </div>
            {actionType === actionOptions.OTHER ? (
              <div className="action-type-other" style={{ marginLeft: "25px" }}>
                <input
                  type="text"
                  id="actionTypeOtherText"
                  className={
                    actionTypeOtherTextError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={actionTypeOtherText}
                  onChange={handleChange}
                  onBlur={onBlur}
                  onFocus={() => setFocusedState(true)}
                />
                <div id="actionTypeOtherTextError" className="invalid-feedback">
                  Please enter a response
                </div>
              </div>
            ) : null}

            <div id="actionTypeError" className="invalid-feedback">
              Please choose an answer
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 action-date-wrapper">
          <label
            id="actionDateLabelId"
            className="field-label"
            htmlFor="actionDateId"
          >
            Action Date (Provide date bankruptcy was filed, or date SIPC was
            initiated, or date of compromise with creditor) *
          </label>
          <InputMask
            style={{ width: "25%" }}
            id="actionDateId"
            mask="99/99/9999"
            maskChar="_"
            className={`${
              actionDateError ? "form-control is-invalid" : "form-control"
            } action-date-input`}
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={actionDate}
            onChange={handleChange}
            onBlur={onBlurDateInput}
            onFocus={() => setFocusedState(true)}
          />
          <div id="actionDateError" className="invalid-feedback">
            {actionDateErrorText}
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="drp-radio-input-wrapper"
          style={{ marginLeft: "40px", width: "80%" }}
        >
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="actionDateExact"
                type="radio"
                id="actionDateExact"
                className="custom-control-input"
                value={dateOptions.EXACT}
                checked={actionOption === dateOptions.EXACT}
                onChange={handleActionDateOptionChange}
              />
              <label
                htmlFor="actionDateExact"
                className="custom-control-label"
                id="actionDateExactLabel"
              >
                {dateOptions.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="actionDateExplanation"
                type="radio"
                id="actionDateExplanation"
                className="custom-control-input"
                value={dateOptions.EXPLANATION}
                checked={actionOption === dateOptions.EXPLANATION}
                onChange={handleActionDateOptionChange}
              />
              <label
                htmlFor="actionDateExplanation"
                className="custom-control-label"
                id="actionDateExplanationLabel"
              >
                {dateOptions.EXPLANATION}
              </label>
            </div>
            {actionOption === dateOptions.EXPLANATION ? (
              <div className="action-date-explanation-container">
                <label
                  htmlFor="actionDateExplanation"
                  className="field-label"
                  id="actionDateExplanationLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="actionDateExplanation"
                  className={
                    actionDateExplanationError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  rows="3"
                  cols={500}
                  value={actionDateExplanation}
                  onChange={handleChange}
                  onBlur={onBlur}
                  onFocus={() => setFocusedState(true)}
                />
                <div
                  id="actionDateExplanationError"
                  className="invalid-feedback"
                >
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row backbutton-drp-bankruptcy">
        <div className="col-md-12 buttons">
          <span
            id="bankruptcyAddDRPBack"
            className="back-button"
            onClick={() => {
              handleToggleBackModal(true);
            }}
          >
            <strong id="bankruptcyDisclBack">Back</strong>
          </span>
          <Button
            disabled={!isFormComplete || isFocusedState}
            id="bankruptcyAddDRPNext"
            style={{ marginRight: "10px" }}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={() => {
          handleToggleBackModal(false);
        }}
        backClick={handleBack}
      />
    </div>
  );
};
export default BankruptcyDisclosureActionDetails;
