import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useReducer } from "react";
import Footer from "../SharedComponents/Footer";
import Header from "../SharedComponents/Header";
import UserName from "../SharedComponents/UserName";
import "../../assets/stylesheets/HomePages.scss";

import { useFlags, withLDProvider } from "launchdarkly-react-client-sdk";
import {
  getDashboard,
  getDashboardMultiContract,
} from "../../api/makeAPIRequest";
import { CONTRACT_STATUS_DESC, isArchived } from "../../constants/Contract";
import { UtagTracking } from "../../utils/utag-tracking";
import Loader from "../SharedComponents/Loader";
import ActiveAndPriorApplications from "./HomePages/ActiveAndPriorApplications";
import OOSPage from "./OOSPage";

function HomePage(props) {
  //OKTA
  const { authState } = useOktaAuth();

  //LAUNCH DARKLY FLAGS
  const { candidateGlobalOos } = useFlags();

  //INITIAL STATE
  const initialState = {
    agencyname: "",
    agencynumber: "",
    applicationStatus: "",
    contracteffectivedate: "",
    contractDesc: "",
    contract_id: "",
    prevSub: "",
    firstname: "",
    lastname: "",
    nyregs: false,
    phonenumber: "",
    candidateid: "",
    contracttypeid: "",
    mmlisallowed: "",
    financingallowed: "",
    contractList: [],
    archiveList: [],
    moreActions: false,
    lastchangedate: "",
    mmlis: "",
    financingonly: "",
    mmlisregistrationdate: "",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "onChange":
        return { ...state, [action.id]: action.value };
      case "filterContracts":
        let filteredState = {
          ...state,
          contractList: action?.value
            ?.filter((x) => !isArchived(x.contractstatusdesc))
            ?.sort(function (a, b) {
              const contractStatusTypes = [
                CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE,
                CONTRACT_STATUS_DESC.APPLICATION_IN_PROGRESS,
                CONTRACT_STATUS_DESC.INVITATION_SENT,
                CONTRACT_STATUS_DESC.MMLIS_PENDING,
              ];

              const aStatusIndex = contractStatusTypes.indexOf(
                a.contractstatusdesc
              );
              const bStatusIndex = contractStatusTypes.indexOf(
                b.contractstatusdesc
              );

              if (aStatusIndex === bStatusIndex)
                return b.contractstatusdesc - a.contractstatusdesc;

              return bStatusIndex - aStatusIndex;
            }),
          archiveList: action.value.filter((x) =>
            isArchived(x.contractstatusdesc)
          ),
        };
        return filteredState;

      case "get_dashboard_data":
        return {
          ...state,
          agencyname: action.agencyname,
          agencynumber: action.agencynumber,
          applicationStatus: action.applicationStatus,
          contractDesc: action.contractDesc,
          contract_id: action.contract_id,
          prevSub: action.prevSub,
          firstname: action.firstname,
          lastname: action.lastname,
          nyregs: action.nyregs,
          phonenumber: action.phonenumber,
          candidateid: action.candidateid,
          contracttypeid: action.contracttypeid,
          mmlisallowed: action.mmlisallowed,
          financingallowed: action.financingallowed,
          contracteffectivedate: action.contracteffectivedate,
          lastchangedate: action.lastchangedate,
          mmlis: action.mmlis,
          financingonly: action.financingonly,
          mmlisregistrationdate: action.mmlisregistrationdate,
        };
      case "get_dashboard_data_error":
        return {
          ...state,
          applicationStatus: action.applicationStatus,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const isSafari = () => {
    const userAgent = navigator.userAgent;
    return /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
  };

  const getHomePageData = async () => {
    try {
      //need delay because of safari
      if (isSafari()) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      // Update the document title using the browser API
      let result = await getDashboard(authState.idToken.claims.sub);

      let multiResult = await getDashboardMultiContract(
        authState.idToken.claims.sub
      );

      dispatch({
        type: "filterContracts",
        value: multiResult,
      });

      if (result === CONTRACT_STATUS_DESC.INVITATION_SENT) {
        dispatch({
          type: "get_dashboard_data_error",
          applicationStatus: CONTRACT_STATUS_DESC.INVITATION_SENT,
        });
      } else {
        dispatch({
          type: "get_dashboard_data",
          agencyname: result.agencyname,
          agencynumber: result.agencynumber,
          applicationStatus: result.contractstatusdesc,
          contractDesc: result.contracttypedesc,
          contract_id: result.contractid,
          prevSub: result.prevsubmission,
          firstname: result.firstname,
          lastname: result.lastname,
          nyregs: result.nyregs,
          phonenumber: result.phonenumber,
          candidateid: result.candidateid,
          contracttypeid: result.contracttypeid,
          mmlisallowed: result.mmlisallowed,
          financingallowed: result.financingallowed,
          contracteffectivedate: result.contracteffectivedate,
          lastchangedate: result.lastchangedate,
          mmlis: result.mmlis,
          financingonly: result.financingonly,
          mmlisregistrationdate: result.mmlisregistrationdate,
        });
        UtagTracking.sendPageNameView(
          "homepage",
          result.candidateid,
          result.agencynumber,
          result.contracttypeid
        );
      }
    } catch (err) {
      console.log(err);

      dispatch({
        type: "get_dashboard_data_error",
        applicationStatus: CONTRACT_STATUS_DESC.INVITATION_SENT,
      });
    }
  };

  useEffect(() => {
    if (state.agencynumber === "") {
      if (
        candidateGlobalOos === false ||
        typeof candidateGlobalOos === "undefined"
      ) {
        getHomePageData();
      }
    }
  }, []);

  return (
    <div id="homepage">
      {candidateGlobalOos === true && <OOSPage />}
      {(candidateGlobalOos === false ||
        typeof candidateGlobalOos === "undefined") && (
        <>
          <Loader
            customClass={state.applicationStatus === "" ? "" : "hidden"}
          />
          <Header />
          <div className="container">
            {state.applicationStatus !== "" && (
              <h1 id="applicant-greeting">
                Hello,&nbsp;
                <UserName
                  firstname={state.firstname}
                  lastname={state.lastname}
                />
              </h1>
            )}
            <ActiveAndPriorApplications
              contractList={state.contractList}
              archiveList={state.archiveList}
            />
            {/* Invitation Sent to Candidate
          

            //In Progress by Candidate 
            {state.applicationStatus ===
              CONTRACT_STATUS_DESC.APPLICATION_IN_PROGRESS && (
              <ApplicationInProgress contract={state.contractDesc} />
            )}

            //Submitted, Ready for Review, Background Checks, Return to Agency 
            {(state.applicationStatus ===
              CONTRACT_STATUS_DESC.APPLICATION_SUBMITTED ||
              state.applicationStatus === CONTRACT_STATUS_DESC.AGENCY_REVIEW ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.BACKGROUND_CHECK_INITIATED ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.BACKGROUND_CHECK_PENDING ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.BACKGROUND_CHECK_RECEIVED ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.RETURNED_TO_AGENCY) && (
              <ApplicationSubmitted
                contract={state.contractDesc}
                agencynumber={state.agencynumber}
                candidateid={state.candidateid}
                contracttypeid={state.contracttypeid}
              />
            )}

            //Returned to Candidate 
            {state.applicationStatus ===
              CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE && (
              <ApplicationReturned
                contract={state.contractDesc}
                contractid={state.contract_id}
                agencynumber={state.agencynumber}
                candidateid={state.candidateid}
                contracttypeid={state.contracttypeid}
              />
            )}

            //Submitted to HO, Pending Info 
            {(state.applicationStatus ===
              CONTRACT_STATUS_DESC.SUBMITTED_TO_HOME_OFFICE ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.HOME_OFFICE_REVIEW ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.PENDING_ADDITIONAL_INFO_AGENCY ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.PENDING_ADDITIONAL_INFO_ANOTHER_DEPT ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.PENDING_ADDITIONAL_INFO_CANDIDATE ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.PENDING_COMPLIANCE_RSD) && (
              <ApplicationPending
                contract={state.contractDesc}
                agencynumber={state.agencynumber}
                candidateid={state.candidateid}
                contracttypeid={state.contracttypeid}
              />
            )}

            //Declined 
            {(state.applicationStatus ===
              CONTRACT_STATUS_DESC.DECLINED_BY_HOME_OFFICE ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.ACKNOWLEDGED_DECLINED) && (
              <ApplicationDeclined
                contract={state.contractDesc}
                agencynumber={state.agencynumber}
                candidateid={state.candidateid}
                contracttypeid={state.contracttypeid}
              />
            )}

            // Approved 
            {(state.applicationStatus ===
              CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ||
              state.applicationStatus ===
                CONTRACT_STATUS_DESC.ACKNOWLEDGED_APPROVED) && (
              <ApplicationApproved
                contract={state.contractDesc}
                agencynumber={state.agencynumber}
                candidateid={state.candidateid}
                contracttypeid={state.contracttypeid}
              />
            )}

            //Archived 
            {state.prevSub === true &&
              (state.applicationStatus ===
                CONTRACT_STATUS_DESC.ARCHIVE_APPLICATION_WITHDRAWN ||
                state.applicationStatus ===
                  CONTRACT_STATUS_DESC.ARCHIVE_DECLINED_BY_AGENCY) && (
                <ApplicationArchivedSub
                  contract={state.contractDesc}
                  agencynumber={state.agencynumber}
                  candidateid={state.candidateid}
                  contracttypeid={state.contracttypeid}
                />
              )}

            {state.prevSub === false &&
              (state.applicationStatus ===
                CONTRACT_STATUS_DESC.ARCHIVE_APPLICATION_WITHDRAWN ||
                state.applicationStatus ===
                  CONTRACT_STATUS_DESC.ARCHIVE_DECLINED_BY_AGENCY) && (
                <ApplicationArchivedNotSub contract={state.contractDesc} />
              )}

          </div> */}
          </div>
          {state.applicationStatus !== "" && (
            <div className="container">
              <Footer />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default withLDProvider({
  clientSideID: window.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
})(HomePage);
