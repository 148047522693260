/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../../store/actions";
import BackButtonModal from "../../../../../Forms/BackButtonModal";

import "./CourtDetailsForm.scss";

import DRPCardNoButton from "../../../common/DRPCard/DRPCardNoButton";
import DRPSummaryListingCardCheck from "../../../common/DRPSummaryListingCard";
import { headerText as courtDetailsHeader } from "../../constants";

const courtActionType = {
  FEDERAL: "Federal Court",
  STATE: "State Court",
  FOREIGN: "Foreign Court",
  MILITARY: "Military Court",
  OTHER: "Other",
};

const CriminalDisclosureAddDRPCourtDetailsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  values,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);

  const [drpCheckList, setDRPCheckList] = useState(
    data?.drpCheckList?.length ? data?.drpCheckList : []
  );

  const [courtAction, setCourtAction] = useState(data?.courtAction || "");
  const [courtActionOther, setCourtActionOther] = useState(
    data?.courtActionOther || ""
  );
  const [courtActionError, setCourtActionError] = useState(false);
  const [courtName, setCourtName] = useState(data?.courtName || "");
  const [courtNameError, setCourtNameError] = useState(false);
  const [courtLocation, setCourtLocation] = useState(data?.courtLocation || "");
  const [courtLocationError, setCourtLocationError] = useState(false);
  const [docketOrCase, setDocketOrCase] = useState(data?.docketOrCase || "");
  const [docketOrCaseError, setDocketOrCaseError] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  const isError =
    courtActionError ||
    courtNameError ||
    courtLocationError ||
    docketOrCaseError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isNextBtnDisabled = !!(
    !courtName?.length ||
    (courtName?.length && courtName?.length < 2) ||
    (!drpCheckList?.length &&
      headerText === courtDetailsHeader.IND_CRIM_DISCL) ||
    !courtLocation?.length ||
    (courtLocation?.length && courtLocation?.length < 2) ||
    !docketOrCase?.length ||
    (docketOrCase?.length && docketOrCase?.length < 2) ||
    !courtAction?.length ||
    (courtAction?.length &&
      courtAction === courtActionType.OTHER &&
      !courtActionOther?.length) ||
    (courtActionOther?.length && courtActionOther?.length < 2) ||
    isError ||
    isFocusedState
  );

  useEffect(() => {
    if (
      values?.length &&
      values?.length === 1 &&
      headerText === courtDetailsHeader.IND_CRIM_DISCL
    ) {
      setDRPCheckList((drp) => [...drp, ...values.map((v) => v.key)]);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleChangeChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    setCourtActionError(false);

    if (value !== courtActionType.OTHER) {
      setCourtActionOther("");
    }

    setCourtAction(newValue);
  };

  const onChangeCourtNameHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setCourtName(value);
  };

  const onBlurCourtNameHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value?.trim()?.length < 2 || value.trim()?.length > 100
      ? setCourtNameError(true)
      : setCourtNameError(false);
  };

  const onChangeCourtLocationHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setCourtLocation(value);
  };
  const onBlurCourtLocationHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value?.trim()?.length < 2 || value.trim()?.length > 100
      ? setCourtLocationError(true)
      : setCourtLocationError(false);
  };

  const onChangeDocketOrCaseHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDocketOrCase(value);
  };
  const onBlurDocketOrCaseHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value?.trim()?.length < 2 || value.trim()?.length > 100
      ? setDocketOrCaseError(true)
      : setDocketOrCaseError(false);
  };

  const onChangeCourtActionHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setCourtActionOther(value);
  };
  const onBlurCourtActionHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value?.trim()?.length < 2 || value.trim()?.length > 500
      ? setCourtActionError(true)
      : setCourtActionError(false);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: isUpdates }));
  }, [isUpdates, dispatch]);

  const handleShowBackModal = () => {
    if (isUpdates) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ courtDetails: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ courtDetails: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(
    () => {
      const submitData = {
        courtAction,
        courtActionOther,
        courtName,
        courtLocation,
        docketOrCase,
      };

      if (values?.length && headerText === courtDetailsHeader.IND_CRIM_DISCL) {
        submitData.drpCheckList = [...new Set(drpCheckList || [])];
      }

      getSubmitDataValues(
        { [`${groupKey}`]: submitData },
        headerText,
        false,
        [...new Set(submitData?.drpCheckList)] || []
      );

      updateCCBForm(nextFormId);
    } /* eslint-disable-next-line react-hooks/exhaustive-deps */,
    [
      updateCCBForm,
      nextFormId,
      getSubmitDataValues,
      courtAction,
      courtActionOther,
      courtName,
      courtLocation,
      docketOrCase,
      headerText,
      values,
      drpCheckList,
      groupKey,
    ]
  );

  const handleChange = ({ target: { checked, value } }) => {
    const list = [];

    if (checked) {
      list.push(value);

      setDRPCheckList((drp) => [...new Set([...drp, ...list])]);
    }

    setFormHasChanged(true);

    if (!checked) {
      setDRPCheckList((drp) => [
        ...new Set(drp?.filter((item) => item !== value)),
      ]);
    }
  };

  const isValues = values?.length && values.length > 1;

  return (
    <div className="criminal-disclosure-court-details-form-wrapper">
      {!!isValues ? (
        <div className="crim-discl-drp-summary">
          {isValues ? (
            <DRPCardNoButton
              header={"Check the question(s) you are responding to:"}
              isDrp={values?.length > 4}
            >
              {values.map(({ key, mainQuestion, question }, index) => (
                <DRPSummaryListingCardCheck
                  key={`${index}_${key}`}
                  questionKey={key}
                  mainQuestion={mainQuestion}
                  question={question}
                  index={index}
                  length={values.length}
                  handleChange={handleChange}
                  drpCheckList={drpCheckList}
                  isCheckbox
                />
              ))}
            </DRPCardNoButton>
          ) : null}
        </div>
      ) : null}

      <div className="row">
        <div className="col-md-12">
          <label className="court-action">Court action brought in: *</label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="federalCourt"
                type="radio"
                id="federal"
                className="custom-control-input"
                value={courtActionType.FEDERAL}
                checked={courtAction === courtActionType.FEDERAL}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="federal"
                className="custom-control-label"
                id="federalLabelId"
              >
                {courtActionType.FEDERAL}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="stateCourt"
                type="radio"
                id="stateCourtId"
                className="custom-control-input"
                value={courtActionType.STATE}
                checked={courtAction === courtActionType.STATE}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="stateCourtId"
                className="custom-control-label"
                id="stateCourtLabelId"
              >
                {courtActionType.STATE}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="foreignCourt"
                type="radio"
                id="foreignCourtId"
                className="custom-control-input"
                value={courtActionType.FOREIGN}
                checked={courtAction === courtActionType.FOREIGN}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="foreignCourtId"
                className="custom-control-label"
                id="foreignCourtLabelId"
              >
                {courtActionType.FOREIGN}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="militaryCourt"
                type="radio"
                id="militaryCourtId"
                className="custom-control-input"
                value={courtActionType.MILITARY}
                checked={courtAction === courtActionType.MILITARY}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="militaryCourtId"
                className="custom-control-label"
                id="militaryCourtLabelId"
              >
                {courtActionType.MILITARY}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="otherRadio"
                type="radio"
                id="otherRadioId"
                className="custom-control-input"
                value={courtActionType.OTHER}
                checked={courtAction === courtActionType.OTHER}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="otherRadioId"
                className="custom-control-label"
                id="otherRadioLabelId"
              >
                {courtActionType.OTHER}
              </label>
            </div>

            {courtAction === courtActionType.OTHER ? (
              <div className="court-action-other">
                <input
                  type="text"
                  id="otherText"
                  className={
                    courtActionError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={courtActionOther}
                  onChange={onChangeCourtActionHandler}
                  onBlur={onBlurCourtActionHandler}
                  onFocus={() => setFocusedState(true)}
                />
                <div id="courtActionError" className="invalid-feedback">
                  Please enter a response
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6" style={{ minWidth: "430px" }}>
          <label
            className="court-name"
            htmlFor="courtName"
            id="courtNameLabelId"
          >
            Name of court: *
          </label>
          <input
            type="text"
            id="courtName"
            className={
              courtNameError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            minLength={2}
            autoComplete="off"
            value={courtName}
            onChange={onChangeCourtNameHandler}
            onBlur={onBlurCourtNameHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="courtNameError" className="invalid-feedback">
            Please enter Name of court
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6" style={{ minWidth: "430px" }}>
          <label
            className="court-location"
            htmlFor="courtLocationId"
            id="courtLocationLabelId"
          >
            Location of court (City or County and State or Country): *
          </label>
          <input
            type="text"
            id="courtLocationId"
            className={
              courtLocationError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            minLength={2}
            autoComplete="off"
            value={courtLocation}
            onChange={onChangeCourtLocationHandler}
            onBlur={onBlurCourtLocationHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="courtLocationError" className="invalid-feedback">
            Please enter Location of court
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6" style={{ minWidth: "430px" }}>
          <label
            className="docker-case"
            htmlFor="docketCaseId"
            id="docketCaseLabelId"
          >
            Docket/Case #: *
          </label>
          <input
            type="text"
            id="docketCaseId"
            className={
              docketOrCaseError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            max={100}
            minLength={2}
            autoComplete="off"
            value={docketOrCase}
            onChange={onChangeDocketOrCaseHandler}
            onBlur={onBlurDocketOrCaseHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="docketOrCaseError" className="invalid-feedback">
            Please enter Docket/Case #
          </div>
        </div>
      </div>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span role="presentation" onClick={handleShowBackModal}>
            <strong id="crimDisclBack">Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CriminalDisclosureAddDRPCourtDetailsForm;
