import React, { useEffect } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch, useSelector } from "react-redux";
import { loadingStatus } from "../../../../../../store/state";

import { ccbActions } from "../../../../../../store/actions";
import "./DRPLayout.scss";

const DisclosureReportingPageLayout = ({
  header,
  backFormId,
  nextFormId,
  updateCCBForm,
  updateCCB14CompletedInd,
  toggleForm,
  children,
  ind,
  isLoading: isLoadingProp,
  isNextDisabled = false,
}) => {
  const { loading } = useSelector((state) => state.ccb14);
  const dispatch = useDispatch();

  const isLoading = loading === loadingStatus.PENDING || isLoadingProp;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackClick = () => {
    updateCCBForm(backFormId, { isBackClick: true });
  };

  const handleNext = () => {
    const obj_error = document.getElementById("ccbNextError");

    if (ind === "finDisclosureCompleteInd") {
      dispatch(ccbActions.setIsBack(true));
    } else {
      document.getElementById("ccbNext").disabled = false;
      obj_error.style.display = "none";
      dispatch(ccbActions.setIsBack(false));
    }

    updateCCB14CompletedInd({ [ind]: true });
    toggleForm(nextFormId);
  };

  return (
    <div className="drp-summary-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <div className="eyebrow">
            <strong id={`eyebrow-${ind}`}>
              Uniform Application for service Industry or Transfer
            </strong>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h1 id={`header-${ind}`}>{header}</h1>
          <h2 id={`"ccb_intro_rev_${ind}`} style={{ color: "black" }}>
            Disclosure Reporting Pages (DRPs)
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <p id={`psr_intro_text_${ind}`}>
            Based on the “Yes” answers you provided in the previous step, you
            are required to provide details of all events or proceedings on the
            appropriate Disclosure Reporting Page(s).
          </p>
        </div>
      </div>

      {children}

      <div className="row" style={{ paddingBottom: "20px" }}>
        <div className="col-md-12 buttons">
          <span
            role="presentation"
            onClick={handleBackClick}
            style={{
              marginRight: "25px",
              color: "#3174af",
              cursor: "pointer",
            }}
          >
            <strong>Back</strong>
          </span>
          <Button
            id="ccbNext"
            label="Next"
            onClick={handleNext}
            disabled={!!isNextDisabled}
          />

          {isLoading && <span className="mm-dots-loading"></span>}
        </div>
      </div>
      <div
        id="ccbNextError"
        className="invalid-feedback"
        style={{ textAlign: "right" }}
      >
        Please assign a DRP to each question
      </div>
    </div>
  );
};

export default DisclosureReportingPageLayout;
