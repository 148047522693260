/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import InputMask from "react-input-mask";
import { dateInputMask, eventStatusDateType } from "../../../../constants";

import BackButtonModal from "../../../../../../Forms/BackButtonModal";

const initialValues = {
  sanctionType: null,
  regCapacitiesAffectedError: null,
  startDate: null,
  startDateRadio: null,
  startDateExp: null,
  endDate: null,
  endDateRadio: null,
  endDateExp: null,
  duration: null,
};

const durationTypes = {
  EXACT: "Exact",
  EXP: "Explanation",
};

const sanctionTypes = {
  BAR: "Bar (Permanent)",
  BAR_TEMP: "Bar (Temporary/Time Limited)",
  SUSP: "Suspension",
};

const formalChargeStyle = { height: "80px", minHeight: "80px" };

const AddNewSanctionContent = ({
  data,
  getSubmitDataValues,
  setShowAddNewChargeModal,
  setIsModalUpdates,
  dataIndex,
  headerText,
}) => {
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [showBackModal, setShowBackModal] = useState(false);

  const [sanctionType, setSanctionType] = useState(data?.sanctionType || "");

  const [regCapacitiesAffected, setRegCapacitiesAffected] = useState(
    data?.regCapacitiesAffected || ""
  );
  const [regCapacitiesAffectedError, setRegCapacitiesAffectedError] =
    useState(false);

  const [startDate, setStartDate] = useState(data?.startDate || "");
  const [startDateError, setStartDateError] = useState(false);
  const [startDateRadio, setStartDateRadio] = useState(
    data?.startDateRadio || ""
  );
  const [startDateExp, setStartDateExp] = useState(data?.startDateExp || "");
  const [startDateExpError, setStartDateExpError] = useState(false);
  const [startDateErrorMsg, setStartDateErrorMsg] = useState("");

  const [endDate, setEndDate] = useState(data?.endDate || "");
  const [endDateError, setEndDateError] = useState(false);
  const [endDateRadio, setEndDateRadio] = useState(data?.endDateRadio || "");
  const [endDateExp, setEndDateExp] = useState(data?.endDateExp || "");
  const [endDateExpError, setEndDateExpError] = useState(false);
  const [endDateErrorMsg, setEndDateErrorMsg] = useState("");

  const [duration, setDuration] = useState(data?.duration || "");
  const [durationError, setDurationError] = useState(false);

  const [durationRadio, setDurationRadio] = useState(data?.durationRadio || "");
  const [durationExp, setDurationExp] = useState(data?.durationExp || "");
  const [durationExpError, setDurationExpError] = useState(false);

  const [isStartDateNextDisabled, setIsStartDateNextDisabled] = useState(false);
  const [isEndDateNextDisabled, setIsEndDateNextDisabled] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  const handleCloseBackModal = () => {
    setShowBackModal(false);
    setShowAddNewChargeModal(true);
  };

  const isError =
    durationError ||
    startDateError ||
    startDateExpError ||
    endDateError ||
    endDateExpError ||
    durationExpError ||
    regCapacitiesAffectedError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isDuration =
    durationRadio.length ||
    (durationRadio.length &&
      durationRadio === durationTypes.EXP &&
      durationExp.length);

  const isStartDate =
    (startDate.length && startDate !== dateInputMask.EMPTY) ||
    startDateRadio.length ||
    (startDateRadio.length &&
      startDateRadio === eventStatusDateType.EXPLANATION &&
      startDateExp.length);

  const isEndDate =
    (endDate.length && endDate !== dateInputMask.EMPTY) ||
    endDateRadio.length ||
    (endDateRadio.length &&
      endDateRadio === eventStatusDateType.EXPLANATION &&
      endDateExp.length);

  const isUpdates =
    (data?.length === 0 &&
      (isDuration ||
        isEndDate ||
        isStartDate ||
        regCapacitiesAffected.length ||
        sanctionType?.length)) ||
    formHasChanged ||
    isError;

  useEffect(() => {
    if (isUpdates || isError) {
      setIsModalUpdates(true);
    } else {
      setIsModalUpdates(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdates, isError]);

  const isStartDateDisabled =
    !startDate?.length ||
    startDate.includes("_") ||
    startDate === dateInputMask.EMPTY ||
    (!startDate?.length && !startDateRadio?.length) ||
    !startDateRadio?.length ||
    !startDate?.length ||
    (startDate?.length &&
      startDateRadio?.length &&
      startDateRadio === eventStatusDateType.EXPLANATION &&
      !startDateExp?.length) ||
    (startDateExp?.length && startDateExp?.length < 2);

  const isEndDateDisabled =
    !endDate?.length ||
    endDate.includes("_") ||
    endDate === dateInputMask.EMPTY ||
    (!endDate?.length && !endDateRadio?.length) ||
    !endDateRadio?.length ||
    !endDate?.length ||
    (endDate?.length &&
      endDateRadio?.length &&
      endDateRadio === eventStatusDateType.EXPLANATION &&
      !endDateExp?.length) ||
    (endDateExp?.length && endDateExp?.length < 2);

  const isNextDisabledFirstForm = !!(
    isStartDateDisabled ||
    isEndDateDisabled ||
    !sanctionType?.length ||
    !regCapacitiesAffected?.length ||
    (regCapacitiesAffected.length && regCapacitiesAffected.length < 1)
  );

  const isNextDisabled =
    isFocusedState ||
    isStartDateNextDisabled ||
    isEndDateNextDisabled ||
    isNextDisabledFirstForm ||
    isError ||
    !duration?.length ||
    !durationRadio?.length ||
    (duration.length && duration?.length < 2) ||
    (duration?.length &&
      durationRadio?.length &&
      durationRadio === durationTypes.EXP &&
      !durationExp?.length);

  const handleChangeReducedChecked = (e) => {
    const { value } = e.target;
    setFormHasChanged(true);
    let newValue = value;

    setSanctionType(newValue);
  };

  const onRegCapacitiesAffectedChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setRegCapacitiesAffected(value);
  };
  const onBlurRegCapacitiesAffectedHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 1 || value.trim().length > 200
      ? setRegCapacitiesAffectedError(true)
      : setRegCapacitiesAffectedError(false);
  };

  const handleChangeStartDateChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setStartDateExpError(false);
    setFormHasChanged(true);
    if (
      startDateExp?.length &&
      startDateRadio !== eventStatusDateType.EXPLANATION
    ) {
      setStartDateExp("");
    }

    setStartDateRadio(newValue);
  };

  const onStartDateChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setStartDate(value);

    if (value?.length === 10 && !value.includes("_") && !startDateError) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));
      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsStartDateNextDisabled(true);
        } else {
          setIsStartDateNextDisabled(false);
        }
      } else {
        setIsStartDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsStartDateNextDisabled(true);
        } else {
          setIsStartDateNextDisabled(false);
        }
      } else {
        setIsStartDateNextDisabled(true);
      }
    }
  };

  const onStartDateExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setStartDateExp(value);
  };

  const onBlurStartDateExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setStartDateExpError(true)
      : setStartDateExpError(false);
  };

  const onStartDateBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setFocusedState(false);
    setIsStartDateNextDisabled(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setStartDateError(true);
      } else {
        setStartDateError(false);
      }
    } else {
      setStartDateError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setStartDateErrorMsg("Date cannot be a future date");
        setStartDateError(true);
      } else if (date < past) {
        setStartDateErrorMsg("Date cannot be more than 100 years ago");
        setStartDateError(true);
      } else {
        setStartDateError(false);
      }
    } else {
      setStartDateErrorMsg("Please enter a valid date");
      setStartDateError(true);
    }
  };

  const handleShowBackModal = () => {
    if (isUpdates || isError) {
      setShowBackModal(true);
      setFormHasChanged(isUpdates);
    } else {
      getSubmitDataValues(
        {
          ...initialValues,
        },
        headerText
      );

      setShowAddNewChargeModal(false);
    }
  };

  const handleChangeEndDateChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setEndDateExpError(false);
    setFormHasChanged(true);
    if (
      endDateExp?.length &&
      endDateRadio !== eventStatusDateType.EXPLANATION
    ) {
      setEndDateExp("");
    }

    setEndDateRadio(newValue);
  };

  const onEndDateChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setEndDate(value);

    if (value?.length === 10 && !value.includes("_") && !endDateError) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));
      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsEndDateNextDisabled(true);
        } else {
          setIsEndDateNextDisabled(false);
        }
      } else {
        setIsEndDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsEndDateNextDisabled(true);
        } else {
          setIsEndDateNextDisabled(false);
        }
      } else {
        setIsEndDateNextDisabled(true);
      }
    }
  };

  const onEndDateExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setEndDateExp(value);
  };

  const onBlurEndDateExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setEndDateExpError(true)
      : setEndDateExpError(false);
  };

  const onEndDateBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setFocusedState(false);
    setIsEndDateNextDisabled(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setEndDateError(true);
      } else {
        setEndDateError(false);
      }
    } else {
      setEndDateError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setEndDateErrorMsg("Date cannot be a future date");
        setEndDateError(true);
      } else if (date < past) {
        setEndDateErrorMsg("Date cannot be more than 100 years ago");
        setEndDateError(true);
      } else {
        setEndDateError(false);
      }
    } else {
      setEndDateErrorMsg("Please enter a valid date");
      setEndDateError(true);
    }
  };

  const onChangeDurationHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDuration(value);
  };
  const onBlurDurationHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setDurationError(true)
      : setDurationError(false);
  };

  const handleChangeDurationChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setDurationExpError(false);
    setFormHasChanged(true);
    if (durationExp?.length && durationRadio !== durationTypes.EXP) {
      setDurationExp("");
    }

    setDurationRadio(newValue);
  };

  const onDurationExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDurationExp(value);
  };

  const onBlurDurationExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setDurationExpError(true)
      : setDurationExpError(false);
  };

  const handleBackClick = () => {
    getSubmitDataValues({ ...initialValues }, headerText);
    handleCloseBackModal();
    setShowAddNewChargeModal(false);
  };

  const handleAddSanction = useCallback(() => {
    const submitData = {
      sanctionType,
      regCapacitiesAffected,
      startDate,
      startDateRadio,
      startDateExp,
      endDate,
      endDateRadio,
      endDateExp,
      duration,
      durationRadio,
      durationExp,
    };

    getSubmitDataValues({ ...submitData }, dataIndex);

    setFormHasChanged(false);

    setShowAddNewChargeModal(false);
  }, [
    getSubmitDataValues,
    setShowAddNewChargeModal,
    sanctionType,
    regCapacitiesAffected,
    startDate,
    startDateRadio,
    startDateExp,
    endDate,
    endDateRadio,
    endDateExp,
    duration,
    dataIndex,
    durationRadio,
    durationExp,
  ]);

  return (
    <div className="add-new-charge-children-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label
            className="add-sanct-sanc-type-label"
            id="regSanctionTypeLabelId"
          >
            Sanction Type: *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="regPermBar"
                type="radio"
                id="regPermBar"
                className="custom-control-input"
                value={sanctionTypes.BAR}
                checked={sanctionType === sanctionTypes.BAR}
                onChange={handleChangeReducedChecked}
              />
              <label
                htmlFor="regPermBar"
                className="custom-control-label"
                id="regPermBarLabelId"
              >
                {sanctionTypes.BAR}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="regPermBar"
                type="radio"
                id="regTempBar"
                className="custom-control-input"
                value={sanctionTypes.BAR_TEMP}
                checked={sanctionType === sanctionTypes.BAR_TEMP}
                onChange={handleChangeReducedChecked}
              />
              <label
                htmlFor="regTempBar"
                className="custom-control-label"
                id="regTempBarLabelId"
              >
                {sanctionTypes.BAR_TEMP}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="regSuspended"
                type="radio"
                id="regSuspended"
                className="custom-control-input"
                value={sanctionTypes.SUSP}
                checked={sanctionType === sanctionTypes.SUSP}
                onChange={handleChangeReducedChecked}
              />
              <label
                htmlFor="regSuspended"
                className="custom-control-label"
                id="regSuspendedLabelId"
              >
                {sanctionTypes.SUSP}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="formal-charge-container">
        <label
          htmlFor="regCapAffectedId"
          id="regCapAffectedLabelId"
          className="formal-charge-label"
        >
          Registration Capacities Affected (e.g. General Securities Principal,
          Financial Operations Principal, All Capacities, etc): *
        </label>
        <textarea
          id="regCapAffectedId"
          style={{ ...formalChargeStyle }}
          onChange={onRegCapacitiesAffectedChangeHandler}
          className={
            regCapacitiesAffectedError
              ? "form-control form-control-15 is-invalid"
              : "form-control form-control-15 formal-charge-id"
          }
          minLength={1}
          maxLength={200}
          rows="3"
          cols={500}
          onBlur={onBlurRegCapacitiesAffectedHandler}
          onFocus={() => setFocusedState(true)}
          value={regCapacitiesAffected}
        />
        <div id="regCapacitiesAffectedError" className="invalid-feedback">
          Please enter a response
        </div>
      </div>

      <div className="col-md-8 event-status-date-wrapper">
        <label id="durationLabelId" htmlFor="durationId">
          <strong id="durationText">Duration (Length of time): *</strong>
        </label>
        <input
          type="text"
          id="durationId"
          className={
            durationError
              ? "form-control form-control-15 is-invalid"
              : "form-control form-control-15"
          }
          style={{ width: "275px" }}
          maxLength={100}
          minLength={2}
          autoComplete="off"
          value={duration}
          onChange={onChangeDurationHandler}
          onBlur={onBlurDurationHandler}
          onFocus={() => setFocusedState(true)}
        />
        <div id="durationError" className="invalid-feedback">
          Please enter a response
        </div>
      </div>

      <div className="row event-status-date-radio-group">
        <div className="custom-control">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-durationExactId"
                type="radio"
                id="durationExactId"
                className="custom-control-input"
                value={durationTypes.EXACT}
                checked={durationRadio === durationTypes.EXACT}
                onChange={handleChangeDurationChecked}
              />
              <label
                htmlFor="durationExactId"
                className="custom-control-label"
                id="durationExactLabelId"
              >
                {durationTypes.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-durationExplanationId"
                type="radio"
                id="durationExplanationId"
                className="custom-control-input"
                value={durationTypes.EXP}
                checked={durationRadio === durationTypes.EXP}
                onChange={handleChangeDurationChecked}
              />
              <label
                htmlFor="durationExplanationId"
                className="custom-control-label"
                id="durationExplanationLabelId"
              >
                {durationTypes.EXP}
              </label>
            </div>

            {durationRadio === durationTypes.EXP ? (
              <div className="event-status-date-explanation-container">
                <label
                  htmlFor="durationExplanation"
                  className="event-status-date-exp"
                  id="durationExplanationTextLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="durationExplanation"
                  onChange={onDurationExplanationChange}
                  className={
                    durationExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurDurationExpHandler}
                  onFocus={() => setFocusedState(true)}
                  rows="3"
                  cols={500}
                  value={durationExp}
                />
                <div id="durationExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="col-md-12 event-status-date-wrapper"
        style={{ marginTop: 0 }}
      >
        <label id="startDateLabelId" htmlFor="startDateId">
          <strong id="startDateText">Start Date: *</strong>
        </label>
        <InputMask
          id="startDateId"
          mask="99/99/9999"
          maskChar="_"
          className={`${
            startDateError ? "form-control is-invalid" : "form-control"
          } event-status-date-input`}
          placeholder="MM/DD/YYYY"
          autoComplete="off"
          required
          value={startDate}
          onChange={onStartDateChangeHandler}
          onBlur={onStartDateBlurHandler}
          onFocus={() => setFocusedState(true)}
        />
        <div id="startDateError" className="invalid-feedback">
          {startDateErrorMsg?.length
            ? startDateErrorMsg
            : "Please enter a valid Start Date"}
        </div>
      </div>

      <div className="row event-status-date-radio-group">
        <div className="custom-control">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-startDateExactId"
                type="radio"
                id="startDateExactId"
                className="custom-control-input"
                value={eventStatusDateType.EXACT}
                checked={startDateRadio === eventStatusDateType.EXACT}
                onChange={handleChangeStartDateChecked}
              />
              <label
                htmlFor="startDateExactId"
                className="custom-control-label"
                id="startDateExactLabelId"
              >
                {eventStatusDateType.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-startDateExplanationId"
                type="radio"
                id="startDateExplanationId"
                className="custom-control-input"
                value={eventStatusDateType.EXPLANATION}
                checked={startDateRadio === eventStatusDateType.EXPLANATION}
                onChange={handleChangeStartDateChecked}
              />
              <label
                htmlFor="startDateExplanationId"
                className="custom-control-label"
                id="startDateExplanationLabelId"
              >
                {eventStatusDateType.EXPLANATION}
              </label>
            </div>
            {startDateRadio === eventStatusDateType.EXPLANATION ? (
              <div className="event-status-date-explanation-container">
                <label
                  htmlFor="startDateExplanation"
                  className="event-status-date-exp"
                  id="startDateExplanationTextLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="startDateExplanation"
                  onChange={onStartDateExplanationChange}
                  className={
                    startDateExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurStartDateExpHandler}
                  onFocus={() => setFocusedState(true)}
                  rows="3"
                  cols={500}
                  value={startDateExp}
                />
                <div id="startDateExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="col-md-12 event-status-date-wrapper"
        style={{ marginTop: 0 }}
      >
        <label id="endDateLabelId" htmlFor="endDateId">
          <strong id="endDateText">End Date: *</strong>
        </label>
        <InputMask
          id="endDateId"
          mask="99/99/9999"
          maskChar="_"
          className={`${
            endDateError ? "form-control is-invalid" : "form-control"
          } event-status-date-input`}
          placeholder="MM/DD/YYYY"
          autoComplete="off"
          required
          value={endDate}
          onChange={onEndDateChangeHandler}
          onBlur={onEndDateBlurHandler}
          onFocus={() => setFocusedState(true)}
        />
        <div id="endDateError" className="invalid-feedback">
          {endDateErrorMsg?.length
            ? endDateErrorMsg
            : "Please enter a valid End Date"}
        </div>
      </div>

      <div className="row event-status-date-radio-group">
        <div className="custom-control">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-endDateExactId"
                type="radio"
                id="endDateExactId"
                className="custom-control-input"
                value={eventStatusDateType.EXACT}
                checked={endDateRadio === eventStatusDateType.EXACT}
                onChange={handleChangeEndDateChecked}
              />
              <label
                htmlFor="endDateExactId"
                className="custom-control-label"
                id="endDateExactLabelId"
              >
                {eventStatusDateType.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-endDateExplanationId"
                type="radio"
                id="endDateExplanationId"
                className="custom-control-input"
                value={eventStatusDateType.EXPLANATION}
                checked={endDateRadio === eventStatusDateType.EXPLANATION}
                onChange={handleChangeEndDateChecked}
              />
              <label
                htmlFor="endDateExplanationId"
                className="custom-control-label"
                id="endDateExplanationLabelId"
              >
                {eventStatusDateType.EXPLANATION}
              </label>
            </div>
            {endDateRadio === eventStatusDateType.EXPLANATION ? (
              <div className="event-status-date-explanation-container">
                <label
                  htmlFor="endDateExplanation"
                  className="event-status-date-exp"
                  id="endDateExplanationTextLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="endDateExplanation"
                  onChange={onEndDateExplanationChange}
                  className={
                    endDateExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurEndDateExpHandler}
                  onFocus={() => setFocusedState(true)}
                  rows="3"
                  cols={500}
                  value={endDateExp}
                />
                <div id="endDateExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row drp-add-new-charge-btns">
        <div className="col-md-12 buttons">
          <span
            onClick={handleShowBackModal}
            style={{
              marginRight: "25px",
              color: "#3174af",
              cursor: "pointer",
            }}
            role="presentation"
          >
            <strong>Cancel</strong>
          </span>

          <Button
            className="addChargeButton"
            label={dataIndex !== null ? "Save" : "Add Sanction"}
            onClick={handleAddSanction}
            disabled={isNextDisabled}
          />

          {false && <span className="mm-dots-loading"></span>}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default AddNewSanctionContent;
