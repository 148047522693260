/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button } from "react-blueprint-library";
import InputMask from "react-input-mask";
import {
  dateValidator,
  formatMoney,
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../../common/Functions";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import "./BondDisclosureDispositionDetailsForm.scss";

const dispositionOptions = {
  DENIED: "Denied",
  PAYOUT: "Payout",
  REVOKED: "Revoked",
};

const dateOptions = {
  EXACT: "Exact",
  EXPLANATION: "Explanation",
};

const BondAddDRPDispositionDetails = ({
  updateCCBForm,
  nextFormId,
  backFormId,
  updateTempDRP,
  tempBondDRP,
}) => {
  const [dispositionType, setDispositionType] = useState(
    tempBondDRP?.dispositionType || ""
  );
  const [dispositionTypeError, setDispositionTypeError] = useState(false);
  const [dispositionDate, setDispositionDate] = useState(
    tempBondDRP?.dispositionDate || ""
  );
  const [dispositionDateError, setDispositionDateError] = useState(false);
  const [dispositionDateErrorText, setDispositionDateErrorText] = useState("");
  const [dispositionOption, setDispositionOption] = useState(
    tempBondDRP?.dispositionOption || ""
  );
  const [dispositionOptionError, setDispositionOptionError] = useState(false);
  const [dispositionDateExplanation, setDispositionDateExplanation] = useState(
    tempBondDRP?.dispositionDateExplanation || ""
  );
  const [dispositionDateExplanationError, setDispositionDateExplanationError] =
    useState(false);
  const [payoutAmount, setPayoutAmount] = useState(
    tempBondDRP?.payoutAmount || ""
  );
  const [payoutAmountError, setPayoutAmountError] = useState(false);
  const [payoutAmountErrorMsg, setPayoutAmountErrorMsg] = useState(false);
  const [payoutDate, setPayoutDate] = useState(tempBondDRP?.payoutDate || "");
  const [payoutDateError, setPayoutDateError] = useState(false);
  const [payoutDateErrorText, setPayoutDateErrorText] = useState("");
  const [payoutOption, setPayoutOption] = useState(
    tempBondDRP?.payoutOption || ""
  );
  const [payoutOptionError, setPayoutOptionError] = useState(false);
  const [payoutDateExplanation, setPayoutDateExplanation] = useState(
    tempBondDRP?.payoutDateExplanation || ""
  );
  const [payoutDateExplanationError, setPayoutDateExplanationError] =
    useState(false);
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  const handleToggleBackModal = (value) => {
    toggleShowBackModal(value);
  };
  const handleNext = () => {
    const tmpDRP = {
      ...tempBondDRP,
      dispositionType,
      dispositionDate,
      dispositionOption,
      dispositionDateExplanation,
      payoutAmount,
      payoutDate,
      payoutOption,
      payoutDateExplanation,
    };
    updateTempDRP(tmpDRP);
    updateCCBForm(nextFormId);
  };
  const handleBack = () => {
    updateCCBForm(backFormId);
  };
  const handleDispositionTypeChange = ({ target: { value } }) => {
    setDispositionType(value);
    if (value !== dispositionOptions.PAYOUT) {
      setPayoutAmount("");
      setPayoutAmountError(false);
      setPayoutDate("");
      setPayoutDateError(false);
      setPayoutOption("");
      setPayoutOptionError(false);
      setPayoutDateExplanation("");
      setPayoutDateExplanationError(false);
    }
  };
  const handleDateChange = ({ target: { id, value } }) => {
    switch (id) {
      case "dispositionDateId":
        setDispositionDate(value);
        break;
      case "payoutDateId":
        setPayoutDate(value);
        break;
      default:
        break;
    }
  };
  const handleDispositionDateOptionChange = ({ target: { value } }) => {
    setDispositionOption(value);
    if (value === dateOptions.EXACT) {
      setDispositionDateExplanation("");
      setDispositionDateExplanationError(false);
    }
  };
  const handleTextInputChange = ({ target: { id, value } }) => {
    switch (id) {
      case "dispositionDateExplanation":
        setDispositionDateExplanation(value);
        break;
      case "payoutDateExplanation":
        setPayoutDateExplanation(value);
        break;
      case "payoutAmount":
        setPayoutAmount(formatMoney(value));
        break;
      default:
        break;
    }
  };
  const handlePayoutDateOptionChange = ({ target: { value } }) => {
    setPayoutOption(value);
    if (value === dateOptions.EXACT) {
      setPayoutDateExplanation("");
      setPayoutDateExplanationError(false);
    }
  };

  const onBlurTextInput = ({ target: { id, value } }) => {
    setFocusedState(false);
    switch (id) {
      case "dispositionDateExplanation":
        setDispositionDateExplanationError(value.length < 2);
        break;
      case "payoutDateExplanation":
        setPayoutDateExplanationError(value.length < 2);
        break;
      case "payoutAmount":
        const isMoneyError =
          parseInt(value?.replaceAll(",", "")) < 1 ||
          parseInt(value?.replaceAll(",", "")) > 100000000 ||
          value?.length < 1;
        const moneyErrMessage =
          parseInt(value?.replaceAll(",", "")) > 100000000 ||
          parseInt(value?.replaceAll(",", "")) < 1
            ? "Please enter a valid number"
            : value?.length < 1
            ? "Please enter Payout Amount"
            : "";
        setPayoutAmountError(isMoneyError);
        setPayoutAmountErrorMsg(moneyErrMessage);
        break;
      default:
        break;
    }
  };

  const onBlurDateInput = ({ target: { id, value } }) => {
    const { isInvalid, errMessage } = dateValidator(value);
    setFocusedState(false);
    switch (id) {
      case "dispositionDateId":
        setDispositionDateError(isInvalid);
        setDispositionDateErrorText(errMessage);
        break;
      case "payoutDateId":
        setPayoutDateError(isInvalid);
        setPayoutDateErrorText(errMessage);
        break;
      default:
        break;
    }
  };

  const isError =
    dispositionTypeError ||
    dispositionDateError ||
    dispositionOptionError ||
    dispositionDateExplanationError ||
    payoutAmountError ||
    payoutDateError ||
    payoutDateExplanationError ||
    payoutOptionError;

  const isUpdates =
    dispositionDate?.length ||
    dispositionOption?.length ||
    dispositionDateExplanation?.length ||
    dispositionOption?.length ||
    dispositionType?.length ||
    payoutAmount?.length ||
    payoutDate?.length ||
    payoutOption?.length ||
    payoutDateExplanation?.length ||
    isError;

  const isNextDisabled =
    dispositionDate?.length < 1 ||
    dispositionDate.includes("_") ||
    dateValidator(dispositionDate).isInvalid ||
    dispositionOption?.length < 1 ||
    (dispositionOption === dateOptions.EXPLANATION &&
      dispositionDateExplanation?.length < 2) ||
    dispositionType?.length < 1 ||
    (dispositionType === dispositionOptions.PAYOUT &&
      (payoutAmount?.length < 1 ||
        payoutDate?.length < 2 ||
        payoutDate.includes("_") ||
        dateValidator(payoutDate).isInvalid ||
        payoutOption?.length < 1 ||
        (payoutOption === dateOptions.EXPLANATION &&
          payoutDateExplanation?.length < 2))) ||
    isError ||
    isFocusedState;

  return (
    <div className="bond-disposition-details-form-wrapper">
      <br />
      <div className="row">
        <div className="col-md-12">
          <label id="dispositionTypeLabelId" className="field-label">
            Disposition Type: *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="dispositionTypeDenied"
                type="radio"
                id="dispositionTypeDenied"
                className="custom-control-input"
                value={dispositionOptions.DENIED}
                checked={dispositionType === dispositionOptions.DENIED}
                onChange={handleDispositionTypeChange}
              />
              <label
                id="dispositionTypeDeniedLabelId"
                htmlFor="dispositionTypeDenied"
                className="custom-control-label"
              >
                {dispositionOptions.DENIED}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="dispositionTypePayout"
                type="radio"
                id="dispositionTypePayout"
                className="custom-control-input"
                value={dispositionOptions.PAYOUT}
                checked={dispositionType === dispositionOptions.PAYOUT}
                onChange={handleDispositionTypeChange}
              />
              <label
                id="dispositionTypePayoutLabelId"
                htmlFor="dispositionTypePayout"
                className="custom-control-label"
              >
                {dispositionOptions.PAYOUT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="dispositionTypeRevoked"
                type="radio"
                id="dispositionTypeRevoked"
                className="custom-control-input"
                value={dispositionOptions.REVOKED}
                checked={dispositionType === dispositionOptions.REVOKED}
                onChange={handleDispositionTypeChange}
              />
              <label
                id="dispositionTypeRevokedLabelId"
                htmlFor="dispositionTypeRevoked"
                className="custom-control-label"
              >
                {dispositionOptions.REVOKED}
              </label>
            </div>
            <div id="dispositionTypeError" className="invalid-feedback">
              Please enter a Disposition Type
            </div>
          </div>
        </div>
      </div>
      <div className="row date-wrapper">
        <div className="col-md-4">
          <label
            id="dispositionDateLabelId"
            className="field-label"
            htmlFor="dispositionDateId"
          >
            Disposition Date: *
          </label>
          <InputMask
            id="dispositionDateId"
            mask="99/99/9999"
            maskChar="_"
            className={`${
              dispositionDateError ? "form-control is-invalid" : "form-control"
            } disposition-date-input`}
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={dispositionDate}
            onChange={handleDateChange}
            onBlur={onBlurDateInput}
            onFocus={() => setFocusedState(true)}
          />
          <div id="dispositionDateError" className="invalid-feedback">
            {dispositionDateErrorText}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="dispositionDateExact"
                type="radio"
                id="dispositionDateExact"
                className="custom-control-input"
                value={dateOptions.EXACT}
                checked={dispositionOption === dateOptions.EXACT}
                onChange={handleDispositionDateOptionChange}
              />
              <label
                htmlFor="dispositionDateExact"
                className="custom-control-label"
                id="dispositionDateExactLabel"
              >
                {dateOptions.EXACT} date
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="dispositionDateExplanation"
                type="radio"
                id="dispositionDateExplanation"
                className="custom-control-input"
                value={dateOptions.EXPLANATION}
                checked={dispositionOption === dateOptions.EXPLANATION}
                onChange={handleDispositionDateOptionChange}
              />
              <label
                htmlFor="dispositionDateExplanation"
                className="custom-control-label"
                id="dispositionDateExplanationLabel"
              >
                {dateOptions.EXPLANATION}
              </label>
            </div>
            {dispositionOption === dateOptions.EXPLANATION ? (
              <div className="disposition-date-explanation-container">
                <label
                  htmlFor="dispositionDateExplanation"
                  className="field-label"
                  id="dispositionDateExplanationLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="dispositionDateExplanation"
                  className={
                    dispositionDateExplanationError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  rows="3"
                  cols={500}
                  value={dispositionDateExplanation}
                  onChange={handleTextInputChange}
                  onBlur={onBlurTextInput}
                  onFocus={() => setFocusedState(true)}
                />
                <div
                  id="dispositionDateExplanationError"
                  className="invalid-feedback"
                >
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {dispositionType === dispositionOptions.PAYOUT ? (
        <>
          <hr />
          <div className="row">
            <div className="col-md-12 info-label" id="bondPayoutText">
              If the disposition resulted in a Payout, provide:
            </div>
          </div>
          <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-md-4">
              <label id="payoutAmountLabel" className="field-label">
                Payout Amount *
              </label>
              <div
                className={
                  payoutAmountError ? "input-group is-invalid" : "input-group"
                }
                id="payoutAmountInput"
              >
                <div className="input-group-prepend">
                  <span
                    className="input-group-text input-group-text-pad-right"
                    id="payoutAmount$"
                  >
                    $
                  </span>
                </div>
                <input
                  type="text"
                  id="payoutAmount"
                  className="form-control form-control-15"
                  spellCheck="false"
                  maxLength={11}
                  value={payoutAmount}
                  onBlur={onBlurTextInput}
                  onFocus={() => setFocusedState(true)}
                  onChange={handleTextInputChange}
                  onKeyPress={onlyNumeric}
                  onPaste={pasteCommaDelimited}
                  autoComplete="off"
                />
              </div>
              <div
                id="payoutAmountError"
                className={
                  payoutAmountError
                    ? "show invalid-feedback"
                    : "invalid-feedback"
                }
              >
                {payoutAmountErrorMsg}
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "15px" }}>
            <div className="col-md-4 payout-date-wrapper">
              <label
                id="payoutDateLabelId"
                className="field-label"
                htmlFor="payoutDateId"
              >
                Payout Date *
              </label>
              <InputMask
                id="payoutDateId"
                mask="99/99/9999"
                maskChar="_"
                className={`${
                  payoutDateError ? "form-control is-invalid" : "form-control"
                } payout-date-input`}
                placeholder="MM/DD/YYYY"
                autoComplete="off"
                value={payoutDate}
                onChange={handleDateChange}
                onBlur={onBlurDateInput}
                onFocus={() => setFocusedState(true)}
              />
              <div id="payoutDateError" className="invalid-feedback">
                {payoutDateErrorText}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-8 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="payoutDateExact"
                    type="radio"
                    id="payoutDateExact"
                    className="custom-control-input"
                    value={dateOptions.EXACT}
                    checked={payoutOption === dateOptions.EXACT}
                    onChange={handlePayoutDateOptionChange}
                  />
                  <label
                    htmlFor="payoutDateExact"
                    className="custom-control-label"
                    id="payoutDateExactLabel"
                  >
                    {dateOptions.EXACT} date
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="payoutDateExplanation"
                    type="radio"
                    id="payoutDateExplanation"
                    className="custom-control-input"
                    value={dateOptions.EXPLANATION}
                    checked={payoutOption === dateOptions.EXPLANATION}
                    onChange={handlePayoutDateOptionChange}
                  />
                  <label
                    htmlFor="payoutDateExplanation"
                    className="custom-control-label"
                    id="payoutDateExplanationLabel"
                  >
                    {dateOptions.EXPLANATION}
                  </label>
                </div>
                {payoutOption === dateOptions.EXPLANATION ? (
                  <div className="payout-date-explanation-container">
                    <label
                      htmlFor="payoutDateExplanation"
                      className="field-label"
                      id="payoutDateExplanationLabelId"
                    >
                      If not exact, provide explanation *
                    </label>
                    <textarea
                      id="payoutDateExplanation"
                      className={
                        payoutDateExplanationError
                          ? "form-control form-control-15 is-invalid"
                          : "form-control form-control-15"
                      }
                      maxLength={500}
                      minLength={2}
                      rows="3"
                      cols={500}
                      value={payoutDateExplanation}
                      onBlur={onBlurTextInput}
                      onFocus={() => setFocusedState(true)}
                      onChange={handleTextInputChange}
                    />
                    <div
                      id="payoutDateExplanationError"
                      className="invalid-feedback"
                    >
                      Please enter an Explanation
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="row backbutton-drp-bond">
        <div className="col-md-12 buttons">
          <span
            id="bondAddDRPBack"
            className="back-button"
            onClick={() => {
              isUpdates ? handleToggleBackModal(true) : handleBack();
            }}
          >
            <strong id="bondDisclBack">Back</strong>
          </span>
          <Button
            disabled={isNextDisabled}
            id="bondAddDRPNext"
            style={{ marginRight: "10px" }}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={() => {
          handleToggleBackModal(false);
        }}
        backClick={handleBack}
      />
    </div>
  );
};
export default BondAddDRPDispositionDetails;
