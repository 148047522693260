import React from "react";
import { Button } from "react-blueprint-library";

const btnContainerStyle = {
  paddingTop: "5%",
  paddingBottom: "20px",
  textAlign: "right",
};

const buttonStyle = {
  verticalAlign: "middle",
  fontSize: "17px",
  border: "none",
  cursor: "pointer",
  textDecoration: "none",
};

const loadingStyle = {
  position: "absolute",
  right: "-20px",
  transform: "translateY(-140%) translateX(10%)",
};

const NavigationButtons = ({
  bckbtnid,
  nxtbtnid,
  onBack,
  onNext,
  isLoading,
  isDisabled,
  isFinOnly = false,
}) => {
  const disabled = isDisabled !== undefined ? isDisabled : isLoading;

  return (
    <div style={{ ...btnContainerStyle }}>
      {!isFinOnly && (
        <button
          id={bckbtnid}
          type="button"
          className="btn btn-link col-sm-1"
          onClick={onBack}
          style={{ ...buttonStyle }}
        >
          <strong>Back</strong>
        </button>
      )}

      <Button id={nxtbtnid} onClick={onNext} label="Next" disabled={disabled} />

      {isLoading && (
        <div
          className="mm-dots-loading-md"
          role="img"
          aria-label="MassMutual Logo"
          id="mm-spinner"
          style={{ ...loadingStyle }}
        />
      )}
    </div>
  );
};

export default NavigationButtons;
