/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, FormCustomControl } from "react-blueprint-library";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../../../store/actions";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import "../../BankruptcyDisclosure.scss";

const courtOptions = {
  FEDERAL: "Federal Court",
  STATE: "State Court",
  FOREIGN: "Foreign Court",
  OTHER: "Other",
};

const BankruptcyDisclosureCourtDetails = ({
  updateCCBForm,
  nextFormId,
  backFormId,
  updateTempBankruptcyDRP,
  tempBankruptcyDRP,
}) => {
  const dispatch = useDispatch();
  const [courtName, setCourtName] = useState("");
  const [courtNameError, setCourtNameError] = useState(false);
  const [courtLocation, setCourtLocation] = useState("");
  const [courtLocationError, setCourtLocationError] = useState(false);
  const [courtDockCaseNumber, setCourtDockCaseNumber] = useState("");
  const [courtDockCaseNumberError, setCourtDockCaseNumberError] =
    useState(false);
  const [courtAction, setCourtAction] = useState("");
  const [courtActionError, setCourtActionError] = useState(false);
  const [courtActionOther, setCourtActionOther] = useState("");
  const [courtActionOtherError, setCourtActionOtherError] = useState(false);
  const [courtIsSSNBankIDCheck, setCourtIsSSNBankIDCheck] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [currentFormChanged, hasCurrentFormChanged] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  const handleToggleBackModal = (value) => {
    if (currentFormChanged) {
      toggleShowBackModal(value);
    } else {
      handleBack();
    }
  };
  const handleNext = () => {
    const tmpDRP = {
      ...tempBankruptcyDRP,
      courtAction: courtAction,
      courtActionOther: courtActionOther,
      courtName: courtName,
      courtLocation: courtLocation,
      courtDockCaseNumber: courtDockCaseNumber,
      courtIsSSNBankIDCheck: courtIsSSNBankIDCheck,
    };
    updateTempBankruptcyDRP(tmpDRP);
    updateCCBForm(nextFormId);
    hasCurrentFormChanged(false);
  };
  const handleBack = () => {
    updateCCBForm(backFormId);
  };
  const handleChange = ({ target: { id, value } }) => {
    hasCurrentFormChanged(true);
    switch (id) {
      case "courtName":
        setCourtName(value);
        break;
      case "courtActionOther":
        setCourtActionOther(value);
        break;
      case "courtLocation":
        setCourtLocation(value);
        break;
      case "courtDockCaseNumber":
        setCourtDockCaseNumber(value);
        break;
      case "courtIsSSNBankIDCheck":
        setCourtIsSSNBankIDCheck(!courtIsSSNBankIDCheck);
        break;
      default:
        break;
    }
  };
  const handleActionTypeChange = ({ target: { value } }) => {
    hasCurrentFormChanged(true);
    setCourtAction(value);
    if (value !== courtOptions.OTHER) {
      setCourtActionOther("");
      setCourtActionOtherError(false);
    }
  };
  const onBlur = ({ target: { id, value } }) => {
    switch (id) {
      case "courtName":
        setCourtNameError(value.trim().length < 2);
        break;
      case "courtActionOther":
        setCourtActionOtherError(value.trim().length < 2);
        break;
      case "courtLocation":
        setCourtLocationError(value.trim().length < 2);
        break;
      case "courtDockCaseNumber":
        setCourtDockCaseNumberError(value.trim().length < 2);
        break;
      default:
        break;
    }

    setFocusedState(false);
  };

  const allFilled = () => {
    return (
      courtName.trim().length > 1 &&
      courtAction !== "" &&
      (courtAction !== courtOptions.OTHER ||
        (courtAction === courtOptions.OTHER &&
          courtActionOther.trim().length > 1)) &&
      courtLocation.trim().length > 1 &&
      courtDockCaseNumber.trim().length > 1
    );
  };
  const noErrors = () => {
    return (
      !courtNameError &&
      !courtActionError &&
      (courtAction !== courtOptions.OTHER ||
        (courtAction === courtOptions.OTHER && !courtActionOtherError)) &&
      !courtLocationError &&
      !courtDockCaseNumberError
    );
  };

  useEffect(() => {
    setIsFormComplete(allFilled() && noErrors());
  });
  useEffect(() => {
    if (
      tempBankruptcyDRP?.courtName &&
      tempBankruptcyDRP?.courtAction &&
      tempBankruptcyDRP?.courtLocation &&
      tempBankruptcyDRP?.courtDockCaseNumber
    ) {
      setCourtAction(tempBankruptcyDRP?.courtAction);
      setCourtActionOther(tempBankruptcyDRP?.courtActionOther);
      setCourtName(tempBankruptcyDRP?.courtName);
      setCourtLocation(tempBankruptcyDRP?.courtLocation);
      setCourtDockCaseNumber(tempBankruptcyDRP?.courtDockCaseNumber);
      setCourtIsSSNBankIDCheck(tempBankruptcyDRP?.courtIsSSNBankIDCheck);
      setIsFormComplete(true);
      //       if (editIndex !== null) {
      //   hasCurrentFormChanged(false);
      // } else {
      hasCurrentFormChanged(true);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
      // }
    }
  }, []);
  useEffect(() => {
    if (currentFormChanged || !noErrors()) {
      hasCurrentFormChanged(true);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    }
  }, [currentFormChanged, noErrors(), dispatch]);
  return (
    <div className="bankruptcy-form-wrapper">
      <br />
      <div className="row">
        <div className="col-md-12">
          <label id="courtActionLabelId" className="field-label">
            Court action brought in: *
          </label>
        </div>
      </div>
      <div className="row">
        <div
          className="drp-radio-input-wrapper"
          style={{ paddingLeft: "10px" }}
        >
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="courtActionBankruptcy"
                type="radio"
                id="courtActionBankruptcy"
                className="custom-control-input"
                value={courtOptions.FEDERAL}
                checked={courtAction === courtOptions.FEDERAL}
                onChange={handleActionTypeChange}
              />
              <label
                id="courtActionBankruptcyLabelId"
                htmlFor="courtActionBankruptcy"
                className="custom-control-label"
              >
                {courtOptions.FEDERAL}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="courtActionCompromise"
                type="radio"
                id="courtActionCompromise"
                className="custom-control-input"
                value={courtOptions.STATE}
                checked={courtAction === courtOptions.STATE}
                onChange={handleActionTypeChange}
              />
              <label
                id="courtActionCompromiseLabelId"
                htmlFor="courtActionCompromise"
                className="custom-control-label"
              >
                {courtOptions.STATE}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="courtActionDeclaration"
                type="radio"
                id="courtActionDeclaration"
                className="custom-control-input"
                value={courtOptions.FOREIGN}
                checked={courtAction === courtOptions.FOREIGN}
                onChange={handleActionTypeChange}
              />
              <label
                id="courtActionDeclarationLabelId"
                htmlFor="courtActionDeclaration"
                className="custom-control-label"
              >
                {courtOptions.FOREIGN}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="courtActionLiquidation"
                type="radio"
                id="courtActionLiquidation"
                className="custom-control-input"
                value={courtOptions.OTHER}
                checked={courtAction === courtOptions.OTHER}
                onChange={handleActionTypeChange}
              />
              <label
                id="courtActionLiquidationLabelId"
                htmlFor="courtActionLiquidation"
                className="custom-control-label"
              >
                {courtOptions.OTHER}
              </label>
            </div>
            {courtAction === courtOptions.OTHER ? (
              <div className="court-action-other">
                <input
                  type="text"
                  id="courtActionOther"
                  className={
                    courtActionOtherError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={courtActionOther}
                  onChange={handleChange}
                  onBlur={onBlur}
                  onFocus={() => setFocusedState(true)}
                  style={{ marginLeft: "25px" }}
                />
                <div
                  id="courtActionOtherError"
                  className="invalid-feedback"
                  style={{ marginLeft: "25px", paddingBottom: "10px" }}
                >
                  Please enter a response
                </div>
              </div>
            ) : null}

            <div id="courtActionError" className="invalid-feedback">
              Please enter a court action
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label
            id="courtNameLabel"
            className="field-label"
            htmlFor="courtName"
          >
            Name of court: *
          </label>
          <input
            style={{ width: "60%" }}
            id="courtName"
            type="text"
            className={
              courtNameError ? "form-control is-invalid" : "form-control"
            }
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={() => setFocusedState(true)}
            maxLength={100}
            minLength={2}
            value={courtName}
            autoComplete="off"
          ></input>
          <div
            id="courtNameError"
            className="invalid-feedback"
            style={{ paddingBottom: "10px" }}
          >
            Please enter Name of court
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label
            id="courtLocationLabel"
            className="field-label"
            htmlFor="courtLocation"
          >
            Location of Court (City or County <u>and</u> State or Country): *
          </label>
          <input
            style={{ width: "60%" }}
            id="courtLocation"
            type="text"
            className={
              courtLocationError ? "form-control is-invalid" : "form-control"
            }
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={() => setFocusedState(true)}
            maxLength={100}
            minLength={2}
            value={courtLocation}
            autoComplete="off"
          ></input>
          <div
            id="courtLocationError"
            className="invalid-feedback"
            style={{ paddingBottom: "10px" }}
          >
            Please enter Location of court
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label
            id="courtDockCaseNumberLabel"
            className="field-label"
            htmlFor="courtDockCaseNumber"
          >
            Docket/Case #: *
          </label>
          <input
            style={{ width: "60%" }}
            id="courtDockCaseNumber"
            type="text"
            className={
              courtDockCaseNumberError
                ? "form-control is-invalid"
                : "form-control"
            }
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={() => setFocusedState(true)}
            maxLength={100}
            minLength={2}
            value={courtDockCaseNumber}
            autoComplete="off"
          ></input>
          <div
            id="courtDockCaseNumberError"
            className="invalid-feedback"
            style={{ paddingBottom: "10px" }}
          >
            Please enter Docket/Case #
          </div>
        </div>
      </div>
      <div className="judgment-lien-court-details-form-wrapper">
        <div
          className="row form-custom-control-wrapper"
          style={{ marginLeft: "0" }}
        >
          <FormCustomControl
            id="courtIsSSNBankIDCheck"
            inputType="checkbox"
            label="Check this box if the Docket/Case # is your SSN, a Bank Card number, or a Personal Identification Number."
            onChange={handleChange}
            checked={courtIsSSNBankIDCheck}
          />
        </div>
      </div>
      {/* <div className="checkbox-container">
        <div
          className="custom-control custom-checkbox"
          style={{ paddingTop: "20px" }}
        >
          <input
            type="checkbox"
            className="custom-control-input"
            onChange={handleChange}
            //onClick={handleShowOtherDiv}
            id="courtIsSSNBankIDCheck"
            value="Other"
            checked={courtIsSSNBankIDCheck}
          />
          <label
            className="custom-control-label"
            htmlFor="courtIsSSNBankIDCheck"
            id="courtIsSSNBankIDCheckLabel"
          >
            <strong id="courtIsSSNBankIDCheckText">
              Check this box if the Docket/Case # is your SSN, a Bank Card
              number, or a Personal Identification Number.
            </strong>
          </label>
        </div>
      </div> */}
      <div className="row backbutton-drp-bankruptcy">
        <div className="col-md-12 buttons">
          <span
            id="bankruptcyAddDRPBack"
            className="back-button"
            onClick={() => {
              handleToggleBackModal(true);
            }}
          >
            <strong id="bankruptcyDisclBack">Back</strong>
          </span>
          <Button
            disabled={!isFormComplete || isFocusedState}
            id="bankruptcyAddDRPNext"
            style={{ marginRight: "10px" }}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={() => {
          handleToggleBackModal(false);
        }}
        backClick={handleBack}
      />
    </div>
  );
};
export default BankruptcyDisclosureCourtDetails;
