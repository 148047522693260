import React, { Component } from "react";
import { Button, FormCustomControl, Modal } from "react-blueprint-library";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import InputMask from "react-input-mask";
import USPSValidationModal from "../../SharedComponents/USPSValidationModal";
import "../../../assets/stylesheets/BackButtonModal.scss";
import Moment from "moment";
import { getUSPSAddress } from "../../../api/uspsAddress";
import { COUNTRIES } from "../../../constants/countries";
import {
  clickFunc,
  isValidDate,
  onlyAlphaNumeric,
} from "../../../common/Functions";
import BackButtonModal from "../Forms/BackButtonModal";

class AddAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      residentialhistoryid: -1,
      autocomplete: "",
      address2: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      usps_address: "",
      usps_address2: "",
      usps_city: "",
      usps_state: "",
      usps_zip_code: "",
      usps_country: "",
      usps_urbanization: "",
      urbanization: "",
      useSuggestedAddress: "",
      addressNotFound: false,
      ctrl_with_focus: "",
      show_urbanization: false,
      show_verification_modal: false,
      move_in_date: "",
      move_out_date: "",
      is_current_address: false,
      is_next_disabled: true,
      show_add_address_modal: this.props.isOpen,
      is_loading: false,
      is_state_mandatory: false,
      state_is_changed: false,
      showBackModal: false,
      county: "",
      us_address_required: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.getAddressComponents = this.getAddressComponents.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onFocusState = this.onFocusState.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onBlurAddress2 = this.onBlurAddress2.bind(this);
    this.handleShowUrbanization = this.handleShowUrbanization.bind(this);
    this.handleHideUrbanization = this.handleHideUrbanization.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.useSuggestedAddress = this.useSuggestedAddress.bind(this);
    this.clearInputErrors = this.clearInputErrors.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
  }
  async componentDidMount() {
    if (this.props.editAddress !== "") {
      await this.setState({
        residentialhistoryid: this.props.editAddress.residentialhistoryid,
        autocomplete: this.props.editAddress.address1,
        address2: this.props.editAddress.address2,
        city: this.props.editAddress.city,
        is_current_address: this.props.editAddress.currentaddress,
        move_in_date: Moment(this.props.editAddress.begindate).format(
          "MM/DD/YYYY"
        ),
        move_out_date:
          this.props.editAddress.enddate !== null
            ? Moment(this.props.editAddress.enddate).format("MM/DD/YYYY")
            : null,
        move_out_date_dispaly:
          this.props.editAddress.currentaddress === true
            ? ""
            : Moment(this.props.editAddress.enddate).format("MM/DD/YYYY"),
        state: this.props.editAddress.state,
        urbanization: this.props.editAddress.urbanization,
        zip_code: this.props.editAddress.zipcode,
        country: this.props.editAddress.country,
        is_next_disabled: false,
        is_state_mandatory:
          this.props.editAddress.country === "US"
            ? true
            : this.props.editAddress.country === "PR"
            ? true
            : false,
        show_urbanization: this.props.editAddress.state === "PR" ? true : false,
        state_is_changed: false,
      });
    }

    if (this.props.addresses.length > 0) {
      this.setState({
        ealiest_move_in_date: Moment(
          this.props.addresses[0].move_in_date
        ).format("MM/DD/YYYY)"),
      });
    }
  }

  handleChange(event) {
    this.setState({ is_validated: false, state_is_changed: true });
    if (event.target.id.includes("zip_code")) {
      let alphanumericRegex = /^[a-zA-Z0-9]*$/;

      this.setState({ zip_code: event.target.value.replace(/[^\w\s]/gi, "") });
    } else if (event.target.id.includes("region")) {
      this.setState({ city: event.target.value });
    } else if (event.target.id.includes("state_option")) {
      this.setState({ state: event.target.value });
    } else {
      this.setState({ [event.target.id]: event.target.value });
    }
    if (event.target.id.includes("move_out_date")) {
      this.setState({
        move_out_date_dispaly: event.target.value,
        move_out_date: event.target.value,
      });
    }
    if (
      event.target.id.includes("state_option") &&
      event.target.value === "PR"
    ) {
      this.handleShowUrbanization();
    } else if (
      event.target.id.includes("state_option") &&
      event.target.value !== "PR"
    ) {
      this.setState({ urbanization: "" });
      this.handleHideUrbanization();
    }
    if (event.target.id.includes("country") && event.target.value === "US") {
      this.setState({ is_state_mandatory: true });
    } else if (
      event.target.id.includes("country") &&
      event.target.value !== "US"
    ) {
      this.setState({ is_state_mandatory: false });
      let obj_error = document.getElementById("state_error");
      let obj = document.getElementById("state");
      obj_error.style.display = "none";
      obj.removeAttribute("style");
      if (this.state.state !== "") {
        this.setState({ state: "" });
      }
      if (this.state.show_urbanization === true) {
        this.handleHideUrbanization();
      }
    }
  }

  handlePlaceSelect(addressObject) {
    this.setState({ is_validated: false, state_is_changed: true });
    var address_components = this.getAddressComponents(
      addressObject.address_components
    );
    //extracting County from object
    const countyComponent = addressObject.address_components.find((component) =>
      component.types.includes("administrative_area_level_2")
    );
    const county = countyComponent ? countyComponent.short_name : "";
    this.setState({ county: county });

    let input;

    if (address_components.street_number === "") {
      input = this.state.autocomplete.split(" ");

      if (address_components.street === "") {
        for (let i = 0; i < input.length; i++)
          input[i] = input[i][0].toUpperCase() + input[i].substr(1);
        this.setState({
          autocomplete: input.join(" "),
        });
      } else {
        this.setState({
          autocomplete: input[0] + " " + address_components.street,
        });
      }
    } else {
      let addr = address_components.street;
      if (addr.toLocaleUpperCase().includes("AVENIDA")) {
        addr = addr.replace("Avenida", "Ave");
      }
      this.setState({
        autocomplete: address_components.street_number + " " + addr,
      });
    }
    this.clearInputErrors();
    this.setState({
      city: address_components.city,
      state: address_components.state,
      zip_code: address_components.zip_code,
      address2: "",
      urbanization: "",
    });
    if (
      address_components.country === "United States" ||
      address_components.country === "UNITED STATES OF AMERICA"
    ) {
      this.setState({ country: "US", is_state_mandatory: true });
    } else if (address_components.country === "Puerto Rico") {
      this.setState({
        is_state_mandatory: true,
        country:
          address_components.country === "Puerto Rico"
            ? "US"
            : address_components.country.match(/\b(\w)/g).join(""),
        usps_urbanization: address_components.urbanization,
      });
    } else {
      this.setState({ is_state_mandatory: false });
    }

    let hasError = false;
    let date = new Moment();
    let moveInDate =
      this.state.move_in_date === "" ? "" : new Moment(this.state.move_in_date);
    let moveOutDate =
      this.state.move_out_date === ""
        ? ""
        : new Moment(this.state.move_out_date);
    let currDate3 = Moment();

    let moveInyearDiff3 = currDate3.diff(moveInDate, "year");
    let moveOutyearDiff3 = currDate3.diff(moveOutDate, "year");

    if (moveOutDate.isAfter(date) && !this.state.is_current_address) {
      hasError = true;
    } else if (
      !isValidDate(this.state.move_out_date) &&
      !this.state.is_current_address
    ) {
      hasError = true;
    } else if (moveOutyearDiff3 >= 100) {
      hasError = true;
    }

    if (moveInDate.isAfter(date)) {
      hasError = true;
    } else if (!isValidDate(this.state.move_in_date)) {
      hasError = true;
    } else if (moveInyearDiff3 >= 100) {
      hasError = true;
    }

    if (moveInDate.isAfter(moveOutDate)) {
      hasError = true;
    }

    if (
      (this.state.is_current_address === false &&
        !this.state.move_in_date.includes("_") &&
        this.state.move_in_date !== "" &&
        !this.state.move_out_date.includes("_") &&
        this.state.move_out_date !== "") ||
      (this.state.is_current_address === true &&
        !this.state.move_in_date.includes("_") &&
        this.state.move_in_date !== "")
    ) {
      this.setState({
        is_next_disabled: hasError,
      });
    }
  }

  clearInputErrors() {
    let streetInput = document.querySelector("#autocomplete");
    let address2Input = document.querySelector("#address2");
    let cityInput = document.querySelector("#region");
    let stateInput = document.querySelector("#state");
    let zipCodeInput = document.querySelector("#zip_code");
    let countryInput = document.querySelector("#country");

    let streetErrorMessageBox = document.getElementById(
      streetInput.id + "_error"
    );
    let address2ErrorMessageBox = document.getElementById(
      address2Input.id + "_error"
    );
    let cityErrorMessageBox = document.getElementById("region_error");
    let stateErrorMessageBox = document.getElementById(
      stateInput.id + "_error"
    );
    let zipCodeErrorMessageBox = document.getElementById(
      zipCodeInput.id + "_error"
    );
    let countryErrorMessageBox = document.getElementById(
      countryInput.id + "_error"
    );

    streetInput.style = "1px solid #6b748e";
    address2Input.style = "1px solid #6b748e";
    cityInput.style = "1px solid #6b748e";
    stateInput.style = "1px solid #6b748e";
    zipCodeInput.style = "1px solid #6b748e";
    countryInput.style = "1px solid #6b748e";

    streetErrorMessageBox.style.display = "none";
    address2ErrorMessageBox.style.display = "none";
    cityErrorMessageBox.style.display = "none";
    stateErrorMessageBox.style.display = "none";
    zipCodeErrorMessageBox.style.display = "none";
    countryErrorMessageBox.style.display = "none";
  }
  getAddressComponents(address) {
    var USAddressComponents = {
      street_number: ["street_number"],
      street: ["street_address", "route"],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      state: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      zip_code: ["postal_code"],
    };

    var address_components = {
      street_number: "",
      street: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      urbanization: "",
    };

    address.forEach((component) => {
      switch (component.long_name) {
        case "Puerto Rico":
        case "United States":
          address_components.country = component.long_name;

          if (address_components.country === "Puerto Rico")
            this.handleShowUrbanization();
          else this.handleHideUrbanization();

          break;
        default:
      }
    });
    address.forEach((component) => {
      for (var addressComponent in USAddressComponents) {
        if (
          USAddressComponents[addressComponent].indexOf(component.types[0]) !==
          -1
        ) {
          if (addressComponent === "state") {
            if (address_components.country === "Puerto Rico") {
              address_components[addressComponent] = "PR";
            } else {
              address_components[addressComponent] = component.short_name;
            }
          } else {
            address_components[addressComponent] = component.long_name;
          }
        }
      }
    });
    return address_components;
  }
  onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "new-address";
    }
  };

  onFocusState() {
    this.setState({ ctrl_with_focus: "" });

    var inputs = document.querySelectorAll("button, a");
    try {
      for (var i = 0; i < inputs.length; ++i) {
        inputs[i].addEventListener("focusout", (event) => {
          if (event.relatedTarget == null) {
            this.setState({ ctrl_with_focus: "" });
          } else {
            this.setState({ ctrl_with_focus: event.relatedTarget.id });
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  blurTimer = null;
  onBlur = (event) => {
    let hasError = false;

    if (
      this.state.ctrl_with_focus.includes("option") &&
      this.state.ctrl_with_focus !== event.target.id
    ) {
      //do nothing
      return;
    } else if (
      !event.target.id.includes("urbanization") &&
      !event.target.id.includes("option")
    ) {
      var obj_error = document.getElementById(event.target.id + "_error");
      if (event.target.className !== "dropdown-item")
        event.target.removeAttribute("style");
      obj_error.style.display = "none";
    }
    if (!event.target.id.includes("state")) {
      if (event.target.id.includes("zip_code")) {
        if (event.target.value.trim(" ").length === 0) {
          obj_error.innerText = "Please enter a Zip Code";
          hasError = true;
        }
        if (
          event.target.value.trim(" ").length > 0 &&
          event.target.value.trim(" ").length < 5
        ) {
          obj_error.innerText = "Please enter a valid Zip Code";
          hasError = true;
        }
      } else if (event.target.id.includes("region")) {
        obj_error = document.getElementById("region_error");

        if (event.target.value.trim(" ").length === 0) {
          obj_error.innerText = "Please enter a City";
          event.target.style.border = "1px solid #e21224";
          event.target.style.color = "#e21224";
          obj_error.style.display = "block";
          event.target.classList.add("validation-error");
        } else if (this.state.city.trim(" ").length < 1) {
          obj_error.innerText = "Please enter a City";
          event.target.style.border = "1px solid #e21224";
          event.target.style.color = "#e21224";
          obj_error.style.display = "block";
          event.target.classList.add("validation-error");
        } else {
          // event.target.style.border = "1px solid #6b748e";
          // event.target.style.color = "#6b748e";
          obj_error.style.display = "none";
          event.target.classList.remove("validation-error");
        }
      } else if (event.target.id.includes("urbanization")) {
        if (event.target.value.trim(" ").length === 0) {
          this.setState({ urbanization: "" });
          event.target.value = "";
        }
      } else if (event.target.id.includes("country")) {
        obj_error = document.getElementById("country_error");
        let obj = document.getElementById(event.target.id);
        // if (event.target.value.trim(" ").length === 0) {
        if (this.state.country.length === 0) {
          obj_error.style.display = "block";
          event.target.style.border = "1px solid #e21224";
          event.target.style.color = "#e21224";
          // await this.setState({ formHasError: true });
        } else {
          obj.style = "none";
          obj_error.style.display = "none";
        }
      } else if (
        !event.target.id.includes("urbanization") &&
        event.target.value.trim(" ").length === 0 &&
        !event.target.id.includes("move")
      ) {
        hasError = true;
      } else if (event.target.id.includes("move")) {
        let date = new Moment();
        let enteredDate = new Moment(event.target.value, "MM/DD/YYYY");
        let moveInDate =
          this.state.move_in_date === ""
            ? ""
            : new Moment(this.state.move_in_date, "MM/DD/YYYY");
        let moveOutDate =
          this.state.move_out_date === ""
            ? ""
            : new Moment(this.state.move_out_date, "MM/DD/YYYY");

        if (event.target.id === "move_in_date") {
          obj_error = document.getElementById("move_in_date_error");
          let currDate3 = Moment();
          let dateParts3 = event.target.value.split("/");
          let month3 = dateParts3[0];
          let day3 = dateParts3[1];
          let year3 = dateParts3[2];
          let dateCalc3 = Moment(year3 + "-" + month3 + "-" + day3);
          let yearDiff3 = currDate3.diff(dateCalc3, "year");
          if (event.target.value.includes("_") || event.target.value === "") {
            hasError = true;
          } else if (enteredDate.isAfter(date)) {
            obj_error.innerText = "Date cannot be a future date";
            hasError = true;
          } else if (moveOutDate !== "" && moveInDate.isAfter(moveOutDate)) {
            obj_error.innerText = "Date cannot be after Move out date";
            hasError = true;
          } else if (
            !isValidDate(event.target.value) ||
            event.target.value.slice(-4) === "0000"
          ) {
            obj_error.innerText = "Please enter a valid date";
            hasError = true;
          } else if (yearDiff3 >= 100) {
            obj_error.innerText = "Date cannot be more than 100 years ago";
            hasError = true;
          }
        }

        if (
          event.target.id === "move_out_date" &&
          this.state.is_current_address === false
        ) {
          let currDate3 = Moment().utc();
          let dateParts3 = event.target.value.split("/");
          let month3 = dateParts3[0];
          let day3 = dateParts3[1];
          let year3 = dateParts3[2];
          let dateCalc3 = Moment(year3 + "-" + month3 + "-" + day3).utc();
          let yearDiff3 = currDate3.diff(dateCalc3, "year");
          obj_error = document.getElementById("move_out_date_error");
          if (
            moveInDate !== "" &&
            moveInDate.isAfter(moveOutDate) &&
            event.target.value.slice(-4) !== "0000"
          ) {
            obj_error.innerText = "Date cannot be before Move In date";
            hasError = true;
          } else if (enteredDate.isAfter(date)) {
            obj_error.innerText = "Date cannot be a future date";
            hasError = true;
          } else if (
            !isValidDate(event.target.value) ||
            event.target.value.slice(-4) === "0000"
          ) {
            this.blurTimer = setTimeout(() => {
              obj_error.innerText = "Please enter a valid date";
              // hasError = true;
              event.target.style.border = "1px solid #e21224";
              event.target.style.color = "#e21224";
              obj_error.style.display = "block";
              event.target.classList.add("validation-error");
            }, 150);
          } else if (yearDiff3 >= 5) {
            obj_error.innerText = "Date cannot be more than 5 years ago";
            hasError = true;
          }

          // else if (moveInDate !== "" && moveOutDate.isAfter(moveInDate)) {
          //   let moveInDateField = document.getElementById("move_in_date");
          //   let moveInDateErrorMessageBox =
          //     document.getElementById("move_in_date_error");

          //   moveInDateField.style = "1px solid #6b748e";
          //   moveInDateErrorMessageBox.style.display = "none";
          // }
        }
      }
    } else {
      var stateCtrl = document.getElementById(event.target.id);
      stateCtrl.style = "none";
      obj_error = document.getElementById("state_error");
      if (
        event.target.id.includes("option") &&
        this.state.ctrl_with_focus.includes("state_option")
      ) {
        //do nothing
      } else if (
        (event.target.id.includes("state") &&
          event.target.innerText.trim(" ").length === 0) ||
        this.state.state === ""
      ) {
        if (this.state.is_state_mandatory === true) {
          stateCtrl.style.border = "1px solid #e21224";
          stateCtrl.style.color = "#e21224";
          obj_error.style.display = "block";
        } else {
          event.target.removeAttribute("style");
          obj_error.style.display = "none";
        }
      }
    }
    this.setState({ formHasChanged: true });

    if (hasError) {
      event.target.style.border = "1px solid #e21224";
      event.target.style.color = "#e21224";
      obj_error.style.display = "block";
      event.target.classList.add("validation-error");
    } else {
      let move_out_date_error = document.getElementById("move_out_date_error");
      if (move_out_date_error.style.display === "block") {
        // run onblur for start_date
        var enddateEvent = new Event("blur");
        Object.defineProperty(enddateEvent, "target", {
          writable: false,
          value: document.getElementById("move_out_date"),
        });
        this.onBlur(enddateEvent);
      }

      let start_date_error = document.getElementById("move_in_date_error");
      if (start_date_error.style.display === "block") {
        // run onblur for start_date
        var startdateEvent = new Event("blur");
        Object.defineProperty(startdateEvent, "target", {
          writable: false,
          value: document.getElementById("move_in_date"),
        });
        this.onBlur(startdateEvent);
      }
    }
    if (this.state.is_state_mandatory) {
      if (
        this.state.autocomplete.length > 0 &&
        this.state.city.length > 0 &&
        this.state.state.length > 0 &&
        this.state.country.length > 0 &&
        this.state.zip_code.length > 4 &&
        this.state.is_current_address === false &&
        !this.state.move_in_date.includes("_") &&
        this.state.move_in_date !== "" &&
        !this.state.move_out_date.includes("_") &&
        this.state.move_out_date !== "" &&
        (this.state.is_current_address
          ? true
          : !this.state.move_out_date_dispaly.includes("_") &&
            this.state.move_out_date_dispaly !== "") &&
        !hasError
      ) {
        this.setState({ is_next_disabled: false });
      } else if (
        this.state.autocomplete.length > 0 &&
        this.state.city.length > 0 &&
        this.state.state.length > 0 &&
        this.state.country.length > 0 &&
        this.state.zip_code.length > 4 &&
        this.state.is_current_address === true &&
        !this.state.move_in_date.includes("_") &&
        (this.state.is_current_address
          ? true
          : !this.state.move_out_date_dispaly.includes("_") &&
            this.state.move_out_date_dispaly !== "") &&
        !hasError
      ) {
        this.setState({ is_next_disabled: false });
      } else {
        this.setState({ is_next_disabled: true });
      }
    } else {
      if (
        this.state.autocomplete.length > 0 &&
        this.state.city.length > 0 &&
        this.state.country.length > 0 &&
        this.state.zip_code.length > 4 &&
        this.state.is_current_address === false &&
        !this.state.move_in_date.includes("_") &&
        this.state.move_in_date !== "" &&
        !this.state.move_out_date.includes("_") &&
        this.state.move_out_date !== "" &&
        (this.state.is_current_address
          ? true
          : !this.state.move_out_date_dispaly.includes("_") &&
            this.state.move_out_date_dispaly !== "") &&
        !hasError
      ) {
        this.setState({ is_next_disabled: false });
      } else if (
        this.state.autocomplete.length > 0 &&
        this.state.city.length > 0 &&
        this.state.country.length > 0 &&
        this.state.zip_code.length > 4 &&
        this.state.is_current_address === true &&
        !this.state.move_in_date.includes("_") &&
        (this.state.is_current_address
          ? true
          : !this.state.move_out_date_dispaly.includes("_") &&
            this.state.move_out_date_dispaly !== "") &&
        !hasError
      ) {
        this.setState({ is_next_disabled: false });
      } else {
        this.setState({ is_next_disabled: true });
      }
    }
  };
  onBlurAddress2 = (event) => {
    var obj_error = document.getElementById(event.target.id + "_error");

    if (
      obj_error.style.display === "block" &&
      event.target.value.trim(" ").length > 0
    ) {
      event.target.removeAttribute("style");
      obj_error.style.display = "none";
    }
  };

  handleBack() {
    this.setState({
      show_verification_modal: false,
      show_add_address_modal: true,
      is_loading: false,
      showModal: false,
    });
  }

  modalclose = () => {
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      this.setState({
        show_verification_modal: false,
        is_loading: false,
        showModal: false,
      });
    }
  };

  async handleNext() {
    await this.setState({ us_address_required: false });
    if (this.state.state_is_changed) {
      if (
        this.state.country !== "US" &&
        this.state.is_current_address === true
      ) {
        await this.setState({ us_address_required: true });
      } else {
        this.setState({ is_loading: true });
        let street = this.state.autocomplete;

        if (this.state.address2 !== undefined) {
          const street2 = street + ", " + this.state.address2;
          street = street2;
        } else {
          this.setState({ address2: "" });
        }

        let data = {
          street: street,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip_code,
          country: this.state.country,
          resurbanization: this.state.urbanization,
          county: this.state.county,
        };
        console.log(data);

        if (this.state.country === "US") {
          const response = await getUSPSAddress(data);
          console.log(response);
          let verifyLevel = "default";
          if (response.data !== "undefined") {
            verifyLevel = response.data.VerificationFlags.VerifyLevel;
          }

          if (verifyLevel === "Verified") {
            this.setState({
              usps_address: response.data.Address.Street1,
              usps_address2: response.data.Address.Street2,
              usps_city: response.data.Address.City,
              usps_state: response.data.Address.State,
              usps_zip_code: response.data.Address.Zip,
              usps_country:
                response.data.Address.Country === "UNITED STATES OF AMERICA"
                  ? "US"
                  : response.data.Address.Country,

              showModal: true,
              addressNotFound: false,
            });
          } else {
            this.setState({
              usps_address: "USPS does not recognize the address you entered.",
              usps_address2: "",
              usps_city: "",
              usps_state: "",
              usps_zip_code: "",
              usps_country: "",
              showModal: true,
              addressNotFound: true,
            });
          }
        } else {
          this.setState({
            usps_address: "USPS does not recognize the address you entered.",
            usps_address2: "",
            usps_city: "",
            usps_state: "",
            usps_zip_code: "",
            usps_country: "",
            showModal: true,
            addressNotFound: true,
          });
        }

        if (this.state.state_is_changed) {
          this.setState({
            show_verification_modal: true,
            show_add_address_modal: false,
          });
        } else {
          let address = {
            residentialhistoryid: this.state.residentialhistoryid,
            address1: this.state.autocomplete,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zipcode: this.state.zip_code,
            urbanization: this.state.urbanization,
            begindate: this.state.move_in_date,
            startdate: this.state.move_out_date,
            currentaddress: this.state.is_current_address,
            country: this.state.country,
          };
          let editedAddress = address;
          this.props.editExistingAddress(editedAddress);
        }
      }
    } else {
      this.props.closeModal();
    }
  }

  handleShowUrbanization() {
    this.setState({ show_urbanization: true });
  }

  handleHideUrbanization() {
    this.setState({ show_urbanization: false, urbanization: "" });
  }

  async handleCheck(event) {
    //this will enable checkbox for all other addresses.
    this.props.updateCurrentAddressId(-1);
    await this.setState({
      state_is_changed: true,
    });
    clearTimeout(this.blurTimer);
    let moveOutDateInput = document.getElementById("move_out_date");
    let moveOutDateErrorMessageBox = document.getElementById(
      "move_out_date_error"
    );
    moveOutDateInput.style = "1px solid #6b748e";
    moveOutDateErrorMessageBox.style.display = "none";

    let currentCheck = this.state.is_current_address;
    await this.setState({
      is_current_address: !currentCheck,
    });
    if (!currentCheck) {
      let month = new Date().getMonth() + 1;
      let day = new Date().getDate();
      let year = new Date().getFullYear();

      let now = `${month >= 10 ? "" : "0"}${month}/${
        day >= 10 ? "" : "0"
      }${day}/${year}`;

      this.setState({
        move_out_date: now,
        move_out_date_dispaly: "",
        current_address_id: -1,
      });
    } else {
      await this.setState({
        is_next_disabled: true,
        move_out_date: Moment().format("MM/DD/YYYY"),
        move_out_date_dispaly: "",
      });
    }

    if (
      this.state.move_in_date !== "" &&
      !this.state.move_in_date.includes("_") &&
      this.state.autocomplete.length > 0 &&
      this.state.city.length > 0 &&
      this.state.zip_code.length > 4 &&
      this.state.country.length > 0 &&
      isValidDate(this.state.move_in_date) &&
      (this.state.is_current_address
        ? true
        : this.state.move_out_date_dispaly !== "" &&
          !this.state.move_in_date.includes("_") &&
          isValidDate(this.state.move_out_date_dispaly)) &&
      (this.state.country === "US" ? this.state.state.length > 0 : true)
    ) {
      this.setState({ is_next_disabled: false });
    }
  }

  useSuggestedAddress() {
    this.setState({ useSuggestedAddress: true });
  }

  handleCloseModal() {
    //this.setState({ showBackModal: false });
    if (this.state.state_is_changed) {
      this.setState({ showBackModal: true });
    } else {
      this.props.closeModal();
      this.setState({ showBackModal: false });
    }
  }

  handleNextClick() {
    this.setState({ showBackModal: false });
    this.handleNext();
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    this.setState({ formHasChanged: false });
    this.handleCloseBackModal();
    this.props.closeModal();
  }

  render() {
    return (
      <>
        <div>
          <div id="addressModal"></div>
          <div>
            <Modal
              id="addAddressModal"
              show={
                this.state.show_add_address_modal && !this.state.showBackModal
              }
              className="modal-md"
              title=""
            >
              <div>
                <div className="modal-body">
                  <div className="container">
                    <div className="row"></div>
                    <div
                      className="row"
                      style={{ margin: "15px 0px 15px 0px" }}
                    >
                      <div className="col-md-12" style={{ padding: "0" }}>
                        <h3>
                          <div style={{ width: "92%" }}>
                            <strong>Add Address</strong>{" "}
                          </div>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close modal dialog"
                            onClick={this.handleCloseModal}
                            style={{ margin: "0" }}
                          />
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Step 1 of 2</h3>
                        <div style={{ paddingTop: "15px" }}>
                          <span className="required-field-text">
                            * = Required Field
                          </span>
                        </div>

                        <label
                          htmlFor="isCurrentAddress"
                          className="required-field-text"
                          style={{ marginBottom: "0", paddingTop: "15px" }}
                        >
                          <FormCustomControl
                            id="isCurrentAddress"
                            inputType="checkbox"
                            label="I currently reside at this address"
                            onChange={this.handleCheck}
                            checked={this.state.is_current_address === true}
                            disabled={
                              this.props.current_address_id !==
                                this.state.residentialhistoryid &&
                              this.props.current_address_id !== -1
                            }
                          />
                        </label>

                        <div className="row">
                          <div className="col">
                            <label>
                              <strong> Move-in Date* </strong>
                            </label>
                            <InputMask
                              id="move_in_date"
                              mask="99/99/9999"
                              maskChar="_"
                              className="form-control"
                              placeholder="MM/DD/YYYY"
                              autoComplete="new-password"
                              value={this.state.move_in_date}
                              onBlur={this.onBlur}
                              onChange={this.handleChange}
                              onKeyDown={this.onKeyDown}
                              required
                            />

                            <div
                              id="move_in_date_error"
                              className="invalid-feedback"
                            >
                              Please enter a valid date
                            </div>
                          </div>
                          <div className="col">
                            <label
                              style={{
                                color:
                                  this.state.is_current_address === true
                                    ? "grey"
                                    : "",
                              }}
                            >
                              <strong> Move-out Date* </strong>
                            </label>
                            <InputMask
                              id="move_out_date"
                              mask="99/99/9999"
                              maskChar="_"
                              className="form-control"
                              placeholder="MM/DD/YYYY"
                              autoComplete="new-password"
                              value={this.state.move_out_date_dispaly}
                              onBlur={this.onBlur}
                              onChange={this.handleChange}
                              onKeyDown={this.onKeyDown}
                              disabled={this.state.is_current_address}
                              required
                            />

                            <div
                              id={"move_out_date_error"}
                              className="invalid-feedback"
                            >
                              Please enter a valid date
                            </div>
                          </div>
                        </div>
                        {/* I currently reside at this address */}
                        <div
                          className="row"
                          style={{
                            paddingTop: "15px",
                          }}
                        >
                          <div className="col">
                            <div
                              id={
                                "autocomplete-" + this.state.form_id + "_error"
                              }
                              className="invalid-feedback"
                            >
                              Please enter a Street Address
                            </div>
                          </div>
                        </div>
                        <div id="AddressForm">
                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col">
                                    <label>
                                      <strong> Street Address*</strong>
                                    </label>
                                    <Autocomplete
                                      apiKey={window.REACT_APP_GOOGLE_API_KEY}
                                      onPlaceSelected={(place) => {
                                        this.handlePlaceSelect(place);
                                      }}
                                      options={{
                                        types: ["geocode", "establishment"],
                                        componentRestrictions: {
                                          country: [
                                            "us" /*United States*/,
                                            //"as" /*American Samoa*/,
                                            //"fm" /*Federated States of Micronesia*/,
                                            //"gu" /*Guam*/,
                                            //"mh" /*Marshall Islands*/,
                                            //"mp" /*Northern Mariana Islands*/,
                                            "pr" /*Puerto Rico*/,
                                            //"pw" /*Palau*/,
                                            //"vi" /*U.S. Virgin Islands*/,
                                            //"um" /*U.S. Minor Outlying Islands*/,
                                          ],
                                        },
                                      }}
                                      id="autocomplete"
                                      className="form-control form-control-15"
                                      placeholder=""
                                      aria-label="aria_autocomplete_streetaddress_resmodal"
                                      value={this.state.autocomplete}
                                      onChange={this.handleChange}
                                      onFocus={this.onFocus}
                                      onBlur={this.onBlur}
                                      maxLength={320}
                                      required
                                      autoComplete="off"
                                    />
                                    <div
                                      id={"autocomplete_error"}
                                      className="invalid-feedback"
                                    >
                                      Please enter a Street Address
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ paddingTop: "20px" }}
                                >
                                  <div className="col">
                                    <label>
                                      <strong>
                                        {" "}
                                        Apartment, Suite, Building, Gate Code{" "}
                                      </strong>
                                    </label>
                                    <input
                                      id="address2"
                                      className="form-control form-control form-control-15"
                                      aria-label="aria_address2_resmodal"
                                      value={this.state.address2}
                                      onChange={this.handleChange}
                                      onBlur={this.onBlurAddress2}
                                      maxLength={320}
                                      autoComplete="off"
                                    ></input>
                                    <div
                                      id={"address2_error"}
                                      className="invalid-feedback"
                                    >
                                      Please enter an Apartment, Suite,
                                      Building, or Gate Code.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ paddingTop: "20px" }}
                                >
                                  <div className="col">
                                    <label aria-label="City">
                                      <strong> City* </strong>
                                    </label>
                                    <input
                                      id={"region"}
                                      className="form-control form-control form-control-15"
                                      value={this.state.city}
                                      onChange={this.handleChange}
                                      aria-label="Aria Region"
                                      onBlur={this.onBlur}
                                      maxLength={320}
                                      required
                                      autoComplete="off"
                                    />
                                    <div
                                      id={"region_error"}
                                      className="invalid-feedback"
                                    >
                                      Please enter a City
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ paddingTop: "20px" }}
                                >
                                  <div className="col">
                                    <label>
                                      <strong>
                                        State/Province
                                        {this.state.is_state_mandatory
                                          ? "*"
                                          : ""}{" "}
                                      </strong>
                                    </label>

                                    <DropdownButton
                                      id={"state"}
                                      type="default"
                                      variant="secondary"
                                      title={this.state.state}
                                      selected={false}
                                      autoComplete="new-address"
                                      aria-label="aria_state_resmodal"
                                      onSelect={(key, event) => {
                                        //event.target.id = "state";
                                        event.target.value = key;
                                        this.handleChange(event);
                                      }}
                                      onBlur={this.onBlur}
                                      onClick={clickFunc}
                                      onFocus={this.onFocusState}
                                      maxLength={320}
                                      // disabled={this.state.country !== "US"}
                                      required
                                    >
                                      <Dropdown.Item
                                        eventKey={""}
                                        id="state_option_blank"
                                      ></Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"PR"}
                                        id="state_option_PR"
                                      >
                                        PR - Puerto Rico
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"AL"}
                                        id="state_option_AL"
                                      >
                                        AL - Alabama
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"AK"}
                                        id="state_option_AK"
                                      >
                                        AK - Alaska
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"AZ"}
                                        id="state_option_AZ"
                                      >
                                        AZ - Arizona
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"AR"}
                                        id="state_option_AR"
                                      >
                                        AR - Arkansas
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"CA"}
                                        id="state_option_CA"
                                      >
                                        CA - California
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"CO"}
                                        id="state_option_CO"
                                      >
                                        CO - Colorodo
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"CT"}
                                        id="state_option_CT"
                                      >
                                        CT - Connecticut
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"DE"}
                                        id="state_option_DE"
                                      >
                                        DE - Delaware
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"DC"}
                                        id="state_option_DC"
                                      >
                                        DC - District of Columbia
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"FL"}
                                        id="state_option_FL"
                                      >
                                        FL - Florida
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"GA"}
                                        id="state_option_GA"
                                      >
                                        GA - Georgia
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"HI"}
                                        id="state_option_HI"
                                      >
                                        HI - Hawaii
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"ID"}
                                        id="state_option_ID"
                                      >
                                        ID - Idaho
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"IL"}
                                        id="state_option_IL"
                                      >
                                        IL - Illinois
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"IN"}
                                        id="state_option_IN"
                                      >
                                        IN - Indiana
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"IA"}
                                        id="state_option_IA"
                                      >
                                        IA - Iowa
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"KS"}
                                        id="state_option_KS"
                                      >
                                        KS - Kansas
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"KY"}
                                        id="state_option_KY"
                                      >
                                        KY - Kentucky
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"LA"}
                                        id="state_option_LA"
                                      >
                                        LA - Louisiana
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"ME"}
                                        id="state_option_ME"
                                      >
                                        ME - Maine
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"MD"}
                                        id="state_option_MD"
                                      >
                                        MD - Maryland
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"MA"}
                                        id="state_option_MA"
                                      >
                                        MA - Massachusetts
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"MI"}
                                        id="state_option_MI"
                                      >
                                        MI - Michigan
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"MN"}
                                        id="state_option_MN"
                                      >
                                        MN - Minnesota
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"MS"}
                                        id="state_option_MS"
                                      >
                                        MS - Mississippi
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"MO"}
                                        id="state_option_MO"
                                      >
                                        MO - Missouri
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"MT"}
                                        id="state_option_MT"
                                      >
                                        MT - Montana
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"NE"}
                                        id="state_option_NE"
                                      >
                                        NE - Nebraska
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"NV"}
                                        id="state_option_NV"
                                      >
                                        NV - Nevada
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"NH"}
                                        id="state_option_NH"
                                      >
                                        NH - New Hampshire
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"NJ"}
                                        id="state_option_NJ"
                                      >
                                        NJ - New Jersey
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"NM"}
                                        id="state_option_NM"
                                      >
                                        NM - New Mexico
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"NY"}
                                        id="state_option_NY"
                                      >
                                        NY - New York
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"NC"}
                                        id="state_option_NC"
                                      >
                                        NC - North Carolina
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"ND"}
                                        id="state_option_ND"
                                      >
                                        ND - North Dakota
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"OH"}
                                        id="state_option_OH"
                                      >
                                        OH - Ohio
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"OK"}
                                        id="state_option_OK"
                                      >
                                        OK - Oklahoma
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"OR"}
                                        id="state_option_OR"
                                      >
                                        OR - Oregon
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"PA"}
                                        id="state_option_PA"
                                      >
                                        PA - Pennsylvania
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"RI"}
                                        id="state_option_RI"
                                      >
                                        RI - Rhode Island
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"SC"}
                                        id="state_option_SC"
                                      >
                                        SC - South Carolina
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"SD"}
                                        id="state_option_SD"
                                      >
                                        SD - South Dakota
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"TN"}
                                        id="state_option_TN"
                                      >
                                        TN - Tennessee
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"TX"}
                                        id="state_option_TX"
                                      >
                                        TX - Texas
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"UT"}
                                        id="state_option_UT"
                                      >
                                        UT - Utah
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"VT"}
                                        id="state_option_VT"
                                      >
                                        VT - Vermont
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"VA"}
                                        id="state_option_VA"
                                      >
                                        VA - Virginia
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"WA"}
                                        id="state_option_WA"
                                      >
                                        WA - Washington
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"WV"}
                                        id="state_option_WV"
                                      >
                                        WV - West Virginia
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"WI"}
                                        id="state_option_WI"
                                      >
                                        WI - Wisconsin
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"WY"}
                                        id="state_option_WY"
                                      >
                                        WY - Wyoming
                                      </Dropdown.Item>
                                    </DropdownButton>
                                    <div
                                      id={"state_error"}
                                      className="invalid-feedback"
                                    >
                                      Please enter a State
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ paddingTop: "20px" }}
                                >
                                  <div className="col">
                                    <label>
                                      <strong> ZIP Code* </strong>
                                    </label>
                                    <input
                                      id={"zip_code"}
                                      className="form-control form-control form-control-15"
                                      value={this.state.zip_code}
                                      onChange={this.handleChange}
                                      aria-label="aria_zip_code_resmodal"
                                      //autoComplete="postal-code"
                                      onBlur={this.onBlur}
                                      required
                                      maxLength={10}
                                      onKeyPress={onlyAlphaNumeric}
                                      autoComplete="off"
                                    ></input>
                                    <div
                                      id={"zip_code_error"}
                                      className="invalid-feedback"
                                    >
                                      Please enter a Zip Code
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ paddingTop: "15px" }}
                                >
                                  <div className="col-md-12">
                                    <label>
                                      <strong>Country*</strong>
                                    </label>
                                    <DropdownButton
                                      id="country"
                                      type="default"
                                      variant="secondary"
                                      title={this.state.country}
                                      selected={false}
                                      autoComplete="new-address"
                                      onSelect={(key, event) => {
                                        event.target.id = "country";
                                        event.target.value = key;
                                        this.handleChange(event);
                                      }}
                                      onBlur={this.onBlur}
                                      onClick={clickFunc}
                                      onFocus={this.onFocusState}
                                      required
                                    >
                                      {COUNTRIES.map((option) => (
                                        <Dropdown.Item
                                          key={option.code}
                                          eventKey={option.code}
                                          id={`country_option_${option.code}`}
                                        >
                                          {option.code + " - " + option.name}
                                        </Dropdown.Item>
                                      ))}
                                    </DropdownButton>
                                  </div>
                                  <div
                                    id="country_error"
                                    className="invalid-feedback"
                                    style={{ paddingLeft: "17px" }}
                                  >
                                    Please select a Country
                                  </div>
                                </div>
                                {this.state.show_urbanization === true && (
                                  <div
                                    className="row"
                                    style={{ paddingTop: "20px" }}
                                  >
                                    <div className="col">
                                      <label>
                                        <strong> Urbanization </strong>
                                      </label>
                                      <input
                                        id={"urbanization"}
                                        type="text"
                                        className="form-control form-control form-control-15"
                                        value={this.state.urbanization}
                                        onChange={this.handleChange}
                                        onBlur={this.onBlur}
                                        maxLength={100}
                                        autoComplete="address-line3"
                                        placeholder="Puerto Rico Residents Only"
                                      ></input>
                                    </div>
                                  </div>
                                )}
                                {this.state.us_address_required === true && (
                                  <div
                                    className="row"
                                    style={{
                                      paddingTop: "20px",
                                    }}
                                  >
                                    <div className="col-12">
                                      <div
                                        id="NonUSAddressAsCurrentAlert"
                                        className="alert-border alert alert-danger--compact"
                                        role="alert"
                                      >
                                        Your current address cannot be outside
                                        of the United States.<br></br> Please
                                        contact your Agency Licensing
                                        Coordinator or Agency Supervisory
                                        Officer for help.
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div
                                  className="row"
                                  style={{
                                    paddingTop: "40px",
                                    paddingBottom: "20px",
                                    float: "right",
                                  }}
                                >
                                  <div
                                    className="col-md-12"
                                    style={{
                                      textAlign: "right",
                                      display: "inline-flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "5px 25px",
                                        color: "#3174af",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span onClick={this.handleCloseModal}>
                                        <strong> Cancel </strong>
                                      </span>
                                    </div>

                                    <div>
                                      <Button
                                        label="Next"
                                        onClick={this.handleNextClick}
                                        disabled={
                                          this.state.is_next_disabled ||
                                          this.state.is_loading
                                          // ||
                                          // this.state.state_is_changed === false
                                        }
                                      />
                                    </div>

                                    {this.state.is_loading === true && (
                                      <div
                                        style={{
                                          margin: "5px 20px",
                                        }}
                                      >
                                        <div className="mm-dots-loading" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        {
          <USPSValidationModal
            isOpen={
              this.state.show_verification_modal && !this.state.showBackModal
            }
            closeModal={this.handleBack}
            useSuggestedAddress={this.handleUseSuggestedAddress}
            residentialhistoryid={this.state.residentialhistoryid}
            street_address={this.state.autocomplete}
            address2={this.state.address2}
            city={this.state.city}
            state={this.state.state}
            zip_code={this.state.zip_code}
            country={this.state.country}
            usps_address={this.state.usps_address}
            usps_address2={this.state.usps_address2}
            usps_city={this.state.usps_city}
            usps_state={this.state.usps_state}
            usps_country={this.state.usps_country}
            usps_zip_code={this.state.usps_zip_code}
            urbanization={this.state.urbanization}
            addressNotFound={this.state.addressNotFound}
            addToAddressHistory={this.props.addToAddressHistory}
            move_in_date={this.state.move_in_date}
            move_out_date={this.state.move_out_date}
            is_current_address={this.state.is_current_address}
            editExistingAddress={this.props.editExistingAddress}
            editAddress={this.props.editAddress}
            handleAddAddress={this.props.handleAddAddress}
            formHasChanged={this.state.state_is_changed}
            usps_urbanization={this.state.usps_urbanization}
            usps_modal_is_loading={this.props.usps_modal_is_loading}
            formId={"residential_history"}
            modalclose={this.modalclose}
            county={this.state.county}
            hasCurrentFormChanged={this.state.formHasChanged}
          />
        }
        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </>
    );
  }
}

export default AddAddressModal;
