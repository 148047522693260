import moment from "moment";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../constants/Contract";

export function isValidDate(date) {
  let dateParts = date.split("/");
  let month, day, year, checkDate;

  if (dateParts.length === 3) {
    month = dateParts[0];
    day = dateParts[1];
    year = dateParts[2];
    checkDate = moment(month + "/" + day + "/" + year, "MM/DD/YYYY", true);
    return checkDate.isValid();
  } else if (dateParts.length === 2) {
    month = dateParts[0];
    year = dateParts[1];
    checkDate = moment(month + "/" + year, "MM/YYYY", true);
    return checkDate.isValid();
  } else {
    return false;
  }
}

export function clickFunc(event) {
  event.target.focus();
}

export function onlyNumeric(event) {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
}

export function onlyAlphaNumeric(event) {
  if (!/[a-zA-Z0-9]/.test(event.key)) {
    event.preventDefault();
  }
}

export function createDateObject(dateString) {
  var momentObj = moment(dateString, "MM-DD-YYYY");
  var momentString = momentObj.format("YYYY-MM-DD");
  return momentString;
}

export function pasteCommaDelimited(event) {
  const paste = event.clipboardData.getData("text/plain");
  const cleanPaste = paste.replaceAll(",", "");

  if (isNaN(cleanPaste)) {
    event.preventDefault();
  }
}

export function pasteZipCode(event) {
  var paste = event.clipboardData.getData("text/plain");
  if (!paste.match(/^[0-9a-z]+$/)) {
    event.preventDefault();
  }
}

export function pasteValue(event) {
  var paste = event.clipboardData.getData("text/plain");
  if (!paste.match(/^[0-9]+$/)) {
    event.preventDefault();
  }
}

export function isSafari() {
  const isSafariBrowser =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );
  return isSafariBrowser;
}

export function formatMoney(value) {
  let cleanNumber = value;
  if (value?.length > 1) {
    while (cleanNumber.charAt(0) === "0") {
      cleanNumber = cleanNumber.substring(1);
    }
  }
  return cleanNumber
    .replace(/,/gi, "")
    .replace("-", "")
    .split(/(?=(?:\d{3})+$)/)
    .join(",");
}
export function dateValidator(value) {
  const today = moment();
  const date = moment(value, "MM/DD/YYYY");
  const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));
  let isInvalid;
  let errMessage = "";
  if (
    date._i.length === 10 &&
    !date._i.includes("_") &&
    date.isValid() &&
    !value.includes("0000")
  ) {
    if (date < past || value.slice(-4, -2) === "00") {
      errMessage = "Date cannot be more than 100 years ago";
      isInvalid = true;
    } else if (moment(value) > today) {
      errMessage = "Date cannot be a future date";
      isInvalid = true;
    } else {
      isInvalid = false;
    }
  } else {
    errMessage = "Please enter a valid date";
    isInvalid = true;
  }
  return { isInvalid, errMessage };
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isCareerContract = (contractType) => {
  return (
    contractType === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
    contractType === CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
    contractType === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
    contractType === CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
    contractType === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
  );
};
