/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { Dropdown, DropdownButton } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";

import { clickFunc } from "../../../../../../../common/Functions";
import { logoutActions } from "../../../../../../../store/actions";
import { RADIO, dateInputMask, eventStatusDateType } from "../../../constants";

import BackButtonModal from "../../../../../Forms/BackButtonModal";

import "./RegActionResDetailsForm.scss";

const specialMatterResolved = {
  OTHER: "Other",
  DEFAULT_VALUE: "Select one",
};

const regActionInitiatedArr = [
  { key: 1, name: "Acceptance, Waiver & Consent (AWC)" },
  { key: 2, name: "Consent" },
  { key: 3, name: "Decision" },
  { key: 4, name: "Decision & Order of Offer of Settlement" },
  { key: 5, name: "Dismissed" },
  { key: 6, name: "Order" },
  { key: 7, name: "Settled" },
  { key: 8, name: "Stipulation and Consent" },
  { key: 9, name: "Vacated" },
  { key: 10, name: "Vacated Nunc Pro Tunc/ab initio" },
  { key: 11, name: "Withdrawn" },
  { key: 12, name: specialMatterResolved.OTHER },
];

const RegActionResDetailsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [finalOrder, setFinalOrder] = useState(
    typeof data?.finalOrder == "boolean" ? data?.finalOrder : null
  );

  const [selectedMatterResolved, setSelectedMatterResolved] = useState(
    data?.selectedMatterResolved || ""
  );

  const [dispOfAmendedChargeError, setDispOfAmendedChargeError] =
    useState(false);

  const [resolutionDate, setDateInitiated] = useState(
    data?.resolutionDate || ""
  );
  const [resolutionDateError, setResolutionDateError] = useState(false);
  const [resolutionDateErrorMsg, setResolutionDateErrorMsg] = useState("");
  const [resolutionDateRadio, setResolutionDateRadio] = useState(
    data?.resolutionDateRadio || ""
  );
  const [resolutionDateExp, setResolutionDateExp] = useState(
    data?.resolutionDateExp || ""
  );
  const [resolutionDateExpError, setResolutionDateExpError] = useState(false);

  const [selectedMatterResolvedExp, setSelectedMatterResolvedExp] = useState(
    data?.selectedMatterResolvedExp || ""
  );
  const [selectedMatterResolvedErr, setSelectedMatterResolvedError] =
    useState(false);

  const [formHasChanged, setFormHasChanged] = useState(false);

  const [showBackModal, setShowBackModal] = useState(false);

  const [isStartDateNextDisabled, setIsStartDateNextDisabled] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleChangeCheckedFinalOrder = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    setFinalOrder(newValue);
  };

  const isError =
    resolutionDateExpError ||
    resolutionDateError ||
    selectedMatterResolvedErr ||
    dispOfAmendedChargeError ||
    resolutionDateExpError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isResolutionDateDisabled =
    !resolutionDate?.length ||
    resolutionDate.includes("_") ||
    resolutionDate === dateInputMask.EMPTY ||
    (!resolutionDate?.length && !resolutionDateRadio?.length) ||
    !resolutionDateRadio?.length ||
    !resolutionDate?.length ||
    (resolutionDate?.length &&
      resolutionDateRadio?.length &&
      resolutionDateRadio === eventStatusDateType.EXPLANATION &&
      !resolutionDateExp?.length) ||
    (resolutionDateExp?.length && resolutionDateExp?.length < 2);

  const isNextBtnDisabled =
    !!(
      isStartDateNextDisabled ||
      !selectedMatterResolved?.length ||
      (selectedMatterResolved?.length &&
        selectedMatterResolved === specialMatterResolved.OTHER &&
        !selectedMatterResolvedExp.length) ||
      (selectedMatterResolved?.length &&
        selectedMatterResolved === specialMatterResolved.OTHER &&
        selectedMatterResolvedExp.length &&
        selectedMatterResolvedExp.length < 2) ||
      isResolutionDateDisabled ||
      typeof finalOrder !== "boolean"
    ) ||
    isError ||
    isFocusedState;

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      selectedMatterResolved,
      resolutionDate,
      resolutionDateRadio,
      finalOrder,
      resolutionDateExp,
      selectedMatterResolvedExp,
    };

    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    setFormHasChanged(false);

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    headerText,
    selectedMatterResolved,
    resolutionDate,
    resolutionDateRadio,
    resolutionDateExp,
    selectedMatterResolvedExp,
    getSubmitDataValues,
    groupKey,
    finalOrder,
  ]);

  const dropdownOnChangeMatterResolvedHandler = (key) => {
    setFormHasChanged(true);
    setSelectedMatterResolvedExp("");
    setSelectedMatterResolvedError(false);
    setSelectedMatterResolved("");
    setSelectedMatterResolvedError(false);
    setDispOfAmendedChargeError(false);
    setSelectedMatterResolved(key);
  };

  const dropdownOnBlurMatterResolvedHandler = () => {
    if (!selectedMatterResolved) {
      setDispOfAmendedChargeError(true);
    }
  };

  const handleChangeResolutionDateChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    setResolutionDateExpError(false);

    if (
      resolutionDateExp.length &&
      resolutionDateRadio !== eventStatusDateType.EXPLANATION
    ) {
      setResolutionDateExp("");
    }

    setResolutionDateRadio(newValue);
  };

  const onResolutionDateChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateInitiated(value);

    if (value?.length === 10 && !value.includes("_") && !resolutionDateError) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));
      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsStartDateNextDisabled(true);
        } else {
          setIsStartDateNextDisabled(false);
        }
      } else {
        setIsStartDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsStartDateNextDisabled(true);
        } else {
          setIsStartDateNextDisabled(false);
        }
      } else {
        setIsStartDateNextDisabled(true);
      }
    }
  };

  const onResolutionDateExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setResolutionDateExp(value);
  };

  const onBlurResolutionDateExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setResolutionDateExpError(true)
      : setResolutionDateExpError(false);
  };

  const onResolutionDateBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setIsStartDateNextDisabled(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setResolutionDateError(true);
      } else {
        setResolutionDateError(false);
      }
    } else {
      setResolutionDateError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setResolutionDateErrorMsg("Date cannot be a future date");
        setResolutionDateError(true);
      } else if (date < past) {
        setResolutionDateErrorMsg("Date cannot be more than 100 years ago");
        setResolutionDateError(true);
      } else {
        setResolutionDateError(false);
      }
    } else {
      setResolutionDateErrorMsg("Please enter a valid date");
      setResolutionDateError(true);
    }

    setFocusedState(false);
  };

  const onChangeSelectedMatterResolvedHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setSelectedMatterResolvedExp(value);
  };
  const onBlurSelectedMatterResolvedHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setSelectedMatterResolvedError(true)
      : setSelectedMatterResolvedError(false);
  };

  return (
    <div className="reg-action-res-details-form-wrapper">
      <div className="row disp-of-charge-container">
        <div className="col-md-12">
          <label
            id="matterResolvedLabelId"
            className="disp-of-charge-label"
            htmlFor="regActionInitiated"
          >
            How was the matter resolved? *
          </label>
          <DropdownButton
            id="regActionInitiated"
            type="default"
            variant="secondary"
            title={
              selectedMatterResolved
                ? selectedMatterResolved
                : specialMatterResolved.DEFAULT_VALUE
            }
            className={
              dispOfAmendedChargeError
                ? "is-invalid"
                : !selectedMatterResolved && "disp-of-charge"
            }
            selected={false}
            autoComplete="off"
            onSelect={dropdownOnChangeMatterResolvedHandler}
            onBlur={dropdownOnBlurMatterResolvedHandler}
            onClick={clickFunc}
          >
            {regActionInitiatedArr.map(({ name, key }) => (
              <Dropdown.Item key={`${name}_${key}`} eventKey={name} id={name}>
                {name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <div id="dispOfAmendedChargeError" className="invalid-feedback">
            Please select one
          </div>
        </div>
      </div>

      {selectedMatterResolved === specialMatterResolved.OTHER && (
        <div className="row full-name-of-reg-container">
          <div className="col-md-12">
            <input
              type="text"
              id="selectedMatterResolvedExp"
              className={
                selectedMatterResolvedErr
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              autoComplete="off"
              value={selectedMatterResolvedExp}
              onChange={onChangeSelectedMatterResolvedHandler}
              onBlur={onBlurSelectedMatterResolvedHandler}
              onFocus={() => setFocusedState(true)}
            />
            <div id="selectedMatterResolvedErr" className="invalid-feedback">
              Please enter a response
            </div>
          </div>
        </div>
      )}

      <div className="col-md-12 event-status-date-wrapper">
        <label id="resDateId" htmlFor="resDateId">
          <strong id="regResolutionDate">Resolution Date: *</strong>
        </label>
        <InputMask
          id="resDateId"
          mask="99/99/9999"
          maskChar="_"
          className={`${
            resolutionDateError ? "form-control is-invalid" : "form-control"
          } event-status-date-input`}
          placeholder="MM/DD/YYYY"
          autoComplete="off"
          required
          value={resolutionDate}
          onChange={onResolutionDateChangeHandler}
          onBlur={onResolutionDateBlurHandler}
          onFocus={() => setFocusedState(true)}
        />
        <div id="resolutionDateError" className="invalid-feedback">
          {resolutionDateErrorMsg?.length
            ? resolutionDateErrorMsg
            : "Please enter an Explanation"}
        </div>
      </div>
      <div className="row event-status-date-radio-group">
        <div className="custom-control">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-resDateExactId"
                type="radio"
                id="resDateExactId"
                className="custom-control-input"
                value={eventStatusDateType.EXACT}
                checked={resolutionDateRadio === eventStatusDateType.EXACT}
                onChange={handleChangeResolutionDateChecked}
              />
              <label
                htmlFor="resDateExactId"
                className="custom-control-label"
                id="resDateExactLabelId"
              >
                {eventStatusDateType.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-resDateExplanationId"
                type="radio"
                id="resDateExplanationId"
                className="custom-control-input"
                value={eventStatusDateType.EXPLANATION}
                checked={
                  resolutionDateRadio === eventStatusDateType.EXPLANATION
                }
                onChange={handleChangeResolutionDateChecked}
              />
              <label
                htmlFor="resDateExplanationId"
                className="custom-control-label"
                id="resDateExplanationLabelId"
              >
                {eventStatusDateType.EXPLANATION}
              </label>
            </div>
            {resolutionDateRadio === eventStatusDateType.EXPLANATION ? (
              <div className="event-status-date-explanation-container">
                <label
                  htmlFor="resDateExplanation"
                  className="event-status-date-exp"
                  id="resDateExplanationTextLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="resDateExplanation"
                  onChange={onResolutionDateExplanationChange}
                  className={
                    resolutionDateExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurResolutionDateExpHandler}
                  onFocus={() => setFocusedState(true)}
                  rows="3"
                  cols={500}
                  value={resolutionDateExp}
                />
                <div id="resolutionDateExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div style={{ position: "relative", top: "-15px" }}>
        <div className="row">
          <div className="col-md-12">
            <label className="inv-business" id="finalOrderLabelId">
              Does the order constitute a final order based on violations of any
              laws or regulations that prohibit fraudulent, manipulative, or
              deceptive conduct? *
            </label>
          </div>
        </div>
        <div className="row">
          <div className="drp-radio-input-wrapper">
            <div className="col-12 copy-md-4 form-group">
              <div className="custom-control custom-radio">
                <input
                  name="finalOrder"
                  type="radio"
                  id="finalOrder_yes"
                  className="custom-control-input"
                  value={true}
                  checked={finalOrder === true}
                  onChange={handleChangeCheckedFinalOrder}
                />
                <label
                  id="finalOrderYes"
                  htmlFor="finalOrder_yes"
                  className="custom-control-label"
                >
                  Yes
                </label>
              </div>

              <div className="custom-control custom-radio">
                <input
                  name="finalOrder"
                  type="radio"
                  id="finalOrder_no"
                  className="custom-control-input"
                  value={false}
                  checked={finalOrder === false}
                  onChange={handleChangeCheckedFinalOrder}
                />
                <label
                  id="finalOrderNo"
                  htmlFor="finalOrder_no"
                  className="custom-control-label"
                >
                  No
                </label>
              </div>
              <div id="radio_error" className="invalid-feedback">
                Please choose a response.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span id="regBack" role="presentation" onClick={handleShowBackModal}>
            <strong id="regBackText">Back</strong>
          </span>
          <Button
            label="Next"
            id="regNext"
            onClick={handleNext}
            disabled={isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default RegActionResDetailsForm;
