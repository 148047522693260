import { withOktaAuth } from "@okta/okta-react";
import React, { Suspense, createRef, lazy } from "react";
import {
  getAdditionalInformation,
  getBlankContract,
  getBlankDisclosure,
  getBrokerDealerInfo,
  getBusinessInfo,
  getCCBInfo,
  getCandidateCorp,
  getCandidateEducation,
  getCandidateMMLIS,
  getCombinedEmploymentHistoryInformation,
  getCorporation,
  getCorporationCCB,
  getCorporationDD,
  getCorporationEO,
  getDirectDepositInfo,
  getEducationDegrees,
  getEducationLevels,
  getEmploymentHistoryIndustries,
  getEmploymentHistoryInformation,
  getErrorsAndOmissions,
  getInternEducation,
  getMMLISObaCria,
  getPSRChartData,
  getPSREmploymentHistoryInformation,
  getPersonalInfoOpportunities,
  getPersonalInformation,
  getProfessionalDesignations,
  getReleaseInformation,
  getResidentialAddress,
  getSignatureWording,
  getStartAppDashboard,
  putESignatureInformation,
} from "../../api/makeAPIRequest";
import { getResidentialHistory } from "../../api/residentialHistory.js";
import { UtagTracking } from "../../utils/utag-tracking";
import Footer from "../SharedComponents/Footer";
import CorpBusinessAddressForm from "./BrokerCorpForms/CorpBusinessAddressForm";
import CorpCCBForm from "./BrokerCorpForms/CorporationCharacterCreditBondingForm";
import CorpDirectDepositForm from "./BrokerCorpForms/CorporationDirectDepositForm";
import CorporationErrorsAndOmissionsForm from "./BrokerCorpForms/CorporationErrorsAndOmissionsForm";
import CorpInformationForm from "./BrokerCorpForms/CorporationInformationForm";
import CivilJudicialForm from "./CASForms/CCB/CivilJudicialForm";
import CriminalDisclosureForm from "./CASForms/CCB/CriminalDisclosureForm";
import CustomerComplaintForm from "./CASForms/CCB/CustomerComplaintForm";
import FinancialDisclosureForm from "./CASForms/CCB/FinancialDisclosureForm";
import RegulatoryActionForm from "./CASForms/CCB/RegulatoryActionForm/index.js";
import TerminationDisclosureForm from "./CASForms/CCB/TerminationDisclosureForm";
import EducationForm from "./CASForms/EducationForm";
import EmploymentHistoryForm from "./CASForms/EmploymentHistoryForm";
import OtherBusinessForm from "./CASForms/OtherBusinessForm";
import PersonalInfoAddlForm from "./CASForms/PersonalInfoAddlForm";
import PersonalSalesRecordCharts from "./CASForms/PersonalSalesRecordCharts";
import PersonalSalesRecordForm from "./CASForms/PersonalSalesRecordForm";
import ProfessionalDesignationsForm from "./CASForms/ProfessionalDesignationsForm";
import ResidentialHistoryForm from "./CASForms/ResidentialHistoryForm";
import AuthorizationForm from "./Forms/AuthorizationForm";
import BrokerDealerForm from "./Forms/BrokerDealerForm";
import BusinessAddressForm from "./Forms/BusinessAddressForm";
import CCBForm from "./Forms/CharacterCreditBondingForm";
import DirectDepositForm from "./Forms/DirectDepositForm";
import ErrorsAndOmissionsForm from "./Forms/ErrorsAndOmissionsForm";
import PersonalInformationForm from "./Forms/PersonalInformationForm";
import ReleaseInformationForm from "./Forms/ReleaseInformationForm";
import ResidentialAddressForm from "./Forms/ResidentialAddressForm";
import ReviewSignForm1 from "./Forms/ReviewAndSign/ReviewSignForm1";
import ReviewSignForm2 from "./Forms/ReviewAndSign/ReviewSignForm2";
import MMLISIntroForm from "./MMLISForms/MMLISIntroForm";
import MMLISOBACRIAForm from "./MMLISForms/MMLISOBACRIAForm";
import MMLISPayToPlayForm1 from "./MMLISForms/MMLISPayToPlayForm1";
import MMLISPayToPlayForm2 from "./MMLISForms/MMLISPayToPlayForm2";
import "./StartApplication.scss";
import StartApplicationHeader from "./StartApplicationHeader";
import StartApplicationNavBROKER from "./StartApplicationNavBROKER";
import StartApplicationNavBROKERCORP from "./StartApplicationNavBROKERCORP";
import StartApplicationNavCAS from "./StartApplicationNavCAS";
import StartApplicationNavMEMBERCORP from "./StartApplicationNavMEMBERCORP";
import StartApplicationNavMMLIS from "./StartApplicationNavMMLIS";

import domToImage from "dom-to-image";
import Moment from "moment";
import { publicIpv4 } from "public-ip";
import { browserName, browserVersion } from "react-device-detect";
import { connect } from "react-redux";
import { getBankruptcyDRP } from "../../api/CCB/DRP/bankruptcyDisclosureDRP.js";
import { getBondDRP } from "../../api/CCB/DRP/bondDisclosureDRP.js";
import { getCCBDRPIndOrgList } from "../../api/CCB/DRP/criminalDisclosureDRP.js";
import { getCustomerComplaintDRP } from "../../api/CCB/DRP/customerComplaintDisclosureDRP.js";
import { getJudgmentLienDRP } from "../../api/CCB/DRP/judgmentLienDisclosureDRP.js";
import { getCCB14DRP } from "../../api/CCB/DRP/regulatoryDRP.js";
import { getTerminationDRP } from "../../api/CCB/DRP/terminationDisclosureDRP.js";
import { getCCB14 } from "../../api/CCB/ccb14.js";
import { DRP_TYPES } from "../../api/helpers/constants.js";
import { isSafari } from "../../common/Functions.js";
import {
  CONTRACT_STATUS_DESC,
  CONTRACT_TYPE_DESCRIPTIONS,
} from "../../constants/Contract.js";
import { FORM_ID } from "../../constants/Forms";
import { ccbActions } from "../../store/actions/index.js";
import { fetchCCB } from "../../store/middlewares/index.js";
import { startApplicationInitialState } from "../../store/state/index.js";
import Loader from "../SharedComponents/Loader";
import { KEYS } from "./CASForms/CCB/constants/index.js";
import { getValues } from "./CASForms/CCB/helpers/index.js";
import MOCInformationForm from "./MemberOfCorporation/MOCInformationForm.js";
import StartApplicationNavFinOnly from "./StartApplicationNavFinOnly.js";

const CCBIntroForm = lazy(() => import("./CASForms/CCB/CCBIntroForm"));
const ref = createRef(null);

const takeScreenShot = async (node) => {
  try {
    const dataURI = await domToImage.toPng(node, {
      quality: 1,
      style: { background: "white" },
    });
    return dataURI;
  } catch (err) {
    console.log(err);
  }
};

const downloadScreenshot = () => takeScreenShot(ref.current);

/* ************************************************************** *
 * USED GLOBALLY TO CLOSE ALERTS & DROPDOWNS WHEN CLICKING OUT    *
 * ************************************************************** */
window.onclick = function (event) {
  if (
    (!event.target.id.includes("dropbtn") &&
      !event.target.id.includes("mnuLink")) ||
    event.target.id.includes("mnuLinkReassign")
  ) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown2 = dropdowns[i];
      if (openDropdown2.classList.contains("show")) {
        openDropdown2.classList.remove("show");
      }
    }
  }
  var toast_address_deleted = document.getElementById("hist_delete_alert");
  if (toast_address_deleted !== null) {
    if (
      toast_address_deleted.classList.contains("show") &&
      event.target.id !== "add_button"
    ) {
      toast_address_deleted.classList.add("d-none");
      toast_address_deleted.classList.remove("show");
    }
  }
  var toast_emphist_added = document.getElementById("emphist_added_alert");
  if (toast_emphist_added !== null) {
    if (
      toast_emphist_added.classList.contains("show") &&
      event.target.id !== "add_button"
    ) {
      toast_emphist_added.classList.add("d-none");
      toast_emphist_added.classList.remove("show");
    }
  }
  var toast_psr_added = document.getElementById("psr_added_alert");
  if (toast_psr_added !== null) {
    if (
      toast_psr_added.classList.contains("show") &&
      event.target.id !== "add_button"
    ) {
      toast_psr_added.classList.add("d-none");
      toast_psr_added.classList.remove("show");
    }
  }
  var toast_add = document.getElementById("address_added_alert");
  if (toast_add !== null) {
    if (
      toast_add.classList.contains("show") &&
      event.target.id !== "add_button"
    ) {
      toast_add.classList.add("d-none");
      toast_add.classList.remove("show");
    }
  }
  var toast_emp_dltd = document.getElementById("emphist_deleted_alert");
  if (toast_emp_dltd !== null) {
    if (
      toast_emp_dltd.classList.contains("show") &&
      event.target.id !== "add_button"
    ) {
      toast_emp_dltd.classList.add("d-none");
      toast_emp_dltd.classList.remove("show");
    }
  }
  var toast_mmlis_add = document.getElementById(
    "mmlis_contribution_added_alert"
  );
  if (toast_mmlis_add !== null) {
    if (
      toast_mmlis_add.classList.contains("show") &&
      event.target.id !== "add_button"
    ) {
      toast_mmlis_add.classList.add("d-none");
      toast_mmlis_add.classList.remove("show");
    }
  }
  var toast_mmlis_delete = document.getElementById(
    "mmlis_contribution_delete_alert"
  );
  if (toast_mmlis_delete !== null) {
    if (
      toast_mmlis_delete.classList.contains("show") &&
      event.target.id !== "add_button"
    ) {
      toast_mmlis_delete.classList.add("d-none");
      toast_mmlis_delete.classList.remove("show");
    }
  }
};

const StartApplicationNavCASContainer = connect(
  ({ logoutModal: showLogoutModal }) => showLogoutModal
)(StartApplicationNavCAS);

const StartApplicationNavFinOnlyContainer = connect(
  ({ logoutModal: showLogoutModal }) => showLogoutModal
)(StartApplicationNavFinOnly);

class StartApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();

    //save information
    this.saveData = this.saveData.bind(this);
    this.saveProfDesData = this.saveProfDesData.bind(this);
    this.savePersonalInfoAdditionalInfo =
      this.savePersonalInfoAdditionalInfo.bind(this);
    this.saveEmpHistInfo = this.saveEmpHistInfo.bind(this);
    this.saveEduInfo = this.saveEduInfo.bind(this);
    this.saveInternEduInfo = this.saveInternEduInfo.bind(this);
    this.setBusinessAddressChecked = this.setBusinessAddressChecked.bind(this);
    this.saveMMLISInfo = this.saveMMLISInfo.bind(this);
    this.saveMMLISOBACRIAInfo = this.saveMMLISOBACRIAInfo.bind(this);

    //update application progress
    this.updateCurrentForm = this.updateCurrentForm.bind(this);
    this.updatePAddlInfoCompleteInd =
      this.updatePAddlInfoCompleteInd.bind(this);
    this.updateBrokerCompleteInd = this.updateBrokerCompleteInd.bind(this);

    //CASUpdateNav
    this.updateResHistoryCompleteInd =
      this.updateResHistoryCompleteInd.bind(this);
    this.updateEduCompleteInd = this.updateEduCompleteInd.bind(this);
    this.updateProfDesCompleteInd = this.updateProfDesCompleteInd.bind(this);
    this.updateEmpHistCompleteInd = this.updateEmpHistCompleteInd.bind(this);
    this.updatePSRCompleteInd = this.updatePSRCompleteInd.bind(this);
    this.updatePAddlInfoCompleteInd =
      this.updatePAddlInfoCompleteInd.bind(this);

    this.updateInd = this.updateInd.bind(this);

    //Seperate Loads for Contracts due to differences
    this.fetchBrokerData = this.fetchBrokerData.bind(this);
    this.fetchBrokerCorpData = this.fetchBrokerCorpData.bind(this);
    this.fetchMemberCorpData = this.fetchMemberCorpData.bind(this);
    this.fetchCareerData = this.fetchCareerData.bind(this);
    this.fetchMMLISData = this.fetchMMLISData.bind(this);
    this.fetchCollegeInternData = this.fetchCollegeInternData.bind(this);

    this.toggleFormComplete = this.toggleFormComplete.bind(this);
    this.updateCCB14CompletedInd = this.updateCCB14CompletedInd.bind(this);
    this.updateCCB14Step = this.updateCCB14Step.bind(this);

    this.hasCurrentFormChanged = this.hasCurrentFormChanged.bind(this);
    this.saveESigData = this.saveESigData.bind(this);
    this.refreshPSR = this.refreshPSR.bind(this);
    this.refreshPSRCharts = this.refreshPSRCharts.bind(this);
    this.buildFinancialPlanData = this.buildFinancialPlanData.bind(this);
    this.buildCommissionData = this.buildCommissionData.bind(this);
    this.onloadSetInds = this.onloadSetInds.bind(this);
    this.getOnloadConditionals = this.getOnloadConditionals.bind(this);

    this.updateCorpID = this.updateCorpID.bind(this);
  }

  initialState() {
    return startApplicationInitialState;
  }

  /* ************************************************************** *
   * SAVE FUNCTIONS:                                                *
   * Needed to save data to application state once updated in form  *
   * TO DO: consolidate save data functions to saveData() function  *
   * ************************************************************** */
  saveData(variable, object) {
    this.setState({ [variable]: object });
    if (
      (variable === "residentialAddress" ||
        variable === "residentialHistory") &&
      this.state.businessAddressChecked === true
    ) {
      //this.updateInd("BusAddrCompleteInd", false);
      this.setState({ resAddressHasChanged: true });
    }
    if (variable === "businessAddress") {
      this.setState({ resAddressHasChanged: false });
    }
  }

  savePersonalInfoAdditionalInfo(additionalInfo) {
    this.setState({ personalInfoAdditionalInfo: additionalInfo });
  }

  saveProfDesData(_profDesData) {
    this.setState({ ProfessionalDesignationsData: _profDesData });
    this.updateEduCompleteInd(_profDesData.hasprofdesignation !== null);
  }
  saveEduInfo(eduData) {
    this.setState({ eduInformation: eduData });
  }

  saveInternEduInfo(internEduData) {
    this.setState({ internEduInformation: internEduData });
  }

  async saveEmpHistInfo(empHistoryData) {
    this.setState({ empHistInformation: empHistoryData });

    for (var i = 0; i < empHistoryData.length; i++) {
      var emp = await getEmploymentHistoryInformation(
        this.props.authState.accessToken.claims.uid,
        empHistoryData[i].id
      );
      if (emp.salesposition === true) {
        await this.setState({ includePSR: true });
      }
    }
  }

  async saveMMLISInfo() {
    var result = await getCandidateMMLIS(this.state.oktaSubId);
    this.setState({ mmlisData: result });
  }

  async saveMMLISOBACRIAInfo() {
    var result = await getMMLISObaCria(this.state.oktaSubId);
    this.setState({ mmlisObaCriaData: result });
  }

  saveCCB14Data(ccb14) {
    this.setState({ ccb14 });
  }

  /* ********************************************************************* *
   * Update the Green Checkmarks on side nav:                              *
   * TO DO: CONSOLIDATE & CONVERT TO REUSABLE FUNCTION                     *
   * ********************************************************************* */

  async updatePAddlInfoCompleteInd(newInd) {
    await this.setState({ PAddlInfoCompleteInd: newInd });
    //this.checkReviewAndSign();
  }

  async updateResHistoryCompleteInd(newInd) {
    await this.setState({ resHistoryCompleteInd: newInd });
  }

  async updateBrokerCompleteInd(newInd) {
    await this.setState({ BrokerCompleteInd: newInd });
    this.checkReviewAndSign();
  }

  async updateEduCompleteInd(newInd) {
    await this.setState({ EduInfoCompleteInd: newInd });

    if (this.state.ProfessionalDesignationsData.hasprofdesignation !== null) {
      await this.setState({ ProfDesInfoCompleteInd: true });
    }
  }
  async updateProfDesCompleteInd(newInd) {
    await this.setState({ ProfDesInfoCompleteInd: newInd });
  }
  async updateEmpHistCompleteInd(newInd) {
    await this.setState({ EmpHistCompleteInd: newInd });
  }
  async updatePSRCompleteInd(newInd) {
    await this.setState({ PSRCompleteInd: newInd });
  }
  async updateInd(ind, newIndValue) {
    await this.setState({ [ind]: newIndValue });
    if (
      ind === "PInfoCompleteInd" &&
      this.state.personalInfoAdditionalInfo.length > 0
    ) {
      await this.setState({ PAddlInfoCompleteInd: true });
    }
    this.checkReviewAndSign();
  }

  updateCCB14CompletedInd(newInd) {
    this.setState({
      ...newInd,
    });
  }

  updateCCB14Step(newStep) {
    this.setState({
      ...newStep,
    });
  }

  updateCurrentForm(newFormNumber) {
    window.scrollTo(0, 0);
    this.setState({ currentForm: newFormNumber });
  }

  hasCurrentFormChanged(formChanged) {
    this.setState({ currentFormChanged: formChanged });
  }

  handleFormHasError = (val) => {
    this.setState({ formHasError: val });
  };

  /* ********************************************************************* *
   * THIS FUNCTION DECIDES WHICH FORM TO LAND ON DURING ONLOAD             *
   * ********************************************************************* */
  async toggleFormComplete() {
    this.setState({ isLoading: true });
    const { contracttypedesc } = this.state.dashboardInfo;

    const passes = await this.getOnloadConditionals(contracttypedesc);

    let pageToNavigateTo = "";
    let formNotCompleted = "";

    switch (contracttypedesc) {
      case CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER:
        for (var passBrokerForm in passes) {
          if (passes[passBrokerForm] === false) {
            formNotCompleted = passBrokerForm;
            break;
          }
        }

        if (formNotCompleted === "passReleaseOfInfoPage") {
          pageToNavigateTo = FORM_ID.RELEASE_OF_INFO;
        } else if (formNotCompleted === "passAuthToWorkPage") {
          pageToNavigateTo = FORM_ID.AUTHORIZATION;
        } else if (formNotCompleted === "passPersonalInfoPage") {
          pageToNavigateTo = FORM_ID.PERSONAL_INFO;
        } else if (formNotCompleted === "passResAddressPage") {
          pageToNavigateTo = FORM_ID.RESIDENTIAL_ADDRESS;
        } else if (formNotCompleted === "passBusInfoPage") {
          pageToNavigateTo = FORM_ID.BUSINESS_INFO;
        } else if (formNotCompleted === "passBrokerDealerPage") {
          pageToNavigateTo = FORM_ID.BROKER_DEALER;
        } else if (formNotCompleted === "passCCBPage") {
          pageToNavigateTo = FORM_ID.CCB;
        } else if (formNotCompleted === "passEOCPage") {
          pageToNavigateTo = FORM_ID.ERRORS_OMISSIONS;
        } else if (formNotCompleted === "passDirectDepositInfoPage") {
          pageToNavigateTo = FORM_ID.DIRECT_DEPOSIT;
        } else {
          pageToNavigateTo = FORM_ID.REVIEW_SIGN_1;
        }

        this.updateCurrentForm(pageToNavigateTo);

        this.onloadSetInds(
          {
            passReleaseOfInfoPage: passes.passReleaseOfInfoPage,
            passAuthToWorkPage: passes.passAuthToWorkPage,
            passPersonalInfoPage: passes.passPersonalInfoPage,
            passResAddressPage: passes.passResAddressPage,
            passBusInfoPage: passes.passBusInfoPage,
            passBrokerDealerPage: passes.passBrokerDealerPage,
            passCCBPage: passes.passCCBPage,
            passEOCPage: passes.passEOCPage,
            passDirectDepositInfoPage: passes.passDirectDepositInfoPage,
          },
          contracttypedesc
        );
        this.checkReviewAndSign();
        break;

      case CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP:
        for (var passMemberCorpForm in passes) {
          if (passes[passMemberCorpForm] === false) {
            formNotCompleted = passMemberCorpForm;
            break;
          }
        }

        if (formNotCompleted === "passReleaseOfInfoPage") {
          pageToNavigateTo = FORM_ID.RELEASE_OF_INFO;
        } else if (formNotCompleted === "passAuthToWorkPage") {
          pageToNavigateTo = FORM_ID.AUTHORIZATION;
        } else if (formNotCompleted === "passMemberCorpInfo") {
          pageToNavigateTo = FORM_ID.MEMBER_CORPORATE_INFO;
        } else if (formNotCompleted === "passPersonalInfoPage") {
          pageToNavigateTo = FORM_ID.PERSONAL_INFO;
        } else if (formNotCompleted === "passResAddressPage") {
          pageToNavigateTo = FORM_ID.RESIDENTIAL_ADDRESS;
        } else if (formNotCompleted === "passBusInfoPage") {
          pageToNavigateTo = FORM_ID.BUSINESS_INFO;
        } else if (formNotCompleted === "passBrokerDealerPage") {
          pageToNavigateTo = FORM_ID.BROKER_DEALER;
        } else if (formNotCompleted === "passCCBPage") {
          pageToNavigateTo = FORM_ID.CCB;
        } else if (formNotCompleted === "passEOCPage") {
          pageToNavigateTo = FORM_ID.ERRORS_OMISSIONS;
        } else if (formNotCompleted === "passDirectDepositInfoPage") {
          pageToNavigateTo = FORM_ID.DIRECT_DEPOSIT;
        } else {
          pageToNavigateTo = FORM_ID.REVIEW_SIGN_1;
        }

        this.updateCurrentForm(pageToNavigateTo);

        this.onloadSetInds(
          {
            passReleaseOfInfoPage: passes.passReleaseOfInfoPage,
            passAuthToWorkPage: passes.passAuthToWorkPage,
            passMemberCorpInfo: passes.passMemberCorpInfo,
            passPersonalInfoPage: passes.passPersonalInfoPage,
            passResAddressPage: passes.passResAddressPage,
            passBusInfoPage: passes.passBusInfoPage,
            passBrokerDealerPage: passes.passBrokerDealerPage,
            passCCBPage: passes.passCCBPage,
            passEOCPage: passes.passEOCPage,
          },
          contracttypedesc
        );
        this.checkReviewAndSign();
        break;

      case CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP:
        for (var passBrokerCorpForm in passes) {
          if (passes[passBrokerCorpForm] === false) {
            formNotCompleted = passBrokerCorpForm;
            break;
          }
        }
        if (formNotCompleted === "passCorpInfo") {
          pageToNavigateTo = FORM_ID.CORPORATE_INFO;
        } else if (formNotCompleted === "passCorpBusInfoPage") {
          pageToNavigateTo = FORM_ID.CORPORATE_BUSINESS_ADDRESS;
        } else if (formNotCompleted === "passCorpCCB") {
          pageToNavigateTo = FORM_ID.CORPORATE_CCB;
        } else if (formNotCompleted === "passCorpEO") {
          pageToNavigateTo = FORM_ID.CORPORATE_ERRORS_OMISSIONS;
        } else if (formNotCompleted === "passCorpDirectDeposit") {
          pageToNavigateTo = FORM_ID.CORPORATE_DIRECT_DEPOSIT;
        } else if (formNotCompleted === "passReleaseOfInfoPage") {
          pageToNavigateTo = FORM_ID.RELEASE_OF_INFO;
        } else if (formNotCompleted === "passAuthToWorkPage") {
          pageToNavigateTo = FORM_ID.AUTHORIZATION;
        } else if (formNotCompleted === "passPersonalInfoPage") {
          pageToNavigateTo = FORM_ID.PERSONAL_INFO;
        } else if (formNotCompleted === "passResAddressPage") {
          pageToNavigateTo = FORM_ID.RESIDENTIAL_ADDRESS;
        } else if (formNotCompleted === "passBusInfoPage") {
          pageToNavigateTo = FORM_ID.BUSINESS_INFO;
        } else if (formNotCompleted === "passBrokerDealerPage") {
          pageToNavigateTo = FORM_ID.BROKER_DEALER;
        } else if (formNotCompleted === "passCCBPage") {
          pageToNavigateTo = FORM_ID.CCB;
        } else if (formNotCompleted === "passEOCPage") {
          pageToNavigateTo = FORM_ID.ERRORS_OMISSIONS;
        } else {
          pageToNavigateTo = FORM_ID.REVIEW_SIGN_1;
        }

        this.updateCurrentForm(pageToNavigateTo);

        this.onloadSetInds(
          {
            passCorpInfo: passes.passCorpInfo,
            passBusInfoPage: passes.passBusInfoPage,
            passCorpCCB: passes.passCorpCCB,
            passCorpEO: passes.passCorpEO,
            passCorpDirectDeposit: passes.passCorpDirectDeposit,
            passCorpBusInfoPage: passes.passCorpBusInfoPage,
            passReleaseOfInfoPage: passes.passReleaseOfInfoPage,
            passAuthToWorkPage: passes.passAuthToWorkPage,
            passPersonalInfoPage: passes.passPersonalInfoPage,
            passResAddressPage: passes.passResAddressPage,
            passBrokerDealerPage: passes.passBrokerDealerPage,
            passCCBPage: passes.passCCBPage,
            passEOCPage: passes.passEOCPage,
          },
          contracttypedesc
        );
        this.checkReviewAndSign();
        break;

      case CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT:
      case CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING:
      case CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN:
      case CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT:
      case CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY:
        formNotCompleted = "";
        for (var passCASForm in passes) {
          if (
            (passes[passCASForm] === false &&
              contracttypedesc.includes("Career")) ||
            (passes[passCASForm] === false &&
              contracttypedesc.includes("Prelim")) ||
            (passes[passCASForm] === false &&
              contracttypedesc ===
                CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT) ||
            (passes[passCASForm] === false &&
              //passCASForm !== "passBusInfoPage" &&
              contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN)
          ) {
            formNotCompleted = passCASForm;
            break;
          }
        }
        if (formNotCompleted === "passReleaseOfInfoPage") {
          pageToNavigateTo = FORM_ID.RELEASE_OF_INFO;
        } else if (formNotCompleted === "passAuthToWorkPage") {
          pageToNavigateTo = FORM_ID.AUTHORIZATION;
        } else if (formNotCompleted === "passPersonalInfoPage") {
          pageToNavigateTo = FORM_ID.PERSONAL_INFO;
        } else if (formNotCompleted === "passAdditionalInfoPage") {
          pageToNavigateTo = FORM_ID.PERSONAL_INFO_ADDITIONAL_INFO;
        } else if (formNotCompleted === "passResHistoryPage") {
          pageToNavigateTo = FORM_ID.RESIDENTIAL_HISTORY;
        } else if (
          contracttypedesc.includes("Career") &&
          contracttypedesc.includes("Prelim") &&
          formNotCompleted === "passBusInfoPage"
        ) {
          pageToNavigateTo = FORM_ID.BUSINESS_INFO;
        } else if (formNotCompleted === "passEducationPage") {
          pageToNavigateTo = FORM_ID.EDUCATION;
        } else if (formNotCompleted === "passProfessionalDesPage") {
          pageToNavigateTo = FORM_ID.PROFESSIONAL_DESIGNATION;
        } else if (formNotCompleted === "passEmploymentHistoryPage") {
          pageToNavigateTo = FORM_ID.EMPLOYMENT_HISTORY;
        } else if (formNotCompleted === "passPSRPage") {
          pageToNavigateTo = FORM_ID.PERSONAL_SALES_RECORD;
        } else if (formNotCompleted === "passCCBCriminalDisclPage") {
          pageToNavigateTo = FORM_ID.CRIMINAL_DISCLOSURE;
        } else if (formNotCompleted === "passCCBRegulatoryActionDiscl") {
          pageToNavigateTo = FORM_ID.REGULATORY_ACTION_DISCLOSURE;
        } else if (formNotCompleted === "passCCBCivilJudicialDiscl") {
          pageToNavigateTo = FORM_ID.CIVIL_JUDICIAL_DISCLOSURE;
        } else if (formNotCompleted === "passCCBCustomerComplDiscl") {
          pageToNavigateTo = FORM_ID.CUSTOMER_COMPLAINT_DISCLOSURE;
        } else if (formNotCompleted === "passCCBTerminationDiscl") {
          pageToNavigateTo = FORM_ID.TERMINATION_DISCLOSURE;
        } else if (formNotCompleted === "passCCBFinancialDiscl") {
          pageToNavigateTo = FORM_ID.FINANCIAL_DISCLOSURE;
        } else if (formNotCompleted === "passDirectDepositInfoPage") {
          pageToNavigateTo = FORM_ID.DIRECT_DEPOSIT;
        } else if (
          formNotCompleted === "passMMLISPayToPlay1Page" &&
          this.state.dashboardInfo.mmlis
        ) {
          pageToNavigateTo = FORM_ID.MMLIS_INTRO;
        } else if (
          formNotCompleted === "passMMLISPayToPlay2Page" &&
          this.state.dashboardInfo.mmlis
        ) {
          pageToNavigateTo = FORM_ID.MMLIS_PAY_TO_PLAY_2;
        } else if (
          formNotCompleted === "passMMLISOBACRIAPage" &&
          this.state.dashboardInfo.mmlis
        ) {
          pageToNavigateTo = FORM_ID.MMLIS_OBA_CRIA;
        } else {
          pageToNavigateTo = FORM_ID.REVIEW_SIGN_1;
        }

        this.updateCurrentForm(pageToNavigateTo);

        this.onloadSetInds(
          {
            passReleaseOfInfoPage: passes.passReleaseOfInfoPage,
            passAuthToWorkPage: passes.passAuthToWorkPage,
            passPersonalInfoPage: passes.passPersonalInfoPage,
            passAdditionalInfoPage: passes.passAdditionalInfoPage,
            passResHistoryPage: passes.passResHistoryPage,
            passBusInfoPage: passes.passBusInfoPage,
            passEducationPage: passes.passEducationPage,
            passProfessionalDesPage: passes.passProfessionalDesPage,
            passEmploymentHistoryPage: passes.passEmploymentHistoryPage,
            passCCBCriminalDisclPage: passes.passCCBCriminalDisclPage,
            passCCBRegulatoryActionDiscl: passes.passCCBRegulatoryActionDiscl,
            passCCBCivilJudicialDiscl: passes.passCCBCivilJudicialDiscl,
            passCCBCustomerComplDiscl: passes.passCCBCustomerComplDiscl,
            passCCBTerminationDiscl: passes.passCCBTerminationDiscl,
            passCCBFinancialDiscl: passes.passCCBFinancialDiscl,
            passDirectDepositInfoPage: passes.passDirectDepositInfoPage,
            passMMLISOBACRIAPage: passes.passMMLISOBACRIAPage,
            passMMLISPayToPlay1Page: passes.passMMLISPayToPlay1Page,
            passMMLISPayToPlay2Page: passes.passMMLISPayToPlay2Page,
          },
          contracttypedesc
        );

        this.checkReviewAndSign();

        break;
      case CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY:
        formNotCompleted = "";

        for (var passFinOnlyForm in passes) {
          if (passes[passFinOnlyForm] === false) {
            formNotCompleted = passFinOnlyForm;
            break;
          }
        }

        if (formNotCompleted === "passPersonalInfoPage") {
          pageToNavigateTo = FORM_ID.PERSONAL_INFO;
        } else {
          pageToNavigateTo = FORM_ID.REVIEW_SIGN_1;
        }

        this.updateCurrentForm(pageToNavigateTo);

        this.onloadSetInds(
          {
            passPersonalInfoPage: passes.passPersonalInfoPage,
          },
          contracttypedesc
        );

        this.checkReviewAndSign();
        break;
      case CONTRACT_TYPE_DESCRIPTIONS.MMLIS:
        formNotCompleted = "";
        for (var passMMLISForm in passes) {
          if (passes[passMMLISForm] === false) {
            formNotCompleted = passMMLISForm;
            break;
          }
        }

        if (formNotCompleted === "passReleaseOfInfoPage") {
          pageToNavigateTo = FORM_ID.RELEASE_OF_INFO;
        } else if (formNotCompleted === "passAuthToWorkPage") {
          pageToNavigateTo = FORM_ID.AUTHORIZATION;
        } else if (formNotCompleted === "passPersonalInfoPage") {
          pageToNavigateTo = FORM_ID.PERSONAL_INFO;
        } else if (formNotCompleted === "passAdditionalInfoPage") {
          pageToNavigateTo = FORM_ID.PERSONAL_INFO_ADDITIONAL_INFO;
        } else if (formNotCompleted === "passResHistoryPage") {
          pageToNavigateTo = FORM_ID.RESIDENTIAL_HISTORY;
        } else if (formNotCompleted === "passBusInfoPage") {
          pageToNavigateTo = FORM_ID.BUSINESS_INFO;
        } else if (formNotCompleted === "passEmploymentHistoryPage") {
          pageToNavigateTo = FORM_ID.EMPLOYMENT_HISTORY;
        } else if (formNotCompleted === "passPSRPage") {
          pageToNavigateTo = FORM_ID.PERSONAL_SALES_RECORD;
        } else if (formNotCompleted === "passCCBCriminalDisclPage") {
          pageToNavigateTo = FORM_ID.CRIMINAL_DISCLOSURE;
        } else if (formNotCompleted === "passCCBRegulatoryActionDiscl") {
          pageToNavigateTo = FORM_ID.REGULATORY_ACTION_DISCLOSURE;
        } else if (formNotCompleted === "passCCBCivilJudicialDiscl") {
          pageToNavigateTo = FORM_ID.CIVIL_JUDICIAL_DISCLOSURE;
        } else if (formNotCompleted === "passCCBCustomerComplDiscl") {
          pageToNavigateTo = FORM_ID.CUSTOMER_COMPLAINT_DISCLOSURE;
        } else if (formNotCompleted === "passCCBTerminationDiscl") {
          pageToNavigateTo = FORM_ID.TERMINATION_DISCLOSURE;
        } else if (formNotCompleted === "passCCBFinancialDiscl") {
          pageToNavigateTo = FORM_ID.FINANCIAL_DISCLOSURE;
        } else if (formNotCompleted === "passMMLISPayToPlay1Page") {
          pageToNavigateTo = FORM_ID.MMLIS_INTRO;
        } else if (formNotCompleted === "passMMLISPayToPlay2Page") {
          pageToNavigateTo = FORM_ID.MMLIS_PAY_TO_PLAY_2;
        } else if (formNotCompleted === "passMMLISOBACRIAPage") {
          pageToNavigateTo = FORM_ID.MMLIS_OBA_CRIA;
        } else {
          pageToNavigateTo = FORM_ID.REVIEW_SIGN_1;
        }

        this.updateCurrentForm(pageToNavigateTo);

        this.onloadSetInds(
          {
            passReleaseOfInfoPage: passes.passReleaseOfInfoPage,
            passAuthToWorkPage: passes.passAuthToWorkPage,
            passPersonalInfoPage: passes.passPersonalInfoPage,
            passAdditionalInfoPage: passes.passAdditionalInfoPage,
            passResHistoryPage: passes.passResHistoryPage,
            passBusInfoPage: passes.passBusInfoPage,
            passEmploymentHistoryPage: passes.passEmploymentHistoryPage,
            passCCBCriminalDisclPage: passes.passCCBCriminalDisclPage,
            passCCBRegulatoryActionDiscl: passes.passCCBRegulatoryActionDiscl,
            passCCBCivilJudicialDiscl: passes.passCCBCivilJudicialDiscl,
            passCCBCustomerComplDiscl: passes.passCCBCustomerComplDiscl,
            passCCBTerminationDiscl: passes.passCCBTerminationDiscl,
            passCCBFinancialDiscl: passes.passCCBFinancialDiscl,
            passMMLISPayToPlay1Page: passes.passMMLISPayToPlay1Page,
            passMMLISPayToPlay2Page: passes.passMMLISPayToPlay2Page,
            passMMLISOBACRIAPage: passes.passMMLISOBACRIAPage,
          },
          contracttypedesc
        );

        this.checkReviewAndSign();

        break;

      default:
        break;
    }

    this.setState({ isLoading: false });
  }
  async checkReviewAndSign() {
    const { contracttypedesc } = this.state.dashboardInfo;

    switch (contracttypedesc) {
      case CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER:
        await this.setState({
          ReviewSignButtonDisabled: !(
            this.state.RInfoCompleteInd === true &&
            this.state.AuthorizationCompleteInd === true &&
            this.state.PInfoCompleteInd === true &&
            this.state.ResAddrCompleteInd === true &&
            this.state.BusAddrCompleteInd === true &&
            this.state.BrokerCompleteInd === true &&
            this.state.CCBCompleteInd === true &&
            this.state.EOCompleteInd === true &&
            this.state.DirectDepositCompleteInd === true
          ),
        });
        break;
      case CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT:
      case CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING:
      case CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN:
      case CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT:
      case CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY:
        await this.setState({
          ReviewSignButtonDisabled: !(
            this.state.RInfoCompleteInd === true &&
            this.state.AuthorizationCompleteInd === true &&
            this.state.PInfoCompleteInd === true &&
            this.state.PAddlInfoCompleteInd === true &&
            this.state.resHistoryCompleteInd === true &&
            this.state.BusAddrCompleteInd === true &&
            this.state.EduInfoCompleteInd === true &&
            this.state.ProfDesInfoCompleteInd === true &&
            this.state.EmpHistCompleteInd === true &&
            this.state.PSRCompleteInd === true &&
            this.state.BrokerCompleteInd === true &&
            this.state.crimDisclosureCompleteInd === true &&
            this.state.regActionDisclosureCompleteInd === true &&
            this.state.civilJudDisclosureCompleteInd === true &&
            this.state.custCompDisclosureCompleteInd === true &&
            this.state.termDisclosureCompleteInd === true &&
            this.state.finDisclosureCompleteInd === true &&
            this.state.DirectDepositCompleteInd === true &&
            this.state.MMLISPage1CompleteInd === true &&
            this.state.MMLISPage2CompleteInd === true &&
            this.state.MMLISOBACRICompleteInd === true
          ),
        });
        break;
      case CONTRACT_TYPE_DESCRIPTIONS.MMLIS:
        await this.setState({
          ReviewSignButtonDisabled: !(
            this.state.RInfoCompleteInd === true &&
            this.state.AuthorizationCompleteInd === true &&
            this.state.PInfoCompleteInd === true &&
            this.state.PAddlInfoCompleteInd === true &&
            this.state.resHistoryCompleteInd === true &&
            this.state.BusAddrCompleteInd === true &&
            this.state.EmpHistCompleteInd === true &&
            this.state.PSRCompleteInd === true &&
            this.state.BrokerCompleteInd === true &&
            this.state.crimDisclosureCompleteInd === true &&
            this.state.regActionDisclosureCompleteInd === true &&
            this.state.civilJudDisclosureCompleteInd === true &&
            this.state.custCompDisclosureCompleteInd === true &&
            this.state.termDisclosureCompleteInd === true &&
            this.state.finDisclosureCompleteInd === true &&
            this.state.MMLISPage1CompleteInd === true &&
            this.state.MMLISPage2CompleteInd === true &&
            this.state.MMLISOBACRICompleteInd === true
          ),
        });
        break;

      case CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP:
        await this.setState({
          ReviewSignButtonDisabled: !(
            this.state.RInfoCompleteInd === true &&
            this.state.AuthorizationCompleteInd === true &&
            this.state.PInfoCompleteInd === true &&
            this.state.ResAddrCompleteInd === true &&
            this.state.BusAddrCompleteInd === true &&
            this.state.CorpBusinessAddressForm === true &&
            this.state.BrokerCompleteInd === true &&
            this.state.CCBCompleteInd === true &&
            this.state.EOCompleteInd === true &&
            this.state.CorpInfoCompleteInd === true &&
            this.state.CorpCCBCompleteInd === true &&
            this.state.CorpEOCompleteInd === true &&
            this.state.CorpDirectDepositCompleteInd === true &&
            this.state.CorpBusAddrCompleteInd === true
          ),
        });
        break;

      case CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY:
        await this.setState({
          ReviewSignButtonDisabled: !(this.state.PInfoCompleteInd === true),
        });
        break;

      case CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP:
        await this.setState({
          ReviewSignButtonDisabled: !(
            this.state.RInfoCompleteInd === true &&
            this.state.AuthorizationCompleteInd === true &&
            this.state.MemberCorpInfoCompleteInd === true &&
            this.state.PInfoCompleteInd === true &&
            this.state.ResAddrCompleteInd === true &&
            this.state.BrokerCompleteInd === true &&
            this.state.ccbCompleteInd === true &&
            this.state.EOCompleteInd === true &&
            this.state.BusAddrCompleteInd === true
          ),
        });
        break;

      default:
        break;
    }
  }

  async onloadSetInds(passes, contracttypedesc) {
    switch (contracttypedesc) {
      case CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER:
        if (passes.passReleaseOfInfoPage) {
          this.updateInd("RInfoCompleteInd", true);
        }
        if (passes.passAuthToWorkPage) {
          this.updateInd("AuthorizationCompleteInd", true);
        }
        if (passes.passPersonalInfoPage) {
          this.updateInd("PInfoCompleteInd", true);
        }
        if (passes.passResAddressPage) {
          this.updateInd("ResAddrCompleteInd", true);
        }
        if (passes.passBusInfoPage) {
          this.updateInd("BusAddrCompleteInd", true);
        }
        if (passes.passBrokerDealerPage) {
          this.updateInd("BrokerCompleteInd", true);
        }
        if (passes.passCCBPage) {
          this.updateInd("CCBCompleteInd", true);
        }
        if (passes.passEOCPage) {
          this.updateInd("EOCompleteInd", true);
        }
        if (passes.passDirectDepositInfoPage) {
          this.updateInd("DirectDepositCompleteInd", true);
        }
        break;
      case CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT:
      case CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING:
      case CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN:
      case CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT:
      case CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY:
        if (passes.passReleaseOfInfoPage) {
          this.updateInd("RInfoCompleteInd", true);
        }
        if (passes.passAuthToWorkPage) {
          this.updateInd("AuthorizationCompleteInd", true);
        }
        if (passes.passPersonalInfoPage) {
          this.updateInd("PInfoCompleteInd", true);
        }
        if (passes.passAdditionalInfoPage) {
          this.updatePAddlInfoCompleteInd(true);
        }
        if (passes.passResHistoryPage) {
          this.updateResHistoryCompleteInd(true);
        }
        if (
          passes.passBusInfoPage &&
          contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN
        ) {
          this.updateInd("BusAddrCompleteInd", true);
        }
        if (passes.passProfessionalDesPage) {
          this.updateEduCompleteInd(true);
        }
        if (passes.passEmploymentHistoryPage) {
          this.updateEmpHistCompleteInd(true);
        }
        if (passes.passCCBCriminalDisclPage) {
          this.updateCCB14CompletedInd({
            crimDisclosureCompleteInd: true,
          });
        }
        if (passes.passCCBRegulatoryActionDiscl) {
          this.updateCCB14CompletedInd({
            regActionDisclosureCompleteInd: true,
          });
        }
        if (passes.passCCBCivilJudicialDiscl) {
          this.updateCCB14CompletedInd({
            civilJudDisclosureCompleteInd: true,
          });
        }
        if (passes.passCCBCustomerComplDiscl) {
          this.updateCCB14CompletedInd({
            custCompDisclosureCompleteInd: true,
          });
        }
        if (passes.passCCBTerminationDiscl) {
          this.updateCCB14CompletedInd({
            termDisclosureCompleteInd: true,
          });
        }
        if (passes.passCCBFinancialDiscl) {
          this.updateCCB14CompletedInd({
            finDisclosureCompleteInd: true,
          });
        }
        if (passes.passDirectDepositInfoPage) {
          this.updateInd("DirectDepositCompleteInd", true);
        }
        if (passes.passMMLISPayToPlay1Page) {
          this.updateInd("MMLISPage1CompleteInd", true);
        }
        if (passes.passMMLISPayToPlay2Page) {
          this.updateInd("MMLISPage2CompleteInd", true);
        }
        if (passes.passMMLISOBACRIAPage) {
          this.updateInd("MMLISOBACRIACompleteInd", true);
        }

        break;

      case CONTRACT_TYPE_DESCRIPTIONS.MMLIS:
        if (passes.passReleaseOfInfoPage) {
          this.updateInd("RInfoCompleteInd", true);
        }
        if (passes.passAuthToWorkPage) {
          this.updateInd("AuthorizationCompleteInd", true);
        }
        if (passes.passPersonalInfoPage) {
          this.updateInd("PInfoCompleteInd", true);
        }
        if (passes.passAdditionalInfoPage) {
          this.updatePAddlInfoCompleteInd(true);
        }
        if (passes.passResHistoryPage) {
          this.updateResHistoryCompleteInd(true);
        }
        if (passes.passBusInfoPage) {
          this.updateInd("BusAddrCompleteInd", true);
        }
        if (passes.passEmploymentHistoryPage) {
          this.updateEmpHistCompleteInd(true);
        }
        if (passes.passCCBCriminalDisclPage) {
          this.updateCCB14CompletedInd({
            crimDisclosureCompleteInd: true,
          });
        }
        if (passes.passCCBRegulatoryActionDiscl) {
          this.updateCCB14CompletedInd({
            regActionDisclosureCompleteInd: true,
          });
        }
        if (passes.passCCBCivilJudicialDiscl) {
          this.updateCCB14CompletedInd({
            civilJudDisclosureCompleteInd: true,
          });
        }
        if (passes.passCCBCustomerComplDiscl) {
          this.updateCCB14CompletedInd({
            custCompDisclosureCompleteInd: true,
          });
        }
        if (passes.passCCBTerminationDiscl) {
          this.updateCCB14CompletedInd({
            termDisclosureCompleteInd: true,
          });
        }
        if (passes.passCCBFinancialDiscl) {
          this.updateCCB14CompletedInd({
            finDisclosureCompleteInd: true,
          });
        }
        if (passes.passMMLISPayToPlay1Page) {
          this.updateInd("MMLISPage1CompleteInd", true);
        }
        if (passes.passMMLISPayToPlay2Page) {
          this.updateInd("MMLISPage2CompleteInd", true);
        }
        if (passes.passMMLISOBACRIAPage) {
          this.updateInd("MMLISOBACRIACompleteInd", true);
        }
        break;

      case CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP:
        if (passes.passCorpInfo) {
          this.updateInd("CorpInfoCompleteInd", true);
        }
        if (passes.passCorpBusInfoPage) {
          this.updateInd("CorpBusAddrCompleteInd", true);
        }
        if (passes.passCorpCCB) {
          this.updateInd("CorpCCBCompleteInd", true);
        }
        if (passes.passCorpEO) {
          this.updateInd("CorpEOCompleteInd", true);
        }
        if (passes.passCorpDirectDeposit) {
          this.updateInd("CorpDirectDepositCompleteInd", true);
        }
        if (passes.passReleaseOfInfoPage) {
          this.updateInd("RInfoCompleteInd", true);
        }
        if (passes.passAuthToWorkPage) {
          this.updateInd("AuthorizationCompleteInd", true);
        }
        if (passes.passPersonalInfoPage) {
          this.updateInd("PInfoCompleteInd", true);
        }
        if (passes.passResAddressPage) {
          this.updateInd("ResAddrCompleteInd", true);
        }
        if (passes.passBusInfoPage) {
          this.updateInd("BusAddrCompleteInd", true);
        }
        if (passes.passBrokerDealerPage) {
          this.updateInd("BrokerCompleteInd", true);
        }
        if (passes.passCCBPage) {
          this.updateInd("CCBCompleteInd", true);
        }
        if (passes.passEOCPage) {
          this.updateInd("EOCompleteInd", true);
        }
        break;

      case CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP:
        if (passes.passReleaseOfInfoPage) {
          this.updateInd("RInfoCompleteInd", true);
        }
        if (passes.passAuthToWorkPage) {
          this.updateInd("AuthorizationCompleteInd", true);
        }
        if (passes.passMemberCorpInfo) {
          this.updateInd("MemberCorpInfoCompleteInd", true);
        }
        if (passes.passPersonalInfoPage) {
          this.updateInd("PInfoCompleteInd", true);
        }
        if (passes.passResAddressPage) {
          this.updateInd("ResAddrCompleteInd", true);
        }
        if (passes.passBusInfoPage) {
          this.updateInd("BusAddrCompleteInd", true);
        }
        if (passes.passBrokerDealerPage) {
          this.updateInd("BrokerCompleteInd", true);
        }
        if (passes.passCCBPage) {
          this.updateInd("CCBCompleteInd", true);
        }
        if (passes.passEOCPage) {
          this.updateInd("EOCompleteInd", true);
        }
        break;
      case CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY:
        if (passes.passPersonalInfoPage) {
          this.updateInd("PInfoCompleteInd", true);
        }
        break;

      default:
        break;
    }
  }
  async getOnloadConditionals(contracttypedesc) {
    const passReleaseOfInfoPage =
      this.state.releaseInfoAuthToWork.releaseofinfo === true;
    const passAuthToWorkPage =
      this.state.releaseInfoAuthToWork.legallyauthorized === true &&
      this.state.releaseInfoAuthToWork.sponsorreq === false;
    let passPersonalInfoPage = null;

    if (contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY) {
      passPersonalInfoPage =
        this.state.personalInformation.genderdesc !== null &&
        this.state.personalInformation.genderid !== null &&
        this.state.personalInformation.ssn !== null &&
        this.state.personalInformation.phonenumber !== null &&
        this.state.personalInformation.dateofbirth !== null;
    } else {
      passPersonalInfoPage =
        this.state.personalInformation.genderdesc !== null &&
        this.state.personalInformation.genderid !== null &&
        this.state.personalInformation.ssn !== null &&
        this.state.personalInformation.phonenumber !== null &&
        this.state.personalInformation.opportunityid !== null &&
        this.state.personalInformation.dateofbirth !== null;
    }

    let passCorpBusInfoPage = false;

    if (contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP) {
      passCorpBusInfoPage =
        this.state.corporationData.address1 !== null &&
        this.state.corporationData.address1 !== undefined &&
        this.state.corporationData.city !== null &&
        this.state.corporationData.city !== undefined &&
        this.state.corporationData.phonenumber !== null &&
        this.state.corporationData.phonenumber !== undefined &&
        this.state.corporationData.state !== null &&
        this.state.corporationData.state !== undefined &&
        this.state.corporationData.zipcode !== null &&
        this.state.corporationData.zipcode !== undefined;
    }

    const passBusInfoPage =
      this.state.businessAddress !== "" &&
      this.state.businessAddress.busaddress1 !== null &&
      this.state.businessAddress.buscity !== null &&
      this.state.businessAddress.businessphone !== null &&
      this.state.businessAddress.busstate !== null &&
      this.state.businessAddress.buszip !== null;

    let passDirectDepositInfoPage =
      this.state.directDepositInformation.compensationtype !== null &&
      this.state.directDepositInformation.mmaccountnumber !== null &&
      this.state.directDepositInformation.mmaccounttype !== null &&
      this.state.directDepositInformation.mmroutingnumber !== null;

    let returnPasses = "";

    switch (contracttypedesc) {
      case CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER:
        const passResAddressPage =
          this.state.residentialAddress.address1 !== null &&
          this.state.residentialAddress.city !== null &&
          this.state.residentialAddress.state !== null &&
          this.state.residentialAddress.zip !== null;
        const passBrokerDealerPage =
          this.state.brokerDealerInformation.brokdealbool !== null;
        const passCCBPage =
          this.state.characterCreditBonding.convictcrime !== null &&
          this.state.characterCreditBonding.failedbusiness !== null &&
          this.state.characterCreditBonding.invlawsuit !== null &&
          this.state.characterCreditBonding.licsuspend !== null;
        const passEOCPage =
          this.state.errorsAndOmissions.eoindgreatermil !== null &&
          this.state.errorsAndOmissions.eoindlessmil !== null &&
          this.state.errorsAndOmissions.eogroup !== null &&
          this.state.errorsAndOmissions.eoacknowledge !== null;

        this.setBusinessAddressChecked(
          this.state.businessAddress.busaddress1 ===
            this.state.residentialAddress.address1 &&
            this.state.businessAddress.buscity ===
              this.state.residentialAddress.city &&
            this.state.businessAddress.busstate ===
              this.state.residentialAddress.state &&
            this.state.businessAddress.buszip ===
              this.state.residentialAddress.zip &&
            this.state.residentialAddress.address1 !== null &&
            this.state.residentialAddress.city !== null &&
            this.state.residentialAddress.state !== null &&
            this.state.residentialAddress.zip !== null
        );

        returnPasses = {
          passReleaseOfInfoPage: passReleaseOfInfoPage,
          passAuthToWorkPage: passAuthToWorkPage,
          passPersonalInfoPage: passPersonalInfoPage,
          passResAddressPage: passResAddressPage,
          passBusInfoPage: passBusInfoPage,
          passBrokerDealerPage: passBrokerDealerPage,
          passCCBPage: passCCBPage,
          passEOCPage: passEOCPage,
          passDirectDepositInfoPage: passDirectDepositInfoPage,
        };
        break;
      case CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY:
        returnPasses = {
          passPersonalInfoPage: passPersonalInfoPage,
        };

        break;
      case CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP:
        const passMemberCorpInfo =
          this.state.corporationData.corpname !== undefined &&
          this.state.corporationData.corptaxid !== undefined;
        const passResAddressPage3 =
          this.state.residentialAddress.address1 !== null &&
          this.state.residentialAddress.city !== null &&
          this.state.residentialAddress.state !== null &&
          this.state.residentialAddress.zip !== null;
        const passBrokerDealerPage3 =
          this.state.brokerDealerInformation.brokdealbool !== null;
        const passCCBPage3 =
          this.state.characterCreditBonding.convictcrime !== null &&
          this.state.characterCreditBonding.failedbusiness !== null &&
          this.state.characterCreditBonding.invlawsuit !== null &&
          this.state.characterCreditBonding.licsuspend !== null;
        const passEOCPage3 =
          this.state.errorsAndOmissions.eoindgreatermil !== null &&
          this.state.errorsAndOmissions.eoindlessmil !== null &&
          this.state.errorsAndOmissions.eogroup !== null &&
          this.state.errorsAndOmissions.eoacknowledge !== null;
        this.setBusinessAddressChecked(
          this.state.businessAddress.busaddress1 ===
            this.state.residentialAddress.address1 &&
            this.state.businessAddress.buscity ===
              this.state.residentialAddress.city &&
            this.state.businessAddress.busstate ===
              this.state.residentialAddress.state &&
            this.state.businessAddress.buszip ===
              this.state.residentialAddress.zip &&
            this.state.residentialAddress.address1 !== null &&
            this.state.residentialAddress.city !== null &&
            this.state.residentialAddress.state !== null &&
            this.state.residentialAddress.zip !== null
        );

        returnPasses = {
          passReleaseOfInfoPage: passReleaseOfInfoPage,
          passAuthToWorkPage: passAuthToWorkPage,
          passMemberCorpInfo: passMemberCorpInfo,
          passPersonalInfoPage: passPersonalInfoPage,
          passResAddressPage: passResAddressPage3,
          passBusInfoPage: passBusInfoPage,
          passBrokerDealerPage: passBrokerDealerPage3,
          passCCBPage: passCCBPage3,
          passEOCPage: passEOCPage3,
        };
        break;

      case CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP:
        const passCorpInfo =
          this.state.corporationData.corpname !== undefined &&
          this.state.corporationData.corpofficername !== undefined &&
          this.state.corporationData.corptaxid !== undefined &&
          this.state.corporationData.numberofreps !== undefined &&
          this.state.corporationData.corpemailaddress !== undefined &&
          this.state.corporationData.corpemailaddress !== null;

        const passCorpCCB =
          this.state.corpCCBData.convictedcrime !== undefined &&
          this.state.corpCCBData.failedbusiness !== undefined &&
          this.state.corpCCBData.involvedlawsuit !== undefined &&
          this.state.corpCCBData.licenseissues !== undefined;

        const passEOCPage2 =
          this.state.errorsAndOmissions.eoindgreatermil !== null &&
          this.state.errorsAndOmissions.eoindlessmil !== null &&
          this.state.errorsAndOmissions.eogroup !== null &&
          this.state.errorsAndOmissions.eoacknowledge !== null;

        const passCorpEO =
          this.state.eocData.eopolicyovermillion !== undefined &&
          this.state.eocData.eopolicyundermillion !== undefined &&
          this.state.eocData.coveredundergrouppolicy !== undefined &&
          this.state.eocData.eoacknowledgement !== undefined;

        const passCorpDirectDeposit =
          this.state.corporationDirectDepositData.compensationtypeid !== null &&
          this.state.corporationDirectDepositData.mmaccountnumber !== null &&
          this.state.corporationDirectDepositData.mmaccounttypeid !== null &&
          this.state.corporationDirectDepositData.mmroutingnumber !== null &&
          this.state.corporationDirectDepositData.compensationtypeid !==
            undefined &&
          this.state.corporationDirectDepositData.mmaccountnumber !==
            undefined &&
          this.state.corporationDirectDepositData.mmaccounttypeid !==
            undefined &&
          this.state.corporationDirectDepositData.mmroutingnumber !== undefined;

        const passResAddressPage2 =
          this.state.residentialAddress.address1 !== null &&
          this.state.residentialAddress.city !== null &&
          this.state.residentialAddress.state !== null &&
          this.state.residentialAddress.zip !== null;

        const passBrokerDealerPage2 =
          this.state.brokerDealerInformation.brokdealbool !== null;

        const passCCBPage2 =
          this.state.characterCreditBonding.convictcrime !== null &&
          this.state.characterCreditBonding.failedbusiness !== null &&
          this.state.characterCreditBonding.invlawsuit !== null &&
          this.state.characterCreditBonding.licsuspend !== null;

        returnPasses = {
          passCorpInfo: passCorpInfo,
          passCorpBusInfoPage: passCorpBusInfoPage,
          passCorpCCB: passCorpCCB,
          passCorpEO: passCorpEO,
          passCorpDirectDeposit: passCorpDirectDeposit,
          passReleaseOfInfoPage: passReleaseOfInfoPage,
          passAuthToWorkPage: passAuthToWorkPage,
          passPersonalInfoPage: passPersonalInfoPage,
          passResAddressPage: passResAddressPage2,
          passBusInfoPage: passBusInfoPage,
          passBrokerDealerPage: passBrokerDealerPage2,
          passCCBPage: passCCBPage2,
          passEOCPage: passEOCPage2,
        };
        break;

      case CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT:
      case CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING:
      case CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN:
      case CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT:
      case CONTRACT_TYPE_DESCRIPTIONS.MMLIS:
      case CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY:
        const passAdditionalInfoPage =
          this.state.personalInfoAdditionalInfo.length > 0;
        const passEducationPage =
          this.state.eduInformation.length > 0 ||
          this.state.internEduInformation.length > 0;
        const passProfessionalDesPage =
          this.state.ProfessionalDesignationsData !== "" &&
          this.state.ProfessionalDesignationsData.hasprofdesignation !== null;

        let passResHistoryPage = false;
        let passEmploymentHistoryPage = false;

        const todaysDate = Moment();
        const fiveYearsAgo = Moment(todaysDate).subtract(5, "years");
        const tenYearsAgo = Moment(todaysDate).subtract(10, "years");
        let bottomBeginDate = Moment();
        let bottomStartDate = Moment();

        if (
          this.state.residentialHistory.length > 0 &&
          this.state.residentialHistory !== ""
        ) {
          const searchForPresent = this.state.residentialHistory.filter(
            (address) => address.currentaddress === true
          );
          const searchForGaps = this.state.residentialHistory.filter(
            (address) => address.istheregap === true
          );
          for (let i = 0; i < this.state.residentialHistory.length; i++) {
            let beginDate = Moment(this.state.residentialHistory[i].begindate);
            if (beginDate < bottomBeginDate) bottomBeginDate = beginDate;
          }
          passResHistoryPage =
            searchForPresent.length > 0 &&
            searchForGaps.length === 0 &&
            bottomBeginDate <= fiveYearsAgo;
        }

        if (
          this.state.empHistInformation.length > 0 &&
          this.state.empHistInformation !== ""
        ) {
          const searchForPresentEmp = this.state.empHistInformation.filter(
            (job) => job.enddate === null
          );
          const searchForGapsEmp = this.state.empHistInformation.filter(
            (job) => job.istheregap === true
          );

          let isCriteriaMet = false;
          for (let i = 0; i < this.state.empHistInformation.length; i++) {
            let startDate = Moment(this.state.empHistInformation[i].startdate);
            let endDate = Moment(this.state.empHistInformation[i].enddate);
            if (startDate < bottomStartDate) bottomStartDate = startDate;
            let test =
              //endDate CAN equal todaysDate
              //endDate.format("MM/DD/YYYY") !==
              //todaysDate.format("MM/DD/YYYY") &&
              todaysDate.diff(endDate, "days") < 89 && todaysDate >= endDate;
            if (test) isCriteriaMet = true;
          }

          passEmploymentHistoryPage =
            (searchForPresentEmp.length > 0 || isCriteriaMet) &&
            searchForGapsEmp.length === 0 &&
            bottomStartDate <= tenYearsAgo;
        }

        let passPSRPage = false;
        let psrCount = 0;
        let psrRecordsCount = 0;
        let skipPSRPage = false;

        if (this.state.psrData.length > 0) {
          psrRecordsCount =
            psrRecordsCount + this.state.psrData[0].personalsalesrecords.length;
          psrCount = this.state.psrData.length;
          passPSRPage = psrCount === psrRecordsCount;
        } else {
          skipPSRPage = true;
        }
        const { ccb14: ccb14Data, oktaSubId } = this.state;

        const CCBCriminalDisclValues = ccb14Data
          ? getValues(ccb14Data, [
              ...KEYS.individualCriminalDisclosure,
              ...KEYS.organizationCriminalDisclosure,
            ])
          : [];

        let passCCBCriminalDisclPage =
          CCBCriminalDisclValues && CCBCriminalDisclValues.length
            ? CCBCriminalDisclValues.every((v) => v !== null && v !== true)
            : false;

        if (
          !passCCBCriminalDisclPage &&
          CCBCriminalDisclValues.some((v) => v === true) &&
          CCBCriminalDisclValues.every((v) => v !== null)
        ) {
          const drpData = await getCCBDRPIndOrgList({
            oktaSubId,
            drpTypeId: DRP_TYPES.Criminal,
          });

          const noCCBYesQuestions = CCBCriminalDisclValues.filter(
            (v) => v === true
          ).length;

          const drpAssociatedQuestions =
            (drpData?.ind && drpData?.ind?.length ? drpData?.ind?.length : 0) +
            (drpData?.org && drpData?.org?.length ? drpData?.org?.length : 0);
          // if the number of questions answerd yes matches the number of questions associated by the DRPs => skip to next page
          if (noCCBYesQuestions <= drpAssociatedQuestions) {
            passCCBCriminalDisclPage = true;
          } else {
            this.props.setIsBack(true);
          }
        }

        const CCBRegulatoryActionDisclValues = ccb14Data
          ? getValues(ccb14Data, [
              ...KEYS.regulatoryActionSECorCFTC,
              ...KEYS.regulatoryActionOtherRegulators,
              ...KEYS.regulatoryActionSRO,
              ...KEYS.professionalSuspension,
              ...KEYS.formalPendingActionInvestigation,
            ])
          : [];

        let passCCBRegulatoryActionDiscl =
          CCBRegulatoryActionDisclValues &&
          CCBRegulatoryActionDisclValues.length
            ? CCBRegulatoryActionDisclValues.every(
                (v) => v !== null && v !== true
              )
            : false;

        if (
          !passCCBRegulatoryActionDiscl &&
          CCBRegulatoryActionDisclValues.some((v) => v === true) &&
          CCBRegulatoryActionDisclValues.every((v) => v !== null)
        ) {
          const drpData = await getCCB14DRP({
            oktaSubId,
            drpTypeId: DRP_TYPES.Regulatory,
          });

          const drpValues =
            Object.values(drpData?.drpvalues).flatMap((v) => Object.keys(v)) ??
            [];

          const noCCBYesQuestions = CCBRegulatoryActionDisclValues.filter(
            (v) => v === true
          ).length;

          const drpAssociatedQuestions = drpValues?.length ?? 0;

          // if the number of questions answerd yes matches the number of questions associated by the DRPs => skip to next page
          if (noCCBYesQuestions === drpAssociatedQuestions) {
            passCCBRegulatoryActionDiscl = true;
          } else {
            this.props.setIsBack(true);
          }
        }

        const CCBCivilJudicialDisclValues = ccb14Data
          ? getValues(ccb14Data, KEYS.civilJudicialActions)
          : [];

        let passCCBCivilJudicialDiscl =
          CCBCivilJudicialDisclValues && CCBCivilJudicialDisclValues.length
            ? CCBCivilJudicialDisclValues.every((v) => v !== null && v !== true)
            : false;

        if (
          !passCCBCivilJudicialDiscl &&
          CCBCivilJudicialDisclValues.some((v) => v === true) &&
          CCBCivilJudicialDisclValues.every((v) => v !== null)
        ) {
          const drpData = await getCCB14DRP({
            oktaSubId,
            drpTypeId: DRP_TYPES.CivilJudicial,
          });

          const drpValues =
            Object.values(drpData?.drpvalues).flatMap((v) => Object.keys(v)) ??
            [];

          const noCCBYesQuestions = CCBCivilJudicialDisclValues.filter(
            (v) => v === true
          ).length;

          const drpAssociatedQuestions = drpValues?.length ?? 0;

          // if the number of questions answerd yes matches the number of questions associated by the DRPs => skip to next page
          if (noCCBYesQuestions === drpAssociatedQuestions) {
            passCCBCivilJudicialDiscl = true;
          } else {
            this.props.setIsBack(true);
          }
        }

        const CCBCustomerComplDisclValues = ccb14Data
          ? getValues(
              ccb14Data,
              KEYS.customerComplaintArbitrationCivilLitigation
            )
          : [];

        let passCCBCustomerComplDiscl =
          CCBCustomerComplDisclValues && CCBCustomerComplDisclValues.length
            ? CCBCustomerComplDisclValues.every((v) => v !== null && v !== true)
            : false;

        if (
          !passCCBCustomerComplDiscl &&
          CCBCustomerComplDisclValues.some((v) => v === true) &&
          CCBCustomerComplDisclValues.every((v) => v !== null)
        ) {
          const drpData = await getCustomerComplaintDRP(oktaSubId);
          const data = drpData?.data ?? [];

          const noCCBYesQuestions = CCBCustomerComplDisclValues.filter(
            (v) => v === true
          ).length;

          const drpAssociatedQuestions = data
            .map((item) => item?.complaintdrp?.drpSelectedCheckList)
            ?.flatMap((item) => item).length;

          // if the number of questions answerd yes matches the number of questions associated by the DRPs => skip to next page
          if (noCCBYesQuestions <= drpAssociatedQuestions) {
            passCCBCustomerComplDiscl = true;
          } else {
            this.props.setIsBack(true);
          }
        }

        const CCBTerminationDisclValues = ccb14Data
          ? getValues(ccb14Data, KEYS.terminationDisclosures)
          : [];

        let passCCBTerminationDiscl =
          CCBTerminationDisclValues && CCBTerminationDisclValues.length
            ? CCBTerminationDisclValues.every((v) => v !== null && v !== true)
            : false;

        if (
          !passCCBTerminationDiscl &&
          CCBTerminationDisclValues.some((v) => v === true) &&
          CCBTerminationDisclValues.every((v) => v !== null)
        ) {
          const drpData = await getTerminationDRP(oktaSubId);
          const data = drpData?.data ?? [];

          const noCCBYesQuestions = CCBTerminationDisclValues.filter(
            (v) => v === true
          ).length;

          const drpAssociatedQuestions = data
            .map((item) => item?.terminationdrp?.drpList)
            ?.flatMap((item) => item).length;

          // if the number of questions answerd yes matches the number of questions associated by the DRPs => skip to next page
          if (noCCBYesQuestions <= drpAssociatedQuestions) {
            passCCBTerminationDiscl = true;
          } else {
            this.props.setIsBack(true);
          }
        }

        const CCBFinancialDisclValues = ccb14Data
          ? getValues(ccb14Data, [
              ...KEYS.personalBankruptcy,
              ...KEYS.organizationBankruptcy,
              ...KEYS.bondDisclosures,
              ...KEYS.judgementLienDisclosures,
            ])
          : [];

        let passCCBFinancialDiscl =
          CCBFinancialDisclValues && CCBFinancialDisclValues.length
            ? CCBFinancialDisclValues.every((v) => v !== null && v !== true)
            : false;

        if (
          !passCCBFinancialDiscl &&
          CCBFinancialDisclValues.some((v) => v === true) &&
          CCBFinancialDisclValues.every((v) => v !== null)
        ) {
          const drpData = await Promise.all([
            getBondDRP(oktaSubId),
            getJudgmentLienDRP(oktaSubId),
            getBankruptcyDRP(oktaSubId),
          ]);

          let drpAssociatedQuestions = 0;

          const bondDrp = drpData[0]?.data[0]?.bonddrp;

          if (bondDrp) {
            drpAssociatedQuestions = drpAssociatedQuestions + 1;
          }

          const judgmentDrp = drpData[1]?.data[0]?.judgmentliendrp;

          if (judgmentDrp) {
            drpAssociatedQuestions = drpAssociatedQuestions + 1;
          }

          const bankruptcyDrp = (drpData[2]?.bankruptcyData ?? [])?.map(
            (item) => item.bankruptcydrp
          );

          bankruptcyDrp.forEach((item) => {
            if ("drpList" in item) {
              const drpList = item.drpList ?? [];
              drpAssociatedQuestions = drpAssociatedQuestions + drpList.length;
            } else {
              drpAssociatedQuestions = drpAssociatedQuestions + 1;
            }
          });

          const noCCBYesQuestions = CCBFinancialDisclValues.filter(
            (v) => v === true
          ).length;

          // if the number of questions answerd yes matches the number of questions associated by the DRPs => skip to next page
          if (noCCBYesQuestions <= drpAssociatedQuestions) {
            passCCBFinancialDiscl = true;
          } else {
            this.props.setIsBack(true);
          }
        }

        if (contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY) {
          if (!passDirectDepositInfoPage) {
            passCCBFinancialDiscl = false;
          }
        }

        let passMMLISPayToPlay1Page = "";
        let passMMLISPayToPlay2Page = "";
        let passMMLISOBACRIAPage = "";

        if (
          contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS ||
          ((contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
            contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
            contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
            contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT) &&
            this.state.dashboardInfo.mmlis)
        ) {
          passMMLISPayToPlay1Page =
            this.state.mmlisData[0].managebool !== null &&
            this.state.mmlisData[0].managebool !== undefined;

          passMMLISPayToPlay2Page =
            this.state.mmlisData[0].contributebool !== null &&
            this.state.mmlisData[0].contributebool !== undefined;

          passMMLISOBACRIAPage =
            this.state.mmlisObaCriaData.length > 0 &&
            this.state.mmlisObaCriaData[0].outsidebusact !== null &&
            this.state.mmlisObaCriaData[0].outsidebusact !== undefined &&
            this.state.mmlisObaCriaData[0].corpreg !== null &&
            this.state.mmlisObaCriaData[0].corpreg !== undefined;
        }

        returnPasses = {
          passReleaseOfInfoPage,
          passAuthToWorkPage,
          passPersonalInfoPage,
          passAdditionalInfoPage,
          passResHistoryPage,
          passBusInfoPage,
          passEducationPage,
          passProfessionalDesPage,
          passEmploymentHistoryPage,
          passPSRPage,
          passCCBCriminalDisclPage,
          passCCBRegulatoryActionDiscl,
          passCCBCivilJudicialDiscl,
          passCCBCustomerComplDiscl,
          passCCBTerminationDiscl,
          passCCBFinancialDiscl,
          passDirectDepositInfoPage,
          passMMLISPayToPlay1Page,
          passMMLISPayToPlay2Page,
          passMMLISOBACRIAPage,
        };

        if (skipPSRPage) {
          delete returnPasses["passPSRPage"];
        }
        if (contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS) {
          delete returnPasses["passEducationPage"];
          delete returnPasses["passProfessionalDesPage"];
          delete returnPasses["passDirectDepositInfoPage"];
        }
        if (
          contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.MMLIS &&
          !this.state.dashboardInfo.mmlis
        ) {
          delete returnPasses["passMMLISPayToPlay1Page"];
          delete returnPasses["passMMLISPayToPlay2Page"];
          delete returnPasses["passMMLISOBACRIAPage"];
        }
        if (contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN) {
          delete returnPasses["passBusInfoPage"];
        }
        if (contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY) {
          delete returnPasses["passDirectDepositInfoPage"];
        }

        break;

      default:
        break;
    }

    return returnPasses;
  }

  async refreshPSR(onLoad, onloadPSRData) {
    let psrData = onLoad
      ? onloadPSRData
      : await getPSREmploymentHistoryInformation(
          this.props.authState.accessToken.claims.uid
        );
    let isPSRDataComplete = false;
    let allData = [];
    let psrCount = 0;
    let psrRecordsCount = 0;

    if (psrData.length > 0) {
      for (let j = 0; j < psrData.length; j++) {
        psrRecordsCount =
          psrRecordsCount + psrData[j][0].personalsalesrecords.length;
        let currentData = psrData[j];
        currentData = currentData.sort((a, b) => {
          return new Date(b.startdate) - new Date(a.startdate);
        });

        psrCount = psrCount + psrData[j].length;

        for (let i = 0; i < currentData.length; i++) {
          currentData[i].psr_id = 0;

          var today = Moment().format("MM/DD/YYYY");
          var endDateTxt = Moment(currentData[i].enddate)
            .utc()
            .format("MM/DD/YYYY");

          currentData[i].enddatetxt =
            today === endDateTxt
              ? "Present"
              : Moment(currentData[i].enddate).utc().format("MM/DD/YYYY");

          for (let x = 0; x < currentData[i].personalsalesrecords.length; x++) {
            if (
              Moment(currentData[i].personalsalesrecords[x].startdate).format(
                "MM/DD/YYYY"
              ) === Moment(currentData[i].startdate).utc().format("MM/DD/YYYY")
            ) {
              currentData[i].psr_id =
                currentData[i].personalsalesrecords[x].psrid;
            }
          }
          if (i > 0) {
            let last_company = currentData[i].companyname;
            let current_company = currentData[i - 1].companyname;

            currentData[i].same_company =
              current_company === last_company ? "Y" : "N";
          } else {
            currentData[i].same_company = "N";
          }
          allData.push(currentData[i]);
        }
      }

      isPSRDataComplete = psrCount === psrRecordsCount;

      await this.setState({
        psrNextEnabled: !isPSRDataComplete,
        PSRCompleteInd: isPSRDataComplete,
        //includePSR: true,
      });
    } else {
      this.setState({ includePSR: false });
    }
    await this.setState({ psrData: allData });
  }
  async refreshPSRCharts(onLoad, onloadPSRChartsData) {
    let result = onLoad
      ? onloadPSRChartsData
      : await getPSRChartData(this.props.authState.accessToken.claims.uid);
    await this.setState({
      psrChartData: result,
      total_fin_plans: result.totalFinPlans,
      psr_data_length: result.payload.length,
    });
    this.buildFinancialPlanData();
    this.buildCommissionData();
    this.BuildCommissionDetail();
  }

  /* Creates Snapshot of UI and sends to API */
  async saveESigData() {
    let imageData = await downloadScreenshot();
    let ipAddr = await publicIpv4();
    let data = {
      contractid: this.state.contractId,
      screenimage: imageData,
      browsertype: browserName + " " + browserVersion,
      useripaddress: ipAddr,
    };
    await putESignatureInformation(this.state.oktaSubId, data);
  }

  async buildFinancialPlanData() {
    var matchObj = [];
    var yearArray = [];
    var financialplanArray = [];

    var currentYear = new Date().getFullYear();
    yearArray.push(currentYear);
    for (var i = 1; i < 6; ++i) {
      yearArray.push(currentYear - i);
    }

    for (var j = 0; j < 6; ++j) {
      for (var x = 0; x < this.state.psrChartData.finPlans.length; ++x) {
        if (yearArray[j] === this.state.psrChartData.finPlans[x].year) {
          matchObj.push(this.state.psrChartData.finPlans[x]);
        }
      }
      if (matchObj.length > 0) {
        var finPlans = 0;
        var year = 0;
        for (var y = 0; y < matchObj.length; ++y) {
          year = matchObj[0].year;
          finPlans = finPlans + matchObj[y].finPlanNr;
        }
        financialplanArray.push([year, finPlans]);
      } else {
        financialplanArray.push([yearArray[j], 0]);
      }
      matchObj = [];
    }
    await this.setState({ financial_plan_data: financialplanArray });
  }

  async buildCommissionData() {
    var matchObj = [];
    var yearArray = [];
    let emptyArray = [];
    var commissionArray = [];

    var currentYear = new Date().getFullYear();
    yearArray.push(currentYear);
    for (var i = 1; i < 6; ++i) {
      yearArray.push(currentYear - i);
    }

    for (var j = 0; j < 6; ++j) {
      for (var x = 0; x < this.state.psrChartData.comissions.length; ++x) {
        if (yearArray[j] === this.state.psrChartData.comissions[x].year) {
          matchObj.push(this.state.psrChartData.comissions[x]);
          break;
        }
      }
      if (matchObj.length > 0) {
        commissionArray.push(matchObj);
      } else {
        emptyArray = [
          {
            year: yearArray[j],
            indlifirstyear: "0",
            indlirenewcom: "0",
            indannfirstyear: "0",
            indannrenewcom: "0",
            inddisincfirstyear: "0",
            inddisincrenewcom: "0",
            indlongtermfirstyear: "0.00",
            indlongtermrenewcom: "0.00",
            feebasedsalesrelfees: "0.00",
            totalPerYear: "0.00",
          },
        ];
        commissionArray.push(emptyArray);
      }
      matchObj = [];
    }
    await this.setState({ commission_data: commissionArray });
  }
  async BuildCommissionDetail() {
    this.setState({
      has_ind_life: false,
      has_ind_annuity: false,
      has_ind_disabilty: false,
      has_ind_long_term: false,
      has_gross_commissions: false,
      has_fee_based_sales: false,
    });
    // individual life
    let indLifeTotal = 0;
    for (var i = 0; i < this.state.commission_data.length; ++i) {
      indLifeTotal =
        indLifeTotal +
        parseFloat(this.state.commission_data[i][0].indlifirstyear);

      indLifeTotal =
        indLifeTotal +
        parseFloat(this.state.commission_data[i][0].indlirenewcom);

      if (indLifeTotal > 0) {
        await this.setState({ has_ind_life: true });
        break;
      }
    }
    // individual annuity
    let indAnnuityTotal = 0;
    for (var j = 0; j < this.state.commission_data.length; ++j) {
      indAnnuityTotal =
        indAnnuityTotal +
        parseFloat(this.state.commission_data[j][0].indannfirstyear);

      indAnnuityTotal =
        indAnnuityTotal +
        parseFloat(this.state.commission_data[j][0].indannrenewcom);

      if (indAnnuityTotal > 0) {
        await this.setState({ has_ind_annuity: true });
        break;
      }
    }
    // individual disaability
    let indDisabilityTotal = 0;
    for (var k = 0; k < this.state.commission_data.length; ++k) {
      indDisabilityTotal =
        indDisabilityTotal +
        parseFloat(this.state.commission_data[k][0].inddisincfirstyear);

      indDisabilityTotal =
        indDisabilityTotal +
        parseFloat(this.state.commission_data[k][0].inddisincrenewcom);

      if (indDisabilityTotal > 0) {
        await this.setState({ has_ind_disabilty: true });
        break;
      }
    }
    // individual long term care
    let indLongTermTotal = 0;
    for (var l = 0; l < this.state.commission_data.length; ++l) {
      indLongTermTotal =
        indLongTermTotal +
        parseFloat(this.state.commission_data[l][0].indlongtermfirstyear);

      indLongTermTotal =
        indLongTermTotal +
        parseFloat(this.state.commission_data[l][0].indlongtermrenewcom);

      if (indLongTermTotal > 0) {
        await this.setState({ has_ind_long_term: true });
        break;
      }
    }
    // investment product gross dealer commissions
    let invProdGross = 0;
    for (var m = 0; m < this.state.commission_data.length; ++m) {
      if (
        this.state.commission_data[m][0].invprodgrossdealercom !== null &&
        typeof this.state.commission_data[m][0].invprodgrossdealercom !==
          "undefined"
      ) {
        invProdGross =
          invProdGross +
          parseFloat(this.state.commission_data[m][0].invprodgrossdealercom);
      }

      if (invProdGross > 0) {
        await this.setState({ has_gross_commissions: true });
        break;
      }
    }
    // fee based sales
    let feeBasedSales = 0;
    for (var n = 0; n < this.state.commission_data.length; ++n) {
      if (
        this.state.commission_data[n][0].feebasedsalesrelfees !== null &&
        typeof this.state.commission_data[n][0].feebasedsalesrelfees !==
          "undefined"
      ) {
        feeBasedSales =
          feeBasedSales +
          parseFloat(this.state.commission_data[n][0].feebasedsalesrelfees);
      }
      if (feeBasedSales > 0) {
        await this.setState({ has_fee_based_sales: true });
        break;
      }
    }
  }

  async setBusinessAddressChecked(checkValue) {
    await this.setState({
      businessAddressChecked: checkValue === true,
    });
  }

  /* ********************************************************************* *
   * DATA FETCH FUNCTIONS:                                                 *
   * ********************************************************************* */
  async fetchBrokerData(contractType, contractTypeId) {
    const { oktaSubId } = this.state;

    let data = await Promise.all([
      getReleaseInformation(oktaSubId),
      getPersonalInformation(oktaSubId),
      getPersonalInfoOpportunities(),
      getResidentialAddress(oktaSubId),
      getBusinessInfo(oktaSubId),
      getBrokerDealerInfo(oktaSubId),
      getCCBInfo(oktaSubId),
      getErrorsAndOmissions(oktaSubId),
      getDirectDepositInfo(oktaSubId),
      getSignatureWording(contractTypeId),
    ]);

    this.setState({
      releaseInfoAuthToWork: data[0],
      personalInformation: data[1],
      personalInfoHearAboutUsOptions: data[2],
      residentialAddress: data[3],
      businessAddress: data[4],
      brokerDealerInformation: data[5],
      characterCreditBonding: data[6],
      errorsAndOmissions: data[7],
      directDepositInformation: data[8],
      reviewSign2Verbiage: data[9],
    });
    if (
      this.state.releaseInfoAuthToWork !== "" &&
      this.state.personalInformation !== "" &&
      this.state.personalInfoHearAboutUsOptions !== "" &&
      this.state.residentialAddress !== "" &&
      this.state.businessAddress !== "" &&
      this.state.brokerDealerInformation !== "" &&
      this.state.characterCreditBonding !== "" &&
      this.state.directDepositInformation !== ""
    ) {
      this.setState({ isLoading: false });
    }
    this.toggleFormComplete();

    const blankContractPDFBlob = await getBlankContract(contractType);
    const blankDisclosurePDFBlob = await getBlankDisclosure();

    this.setState({
      blankContractBlob: blankContractPDFBlob,
      blankDisclosureBlob: blankDisclosurePDFBlob,
    });
  }

  async fetchBrokerCorpData(contractType, contractTypeId) {
    const { oktaSubId } = this.state;
    let corpdata = {
      candidateid: this.state.candidateId,
      contractid: this.state.contractId,
    };
    let corpresults = await getCandidateCorp(oktaSubId, corpdata);
    let corpid = 0;
    if (corpresults.corpid !== undefined) {
      corpid = corpresults.corpid;
    }
    this.setState({ corp_id: corpid });

    let corporation = {
      corpid: corpid,
    };
    let data = await Promise.all([
      getCorporation(oktaSubId, corporation),
      getReleaseInformation(oktaSubId),
      getPersonalInformation(oktaSubId),
      getPersonalInfoOpportunities(),
      getResidentialAddress(oktaSubId),
      getBrokerDealerInfo(oktaSubId),
      getCCBInfo(oktaSubId),
      getErrorsAndOmissions(oktaSubId),
      getSignatureWording(contractTypeId),
      getCorporationCCB(oktaSubId, corporation),
      getCorporationEO(oktaSubId, corporation),
      getCorporationDD(oktaSubId, corporation),
      getBusinessInfo(oktaSubId),
    ]);
    this.setState({
      corporationData: data[0],
      releaseInfoAuthToWork: data[1],
      personalInformation: data[2],
      personalInfoHearAboutUsOptions: data[3],
      residentialAddress: data[4],
      brokerDealerInformation: data[5],
      characterCreditBonding: data[6],
      errorsAndOmissions: data[7],
      reviewSign2Verbiage: data[8],
      corpCCBData: data[9],
      eocData: data[10],
      corporationDirectDepositData: data[11],
      businessAddress: data[12],
    });

    if (
      this.state.corporationData !== "" &&
      this.state.releaseInfoAuthToWork !== "" &&
      this.state.personalInformation !== "" &&
      this.state.personalInfoHearAboutUsOptions !== "" &&
      this.state.residentialAddress !== "" &&
      this.state.brokerDealerInformation !== "" &&
      this.state.characterCreditBonding !== "" &&
      this.state.corporationDirectDepositData !== "" &&
      this.state.errorsAndOmissions !== "" &&
      this.state.reviewSign2Verbiage !== "" &&
      this.state.corpCCBData !== "" &&
      this.state.eocData !== "" &&
      this.state.businessAddress !== ""
    ) {
      this.setState({ isLoading: false });
    }
    this.toggleFormComplete();

    const blankContractPDFBlob = await getBlankContract(contractType);
    const blankDisclosurePDFBlob = await getBlankDisclosure();

    this.setState({
      blankContractBlob: blankContractPDFBlob,
      blankDisclosureBlob: blankDisclosurePDFBlob,
    });
  }

  async fetchMemberCorpData(contractType, contractTypeId) {
    const { oktaSubId } = this.state;

    let corpdata = {
      candidateid: this.state.candidateId,
      contractid: this.state.contractId,
    };
    let corpresults = await getCandidateCorp(oktaSubId, corpdata);
    let corpid = 0;
    if (corpresults.corpid !== undefined) {
      corpid = corpresults.corpid;
    }
    this.setState({ corp_id: corpid });

    let corporation = {
      corpid: corpid,
    };

    let data = await Promise.all([
      getReleaseInformation(oktaSubId),
      getPersonalInformation(oktaSubId),
      getPersonalInfoOpportunities(),
      getResidentialAddress(oktaSubId),
      getBrokerDealerInfo(oktaSubId),
      getCCBInfo(oktaSubId),
      getErrorsAndOmissions(oktaSubId),
      getSignatureWording(contractTypeId),
      getCorporation(oktaSubId, corporation),
      getBusinessInfo(oktaSubId),
    ]);

    this.setState({
      releaseInfoAuthToWork: data[0],
      personalInformation: data[1],
      personalInfoHearAboutUsOptions: data[2],
      residentialAddress: data[3],
      brokerDealerInformation: data[4],
      characterCreditBonding: data[5],
      errorsAndOmissions: data[6],
      reviewSign2Verbiage: data[7],
      corporationData: data[8],
      businessAddress: data[9],
    });

    if (
      this.state.releaseInfoAuthToWork !== "" &&
      this.state.personalInformation !== "" &&
      this.state.personalInfoHearAboutUsOptions !== "" &&
      this.state.residentialAddress !== "" &&
      this.state.brokerDealerInformation !== "" &&
      this.state.characterCreditBonding !== "" &&
      this.state.corporationData !== "" &&
      this.state.businessAddress !== ""
    ) {
      this.setState({ isLoading: false });
    }
    this.toggleFormComplete();

    const blankContractPDFBlob = await getBlankContract(contractType);
    const blankDisclosurePDFBlob = await getBlankDisclosure();

    this.setState({
      blankContractBlob: blankContractPDFBlob,
      blankDisclosureBlob: blankDisclosurePDFBlob,
    });
  }

  async fetchFinOnlyData() {
    try {
      const { oktaSubId } = this.state;
      let data = await Promise.all([getPersonalInformation(oktaSubId)]);

      await this.setState({
        personalInformation: data[0],
      });

      if (this.state.personalInformation !== "") {
        this.setState({ isLoading: false });
      }

      this.toggleFormComplete();
    } catch (e) {
      console.log(e);
    }
  }

  async fetchCareerData(contractType, contractTypeId, mmlis, financing) {
    try {
      const { oktaSubId } = this.state;

      let getSignatureWordingParams = { contracttypeid: contractTypeId };

      if (mmlis) {
        getSignatureWordingParams = {
          ...getSignatureWordingParams,
          mmlis: true,
        };
      }
      if (financing) {
        getSignatureWordingParams = {
          ...getSignatureWordingParams,
          finance: true,
        };
      }

      let data = await Promise.all([
        getReleaseInformation(oktaSubId),
        getPersonalInformation(oktaSubId),
        getPersonalInfoOpportunities(),
        getAdditionalInformation(oktaSubId),
        getResidentialHistory(oktaSubId),
        getBusinessInfo(oktaSubId),
        getCandidateEducation(oktaSubId),
        getEducationDegrees(),
        getEducationLevels(),
        getProfessionalDesignations(oktaSubId),
        getCombinedEmploymentHistoryInformation(oktaSubId),
        getDirectDepositInfo(oktaSubId),
        getCCB14({ oktaSubId }),
        getPSREmploymentHistoryInformation(oktaSubId),
        getPSRChartData(oktaSubId),
        getSignatureWording(contractTypeId, getSignatureWordingParams),
        getInternEducation(oktaSubId),
        getEmploymentHistoryIndustries(),
      ]);

      await this.setState({
        releaseInfoAuthToWork: data[0],
        personalInformation: data[1],
        personalInfoHearAboutUsOptions: data[2],
        personalInfoAdditionalInfo: data[3],
        residentialHistory: data[4],
        businessAddress: data[5],
        eduInformation: data[6],
        eduDegrees: data[7],
        eduLevels: data[8],
        ProfessionalDesignationsData: data[9],
        empHistInformation: data[10],
        directDepositInformation: data[11],
        ccb14: data[12],
        reviewSign2Verbiage: data[15],
        internEduInformation: data[16],
        empHistIndustries: data[17],
      });

      if (this.state.dashboardInfo.mmlis) {
        let data = await Promise.all([
          getCandidateMMLIS(oktaSubId),
          getMMLISObaCria(oktaSubId),
        ]);
        await this.setState({
          mmlisData: data[0],
          mmlisObaCriaData: data[1],
        });
      }

      await this.refreshPSR(true, data[13]);
      await this.refreshPSRCharts(true, data[14]);

      if (
        this.state.releaseInfoAuthToWork !== "" &&
        this.state.personalInformation !== "" &&
        this.state.personalInfoHearAboutUsOptions !== "" &&
        this.state.personalInfoAdditionalInfo !== "" &&
        this.state.residentialHistory !== "" &&
        this.state.businessAddress !== "" &&
        (this.state.eduInformation !== "" ||
          this.state.internEduInformation !== "") &&
        this.state.eduDegrees !== "" &&
        this.state.eduLevels !== "" &&
        this.state.ProfessionalDesignationsData !== "" &&
        this.state.empHistInformation !== "" &&
        this.state.ccb14 !== ""
      ) {
        this.setState({ isLoading: false });
      }

      this.toggleFormComplete();
      const blankContractPDFBlob = await getBlankContract(contractType);
      const blankDisclosurePDFBlob = await getBlankDisclosure();

      this.setState({
        blankContractBlob: blankContractPDFBlob,
        blankDisclosureBlob: blankDisclosurePDFBlob,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchMMLISData(contractType, contractTypeId) {
    try {
      const { oktaSubId } = this.state;

      let data = await Promise.all([
        getReleaseInformation(oktaSubId),
        getPersonalInformation(oktaSubId),
        getPersonalInfoOpportunities(),
        getAdditionalInformation(oktaSubId),
        getResidentialHistory(oktaSubId),
        getBusinessInfo(oktaSubId),
        getCombinedEmploymentHistoryInformation(oktaSubId),
        getCCB14({ oktaSubId }),
        getPSREmploymentHistoryInformation(oktaSubId),
        //getPSRChartData(oktaSubId),
        getSignatureWording(contractTypeId),
        getEmploymentHistoryIndustries(),
        getCandidateMMLIS(oktaSubId),
        getMMLISObaCria(oktaSubId),
      ]);

      await this.setState({
        releaseInfoAuthToWork: data[0],
        personalInformation: data[1],
        personalInfoHearAboutUsOptions: data[2],
        personalInfoAdditionalInfo: data[3],
        residentialHistory: data[4],
        businessAddress: data[5],
        empHistInformation: data[6],
        ccb14: data[7],
        reviewSign2Verbiage: data[9],
        empHistIndustries: data[10],
        mmlisData: data[11],
        mmlisObaCriaData: data[12],
      });
      //await this.refreshPSR(true, data[8]);
      //await this.refreshPSRCharts(true, data[9]);

      if (
        this.state.releaseInfoAuthToWork !== "" &&
        this.state.personalInformation !== "" &&
        this.state.personalInfoHearAboutUsOptions !== "" &&
        this.state.residentialHistory !== "" &&
        this.state.businessAddress !== "" &&
        this.state.empHistInformation !== "" &&
        this.state.ccb14 !== "" &&
        this.state.mmlisData !== "" &&
        this.state.mmlisObaCriaData !== ""
      ) {
        this.setState({ isLoading: false });
      }

      this.toggleFormComplete();

      const blankContractPDFBlob = await getBlankContract(contractType);
      const blankDisclosurePDFBlob = await getBlankDisclosure();

      this.setState({
        blankContractBlob: blankContractPDFBlob,
        blankDisclosureBlob: blankDisclosurePDFBlob,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchCollegeInternData() {}

  /* ********************************************************************* *
   * ONLOAD FUNCTION:                                                      *
   * First function that runs when page is loaded.                         *
   * This is where data is originally fetched from api and stored in state *
   * ********************************************************************* */
  async componentDidMount() {
    const oktaSubId = this.props.authState.accessToken.claims.uid;

    try {
      const queryParams = new URLSearchParams(window.location.search);
      const contract = queryParams.get("contract");

      if (contract) {
        var appStatusName = "";
        const result = await getStartAppDashboard(
          oktaSubId,
          parseInt(contract)
        );

        if (result === undefined) {
          window.location = "/";
        }

        if (
          result.contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY
        ) {
          this.props.fetchCCB(oktaSubId);
        }

        this.setState({
          contractId: result.contractid,
          dashboardInfo: result,
          candidateId: result.candidateid,
          hasNYRegs: result.nyregs,
          oktaSubId: oktaSubId,
          agencynumber: result.agencynumber,
          contracttypeid: result.contracttypeid,
          phonenumber: result.phonenumber,
        });

        UtagTracking.sendPageNameView(
          "startapplication",
          this.state.candidateId,
          this.state.agencynumber,
          this.state.contracttypeid
        );

        appStatusName = result.contractstatusdesc;

        if (
          appStatusName !== CONTRACT_STATUS_DESC.INVITATION_SENT && //5
          appStatusName !== CONTRACT_STATUS_DESC.APPLICATION_IN_PROGRESS && //4
          appStatusName !== CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE //7
        ) {
          //redirect to dashboard
          window.location = "/";
        }

        if (
          result.contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY
        ) {
          //Individual Broker Only Loaded Data
          if (
            result.contracttypedesc ===
            CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER
          ) {
            this.fetchBrokerData(
              result.contracttypedesc,
              result.contracttypeid
            );
          }

          //Broker Corp Only Loaded Data
          if (
            result.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP
          ) {
            this.fetchBrokerCorpData(
              result.contracttypedesc,
              result.contracttypeid
            );
          }

          //Member Corp Only Loaded Data
          if (
            result.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
          ) {
            this.fetchMemberCorpData(
              result.contracttypedesc,
              result.contracttypeid
            );
          }

          //Career Agent/Career Sampling Only Loaded Data
          if (
            result.contracttypedesc ===
              CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
            result.contracttypedesc ===
              CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
            result.contracttypedesc ===
              CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
            result.contracttypedesc ===
              CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
            result.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
          ) {
            this.fetchCareerData(
              result.contracttypedesc,
              result.contracttypeid,
              result.mmlis,
              result.financingonly
            );
          }

          if (result.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS) {
            this.fetchMMLISData(result.contracttypedesc, result.contracttypeid);
          }
        }

        if (
          result.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
          result.contracttypedesc ===
            CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
          result.contracttypedesc ===
            CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
          result.contracttypedesc ===
            CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
          result.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY
        ) {
          this.fetchFinOnlyData();
        }
      } else {
        window.location = "/";
      }
    } catch (err) {
      console.log(err);
    }
  }

  async updateCorpID() {
    const { oktaSubId } = this.state;
    let corpdata = {
      candidateid: this.state.candidateId,
      contractid: this.state.contractId,
    };

    let corpresults = await getCandidateCorp(oktaSubId, corpdata);

    let corpid = 0;
    if (corpresults.corpid !== undefined) {
      corpid = corpresults.corpid;
    }
    await this.setState({ corp_id: corpid });
  }

  render() {
    return (
      <div
        id="startapplication"
        className={isSafari() ? "safari-browser-only" : ""}
      >
        <div className="row" style={{ width: "100%" }}>
          <div
            className="col-md-12"
            style={{ padding: "0px 40px 20px 40px" }}
            ref={ref}
          >
            <div className="row">
              <div className="col-md-3 start-app-nav-border">
                {(this.state.dashboardInfo.contracttypedesc ===
                  CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
                  this.state.dashboardInfo.contracttypedesc ===
                    CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
                  this.state.dashboardInfo.contracttypedesc ===
                    CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
                  this.state.dashboardInfo.contracttypedesc ===
                    CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
                  this.state.dashboardInfo.contracttypedesc ===
                    CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY) && (
                  <StartApplicationNavCASContainer
                    psrData={this.state.psrData.length}
                    toggleForm={this.updateCurrentForm}
                    currentForm={this.state.currentForm}
                    dashboardInfo={this.state.dashboardInfo}
                    contracttypedesc={this.state.dashboardInfo.contracttypedesc}
                    rInfoCompleteInd={this.state.RInfoCompleteInd}
                    authorizationCompleteInd={
                      this.state.AuthorizationCompleteInd
                    }
                    pInfoCompleteInd={this.state.PInfoCompleteInd}
                    pAddlInfoCompleteInd={this.state.PAddlInfoCompleteInd}
                    resHistoryCompleteInd={this.state.resHistoryCompleteInd}
                    busAddrCompleteInd={this.state.BusAddrCompleteInd}
                    eduInfoCompleteInd={this.state.EduInfoCompleteInd}
                    profDesInfoCompleteInd={this.state.ProfDesInfoCompleteInd}
                    empHistCompleteInd={this.state.EmpHistCompleteInd}
                    psrCompleteInd={this.state.PSRCompleteInd}
                    directDepositCompleteInd={
                      this.state.DirectDepositCompleteInd
                    }
                    reviewSignButtonDisabled={
                      this.state.ReviewSignButtonDisabled
                    }
                    includePSR={this.state.includePSR}
                    currentFormChanged={this.state.currentFormChanged}
                    hasNYRegs={this.state.hasNYRegs}
                    mmlisPage1CompleteInd={this.state.MMLISPage1CompleteInd}
                    mmlisPage2CompleteInd={this.state.MMLISPage2CompleteInd}
                    mmlisOBACRIACompleteInd={this.state.MMLISOBACRIACompleteInd}
                    isMMLIS={this.state.dashboardInfo.mmlis}
                    //aInfoCompleteInd={this.state.AInfoCompleteInd}
                    crimDisclosureCompleteInd={{
                      ind: this.state.crimDisclosureCompleteInd,
                      step: this.state.crimDisclosureCompleteStep,
                    }}
                    regActionDisclosureCompleteInd={{
                      ind: this.state.regActionDisclosureCompleteInd,
                      step: this.state.regActionDisclosureCompleteStep,
                    }}
                    civilJudDisclosureCompleteInd={{
                      ind: this.state.civilJudDisclosureCompleteInd,
                      step: this.state.civilJudDisclosureCompleteStep,
                    }}
                    custCompDisclosureCompleteInd={{
                      ind: this.state.custCompDisclosureCompleteInd,
                      step: this.state.custCompDisclosureCompleteStep,
                    }}
                    termDisclosureCompleteInd={{
                      ind: this.state.termDisclosureCompleteInd,
                      step: this.state.termDisclosureCompleteStep,
                    }}
                    finDisclosureCompleteInd={{
                      ind: this.state.finDisclosureCompleteInd,
                      step: this.state.finDisclosureCompleteStep,
                    }}
                  />
                )}
                {this.state.dashboardInfo.contracttypedesc ===
                  CONTRACT_TYPE_DESCRIPTIONS.MMLIS && (
                  <StartApplicationNavMMLIS
                    psrData={this.state.psrData.length}
                    toggleForm={this.updateCurrentForm}
                    currentForm={this.state.currentForm}
                    dashboardInfo={this.state.dashboardInfo}
                    contracttypedesc={this.state.dashboardInfo.contracttypedesc}
                    rInfoCompleteInd={this.state.RInfoCompleteInd}
                    authorizationCompleteInd={
                      this.state.AuthorizationCompleteInd
                    }
                    pInfoCompleteInd={this.state.PInfoCompleteInd}
                    pAddlInfoCompleteInd={this.state.PAddlInfoCompleteInd}
                    resHistoryCompleteInd={this.state.resHistoryCompleteInd}
                    busAddrCompleteInd={this.state.BusAddrCompleteInd}
                    empHistCompleteInd={this.state.EmpHistCompleteInd}
                    psrCompleteInd={this.state.PSRCompleteInd}
                    mmlisPage1CompleteInd={this.state.MMLISPage1CompleteInd}
                    mmlisPage2CompleteInd={this.state.MMLISPage2CompleteInd}
                    mmlisOBACRIACompleteInd={this.state.MMLISOBACRIACompleteInd}
                    reviewSignButtonDisabled={
                      this.state.ReviewSignButtonDisabled
                    }
                    //includePSR={this.state.includePSR}
                    includePSR={false}
                    currentFormChanged={this.state.currentFormChanged}
                    hasNYRegs={this.state.hasNYRegs}
                    crimDisclosureCompleteInd={{
                      ind: this.state.crimDisclosureCompleteInd,
                      step: this.state.crimDisclosureCompleteStep,
                    }}
                    regActionDisclosureCompleteInd={{
                      ind: this.state.regActionDisclosureCompleteInd,
                      step: this.state.regActionDisclosureCompleteStep,
                    }}
                    civilJudDisclosureCompleteInd={{
                      ind: this.state.civilJudDisclosureCompleteInd,
                      step: this.state.civilJudDisclosureCompleteStep,
                    }}
                    custCompDisclosureCompleteInd={{
                      ind: this.state.custCompDisclosureCompleteInd,
                      step: this.state.custCompDisclosureCompleteStep,
                    }}
                    termDisclosureCompleteInd={{
                      ind: this.state.termDisclosureCompleteInd,
                      step: this.state.termDisclosureCompleteStep,
                    }}
                    finDisclosureCompleteInd={{
                      ind: this.state.finDisclosureCompleteInd,
                      step: this.state.finDisclosureCompleteStep,
                    }}
                  />
                )}
                {this.state.dashboardInfo.contracttypedesc ===
                  CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER && (
                  <StartApplicationNavBROKER
                    toggleForm={this.updateCurrentForm}
                    currentForm={this.state.currentForm}
                    dashboardInfo={this.state.dashboardInfo}
                    authorizationCompleteInd={
                      this.state.AuthorizationCompleteInd
                    }
                    pInfoCompleteInd={this.state.PInfoCompleteInd}
                    busAddrCompleteInd={this.state.BusAddrCompleteInd}
                    resAddrCompleteInd={this.state.ResAddrCompleteInd}
                    ccbCompleteInd={this.state.CCBCompleteInd}
                    EOCompleteInd={this.state.EOCompleteInd}
                    brokerCompleteInd={this.state.BrokerCompleteInd}
                    rInfoCompleteInd={this.state.RInfoCompleteInd}
                    directDepositCompleteInd={
                      this.state.DirectDepositCompleteInd
                    }
                    reviewSignButtonDisabled={
                      this.state.ReviewSignButtonDisabled
                    }
                    currentFormChanged={this.state.currentFormChanged}
                    candidateId={this.state.candidateId}
                    agencynumber={this.state.agencynumber}
                    contracttypeid={this.state.contracttypeid}
                  />
                )}
                {this.state.dashboardInfo.contracttypedesc ===
                  CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP && (
                  <StartApplicationNavBROKERCORP
                    toggleForm={this.updateCurrentForm}
                    currentForm={this.state.currentForm}
                    dashboardInfo={this.state.dashboardInfo}
                    contracttypedesc={this.state.dashboardInfo.contracttypedesc}
                    rInfoCompleteInd={this.state.RInfoCompleteInd}
                    authorizationCompleteInd={
                      this.state.AuthorizationCompleteInd
                    }
                    pInfoCompleteInd={this.state.PInfoCompleteInd}
                    busAddrCompleteInd={this.state.BusAddrCompleteInd}
                    corpbusAddrCompleteInd={this.state.CorpBusAddrCompleteInd}
                    corpDirectDepositCompleteInd={
                      this.state.CorpDirectDepositCompleteInd
                    }
                    corpInfoCompleteInd={this.state.CorpInfoCompleteInd}
                    corpCCBCompleteInd={this.state.CorpCCBCompleteInd}
                    corpEOCompleteInd={this.state.CorpEOCompleteInd}
                    reviewSignButtonDisabled={
                      this.state.ReviewSignButtonDisabled
                    }
                    currentFormChanged={this.state.currentFormChanged}
                    resAddrCompleteInd={this.state.ResAddrCompleteInd}
                    ccbCompleteInd={this.state.CCBCompleteInd}
                    EOCompleteInd={this.state.EOCompleteInd}
                    brokerCompleteInd={this.state.BrokerCompleteInd}
                  />
                )}
                {this.state.dashboardInfo.contracttypedesc ===
                  CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP && (
                  <StartApplicationNavMEMBERCORP
                    toggleForm={this.updateCurrentForm}
                    currentForm={this.state.currentForm}
                    dashboardInfo={this.state.dashboardInfo}
                    contracttypedesc={this.state.dashboardInfo.contracttypedesc}
                    rInfoCompleteInd={this.state.RInfoCompleteInd}
                    authorizationCompleteInd={
                      this.state.AuthorizationCompleteInd
                    }
                    pInfoCompleteInd={this.state.PInfoCompleteInd}
                    busAddrCompleteInd={this.state.BusAddrCompleteInd}
                    corpInfoCompleteInd={this.state.MemberCorpInfoCompleteInd}
                    reviewSignButtonDisabled={
                      this.state.ReviewSignButtonDisabled
                    }
                    currentFormChanged={this.state.currentFormChanged}
                    resAddrCompleteInd={this.state.ResAddrCompleteInd}
                    ccbCompleteInd={this.state.CCBCompleteInd}
                    EOCompleteInd={this.state.EOCompleteInd}
                    brokerCompleteInd={this.state.BrokerCompleteInd}
                  />
                )}
                {this.state.dashboardInfo.contracttypedesc ===
                  CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY && (
                  <StartApplicationNavFinOnlyContainer
                    toggleForm={this.updateCurrentForm}
                    dashboardInfo={this.state.dashboardInfo}
                    currentForm={this.state.currentForm}
                    contracttypedesc={this.state.dashboardInfo.contracttypedesc}
                    pInfoCompleteInd={this.state.PInfoCompleteInd}
                    currentFormChanged={this.state.currentFormChanged}
                    reviewSignButtonDisabled={
                      this.state.ReviewSignButtonDisabled
                    }
                  />
                )}
              </div>
              <div className="col-md-9 myapp">
                <StartApplicationHeader
                  currentFormChanged={this.state.currentFormChanged}
                />
                {this.state.isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <Suspense fallback={<Loader />}>
                      <div
                        className="content active-content"
                        id={`divForm${this.state.currentForm}`}
                      >
                        {this.state.currentForm === FORM_ID.RELEASE_OF_INFO && (
                          <ReleaseInformationForm
                            releaseInfoData={this.state.releaseInfoAuthToWork}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            saveReleaseInfoAuthToWork={this.saveData}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm === FORM_ID.AUTHORIZATION && (
                          <AuthorizationForm
                            authToWorkData={this.state.releaseInfoAuthToWork}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            saveReleaseInfoAuthToWork={this.saveData}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm === FORM_ID.PERSONAL_INFO && (
                          <PersonalInformationForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            savePersonalInfo={this.saveData}
                            personalInfoData={this.state.personalInformation}
                            hearAboutUsSelectOptions={
                              this.state.personalInfoHearAboutUsOptions
                            }
                            oktasubid={this.state.oktaSubId}
                            email={this.props.authState.accessToken.claims.sub}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            candidatePresentationMode={
                              this.props.candidatePresentationMode
                            }
                            candidateId={this.state.candidateId}
                            currentFormChanged={this.state.currentFormChanged}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.PERSONAL_INFO_ADDITIONAL_INFO && (
                          <PersonalInfoAddlForm
                            pInfoAddlData={
                              this.state.personalInfoAdditionalInfo
                            }
                            savePersonalInfoAdditionalInfo={
                              this.savePersonalInfoAdditionalInfo
                            }
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updatePAddlInfoCompleteInd}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            candidateId={this.state.candidateId}
                            currentFormChanged={this.state.currentFormChanged}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.RESIDENTIAL_ADDRESS && (
                          <ResidentialAddressForm
                            toggleForm={this.updateCurrentForm}
                            saveResAddress={this.saveData}
                            isFormComplete={this.updateInd}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            dashboardInfo={this.state.dashboardInfo}
                            residentialAddress={this.state.residentialAddress}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.RESIDENTIAL_HISTORY && (
                          <ResidentialHistoryForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateResHistoryCompleteInd}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            candidateId={this.state.candidateId}
                            residentialHistory={this.state.residentialHistory}
                            saveResHistory={this.saveData}
                            currentFormChanged={this.state.currentFormChanged}
                            contracttypedesc={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm === FORM_ID.BUSINESS_INFO && (
                          <BusinessAddressForm
                            toggleForm={this.updateCurrentForm}
                            resAddress={this.state.residentialAddress}
                            busAddrCheck={this.state.businessAddressChecked}
                            busAddress={this.state.businessAddress}
                            setBusAddrChecked={this.setBusinessAddressChecked}
                            saveBusAddress={this.saveData}
                            resAddressHasChanged={
                              this.state.resAddressHasChanged
                            }
                            isFormComplete={this.updateInd}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            dashboardInfo={this.state.dashboardInfo}
                            residentialHistory={this.state.residentialHistory}
                            currentFormChanged={this.state.currentFormChanged}
                            handleFormHasError={this.handleFormHasError}
                            formHasError1={this.state.formHasError}
                            contracttypedesc={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm === FORM_ID.EDUCATION && (
                          <EducationForm
                            eduData={this.state.eduInformation}
                            internEduData={this.state.internEduInformation}
                            eduDegrees={this.state.eduDegrees}
                            eduLevels={this.state.eduLevels}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateEduCompleteInd}
                            saveEduInfo={this.saveEduInfo}
                            saveInternEduInfo={this.saveInternEduInfo}
                            subId={this.state.oktaSubId}
                            candidateId={this.state.candidateId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            currentFormChanged={this.state.currentFormChanged}
                            contracttypedesc={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.PROFESSIONAL_DESIGNATION && (
                          <ProfessionalDesignationsForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateProfDesCompleteInd}
                            profDesData={
                              this.state.ProfessionalDesignationsData
                            }
                            saveProfDesData={this.saveProfDesData}
                            subId={this.state.oktaSubId}
                            candidateId={this.state.candidateId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.EMPLOYMENT_HISTORY && (
                          <EmploymentHistoryForm
                            empHistoryData={this.state.empHistInformation}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateEmpHistCompleteInd}
                            saveEmpHistInfo={this.saveEmpHistInfo}
                            subId={this.state.oktaSubId}
                            candidateId={this.state.candidateId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            refreshPSR={this.refreshPSR}
                            hasNYRegs={this.state.hasNYRegs}
                            psrData={this.state.psrData}
                            currentFormChanged={this.state.currentFormChanged}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            industries={this.state.empHistIndustries}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.PERSONAL_SALES_RECORD && (
                          <PersonalSalesRecordForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updatePSRCompleteInd}
                            saveEmpHistInfo={this.saveEmpHistInfo}
                            subId={this.state.oktaSubId}
                            candidateId={this.state.candidateId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            psrData={this.state.psrData}
                            refreshPSR={this.refreshPSR}
                            psrChartData={this.state.psrChartData}
                            refreshPSRCharts={this.refreshPSRCharts}
                            psrNextEnabled={this.state.psrNextEnabled}
                            hasNYRegs={this.state.hasNYRegs}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            phonenumber={this.state.phonenumber}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.PERSONAL_SALES_RECORD_CHARTS && (
                          <PersonalSalesRecordCharts
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updatePSRCompleteInd}
                            saveEmpHistInfo={this.saveEmpHistInfo}
                            subId={this.state.oktaSubId}
                            candidateId={this.state.candidateId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            psrChartData={this.state.psrChartData}
                            refreshPSRCharts={this.refreshPSRCharts}
                            psrComData={this.state.commission_data}
                            psrFinData={this.state.financial_plan_data}
                            totalFinPlans={this.state.total_fin_plans}
                            psrDataLength={this.state.psr_data_length}
                            has_ind_life={this.state.has_ind_life}
                            has_ind_annuity={this.state.has_ind_annuity}
                            has_ind_disabilty={this.state.has_ind_disabilty}
                            has_ind_long_term={this.state.has_ind_long_term}
                            has_gross_commissions={
                              this.state.has_gross_commissions
                            }
                            has_fee_based_sales={this.state.has_fee_based_sales}
                            hasNYRegs={this.state.hasNYRegs}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm === FORM_ID.OTHER_BUSINESS && (
                          <OtherBusinessForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateBrokerCompleteInd}
                            saveEmpHistInfo={this.saveEmpHistInfo}
                            subId={this.state.oktaSubId}
                            candidateId={this.state.candidateId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            psrData={this.state.psrData.length}
                            currentFormChanged={this.state.currentFormChanged}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm === FORM_ID.BROKER_DEALER && (
                          <BrokerDealerForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            brokerDealerInfo={
                              this.state.brokerDealerInformation
                            }
                            saveBrokerDealerInfo={this.saveData}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm === FORM_ID.CCB && (
                          <CCBForm
                            ccbData={this.state.characterCreditBonding}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            saveCharCreditBonding={this.saveData}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.ERRORS_OMISSIONS && (
                          <ErrorsAndOmissionsForm
                            eocData={this.state.errorsAndOmissions}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            saveErrorsOmissions={this.saveData}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.CORPORATE_ERRORS_OMISSIONS && (
                          <CorporationErrorsAndOmissionsForm
                            eocData={this.state.eocData}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            saveCorpErrorsOmissions={this.saveData}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            corpid={this.state.corp_id}
                          />
                        )}

                        {this.state.currentForm === FORM_ID.MMLIS_INTRO && (
                          <MMLISIntroForm
                            toggleForm={this.updateCurrentForm}
                            contractid={this.state.dashboardInfo.contractid}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}

                        {this.state.currentForm ===
                          FORM_ID.MMLIS_PAY_TO_PLAY_1 && (
                          <MMLISPayToPlayForm1
                            toggleForm={this.updateCurrentForm}
                            contractid={this.state.dashboardInfo.contractid}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            mmlisData={this.state.mmlisData}
                            isFormComplete={this.updateInd}
                            saveMMLISInfo={this.saveMMLISInfo}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            currentFormChanged={this.state.currentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.MMLIS_PAY_TO_PLAY_2 && (
                          <MMLISPayToPlayForm2
                            toggleForm={this.updateCurrentForm}
                            contractid={this.state.dashboardInfo.contractid}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            mmlisData={this.state.mmlisData}
                            isFormComplete={this.updateInd}
                            saveMMLISInfo={this.saveMMLISInfo}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            currentFormChanged={this.state.currentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm === FORM_ID.MMLIS_OBA_CRIA && (
                          <MMLISOBACRIAForm
                            toggleForm={this.updateCurrentForm}
                            contractid={this.state.dashboardInfo.contractid}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            mmlisData={this.state.mmlisObaCriaData}
                            isFormComplete={this.updateInd}
                            saveMMLISOBACRIAInfo={this.saveMMLISOBACRIAInfo}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            currentFormChanged={this.state.currentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}

                        {this.state.currentForm === FORM_ID.DIRECT_DEPOSIT && (
                          <DirectDepositForm
                            isMMLIS={this.state.dashboardInfo.mmlis}
                            ddData={this.state.directDepositInformation}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            saveDirectDepositInfo={this.saveData}
                            isNextDisabled={this.state.ReviewSignButtonDisabled}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            contractid={this.state.dashboardInfo.contractid}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.CORPORATE_BUSINESS_ADDRESS && (
                          <CorpBusinessAddressForm
                            toggleForm={this.updateCurrentForm}
                            busAddrCheck={this.state.businessAddressChecked}
                            busAddress={this.state.corporationData}
                            setBusAddrChecked={this.setBusinessAddressChecked}
                            saveBusAddress={this.saveData}
                            isFormComplete={this.updateInd}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            dashboardInfo={this.state.dashboardInfo}
                            currentFormChanged={this.state.currentFormChanged}
                            handleFormHasError={this.handleFormHasError}
                            formHasError1={this.state.formHasError}
                            contracttypedesc={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            corpid={this.state.corp_id}
                          />
                        )}

                        {this.state.currentForm === FORM_ID.CORPORATE_INFO && (
                          <CorpInformationForm
                            corporationData={this.state.corporationData}
                            updateCorpID={this.updateCorpID}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            saveCorporationInfo={this.saveData}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            contractid={this.state.dashboardInfo.contractid}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            corpid={this.state.corp_id}
                            contracttypedesc={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.CORPORATE_DIRECT_DEPOSIT && (
                          <CorpDirectDepositForm
                            ddData={this.state.corporationDirectDepositData}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            saveCorpDirectDepositInfo={this.saveData}
                            isNextDisabled={this.state.ReviewSignButtonDisabled}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            contractid={this.state.dashboardInfo.contractid}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            corpid={this.state.corp_id}
                          />
                        )}

                        {
                          //!this.state.ReviewSignButtonDisabled &&
                          this.state.currentForm === FORM_ID.REVIEW_SIGN_1 && (
                            <ReviewSignForm1
                              toggleForm={this.updateCurrentForm}
                              hasCurrentFormChanged={this.hasCurrentFormChanged}
                              saveESigData={this.saveESigData}
                              contracttypedesc={
                                this.state.dashboardInfo.contracttypedesc
                              }
                              contractid={this.state.dashboardInfo?.contractid}
                              personalInformationData={
                                this.state.personalInformation
                              }
                              resAddressData={this.state.residentialAddress}
                              busAddrCheck={this.state.businessAddressChecked}
                              busInfoData={this.state.businessAddress}
                              brokerDealerData={
                                this.state.brokerDealerInformation
                              }
                              ccbData={this.state.characterCreditBonding}
                              eocData={this.state.errorsAndOmissions}
                              ddInfoData={this.state.directDepositInformation}
                              subId={this.state.oktaSubId}
                              candidatePresentationMode={
                                this.props.candidatePresentationMode
                              }
                              psrData={this.state.psrData}
                              hasNYRegs={this.state.hasNYRegs}
                              isMMLIS={this.state.dashboardInfo.mmlis}
                            />
                          )
                        }
                        {
                          //!this.state.ReviewSignButtonDisabled &&
                          this.state.currentForm === FORM_ID.REVIEW_SIGN_2 && (
                            <ReviewSignForm2
                              reviewSign2Verbiage={
                                this.state.reviewSign2Verbiage
                              }
                              dashboardInfo={this.state.dashboardInfo}
                              pInfo={this.state.personalInformation}
                              toggleForm={this.updateCurrentForm}
                              hasCurrentFormChanged={this.hasCurrentFormChanged}
                              saveESigData={this.saveESigData}
                              contracttypedesc={
                                this.state.dashboardInfo.contracttypedesc
                              }
                              blankContractBlob={this.state.blankContractBlob}
                              blankDisclosureBlob={
                                this.state.blankDisclosureBlob
                              }
                              candidateId={this.state.candidateId}
                              agencynumber={this.state.agencynumber}
                              contracttypeid={this.state.contracttypeid}
                              corpid={this.state.corp_id}
                              corpInfo={this.state.corporationData}
                            />
                          )
                        }
                        {this.state.currentForm === FORM_ID.CORPORATE_CCB && (
                          <CorpCCBForm
                            corpCCBData={this.state.corpCCBData}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            saveCharCreditBonding={this.saveData}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            corpid={this.state.corp_id}
                          />
                        )}

                        {this.state.currentForm === FORM_ID.CCB_INTRO && (
                          <CCBIntroForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateBrokerCompleteInd}
                            updateCCB14CompletedInd={
                              this.updateCCB14CompletedInd
                            }
                            updateCCB14Step={this.updateCCB14CompletedInd}
                            subId={this.state.oktaSubId}
                            candidateId={this.state.candidateId}
                            psrData={this.state.psrData.length}
                            agencynumber={this.state.agencynumber}
                            phonenumber={this.state.phonenumber}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.CRIMINAL_DISCLOSURE && (
                          <CriminalDisclosureForm
                            toggleForm={this.updateCurrentForm}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            updateCCB14CompletedInd={
                              this.updateCCB14CompletedInd
                            }
                            updateCCB14Step={this.updateCCB14CompletedInd}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.REGULATORY_ACTION_DISCLOSURE && (
                          <RegulatoryActionForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateBrokerCompleteInd}
                            subId={this.state.oktaSubId}
                            updateCCB14CompletedInd={
                              this.updateCCB14CompletedInd
                            }
                            updateCCB14Step={this.updateCCB14CompletedInd}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.CIVIL_JUDICIAL_DISCLOSURE && (
                          <CivilJudicialForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateBrokerCompleteInd}
                            subId={this.state.oktaSubId}
                            updateCCB14CompletedInd={
                              this.updateCCB14CompletedInd
                            }
                            updateCCB14Step={this.updateCCB14CompletedInd}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.CUSTOMER_COMPLAINT_DISCLOSURE && (
                          <CustomerComplaintForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateBrokerCompleteInd}
                            subId={this.state.oktaSubId}
                            updateCCB14CompletedInd={
                              this.updateCCB14CompletedInd
                            }
                            updateCCB14Step={this.updateCCB14CompletedInd}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.TERMINATION_DISCLOSURE && (
                          <TerminationDisclosureForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateBrokerCompleteInd}
                            subId={this.state.oktaSubId}
                            updateCCB14CompletedInd={
                              this.updateCCB14CompletedInd
                            }
                            updateCCB14Step={this.updateCCB14CompletedInd}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.FINANCIAL_DISCLOSURE && (
                          <FinancialDisclosureForm
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateBrokerCompleteInd}
                            subId={this.state.oktaSubId}
                            updateCCB14CompletedInd={
                              this.updateCCB14CompletedInd
                            }
                            updateCCB14Step={this.updateCCB14CompletedInd}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                          />
                        )}
                        {this.state.currentForm ===
                          FORM_ID.MEMBER_CORPORATE_INFO && (
                          <MOCInformationForm
                            corporationData={this.state.corporationData}
                            updateCorpID={this.updateCorpID}
                            toggleForm={this.updateCurrentForm}
                            isFormComplete={this.updateInd}
                            saveCorporationInfo={this.saveData}
                            subId={this.state.oktaSubId}
                            hasCurrentFormChanged={this.hasCurrentFormChanged}
                            contractType={
                              this.state.dashboardInfo.contracttypedesc
                            }
                            contractid={this.state.dashboardInfo.contractid}
                            currentFormChanged={this.state.currentFormChanged}
                            candidateId={this.state.candidateId}
                            agencynumber={this.state.agencynumber}
                            contracttypeid={this.state.contracttypeid}
                            corpid={this.state.corp_id}
                          />
                        )}
                      </div>
                    </Suspense>
                    <div className="row">
                      <div
                        className="col-12"
                        style={{
                          marginTop: "300px",
                          padding: "0 3.2rem",
                        }}
                      >
                        <Footer />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCCB: (oktaSubId) => dispatch(fetchCCB(oktaSubId)),
    setIsBack: (bool) => dispatch(ccbActions.setIsBack(bool)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withOktaAuth(StartApplication));
