import React from "react";
import { Button, Modal, Alert } from "react-blueprint-library";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Moment from "moment";
import "../StartApplication.scss";
import "../../../assets/stylesheets/StartApplication/CAS/PersonalSalesRecordModal.scss";
import {
  insertPSRRecord,
  getPSRRecord,
  updatePSRRecord,
} from "../../../api/makeAPIRequest";
import {
  onlyNumeric,
  pasteCommaDelimited,
  pasteValue,
} from "../../../common/Functions";
import BackButtonModal from "../Forms/BackButtonModal";

class PersonalSalesRecordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.nextClick = this.nextClick.bind(this);
    this.addClick = this.addClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
    this.backClick = this.backClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.handleInvestmentProductsChange =
      this.handleInvestmentProductsChange.bind(this);
    this.handleFeeBasedChange = this.handleFeeBasedChange.bind(this);
    this.handleFinancialPlanningChange =
      this.handleFinancialPlanningChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.reviewEditData.startdate !== this.state.startdate ||
      this.props.reviewEditData.enddate !== this.state.enddate ||
      this.props.reviewEditData.companyname !== this.state.companyname ||
      this.props.reviewEditData.jobtitle !== this.state.jobtitle
    ) {
      var showLTCPanel = false;
      if (this.props.reviewEditData.solicitindltc === true) {
        showLTCPanel = true;
      }
      var showLifePanel = false;
      if (this.props.reviewEditData.solicitindlife === true) {
        showLifePanel = true;
      }
      var showDisabilityPanel = false;
      if (this.props.reviewEditData.solicitinddisability === true) {
        showDisabilityPanel = true;
      }
      var showAnnuityPanel = false;
      if (this.props.reviewEditData.solicitindannuity === true) {
        showAnnuityPanel = true;
      }
      var numberOfMonths = Math.round(
        Moment(new Date(this.props.reviewEditData.enddate)).diff(
          new Date(this.props.reviewEditData.startdate),
          "months",
          true
        )
      );

      await this.setState({
        employment_history_id: this.props.reviewEditData.employmenthistoryid,
        candidate_id: this.props.reviewEditData.candidateId,
        jobtitle: this.props.reviewEditData.jobtitle,
        startdate: this.props.reviewEditData.startdate,
        enddate: this.props.reviewEditData.enddate,
        companyname: this.props.reviewEditData.companyname,
        num_months: numberOfMonths,
        show_individual_annuity_panel: showAnnuityPanel,
        show_individual_disability_income_panel: showDisabilityPanel,
        show_individual_life_insurance_panel: showLifePanel,
        show_individual_long_term_care_panel: showLTCPanel,
        review_edit: false,
        ind_life_first_year: "",
        ind_life_renewal: "",
        ind_annuity_first_year: "",
        ind_annuity_renewal: "",
        ind_disability_first_year: "",
        ind_disability_renewal: "",
        ind_longterm_first_year: "",
        ind_longterm_renewal: "",
        psr_id: 0,
        form_has_changed: false,
      });

      if (this.props.reviewEditData.psr_id > 0) {
        var result = await getPSRRecord(
          this.props.subId,
          this.props.reviewEditData.psr_id
        );

        if (result.sellinvproducts === true) {
          this.setState({
            investment_products: "Y",
            show_investment_products: true,
          });
        } else {
          this.setState({
            investment_products: "N",
            show_investment_products: false,
          });
        }
        if (result.feebasedsales === true) {
          this.setState({ fee_based: "Y", show_fee_based: true });
        } else {
          this.setState({ fee_based: "N", show_fee_based: false });
        }
        if (result.sellfinplanning === true) {
          this.setState({
            financial_planning: "Y",
            show_financial_planning: true,
          });
        } else {
          this.setState({
            financial_planning: "N",
            show_financial_planning: false,
          });
        }

        if (result.indlifirstyear !== null) {
          var indFirstYearLife = Math.trunc(result.indlifirstyear).toString();
          var commaSeparatedNumber = indFirstYearLife
            .split(/(?=(?:\d{3})+$)/)
            .join(",");
          await this.setState({
            ind_life_first_year: commaSeparatedNumber,
          });
        }
        if (result.indlirenewcom !== null) {
          var indLifeRen = Math.trunc(result.indlirenewcom).toString();
          commaSeparatedNumber = indLifeRen.split(/(?=(?:\d{3})+$)/).join(",");
          await this.setState({
            ind_life_renewal: commaSeparatedNumber,
          });
        }
        if (result.indannfirstyear !== null) {
          var indFirstYearAnnuity = Math.trunc(
            result.indannfirstyear
          ).toString();
          commaSeparatedNumber = indFirstYearAnnuity
            .split(/(?=(?:\d{3})+$)/)
            .join(",");
          await this.setState({
            ind_annuity_first_year: commaSeparatedNumber,
          });
        }
        if (result.indannrenewcom !== null) {
          var indAnnuityRen = Math.trunc(result.indannrenewcom).toString();
          commaSeparatedNumber = indAnnuityRen
            .split(/(?=(?:\d{3})+$)/)
            .join(",");
          await this.setState({
            ind_annuity_renewal: commaSeparatedNumber,
          });
        }
        if (result.inddisincfirstyear !== null) {
          var indFirstYearDisability = Math.trunc(
            result.inddisincfirstyear
          ).toString();
          commaSeparatedNumber = indFirstYearDisability
            .split(/(?=(?:\d{3})+$)/)
            .join(",");
          await this.setState({
            ind_disability_first_year: commaSeparatedNumber,
          });
        }
        if (result.inddisincrenewcom !== null) {
          var indDisabilityRen = Math.trunc(
            result.inddisincrenewcom
          ).toString();
          commaSeparatedNumber = indDisabilityRen
            .split(/(?=(?:\d{3})+$)/)
            .join(",");
          await this.setState({
            ind_disability_renewal: commaSeparatedNumber,
          });
        }
        if (result.indlongtermfirstyear !== null) {
          var indFirstYearLongTerm = Math.trunc(
            result.indlongtermfirstyear
          ).toString();
          commaSeparatedNumber = indFirstYearLongTerm
            .split(/(?=(?:\d{3})+$)/)
            .join(",");
          await this.setState({
            ind_longterm_first_year: commaSeparatedNumber,
          });
        }
        if (result.indlongtermrenewcom !== null) {
          var indLongTermRen = Math.trunc(
            result.indlongtermrenewcom
          ).toString();
          commaSeparatedNumber = indLongTermRen
            .split(/(?=(?:\d{3})+$)/)
            .join(",");
          await this.setState({
            ind_longterm_renewal: commaSeparatedNumber,
          });
        }
        if (result.invprodgrossdealercom !== null) {
          var invProdGross = Math.trunc(
            result.invprodgrossdealercom
          ).toString();
          commaSeparatedNumber = invProdGross
            .split(/(?=(?:\d{3})+$)/)
            .join(",");
          await this.setState({
            investment_product_gross: commaSeparatedNumber,
          });
        }

        if (result.feebasedsalesrelfees !== null) {
          var feeBasedSales = Math.trunc(
            result.feebasedsalesrelfees
          ).toString();
          commaSeparatedNumber = feeBasedSales
            .split(/(?=(?:\d{3})+$)/)
            .join(",");
          await this.setState({
            fee_based_sales: commaSeparatedNumber,
          });
        }

        if (result.nrfinplanssell !== null) {
          var numFinPlanSell = Math.trunc(result.nrfinplanssell).toString();
          commaSeparatedNumber = numFinPlanSell
            .split(/(?=(?:\d{3})+$)/)
            .join(",");
          await this.setState({
            number_financial_plans: commaSeparatedNumber,
          });
        }

        this.setState({
          percent_ladl: result.positionladlpct,
          step_number: 1,
          is_next_disabled: false,
          employment_history_id: this.props.reviewEditData.employmenthistoryid,
          candidate_id: this.props.reviewEditData.candidateId,
          jobtitle: this.props.reviewEditData.jobtitle,
          startdate: this.props.reviewEditData.startdate,
          enddate: this.props.reviewEditData.enddate,
          companyname: this.props.reviewEditData.companyname,
          num_months: Math.ceil(numberOfMonths),
          show_individual_annuity_panel: showAnnuityPanel,
          show_individual_disability_income_panel: showDisabilityPanel,
          show_individual_life_insurance_panel: showLifePanel,
          show_individual_long_term_care_panel: showLTCPanel,
          //number_financial_plans: result.nrfinplanssell,
          is_add_disabled: false,
          review_edit: true,
          psr_id: this.props.reviewEditData.psr_id,
        });
        this.setState({
          calc_months: (
            (this.state.percent_ladl * this.state.num_months) /
            100
          ).toFixed(2),
        });
      } else if (this.props.reviewEditData.psr_id === 0) {
        await this.setState({
          employment_history_id: this.props.reviewEditData.employmenthistoryid,
          candidate_id: this.props.reviewEditData.candidateId,
          jobtitle: this.props.reviewEditData.jobtitle,
          startdate: this.props.reviewEditData.startdate,
          enddate: this.props.reviewEditData.enddate,
          companyname: this.props.reviewEditData.companyname,
          num_months: numberOfMonths,
          show_individual_annuity_panel: showAnnuityPanel,
          show_individual_disability_income_panel: showDisabilityPanel,
          show_individual_life_insurance_panel: showLifePanel,
          show_individual_long_term_care_panel: showLTCPanel,
          //personalsalesrecords: this.props.reviewEditData.personalsalesrecords,
          review_edit: false,
          ind_life_first_year: "",
          ind_life_renewal: "",
          ind_annuity_first_year: "",
          ind_annuity_renewal: "",
          ind_disability_first_year: "",
          ind_disability_renewal: "",
          ind_longterm_first_year: "",
          ind_longterm_renewal: "",
          psr_id: 0,
          is_next_disabled: true,
          step_number: 1,
          percent_ladl: "",
          sell_investment: "",
          sell_fee_based: "",
          sell_financial_planning: "",
          investment_products: "",
          fee_based: "",
          financial_planning: "",
          show_investment_products: false,
          show_fee_based: false,
          show_financial_planning: false,
        });
      }
      //var endDateText = "";
      var endDateTmp = Moment(this.state.enddate).utc().format("MM/DD/YYYY");
      var today = Moment().format("MM/DD/YYYY");
      if (endDateTmp === today) {
        this.setState({ endDateText: "Present" });
      } else {
        this.setState({ endDateText: endDateTmp });
      }
    }
  }
  async validateFields() {
    await this.setState({
      is_add_disabled: true,
      is_next_disabled: true,
    });
    if (this.state.step_number === 1 && this.props.hasNYRegs === false) {
      var hasError = false;

      if (this.state.show_individual_life_insurance_panel === true) {
        if (
          this.state.ind_life_first_year === "" ||
          this.state.ind_life_renewal === ""
        ) {
          hasError = true;
        }
      }
      if (this.state.show_individual_annuity_panel === true) {
        if (
          this.state.ind_annuity_first_year === "" ||
          this.state.ind_annuity_renewal === ""
        ) {
          hasError = true;
        }
      }
      if (this.state.show_individual_disability_income_panel === true) {
        if (
          this.state.ind_disability_first_year === "" ||
          this.state.ind_disability_renewal === ""
        ) {
          hasError = true;
        }
      }
      if (this.state.show_individual_long_term_care_panel === true) {
        if (
          this.state.ind_longterm_first_year === "" ||
          this.state.ind_longterm_renewal === ""
        ) {
          hasError = true;
        }
      }
      if (hasError === false) {
        await this.setState({
          is_add_disabled: false,
          is_next_disabled: false,
        });
      }
    } else if (this.state.step_number === 1 && this.props.hasNYRegs === true) {
      hasError = false;

      if (
        this.state.show_individual_life_insurance_panel === true &&
        this.state.ind_life_first_year === ""
      ) {
        hasError = true;
      }
      if (
        this.state.show_individual_annuity_panel === true &&
        this.state.ind_annuity_first_year === ""
      ) {
        hasError = true;
      }
      if (
        this.state.show_individual_disability_income_panel === true &&
        this.state.ind_disability_first_year === ""
      ) {
        hasError = true;
      }
      if (
        this.state.show_individual_long_term_care_panel === true &&
        this.state.ind_longterm_first_year === ""
      ) {
        hasError = true;
      }
      if (hasError === false) {
        await this.setState({
          is_add_disabled: false,
          is_next_disabled: false,
        });
      }
    } else if (this.state.step_number === 2 && this.props.hasNYRegs === false) {
      hasError = false;

      if (
        this.state.percent_ladl === "" ||
        parseInt(this.state.percent_ladl) < 0 ||
        parseInt(this.state.percent_ladl) > 100
      ) {
        hasError = true;
      }
      if (
        this.state.investment_products === "Y" &&
        (this.state.investment_product_gross === "" ||
          parseInt(this.state.investment_product_gross) === 0)
      ) {
        hasError = true;
      }

      if (
        this.state.fee_based === "Y" &&
        (this.state.fee_based_sales === "" ||
          parseInt(this.state.fee_based_sales) === 0)
      ) {
        hasError = true;
      }

      if (
        this.state.financial_planning === "Y" &&
        (this.state.number_financial_plans === "" ||
          parseInt(this.state.number_financial_plans) === 0)
      ) {
        hasError = true;
      }

      if (
        this.state.investment_products === "" ||
        this.state.fee_based === "" ||
        this.state.financial_planning === ""
      ) {
        hasError = true;
      }

      if (hasError === false) {
        await this.setState({ is_add_disabled: false });
      }
    } else if (this.state.step_number === 2 && this.props.hasNYRegs === true) {
      hasError = false;

      if (
        this.state.percent_ladl === "" ||
        parseInt(this.state.percent_ladl) < 0
      ) {
        hasError = true;
      }
      if (
        this.state.financial_planning === "Y" &&
        (this.state.number_financial_plans === "" ||
          parseInt(this.state.number_financial_plans) === 0)
      ) {
        hasError = true;
      }

      if (
        //this.state.investment_products === "" ||
        //this.state.fee_based === "" ||
        this.state.financial_planning === ""
      ) {
        hasError = true;
      }

      if (hasError === false) {
        await this.setState({ is_add_disabled: false });
      }
    }
  }
  onKeyDown() {
    this.setState({ form_has_changed: true });
  }

  initialState() {
    return {
      step_number: 0,
      is_add_disabled: true,
      is_next_disabled: true,
      show_add_button: true,
      show_next_button: false,
      companyname: "",
      jobtitle: "",
      startdate: "",
      enddate: "",
      ind_life_first_year: "",
      ind_life_renewal: "",
      ind_annuity_first_year: "",
      ind_annuity_renewal: "",
      ind_disability_first_year: "",
      ind_disability_renewal: "",
      ind_longterm_first_year: "",
      ind_longterm_renewal: "",
      investment_product: "",
      fee_based: "",
      financial_planning: "",
      show_investment_products: false,
      show_fee_based: false,
      show_financial_planning: false,
      number_financial_plans: "",
      fee_based_sales: "",
      investment_product_gross: "",
      percent_ladl: "",
      num_months: "",
      calc_months: "",
      investment_products: "",
      // is_form_saved: false,
      review_edit: false,
      row_id: -1,
      show_individual_life_insurance_panel: false,
      show_individual_annuity_panel: false,
      show_individual_disability_income_panel: false,
      show_individual_long_term_care_panel: false,
      personalsalesrecords: [],
      employment_history_id: "",
      candidate_id: "",
      psr_id: "",
      endDateText: "",
      form_has_changed: false,
      showBackModal: false,
    };
  }
  async nextClick() {
    await this.setState({
      step_number: 2,
    });
    if (this.state.review_edit === true) {
      this.setState({ is_add_disabled: false });
    }

    let myRef = document.getElementById("top_element");
    myRef.scrollIntoView();
    this.validateFields();
  }
  async addClick() {
    //save record
    var sell_investment = false;
    if (this.state.investment_products === "Y") {
      sell_investment = true;
    }
    var sell_fee_based = false;
    if (this.state.fee_based === "Y") {
      sell_fee_based = true;
    }
    var sell_financial_planning = false;
    if (this.state.financial_planning === "Y") {
      sell_financial_planning = true;
    }
    if (typeof this.state.ind_life_first_year === "undefined") {
      this.setState({ ind_life_first_year: "" });
    }
    if (typeof this.state.ind_life_renewal === "undefined") {
      this.setState({ ind_life_renewal: "" });
    }
    if (typeof this.state.ind_annuity_first_year === "undefined") {
      this.setState({ ind_annuity_first_year: "" });
    }
    if (typeof this.state.ind_annuity_renewal === "undefined") {
      this.setState({ ind_annuity_renewal: "" });
    }
    if (typeof this.state.ind_disability_first_year === "undefined") {
      this.setState({ ind_disability_first_year: "" });
    }
    if (typeof this.state.ind_disability_renewal === "undefined") {
      this.setState({ ind_disability_renewal: "" });
    }
    if (typeof this.state.ind_longterm_first_year === "undefined") {
      this.setState({ ind_longterm_first_year: "" });
    }
    if (typeof this.state.ind_longterm_renewal === "undefined") {
      this.setState({ ind_longterm_renewal: "" });
    }
    var lifeFirstYear = 0;
    if (
      this.state.ind_life_first_year !== "" &&
      this.state.ind_life_first_year !== null
    ) {
      lifeFirstYear = parseInt(
        this.state.ind_life_first_year.replaceAll(",", "")
      );
    }
    var lifeRenewal = 0;
    if (
      this.state.ind_life_renewal !== "" &&
      this.state.ind_life_renewal !== null
    ) {
      lifeRenewal = parseInt(this.state.ind_life_renewal.replaceAll(",", ""));
    }
    var annuityFirstYear = 0;
    if (
      this.state.ind_annuity_first_year !== "" &&
      this.state.ind_annuity_first_year !== null
    ) {
      annuityFirstYear = parseInt(
        this.state.ind_annuity_first_year.replaceAll(",", "")
      );
    }
    var annuityRenewal = 0;
    if (
      this.state.ind_annuity_renewal !== "" &&
      this.state.ind_annuity_renewal !== null
    ) {
      annuityRenewal = parseInt(
        this.state.ind_annuity_renewal.replaceAll(",", "")
      );
    }
    var disabilityFirstYear = 0;
    if (
      this.state.ind_disability_first_year !== "" &&
      this.state.ind_disability_first_year !== null
    ) {
      disabilityFirstYear = parseInt(
        this.state.ind_disability_first_year.replaceAll(",", "")
      );
    }
    var disabilityRenewal = 0;
    if (
      this.state.ind_disability_renewal !== "" &&
      this.state.ind_disability_renewal !== null
    ) {
      disabilityRenewal = parseInt(
        this.state.ind_disability_renewal.replaceAll(",", "")
      );
    }
    var longtermFirstYear = 0;
    if (
      this.state.ind_longterm_first_year !== "" &&
      this.state.ind_longterm_first_year !== null
    ) {
      longtermFirstYear = parseInt(
        this.state.ind_longterm_first_year.replaceAll(",", "")
      );
    }
    var longtermRenewal = 0;
    if (
      this.state.ind_longterm_renewal !== "" &&
      this.state.ind_longterm_renewal !== null
    ) {
      longtermRenewal = parseInt(
        this.state.ind_longterm_renewal.replaceAll(",", "")
      );
    }
    var invProdGross = null;
    if (
      this.state.investment_product_gross !== "" &&
      this.state.investment_product_gross !== null
    ) {
      invProdGross = parseInt(
        this.state.investment_product_gross.replaceAll(",", "")
      );
    }
    var feeBasedSales = null;
    if (
      this.state.fee_based_sales !== "" &&
      this.state.fee_based_sales !== null
    ) {
      feeBasedSales = parseInt(this.state.fee_based_sales.replaceAll(",", ""));
    }

    var nrfinplansell = null;
    if (
      this.state.number_financial_plans !== "" &&
      this.state.number_financial_plans !== null
    ) {
      nrfinplansell = parseInt(
        this.state.number_financial_plans.replaceAll(",", "")
      );
    }

    let data = {
      candidateid: this.props.candidateId,
      startdate: this.state.startdate,
      enddate: this.state.enddate,
      indlifirstyear: lifeFirstYear,
      indlirenewcom: lifeRenewal,
      indannfirstyear: annuityFirstYear,
      indannrenewcom: annuityRenewal,
      inddisincfirstyear: disabilityFirstYear,
      inddisincrenewcom: disabilityRenewal,
      indlongtermfirstyear: longtermFirstYear,
      indlongtermrenewcom: longtermRenewal,
      positionladlpct: parseInt(this.state.percent_ladl),
      sellinvproducts: sell_investment,
      invprodgrossdealercom: invProdGross,
      feebasedsales: sell_fee_based,
      feebasedsalesrelfees: feeBasedSales,
      sellfinplanning: sell_financial_planning,
      nrfinplanssell: nrfinplansell,
    };

    // insert or update?
    if (this.state.review_edit !== true) {
      //insert new
      this.setState({ step_number: 0 });

      await insertPSRRecord(
        this.props.subId,
        this.state.employment_history_id,
        data
      );
    } else {
      //update existing
      await updatePSRRecord(this.props.subId, this.state.psr_id, data);
    }

    this.props.showAlert();
    this.props.actionCancel(this.state);
    this.resetFields();
  }
  async cancelClick() {
    this.resetFields();
    this.props.actionCancel(null);
  }
  async backClick() {
    await this.setState({
      step_number: 1,
      is_next_disabled: false,
      show_next_button: true,
      show_add_button: false,
    });
    let myRef = document.getElementById("top_element");
    myRef.scrollIntoView();
  }
  async handleChange(event) {
    await this.setState({
      [event.target.id]: event.target.value,
      is_form_saved: false,
    });

    if (
      event.target.id.startsWith("ind_") ||
      event.target.id.startsWith("fee_based_sales") ||
      event.target.id.startsWith("investment_product_gross") ||
      event.target.id.startsWith("number_financial_plans")
    ) {
      var tempNumber = event.target.value.replace(/,/gi, "");
      var commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");
      await this.setState({ [event.target.id]: commaSeparatedNumber });
    }

    // if (event.target.id.startsWith("percent_ladl")) {
    //   if (event.target.value < 101 && event.target.value > 0) {
    //     this.setState({
    //       calc_months: (
    //         (this.state.percent_ladl * this.state.num_months) /
    //         100
    //       ).toFixed(2),
    //     });
    //     var ladlEvent = new Event("blur");
    //     Object.defineProperty(ladlEvent, "target", {
    //       writable: false,
    //       value: document.getElementById("percent_ladl"),
    //     });
    //     await this.onBlur(ladlEvent);
    //   } else {
    //     await this.setState({ percent_ladl: "" });
    //     var ladlEvent2 = new Event("blur");
    //     Object.defineProperty(ladlEvent2, "target", {
    //       writable: false,
    //       value: document.getElementById("percent_ladl"),
    //     });
    //     await this.onBlur(ladlEvent2);
    //   }
    // }

    //this.props.hasCurrentFormChanged(true);
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async resetFields() {
    await this.setState({
      step_number: 0,
      is_add_disabled: true,
      is_next_disabled: true,
      show_add_button: true,
      show_next_button: false,
      display_title: "",
      display_start_date: "",
      display_end_date: "",
      ind_life_first_year: "",
      ind_life_renewal: "",
      ind_annuity_first_year: "",
      ind_annuity_renewal: "",
      ind_disability_first_year: "",
      ind_disability_renewal: "",
      ind_longterm_first_year: "",
      ind_longterm_renewal: "",
      investment_product: "",
      fee_based: "",
      financial_planning: "",
      show_investment_products: false,
      show_fee_based: false,
      show_financial_planning: false,
      number_financial_plans: "",
      fee_based_sales: "",
      investment_product_gross: "",
      percent_ladl: "",
      num_months: "",
      calc_months: "",
      investment_products: "",
      show_individual_life_insurance_panel: false,
      show_individual_annuity_panel: false,
      show_individual_disability_income_panel: false,
      show_individual_long_term_care_panel: false,
      personalsalesrecords: [],
      employment_history_id: "",
      candidate_id: "",
      psr_id: "",
      endDateText: "",
    });
  }
  async handleInvestmentProductsChange(event) {
    await this.setState({ investment_products: event.target.value });

    if (event.target.value === "Y") {
      this.setState({ show_investment_products: true });
    } else {
      this.setState({
        show_investment_products: false,
        investment_product_gross: "",
      });
    }
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleFeeBasedChange(event) {
    await this.setState({ fee_based: event.target.value });

    if (event.target.value === "Y") {
      this.setState({ show_fee_based: true });
    } else {
      this.setState({ show_fee_based: false, fee_based_sales: "" });
    }
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleFinancialPlanningChange(event) {
    await this.setState({ financial_planning: event.target.value });

    if (event.target.value === "Y") {
      this.setState({ show_financial_planning: true });
    } else {
      this.setState({
        show_financial_planning: false,
        number_financial_plans: "",
      });
    }
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async onBlur(event) {
    var obj_error;
    var obj_input_$;
    if (event.target.id.startsWith("ind_")) {
      obj_error = document.getElementById(event.target.id + "_error");
      obj_input_$ = document.getElementById(event.target.id + "_input_$");
      if (event.target.value.trim(" ").length < 1) {
        obj_error.style.display = "block";
        obj_input_$.style.color = "#e21224";
        obj_input_$.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        event.target.style.border = "1px solid #e21224";
        event.target.style.borderLeft = "0px";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        obj_input_$.removeAttribute("style");
      }
    } else if (event.target.id === "number_financial_plans") {
      obj_error = document.getElementById(event.target.id + "_error");
      let fin_plan_error = false;
      if (event.target.value === "") {
        fin_plan_error = true;
        obj_error.innerText = "Please enter how many Financial Plans you sold";
      }
      if (parseInt(event.target.value) === 0) {
        fin_plan_error = true;
        obj_error.innerText = "Please enter a value greater than zero";
      }
      if (fin_plan_error === true) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "fee_based_sales") {
      obj_error = document.getElementById(event.target.id + "_error");
      obj_input_$ = document.getElementById(event.target.id + "_input_$");
      let fee_based_error = false;

      if (event.target.value === "") {
        fee_based_error = true;
        obj_error.innerText = "Please enter your Fee-based Sales Relevant Fees";
      }
      if (parseInt(event.target.value) === 0) {
        fee_based_error = true;
        obj_error.innerText = "Please enter a value greater than zero";
      }

      if (fee_based_error === true) {
        obj_error.style.display = "block";
        obj_input_$.style.color = "#e21224";
        obj_input_$.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        event.target.style.border = "1px solid #e21224";
        event.target.style.borderLeft = "0px";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        obj_input_$.removeAttribute("style");
      }
    } else if (event.target.id === "investment_product_gross") {
      obj_error = document.getElementById(event.target.id + "_error");
      obj_input_$ = document.getElementById(event.target.id + "_input_$");
      let inv_prod_gross_error = false;

      if (event.target.value === "") {
        inv_prod_gross_error = true;
        obj_error.innerText =
          "Please enter your Investment Product Gross Dealer Commissions";
      }
      if (parseInt(event.target.value) === 0) {
        inv_prod_gross_error = true;
        obj_error.innerText = "Please enter a value greater than zero";
      }

      if (inv_prod_gross_error === true) {
        obj_error.style.display = "block";
        obj_input_$.style.color = "#e21224";
        obj_input_$.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        event.target.style.border = "1px solid #e21224";
        event.target.style.borderLeft = "0px";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        obj_input_$.removeAttribute("style");
      }
    } else if (event.target.id === "percent_ladl") {
      obj_error = document.getElementById(event.target.id + "_error");
      obj_input_$ = document.getElementById(event.target.id + "_input_$");

      if (
        event.target.value === "" ||
        event.target.value > 100 ||
        event.target.value < 0
      ) {
        obj_error.style.display = "block";
        obj_input_$.style.color = "#e21224";
        obj_input_$.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        event.target.style.border = "1px solid #e21224";
        event.target.style.borderRight = "0px";
        await this.setState({ formHasError: true });
        this.setState({
          calc_months: (
            (this.state.percent_ladl * this.state.num_months) /
            100
          ).toFixed(2),
        });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        obj_input_$.removeAttribute("style");

        this.setState({
          calc_months: (
            (this.state.percent_ladl * this.state.num_months) /
            100
          ).toFixed(2),
        });
      }
    } else if (event.target.id === "num_months") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value !== "") {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    }
    this.validateFields();
  }
  handleCloseModal(x) {
    if (this.state.form_has_changed) {
      this.setState({ showBackModal: true });
    } else {
      this.setState({ showBackModal: false });
      this.setState({ showModal: false });
      this.cancelClick();
    }
  }
  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    this.setState({ form_has_changed: false });
    this.handleCloseBackModal();
    this.resetFields();
    this.props.actionCancel();
  }
  render() {
    return (
      <>
        <div>
          <div id="addressModal"></div>

          <div>
            <Modal
              show={this.props.isOpen}
              className="modal-md"
              title=""
              id="PersonalSalesRecordModal"
            >
              {this.state.step_number === 0 && (
                <>
                  <div
                    className="row"
                    style={{ marginTop: "15px", height: "350px" }}
                  >
                    <div
                      id="emp_hist_loading"
                      className="mm-dots-loading-xl"
                      style={{ marginLeft: "290px", marginTop: "170px" }}
                    />
                  </div>
                </>
              )}
              {this.state.step_number > 0 && (
                <div id="top_element">
                  <div className="modal-body">
                    <div className="container">
                      <div className="row">
                        <div
                          id="psr_modal_header"
                          className="col-md-11"
                          style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        >
                          <div className="mm-spacing--minor">
                            <span style={{ fontSize: "20px" }}>
                              {this.props.hasNYRegs === false ? (
                                <h3>Personal Sales Record</h3>
                              ) : (
                                <h3>Personal Production Record</h3>
                              )}
                            </span>
                          </div>
                          <div
                            role="alert"
                            className="alert alert-info container"
                          >
                            <span>
                              You are filling this form for the following
                              role/time period only:
                            </span>
                            <br />
                            <b style={{ color: "#000000" }}>
                              <span
                                style={{
                                  display: "block",
                                  overflow: "hidden",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {this.state.jobtitle} at{" "}
                                {this.state.companyname}
                                <br />
                                {`${Moment(this.state.startdate)
                                  .utc()
                                  .format("MM/DD/YYYY")}
                               - ${this.state.endDateText}`}
                              </span>
                            </b>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <h2 style={{ marginBottom: ".4rem" }}>
                            <button
                              id="psr_modal_close"
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close modal dialog"
                              onClick={this.handleCloseModal}
                            />
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p id="psr_step_text">
                          <strong>Step {this.state.step_number} of 2</strong>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <span className="required-field-text">
                          * = Required Field
                        </span>
                      </div>
                    </div>
                    {this.state.step_number === 1 && (
                      <>
                        {this.state.show_individual_life_insurance_panel ===
                          true && (
                          <>
                            <div className="row" style={{ marginTop: "15px" }}>
                              <div className="col-md-12">
                                <p id="psr_ind_life_label">
                                  <strong>Individual Life Insurance</strong>
                                </p>
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: "10px" }}>
                              <div className="col-md-12">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="psr-top-cell">
                                        <p id="psr_ind_life_first_year_label">
                                          {this.props.hasNYRegs === false ? (
                                            <>First Year Commissions*</>
                                          ) : (
                                            <>First Year Premium*</>
                                          )}
                                        </p>
                                      </td>
                                      <td width="50px"></td>
                                      <td>
                                        <div
                                          className="input-group"
                                          id="ind_life_first_year_input"
                                        >
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text input-group-text-pad-right"
                                              id="ind_life_first_year_input_$"
                                            >
                                              $
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            id="ind_life_first_year"
                                            className="form-control input-pad-left"
                                            spellCheck="false"
                                            maxLength={15}
                                            value={
                                              this.state.ind_life_first_year
                                            }
                                            onBlur={this.onBlur}
                                            onChange={this.handleChange}
                                            onKeyDown={this.onKeyDown}
                                            onKeyPress={onlyNumeric}
                                            onPaste={pasteCommaDelimited}
                                            autoComplete="off"
                                          />
                                        </div>
                                        <div
                                          id="ind_life_first_year_error"
                                          className="invalid-feedback psr-error-padding"
                                        >
                                          {this.props.hasNYRegs === false ? (
                                            <>
                                              Please enter First Year
                                              Commissions
                                            </>
                                          ) : (
                                            <>Please enter First Year Premium</>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                    {this.props.hasNYRegs === false && (
                                      <tr>
                                        <td className="psr-top-cell">
                                          <p id="psr_ind_life_renewal_label">
                                            Renewal Commissions*
                                          </p>
                                        </td>
                                        <td width="50px"></td>
                                        <td>
                                          <div
                                            className="input-group"
                                            id="ind_life_renewal_input"
                                          >
                                            <div className="input-group-prepend">
                                              <span
                                                className="input-group-text input-group-text-pad-right"
                                                id="ind_life_renewal_input_$"
                                              >
                                                $
                                              </span>
                                            </div>
                                            <input
                                              type="text"
                                              id="ind_life_renewal"
                                              className="form-control input-pad-left"
                                              spellCheck="false"
                                              maxLength={15}
                                              value={
                                                this.state.ind_life_renewal
                                              }
                                              onBlur={this.onBlur}
                                              onChange={this.handleChange}
                                              onKeyDown={this.onKeyDown}
                                              onKeyPress={onlyNumeric}
                                              onPaste={pasteCommaDelimited}
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div
                                            id="ind_life_renewal_error"
                                            className="invalid-feedback psr-error-padding"
                                          >
                                            Please enter Renewal Commissions
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                        {this.state.show_individual_annuity_panel === true && (
                          <>
                            <div className="row" style={{ marginTop: "25px" }}>
                              <div className="col-md-12">
                                <p id="psr_ind_annuity_label">
                                  <strong>Individual Annuity</strong>
                                </p>
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: "10px" }}>
                              <div className="col-md-12">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="psr-top-cell">
                                        <p id="psr_ind_annuity_first_year_label">
                                          {this.props.hasNYRegs === false ? (
                                            <>First Year Commissions*</>
                                          ) : (
                                            <>First Year Premium*</>
                                          )}
                                        </p>
                                      </td>
                                      <td width="50px"></td>
                                      <td>
                                        <div
                                          className="input-group"
                                          id="ind_annuity_first_year_input"
                                        >
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text input-group-text-pad-right"
                                              id="ind_annuity_first_year_input_$"
                                            >
                                              $
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            id="ind_annuity_first_year"
                                            className="form-control input-pad-left"
                                            spellCheck="false"
                                            maxLength={15}
                                            value={
                                              this.state.ind_annuity_first_year
                                            }
                                            onBlur={this.onBlur}
                                            onChange={this.handleChange}
                                            onKeyDown={this.onKeyDown}
                                            onKeyPress={onlyNumeric}
                                            onPaste={pasteCommaDelimited}
                                            autoComplete="off"
                                          />
                                        </div>
                                        <div
                                          id="ind_annuity_first_year_error"
                                          className="invalid-feedback psr-error-padding"
                                        >
                                          {this.props.hasNYRegs === false ? (
                                            <>
                                              Please enter First Year
                                              Commissions
                                            </>
                                          ) : (
                                            <>Please enter First Year Premium</>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                    {this.props.hasNYRegs === false && (
                                      <tr>
                                        <td className="psr-top-cell">
                                          <p id="psr_ind_annuity_renewal_label">
                                            Renewal Commissions*
                                          </p>
                                        </td>
                                        <td width="50px"></td>
                                        <td>
                                          <div
                                            className="input-group"
                                            id="ind_annuity_renewal_input"
                                          >
                                            <div className="input-group-prepend">
                                              <span
                                                className="input-group-text input-group-text-pad-right"
                                                id="ind_annuity_renewal_input_$"
                                              >
                                                $
                                              </span>
                                            </div>
                                            <input
                                              type="text"
                                              id="ind_annuity_renewal"
                                              className="form-control input-pad-left"
                                              spellCheck="false"
                                              maxLength={15}
                                              value={
                                                this.state.ind_annuity_renewal
                                              }
                                              onBlur={this.onBlur}
                                              onChange={this.handleChange}
                                              onKeyDown={this.onKeyDown}
                                              onKeyPress={onlyNumeric}
                                              onPaste={pasteCommaDelimited}
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div
                                            id="ind_annuity_renewal_error"
                                            className="invalid-feedback psr-error-padding"
                                          >
                                            Please enter Renewal Commissions
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}

                        {this.state.show_individual_disability_income_panel ===
                          true && (
                          <>
                            <div className="row" style={{ marginTop: "25px" }}>
                              <div className="col-md-12">
                                <p id="psr_ind_disability_label">
                                  <strong>Individual Disability Income</strong>
                                </p>
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: "10px" }}>
                              <div className="col-md-12">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="psr-top-cell">
                                        <p id="psr_ind_disability_first_year_label">
                                          {this.props.hasNYRegs === false ? (
                                            <>First Year Commissions*</>
                                          ) : (
                                            <>First Year Premium*</>
                                          )}
                                        </p>
                                      </td>
                                      <td width="50px"></td>
                                      <td>
                                        <div
                                          className="input-group"
                                          id="ind_disability_first_year_input"
                                        >
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text input-group-text-pad-right"
                                              id="ind_disability_first_year_input_$"
                                            >
                                              $
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            id="ind_disability_first_year"
                                            className="form-control input-pad-left"
                                            spellCheck="false"
                                            maxLength={15}
                                            value={
                                              this.state
                                                .ind_disability_first_year
                                            }
                                            onBlur={this.onBlur}
                                            onChange={this.handleChange}
                                            onKeyDown={this.onKeyDown}
                                            onKeyPress={onlyNumeric}
                                            onPaste={pasteCommaDelimited}
                                            autoComplete="off"
                                          />
                                        </div>
                                        <div
                                          id="ind_disability_first_year_error"
                                          className="invalid-feedback psr-error-padding"
                                        >
                                          {this.props.hasNYRegs === false ? (
                                            <>
                                              Please enter First Year
                                              Commissions
                                            </>
                                          ) : (
                                            <>Please enter First Year Premium</>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                    {this.props.hasNYRegs === false && (
                                      <tr>
                                        <td className="psr-top-cell">
                                          <p id="psr_ind_disability_renewal_label">
                                            Renewal Commissions*
                                          </p>
                                        </td>
                                        <td width="50px"></td>
                                        <td>
                                          <div
                                            className="input-group"
                                            id="ind_disability_renewal_input"
                                          >
                                            <div className="input-group-prepend">
                                              <span
                                                className="input-group-text input-group-text-pad-right"
                                                id="ind_disability_renewal_input_$"
                                              >
                                                $
                                              </span>
                                            </div>
                                            <input
                                              type="text"
                                              id="ind_disability_renewal"
                                              className="form-control input-pad-left"
                                              spellCheck="false"
                                              maxLength={15}
                                              value={
                                                this.state
                                                  .ind_disability_renewal
                                              }
                                              onBlur={this.onBlur}
                                              onChange={this.handleChange}
                                              onKeyDown={this.onKeyDown}
                                              onKeyPress={onlyNumeric}
                                              onPaste={pasteCommaDelimited}
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div
                                            id="ind_disability_renewal_error"
                                            className="invalid-feedback psr-error-padding"
                                          >
                                            Please enter Renewal Commissions
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}

                        {this.state.show_individual_long_term_care_panel ===
                          true && (
                          <>
                            <div className="row" style={{ marginTop: "25px" }}>
                              <div className="col-md-12">
                                <p id="psr_ind_longterm_label">
                                  <strong>Individual Long Term Care</strong>
                                </p>
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: "10px" }}>
                              <div className="col-md-12">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="psr-top-cell">
                                        <p id="psr_ind_longterm_first_year_label">
                                          {this.props.hasNYRegs === false ? (
                                            <>First Year Commissions*</>
                                          ) : (
                                            <>First Year Premium*</>
                                          )}
                                        </p>
                                      </td>
                                      <td width="50px"></td>
                                      <td>
                                        <div
                                          className="input-group"
                                          id="ind_longterm_first_year_input"
                                        >
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text input-group-text-pad-right"
                                              id="ind_longterm_first_year_input_$"
                                            >
                                              $
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            id="ind_longterm_first_year"
                                            className="form-control input-pad-left"
                                            spellCheck="false"
                                            maxLength={15}
                                            value={
                                              this.state.ind_longterm_first_year
                                            }
                                            onBlur={this.onBlur}
                                            onChange={this.handleChange}
                                            onKeyDown={this.onKeyDown}
                                            onKeyPress={onlyNumeric}
                                            onPaste={pasteCommaDelimited}
                                            autoComplete="off"
                                          />
                                        </div>
                                        <div
                                          id="ind_longterm_first_year_error"
                                          className="invalid-feedback psr-error-padding"
                                        >
                                          {this.props.hasNYRegs === false ? (
                                            <>
                                              Please enter First Year
                                              Commissions
                                            </>
                                          ) : (
                                            <>Please enter First Year Premium</>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                    {this.props.hasNYRegs === false && (
                                      <tr>
                                        <td className="psr-top-cell">
                                          <p id="psr_ind_longterm_renewal_label">
                                            Renewal Commissions*
                                          </p>
                                        </td>
                                        <td width="50px"></td>
                                        <td>
                                          <div
                                            className="input-group"
                                            id="ind_longterm_renewal_input"
                                          >
                                            <div className="input-group-prepend">
                                              <span
                                                className="input-group-text input-group-text-pad-right"
                                                id="ind_longterm_renewal_input_$"
                                              >
                                                $
                                              </span>
                                            </div>
                                            <input
                                              type="text"
                                              id="ind_longterm_renewal"
                                              className="form-control input-pad-left"
                                              spellCheck="false"
                                              maxLength={15}
                                              value={
                                                this.state.ind_longterm_renewal
                                              }
                                              onBlur={this.onBlur}
                                              onChange={this.handleChange}
                                              onKeyDown={this.onKeyDown}
                                              onKeyPress={onlyNumeric}
                                              onPaste={pasteCommaDelimited}
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div
                                            id="ind_longterm_renewal_error"
                                            className="invalid-feedback psr-error-padding"
                                          >
                                            Please enter Renewal Commissions
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              paddingTop: "50px",
                              paddingBottom: "20px",
                              display: "inline",
                              float: "right",
                              textAlign: "right",
                            }}
                          >
                            <button
                              id="psr_modal_cancel_button"
                              type="button"
                              className="btn btn-link"
                              onClick={this.handleCloseModal}
                              style={{
                                textAlign: "left",
                                fontSize: "1.4rem",
                                padding: "4px 0px",
                              }}
                            >
                              Cancel
                            </button>
                            <Button
                              label="NEXT"
                              onClick={this.nextClick}
                              id="next_button"
                              style={{
                                padding: "5px",
                                float: "none",
                                width: "150px",
                                fontSize: "1.3rem",
                              }}
                              disabled={this.state.is_next_disabled}
                            ></Button>
                          </div>
                        </div>
                      </>
                    )}

                    {this.state.step_number === 2 && (
                      <>
                        <div className="row" style={{ paddingTop: "20px" }}>
                          <div
                            id="psr_modal_step_2_intro_text"
                            className="col-md-12"
                          >
                            What percentage of the time did you sell LADL in
                            this position at this time?<br></br>
                            <span className="required-field-text">
                              (LADL=Individual Life, Annuity, Disability, and
                              Long Term Care Insurance)
                            </span>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <table>
                              <tbody>
                                <tr>
                                  <td width="20%">
                                    <div
                                      className="input-group"
                                      id="percent_ladl_input"
                                    >
                                      <input
                                        type="text"
                                        id="percent_ladl"
                                        className="form-control"
                                        spellCheck="false"
                                        maxLength={3}
                                        value={this.state.percent_ladl}
                                        onBlur={this.onBlur}
                                        onChange={this.handleChange}
                                        onKeyDown={this.onKeyDown}
                                        onPaste={pasteValue}
                                        onKeyPress={onlyNumeric}
                                        autoComplete="off"
                                      />
                                      <div className="input-group-append">
                                        <span
                                          className="input-group-text"
                                          id="percent_ladl_input_$"
                                        >
                                          %
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>&nbsp;of&nbsp; </td>
                                  <td
                                    style={{
                                      paddingBottom: "7px",
                                      width: "11%",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      id="num_months"
                                      className="form-control"
                                      spellCheck="false"
                                      maxLength={2}
                                      value={this.state.num_months}
                                      onBlur={this.onBlur}
                                      onChange={this.handleChange}
                                      onKeyDown={this.onKeyDown}
                                      disabled={true}
                                      autoComplete="off"
                                    />
                                  </td>
                                  <td>
                                    &nbsp;months&nbsp;
                                    <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      overlay={
                                        <Popover
                                          id="popover-basic"
                                          style={{
                                            padding: "10px",
                                            backgroundColor: "#555",

                                            borderRadius: "10px",
                                            color: "white",
                                          }}
                                        >
                                          Months equals length of time you have
                                          been in this role during the provided
                                          calendar year.
                                        </Popover>
                                      }
                                    >
                                      <button
                                        variant="link"
                                        className="btn btn-link"
                                        id="sales_button"
                                        style={{ paddingTop: "1px" }}
                                      >
                                        <span className="icon-info"></span>
                                      </button>
                                    </OverlayTrigger>
                                    &nbsp;= {this.state.calc_months} months of
                                    experience.
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={7}>
                                    <div
                                      id="percent_ladl_error"
                                      className="invalid-feedback"
                                    >
                                      Please enter a number between 0 and 100
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {this.props.hasNYRegs === false && (
                          <div className="row" style={{ marginTop: "25px" }}>
                            <div className="col-md-9">
                              <span id="psr_sell_inv_products_label">
                                In this position, did you sell Investment
                                Products?*
                              </span>
                            </div>
                            <div className="col-md-1 custom-control custom-radio">
                              <input
                                type="radio"
                                id="investment_products_yes"
                                name="investment_products"
                                className="custom-control-input"
                                checked={this.state.investment_products === "Y"}
                                value="Y"
                                onChange={this.handleInvestmentProductsChange}
                              />
                              <label
                                htmlFor="investment_products_yes"
                                className="custom-control-label"
                                id="label_investment_products_yes"
                              >
                                Yes
                              </label>
                            </div>
                            <div
                              className="col-md-1 custom-control custom-radio"
                              style={{ paddingLeft: "45px" }}
                            >
                              <input
                                type="radio"
                                id="investment_products_no"
                                name="investment_products"
                                className="custom-control-input"
                                checked={this.state.investment_products === "N"}
                                value="N"
                                onChange={this.handleInvestmentProductsChange}
                              />
                              <label
                                htmlFor="investment_products_no"
                                className="custom-control-label"
                                id="label_investment_products_no"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        )}
                        {this.state.show_investment_products &&
                          this.props.hasNYRegs === false && (
                            <>
                              <div
                                className="row"
                                style={{ marginTop: "10px" }}
                              >
                                <div className="col-md-12">
                                  <p id="psr_gross_dealer_comissions_label">
                                    What were your Investment Product Gross
                                    Dealer Commissions?*
                                  </p>
                                  <div
                                    className="col-md-5"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <div
                                      className="input-group"
                                      id="investment_product_gross_input"
                                    >
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text input-group-text-pad-right"
                                          id="investment_product_gross_input_$"
                                        >
                                          $
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        id="investment_product_gross"
                                        className="form-control input-pad-left"
                                        spellCheck="false"
                                        maxLength={15}
                                        value={
                                          this.state.investment_product_gross
                                        }
                                        onBlur={this.onBlur}
                                        onChange={this.handleChange}
                                        onKeyDown={this.onKeyDown}
                                        onKeyPress={onlyNumeric}
                                        onPaste={pasteCommaDelimited}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    id="investment_product_gross_error"
                                    className="invalid-feedback"
                                  >
                                    Please enter your Investment Product Gross
                                    Dealer Commissions
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        {this.props.hasNYRegs === false && (
                          <div className="row" style={{ marginTop: "15px" }}>
                            <div className="col-md-9">
                              <span id="psr_fee_based_sales_label">
                                In this position, did you have any Fee-Based
                                Sales?*
                              </span>
                            </div>
                            <div className="col-md-1 custom-control custom-radio">
                              <input
                                type="radio"
                                id="fee_based_yes"
                                name="fee_based"
                                className="custom-control-input"
                                checked={this.state.fee_based === "Y"}
                                value="Y"
                                onChange={this.handleFeeBasedChange}
                              />
                              <label
                                htmlFor="fee_based_yes"
                                className="custom-control-label"
                                id="label_fee_based_yes"
                              >
                                Yes
                              </label>
                            </div>
                            <div
                              className="col-md-1 custom-control custom-radio"
                              style={{ paddingLeft: "45px" }}
                            >
                              <input
                                type="radio"
                                id="fee_based_no"
                                name="fee_based"
                                className="custom-control-input"
                                checked={this.state.fee_based === "N"}
                                value="N"
                                onChange={this.handleFeeBasedChange}
                              />
                              <label
                                htmlFor="fee_based_no"
                                className="custom-control-label"
                                id="label_fee_based_no"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        )}
                        {this.state.show_fee_based &&
                          this.props.hasNYRegs === false && (
                            <>
                              <div
                                className="row"
                                style={{ marginTop: "10px" }}
                              >
                                <div className="col-md-12">
                                  <p id="psr_relevant_fees_label">
                                    What were your Fee-based Sales Relevant
                                    Fees?*
                                  </p>
                                  <div
                                    className="col-md-5"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <div
                                      className="input-group"
                                      id="fee_based_sales_input"
                                    >
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text input-group-text-pad-right"
                                          id="fee_based_sales_input_$"
                                        >
                                          $
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        id="fee_based_sales"
                                        className="form-control input-pad-left"
                                        spellCheck="false"
                                        maxLength={15}
                                        value={this.state.fee_based_sales}
                                        onBlur={this.onBlur}
                                        onChange={this.handleChange}
                                        onKeyDown={this.onKeyDown}
                                        onKeyPress={onlyNumeric}
                                        onPaste={pasteCommaDelimited}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    id="fee_based_sales_error"
                                    className="invalid-feedback"
                                  >
                                    Please enter your Fee-based Sales Relevant
                                    Fees
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                        <div className="row" style={{ marginTop: "15px" }}>
                          <div className="col-md-9">
                            <span id="psr_financial_planning_label">
                              In this position, did you sell Financial
                              Planning?*
                            </span>
                          </div>
                          <div className="col-md-1 custom-control custom-radio">
                            <input
                              type="radio"
                              id="financial_planning_yes"
                              name="financial_planning"
                              className="custom-control-input"
                              checked={this.state.financial_planning === "Y"}
                              value="Y"
                              onChange={this.handleFinancialPlanningChange}
                            />
                            <label
                              htmlFor="financial_planning_yes"
                              className="custom-control-label"
                              id="label_financial_planning_yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div
                            className="col-md-1 custom-control custom-radio"
                            style={{ paddingLeft: "45px" }}
                          >
                            <input
                              type="radio"
                              id="financial_planning_no"
                              name="financial_planning"
                              className="custom-control-input"
                              checked={this.state.financial_planning === "N"}
                              value="N"
                              onChange={this.handleFinancialPlanningChange}
                            />
                            <label
                              htmlFor="financial_planning_no"
                              className="custom-control-label"
                              id="label_financial_planning_no"
                            >
                              No
                            </label>
                          </div>
                        </div>
                        {this.state.show_financial_planning && (
                          <>
                            <div className="row" style={{ marginTop: "10px" }}>
                              <div className="col-md-12">
                                <p id="psr_number_of_financial_plans_label">
                                  What number of financial plans did you sell?*
                                </p>
                                <div
                                  className="col-md-5"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <input
                                    type="text"
                                    id="number_financial_plans"
                                    className="form-control"
                                    spellCheck="false"
                                    maxLength={11}
                                    value={this.state.number_financial_plans}
                                    onBlur={this.onBlur}
                                    onChange={this.handleChange}
                                    onKeyDown={this.onKeyDown}
                                    onKeyPress={onlyNumeric}
                                    onPaste={pasteValue}
                                    autoComplete="off"
                                  />
                                </div>
                                <div
                                  id="number_financial_plans_error"
                                  className="invalid-feedback"
                                >
                                  Please enter how many Financial Plans you sold
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              paddingTop: "20px",
                              paddingBottom: "20px",
                              display: "inline",
                              float: "right",
                              textAlign: "right",
                            }}
                          >
                            <button
                              id="psr_modal_back_button"
                              type="button"
                              className="btn btn-link"
                              onClick={this.backClick}
                              style={{
                                textAlign: "left",
                                fontSize: "1.4rem",
                                padding: "4px 0px",
                              }}
                            >
                              Back
                            </button>
                            {this.state.review_edit === false && (
                              <Button
                                label="ADD"
                                id="add_button"
                                onClick={this.addClick}
                                style={{
                                  padding: "5px",
                                  float: "none",
                                  width: "150px",
                                  fontSize: "1.3rem",
                                }}
                                disabled={this.state.is_add_disabled}
                              ></Button>
                            )}
                            {this.state.review_edit === true && (
                              <Button
                                label="SAVE"
                                id="add_button"
                                onClick={this.addClick}
                                style={{
                                  padding: "5px",
                                  float: "none",
                                  width: "150px",
                                  fontSize: "1.3rem",
                                }}
                                disabled={this.state.is_add_disabled}
                              ></Button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </Modal>
          </div>
        </div>
        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </>
    );
  }
}

export default PersonalSalesRecordModal;
