/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";

import { logoutActions } from "../../../../../../../store/actions";
import BackButtonModal from "../../../../../Forms/BackButtonModal";
import { dateInputMask, eventStatusDateType } from "../../../constants";

import "./RegActionInvDetails.scss";

const noticeReceivedType = {
  SRO: "SRO",
  FOREIGN_FIN_REG_AUT: "Foreign Financial Regulatory Authority",
  JURISDICTION: "Jurisdiction",
  SEC: "SEC",
  OTHER_FEDERAL: "Other Federal Agency",
  OTHER: "Other",
};

const RegActionInvDetails = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  values,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);

  const [fullNameReg, setFullNameReg] = useState(data?.fullNameReg || "");
  const [fullNameRegError, setFullNameRegError] = useState(false);

  const [drpCheckList, setDRPCheckList] = useState(
    data?.drpCheckList?.length ? data?.drpCheckList : []
  );

  const [formHasChanged, setFormHasChanged] = useState(false);

  const [noticeReceived, setActionAppealed] = useState(
    data?.noticeReceived || ""
  );
  const [noticeReceivedOther, setActionAppealedOther] = useState(
    data?.noticeReceivedOther || ""
  );
  const [noticeReceivedError, setActionAppealedError] = useState(false);

  const [noticeDateErrorMsg, setNoticeErrorMsg] = useState("");
  const [noticeDate, setNotice] = useState(data?.noticeDate || "");
  const [noticeDateError, setNoticeError] = useState(false);
  const [noticeDateRadio, setNoticeRadio] = useState(
    data?.noticeDateRadio || ""
  );
  const [noticeDateExp, setNoticeExp] = useState(data?.noticeDateExp || "");
  const [noticeDateExpError, setNoticeExpError] = useState(false);

  const [invDetRes, setInvDetRes] = useState(data?.invDetRes || "");
  const [invDetResError, setInvDetResError] = useState(data?.comments || "");
  const [isFocusedState, setFocusedState] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    if (values?.length && values?.length === 1) {
      setDRPCheckList((drp) => [...drp, ...values.map((v) => v.key)]);
    }
  }, []);

  const isDateNoticeDisabled =
    !noticeDate?.length ||
    noticeDate.includes("_") ||
    noticeDate === dateInputMask.EMPTY ||
    (!noticeDate?.length && !noticeDateRadio?.length) ||
    !noticeDateRadio?.length ||
    (noticeDate?.length &&
      noticeDateRadio?.length &&
      noticeDateRadio === eventStatusDateType.EXPLANATION &&
      !noticeDateExp?.length) ||
    (noticeDateExp?.length && noticeDateExp?.length < 2);

  const isNoticeReceived =
    !noticeReceived?.length ||
    (noticeReceived.length &&
      noticeReceived === noticeReceivedType.OTHER &&
      !noticeReceivedOther?.length);

  const isError =
    noticeReceivedError ||
    noticeDateError ||
    fullNameRegError ||
    invDetResError ||
    noticeDateError ||
    noticeDateExpError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isNextBtnDisabled =
    !!(
      isDateNoticeDisabled ||
      (noticeReceived !== noticeReceivedType.SEC && !fullNameReg.length) ||
      !invDetRes.length ||
      isNoticeReceived
    ) ||
    isError ||
    isFocusedState;

  const handleChangeCheckedInvRes = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    setActionAppealedError(false);

    if (value !== noticeReceivedType.OTHER) {
      setActionAppealedOther("");
    }

    setFullNameReg("");
    setFullNameRegError(false);

    setActionAppealed(newValue);
  };

  const handleDateNoticeChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    setNoticeExpError(false);
    if (
      noticeDateExp.length &&
      noticeDateRadio !== eventStatusDateType.EXPLANATION
    ) {
      setNoticeExp("");
    }

    setNoticeRadio(newValue);
  };

  const onDateNoticeOnChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setNotice(value);
  };

  const onDateNoticeExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setNoticeExp(value);
  };

  const onDateNoticeBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setFocusedState(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setNoticeError(true);
      } else {
        setNoticeError(false);
      }
    } else {
      setNoticeError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setNoticeErrorMsg("Date cannot be a future date");
        setNoticeError(true);
      } else if (date < past) {
        setNoticeErrorMsg("Date cannot be more than 100 years ago");
        setNoticeError(true);
      } else {
        setNoticeError(false);
      }
    } else {
      setNoticeErrorMsg("Please enter a valid date");
      setNoticeError(true);
    }
  };

  const onBlurDateNoticeExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setNoticeExpError(true)
      : setNoticeExpError(false);
  };

  const onInvDetResChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setInvDetRes(value);
  };
  const onBlurInvDetResHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setInvDetResError(true)
      : setInvDetResError(false);
  };

  const onChangeFullNameRegHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setFullNameReg(value);
  };
  const onBlurFullNameRegHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setFullNameRegError(true)
      : setFullNameRegError(false);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  const onChangeInvResolvedHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setActionAppealedOther(value);
  };
  const onBlurInvResolvedHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value?.trim()?.length < 2 || value.trim()?.length > 500
      ? setActionAppealedError(true)
      : setActionAppealedError(false);
  };

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: isUpdates }));
  }, [isUpdates, dispatch]);

  const handleShowBackModal = () => {
    if (isUpdates) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      fullNameReg,
      noticeDate,
      noticeDateRadio,
      noticeDateExp,
      noticeReceived,
      noticeReceivedOther,
      invDetRes,
    };

    if (values?.length) {
      submitData.drpCheckList = [...new Set(drpCheckList || [])];
    }

    setFormHasChanged(false);
    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    getSubmitDataValues,
    headerText,
    noticeDate,
    fullNameReg,
    noticeDateRadio,
    noticeDateExp,
    groupKey,
    invDetRes,
    noticeReceived,
    values,
    drpCheckList,
    noticeReceivedOther,
  ]);

  return (
    <div className="reg-action-inv-det-form-wrapper">
      <>
        <div className="notice-rec-container">
          <div className="row">
            <div className="col-md-12">
              <label className="court-action">Notice received from: *</label>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-4 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="actionSRO"
                    type="radio"
                    id="actionSROId"
                    className="custom-control-input"
                    value={noticeReceivedType.SRO}
                    checked={noticeReceived === noticeReceivedType.SRO}
                    onChange={handleChangeCheckedInvRes}
                  />
                  <label
                    htmlFor="actionSROId"
                    className="custom-control-label"
                    id="stateCourtLabelIdSRO"
                  >
                    {noticeReceivedType.SRO}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="actionFederal"
                    type="radio"
                    id="actionFederalId"
                    className="custom-control-input"
                    value={noticeReceivedType.FOREIGN_FIN_REG_AUT}
                    checked={
                      noticeReceived === noticeReceivedType.FOREIGN_FIN_REG_AUT
                    }
                    onChange={handleChangeCheckedInvRes}
                  />
                  <label
                    htmlFor="actionFederalId"
                    className="custom-control-label"
                    id="stateCourtLabelIdFederal"
                  >
                    {noticeReceivedType.FOREIGN_FIN_REG_AUT}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="actionState"
                    type="radio"
                    id="actionStateId"
                    className="custom-control-input"
                    value={noticeReceivedType.JURISDICTION}
                    checked={noticeReceived === noticeReceivedType.JURISDICTION}
                    onChange={handleChangeCheckedInvRes}
                  />
                  <label
                    htmlFor="actionStateId"
                    className="custom-control-label"
                    id="stateCourtLabelIdJurisdiction"
                  >
                    {noticeReceivedType.JURISDICTION}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="actionSec"
                    type="radio"
                    id="actionSecId"
                    className="custom-control-input"
                    value={noticeReceivedType.SEC}
                    checked={noticeReceived === noticeReceivedType.SEC}
                    onChange={handleChangeCheckedInvRes}
                  />
                  <label
                    htmlFor="actionSecId"
                    className="custom-control-label"
                    id="federalLabelIdSEC"
                  >
                    {noticeReceivedType.SEC}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="stateCourt"
                    type="radio"
                    id="stateCourtId"
                    className="custom-control-input"
                    value={noticeReceivedType.OTHER_FEDERAL}
                    checked={
                      noticeReceived === noticeReceivedType.OTHER_FEDERAL
                    }
                    onChange={handleChangeCheckedInvRes}
                  />
                  <label
                    htmlFor="stateCourtId"
                    className="custom-control-label"
                    id="stateCourtLabelIdOtherFederal"
                  >
                    {noticeReceivedType.OTHER_FEDERAL}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="otherRadio"
                    type="radio"
                    id="otherRadioId"
                    className="custom-control-input"
                    value={noticeReceivedType.OTHER}
                    checked={noticeReceived === noticeReceivedType.OTHER}
                    onChange={handleChangeCheckedInvRes}
                  />
                  <label
                    htmlFor="otherRadioId"
                    className="custom-control-label"
                    id="otherRadioLabelId"
                  >
                    {noticeReceivedType.OTHER}
                  </label>
                </div>

                {noticeReceived === noticeReceivedType.OTHER ? (
                  <div className="court-action-other">
                    <input
                      type="text"
                      id="otherText"
                      className={
                        noticeReceivedError
                          ? "form-control form-control-15 is-invalid"
                          : "form-control form-control-15"
                      }
                      maxLength={500}
                      minLength={2}
                      autoComplete="off"
                      value={noticeReceivedOther}
                      onChange={onChangeInvResolvedHandler}
                      onBlur={onBlurInvResolvedHandler}
                      onFocus={() => setFocusedState(true)}
                    />
                    <div id="noticeReceivedError" className="invalid-feedback">
                      Please enter a response
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {noticeReceived === noticeReceivedType.SEC ||
        !noticeReceived ||
        noticeReceived === "" ? null : (
          <div className="row full-name-of-reg-container">
            <div className="col-md-12">
              <label className="full-name-of-reg" htmlFor="fullNameReg">
                Full name of regulator (if other than SEC) that initiated the
                investigation:*
              </label>
              <input
                type="text"
                id="fullNameReg"
                className={
                  fullNameRegError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                minLength={2}
                maxLength={100}
                autoComplete="off"
                value={fullNameReg}
                onChange={onChangeFullNameRegHandler}
                onBlur={onBlurFullNameRegHandler}
                onFocus={() => setFocusedState(true)}
              />
              <div id="fullNameRegError" className="invalid-feedback">
                Please enter Full Name of Regulator
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12 event-status-date-wrapper">
            <label id="noticeDateLabelPaiId" htmlFor="noticeDateId">
              <strong>Notice Date:*</strong>
            </label>
            <InputMask
              id="noticeDateId"
              mask="99/99/9999"
              maskChar="_"
              className={`${
                noticeDateError ? "form-control is-invalid" : "form-control"
              } event-status-date-input`}
              placeholder="MM/DD/YYYY"
              autoComplete="off"
              required
              value={noticeDate}
              onChange={onDateNoticeOnChangeHandler}
              onBlur={onDateNoticeBlurHandler}
              onFocus={() => setFocusedState(true)}
            />
            <div id="noticeDateError" className="invalid-feedback">
              {noticeDateErrorMsg?.length
                ? noticeDateErrorMsg
                : "Please enter a valid Notice Date"}
            </div>
          </div>

          <div className="row event-status-date-radio-group">
            <div className="custom-control">
              <div className="col-12 copy-md-8 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="radio-guide-noticeDateExactId"
                    type="radio"
                    id="noticeDateExactId"
                    className="custom-control-input"
                    value={eventStatusDateType.EXACT}
                    checked={noticeDateRadio === eventStatusDateType.EXACT}
                    onChange={handleDateNoticeChecked}
                  />
                  <label
                    htmlFor="noticeDateExactId"
                    className="custom-control-label"
                    id="label_exactDate"
                  >
                    {eventStatusDateType.EXACT}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="radio-guide-noticeDateExplanationId"
                    type="radio"
                    id="noticeDateExplanationId"
                    className="custom-control-input"
                    value={eventStatusDateType.EXPLANATION}
                    checked={
                      noticeDateRadio === eventStatusDateType.EXPLANATION
                    }
                    onChange={handleDateNoticeChecked}
                  />
                  <label
                    htmlFor="noticeDateExplanationId"
                    className="custom-control-label"
                    id="label_explanation"
                  >
                    {eventStatusDateType.EXPLANATION}
                  </label>
                </div>

                {noticeDateRadio === eventStatusDateType.EXPLANATION ? (
                  <div className="event-status-date-explanation-container">
                    <label
                      htmlFor="noticeDateExplanation"
                      className="event-status-date-exp"
                      id="noticeDateExplanationLabelId"
                    >
                      If not exact, provide explanation *
                    </label>
                    <textarea
                      id="noticeDateExplanation"
                      onChange={onDateNoticeExplanationChange}
                      className={
                        noticeDateExpError
                          ? "form-control form-control-15 is-invalid"
                          : "form-control form-control-15"
                      }
                      maxLength={500}
                      minLength={2}
                      onBlur={onBlurDateNoticeExpHandler}
                      onFocus={() => setFocusedState(true)}
                      rows="3"
                      cols={500}
                      value={noticeDateExp}
                    />
                    <div id="noticeDateExpError" className="invalid-feedback">
                      Please enter an Explanation
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="crim-comments-container">
          <label htmlFor="invDetRes" className="comments-label">
            Describe briefly the nature of the investigation, if known, or
            details of the resolution: *
          </label>
          <textarea
            id="invDetRes"
            onChange={onInvDetResChangeHandler}
            onBlur={onBlurInvDetResHandler}
            onFocus={() => setFocusedState(true)}
            className={
              invDetResError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            minLength={2}
            maxLength={500}
            rows="3"
            cols={500}
            value={invDetRes}
          />
          <div id="invDetResError" className="invalid-feedback">
            Please enter a response
          </div>
        </div>
      </>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span id="regBack" role="presentation" onClick={handleShowBackModal}>
            <strong id="regBackText">Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
            id="regNext"
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default RegActionInvDetails;
