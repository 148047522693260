/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import InputMask from "react-input-mask";

import {
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../../common/Functions";
import {
  RADIO,
  dateInputMask,
  eventStatusDateType,
} from "../../../../constants";

import BackButtonModal from "../../../../../../Forms/BackButtonModal";

const initialValues = {
  sanctionType: null,
  sanctionTypeOther: null,
  totalAmount: null,
  portionLevied: null,
  datePaid: null,
  datePaidRadio: null,
  datePaidExp: null,
  portionPenaltyWaived: null,
};

const AMOUNT_CUSTOM_ERR = "Please enter $ amount";

const sanctionTypes = {
  MONETARY: "Monetary Fine",
  DISG: "Disgorgement",
  REST: "Restitution",
  OTHER: "Other",
};

const AddNewMonetaryRelatedSanctionContent = ({
  data,
  getSubmitDataValues,
  setShowAddNewChargeModal,
  setIsModalUpdates,
  dataIndex,
  headerText,
}) => {
  const [formHasChanged, setFormHasChanged] = useState(false);

  const [sanctionType, setSanctionType] = useState(data?.sanctionType || "");
  const [sanctionTypeOther, setSanctionTypeOther] = useState(
    data?.sanctionTypeOther || ""
  );
  const [sanctionTypeOtherError, setSanctionTypeOtherError] = useState(false);

  const [totalAmount, setTotalAmount] = useState(data?.totalAmount || "");
  const [totalAmountError, setTotalAmountError] = useState(false);
  const [totalAmountErrorMsg, setTotalAmountErrorMsg] = useState("");

  const [portionLevied, setPortionLevied] = useState(data?.portionLevied || "");
  const [portionLeviedError, setPortionLeviedError] = useState(false);
  const [portionLeviedErrorMsg, setPortionLeviedErrorMsg] = useState("");

  const [totalTotalPortionAmountFocus, setTotalPortionAmountFocus] =
    useState(false);
  const [portionPortionAmountFocus, setPortionAmountFocus] = useState(false);

  const [datePaid, setDatePaid] = useState(data?.datePaid || "");
  const [datePaidError, setDatePaidError] = useState(false);
  const [datePaidRadio, setDatePaidRadio] = useState(data?.datePaidRadio || "");
  const [datePaidExp, setDatePaidExp] = useState(data?.datePaidExp || "");
  const [datePaidExpError, setDatePaidExpError] = useState(false);
  const [datePaidErrorMsg, setDatePaidErrorMsg] = useState("");

  const [portionPenaltyWaived, setPortionPenaltyWaived] = useState(
    typeof data?.portionPenaltyWaived == "boolean"
      ? data?.portionPenaltyWaived
      : null
  );

  const [portionOfPenaltyAmount, setPortionOfPenaltyAmount] = useState(
    data?.portionOfPenaltyAmount || ""
  );
  const [portionOfPenaltyAmountError, setPortionOfPenaltyAmountError] =
    useState(false);
  const [portionOfPenaltyAmountErrorMsg, setPortionOfPenaltyAmountErrorMsg] =
    useState("");
  const [amountFocus, setAmountFocus] = useState(false);

  const [showBackModal, setShowBackModal] = useState(false);

  const [isNotDateNextDisabled, setIsNotDateNextDisabled] = useState(false);

  const [isFocusedState, setFocusedState] = useState(false);

  const onChangeCourtActionHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setSanctionTypeOther(value);
  };
  const onBlurCourtActionHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value?.trim()?.length < 2 || value.trim()?.length > 500
      ? setSanctionTypeOtherError(true)
      : setSanctionTypeOtherError(false);
  };

  const handleChangeReducedChecked = (e) => {
    const { value } = e.target;
    setFormHasChanged(true);
    let newValue = value;

    setSanctionTypeOther("");
    setSanctionTypeOtherError(false);

    setSanctionType(newValue);
  };

  const handleOnTotalAmountChange = ({ target: { value } }) => {
    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");
    setFormHasChanged(true);

    setTotalAmount(commaSeparatedNumber);
  };

  const onTotalAmountBlurHandler = ({ target: { value } }) => {
    setTotalAmountError(value?.length === 0);

    setTotalAmountErrorMsg("");
    setFocusedState(false);

    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    if (
      (commaSeparatedNumber?.length &&
        typeof +commaSeparatedNumber === "number" &&
        (+commaSeparatedNumber === 0 || +commaSeparatedNumber < 1)) ||
      Number.parseInt(commaSeparatedNumber) === 0 ||
      commaSeparatedNumber === "-" ||
      commaSeparatedNumber.includes("-")
    ) {
      setTotalAmountErrorMsg(AMOUNT_CUSTOM_ERR);

      return setTotalAmountError(true);
    }

    return !value.length
      ? setTotalAmountError(true)
      : setTotalAmountError(false);
  };

  const handleOnPortionLeviedChange = ({ target: { value } }) => {
    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");
    setFormHasChanged(true);

    setPortionLevied(commaSeparatedNumber);
  };

  const onPortionLeviedBlurHandler = ({ target: { value } }) => {
    setPortionLeviedError(value?.length === 0);
    setFormHasChanged(true);
    setFocusedState(false);
    setPortionLeviedErrorMsg("");

    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    if (
      (commaSeparatedNumber?.length &&
        typeof +commaSeparatedNumber === "number" &&
        (+commaSeparatedNumber === 0 || +commaSeparatedNumber < 1)) ||
      Number.parseInt(commaSeparatedNumber) === 0 ||
      commaSeparatedNumber === "-" ||
      commaSeparatedNumber.includes("-")
    ) {
      setPortionLeviedErrorMsg(AMOUNT_CUSTOM_ERR);

      return setPortionLeviedError(true);
    }

    return !value.length
      ? setPortionLeviedError(true)
      : setPortionLeviedError(false);
  };

  const handleCloseBackModal = () => {
    setShowBackModal(false);
    setShowAddNewChargeModal(true);
  };

  const handleOnPortionOfPenaltyAmountChange = ({ target: { value } }) => {
    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");
    setFormHasChanged(true);

    setPortionOfPenaltyAmount(commaSeparatedNumber);
  };

  const onPortionOfPenaltyAmountBlurHandler = ({ target: { value } }) => {
    setPortionOfPenaltyAmountError(value?.length === 0);
    setFormHasChanged(true);
    setFocusedState(false);
    setPortionOfPenaltyAmountErrorMsg("");

    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    if (
      (commaSeparatedNumber?.length &&
        typeof +commaSeparatedNumber === "number" &&
        (+commaSeparatedNumber === 0 || +commaSeparatedNumber < 1)) ||
      Number.parseInt(commaSeparatedNumber) === 0 ||
      commaSeparatedNumber === "-" ||
      commaSeparatedNumber.includes("-")
    ) {
      setPortionOfPenaltyAmountErrorMsg(AMOUNT_CUSTOM_ERR);

      return setPortionOfPenaltyAmountError(true);
    }

    return !value.length
      ? setPortionOfPenaltyAmountError(true)
      : setPortionOfPenaltyAmountError(false);
  };

  const handleChangePortionPenaltyWaivedChecked = (e) => {
    const { value } = e.target;
    let newValue = value;
    setFormHasChanged(true);

    setPortionOfPenaltyAmount("");
    setPortionOfPenaltyAmountError(false);
    setPortionOfPenaltyAmountErrorMsg("");

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    setPortionPenaltyWaived(newValue);
  };

  const handleChangeDatePaidChecked = (e) => {
    const { value } = e.target;
    let newValue = value;
    setFormHasChanged(true);

    setDatePaidExpError(false);
    if (
      datePaidExp?.length &&
      datePaidRadio !== eventStatusDateType.EXPLANATION
    ) {
      setDatePaidExp("");
    }

    setDatePaidRadio(newValue);
  };

  const onDatePaidChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDatePaid(value);

    if (value?.length === 10 && !value.includes("_") && !datePaidError) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsNotDateNextDisabled(true);
        } else {
          setIsNotDateNextDisabled(false);
        }
      } else {
        setIsNotDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsNotDateNextDisabled(true);
        } else {
          setIsNotDateNextDisabled(false);
        }
      } else {
        setIsNotDateNextDisabled(true);
      }
    }
  };

  const onDatePaidExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDatePaidExp(value);
  };

  const onBlurDatePaidExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setDatePaidExpError(true)
      : setDatePaidExpError(false);
  };

  const onDatePaidBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setIsNotDateNextDisabled(false);
    setFocusedState(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setDatePaidError(true);
      } else {
        setDatePaidError(false);
      }
    } else {
      setDatePaidError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setDatePaidErrorMsg("Date cannot be a future date");
        setDatePaidError(true);
      } else if (date < past) {
        setDatePaidErrorMsg("Date cannot be more than 100 years ago");
        setDatePaidError(true);
      } else {
        setDatePaidError(false);
      }
    } else {
      setDatePaidErrorMsg("Please enter a valid date");
      setDatePaidError(true);
    }
  };

  const isError =
    sanctionTypeOtherError ||
    portionLeviedError ||
    totalAmountError ||
    portionLeviedError ||
    datePaidError ||
    datePaidExpError ||
    portionOfPenaltyAmountError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isUpdates =
    (data?.length === 0 &&
      (sanctionTypeOther.length || sanctionType?.length)) ||
    formHasChanged ||
    isError;

  useEffect(() => {
    if (isUpdates || isError) {
      setIsModalUpdates(true);
    } else {
      setIsModalUpdates(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdates, isError]);

  const isDatePaidDisabled =
    !datePaid?.length ||
    datePaid.includes("_") ||
    datePaid === dateInputMask.EMPTY ||
    (!datePaid?.length && !datePaidRadio?.length) ||
    !datePaidRadio?.length ||
    !datePaid?.length ||
    (datePaid?.length &&
      datePaidRadio?.length &&
      datePaidRadio === eventStatusDateType.EXPLANATION &&
      !datePaidExp?.length) ||
    (datePaidExp?.length && datePaidExp?.length < 2);

  const isNextDisabled = !!(
    isNotDateNextDisabled ||
    (sanctionType?.length &&
      sanctionType === sanctionTypes.OTHER &&
      !sanctionTypeOther?.length) ||
    !sanctionType.length ||
    (sanctionTypeOther?.length && sanctionTypeOther?.length < 2) ||
    portionPenaltyWaived === null ||
    isDatePaidDisabled ||
    !portionLevied?.length ||
    !totalAmount?.length ||
    !portionLevied?.length ||
    Number.parseInt(totalAmount) === 0 ||
    Number.parseInt(portionLevied) === 0 ||
    (!!portionPenaltyWaived && !portionOfPenaltyAmount?.length) ||
    (!!portionPenaltyWaived && Number.parseInt(portionOfPenaltyAmount) === 0) ||
    isError ||
    isFocusedState
  );

  const handleShowBackModal = () => {
    if (isUpdates || isError) {
      setShowBackModal(true);
      setFormHasChanged(isUpdates);
    } else {
      getSubmitDataValues(
        {
          ...initialValues,
        },
        headerText
      );

      setShowAddNewChargeModal(false);
    }
  };

  const handleBackClick = () => {
    getSubmitDataValues({ ...initialValues }, headerText);
    handleCloseBackModal();
    setShowAddNewChargeModal(false);
  };

  const handleAddRequaification = useCallback(() => {
    const submitData = {
      sanctionType,
      sanctionTypeOther,
      totalAmount,
      portionLevied,
      datePaid,
      datePaidRadio,
      datePaidExp,
      portionPenaltyWaived,
      portionOfPenaltyAmount,
    };

    getSubmitDataValues({ ...submitData }, dataIndex);

    setFormHasChanged(false);

    setShowAddNewChargeModal(false);
  }, [
    getSubmitDataValues,
    sanctionType,
    sanctionTypeOther,
    totalAmount,
    portionLevied,
    datePaid,
    datePaidRadio,
    datePaidExp,
    portionPenaltyWaived,
    portionOfPenaltyAmount,
    setShowAddNewChargeModal,
    dataIndex,
  ]);

  return (
    <div className="add-new-charge-children-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label
            className="add-new-monetary-related-sanction-label"
            id="dispositionLabelId"
          >
            If disposition resulted in a fine, penalty, restitution,
            disgorgement or monetary compensation, provide:
          </label>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <label className="court-action" id="monetarySanctionLabelId">
            Monetary Related Sanction Type: *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="felonyCourtReduced"
                type="radio"
                id="felony-court-reduced"
                className="custom-control-input"
                value={sanctionTypes.MONETARY}
                checked={sanctionType === sanctionTypes.MONETARY}
                onChange={handleChangeReducedChecked}
              />
              <label
                htmlFor="felony-court-reduced"
                className="custom-control-label"
                id="monetaryFineLabelId"
              >
                {sanctionTypes.MONETARY}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="misdCourtReduced"
                type="radio"
                id="misdCourtReducedId"
                className="custom-control-input"
                value={sanctionTypes.DISG}
                checked={sanctionType === sanctionTypes.DISG}
                onChange={handleChangeReducedChecked}
              />
              <label
                htmlFor="misdCourtReducedId"
                className="custom-control-label"
                id="disgorgementLabelId"
              >
                {sanctionTypes.DISG}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="sancReduced"
                type="radio"
                id="sancReducedId"
                className="custom-control-input"
                value={sanctionTypes.REST}
                checked={sanctionType === sanctionTypes.REST}
                onChange={handleChangeReducedChecked}
              />
              <label
                htmlFor="sancReducedId"
                className="custom-control-label"
                id="restitutionLabelId"
              >
                {sanctionTypes.REST}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="otherReducedRadio"
                type="radio"
                id="otherReducedRadioId"
                className="custom-control-input"
                value={sanctionTypes.OTHER}
                checked={sanctionType === sanctionTypes.OTHER}
                onChange={handleChangeReducedChecked}
              />
              <label
                htmlFor="otherReducedRadioId"
                className="custom-control-label"
                id="otherReducedRadioLabelId"
              >
                {sanctionTypes.OTHER}
              </label>
            </div>

            {sanctionType === sanctionTypes.OTHER ? (
              <div className="court-action-other">
                <input
                  type="text"
                  id="otherText"
                  className={
                    sanctionTypeOtherError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={sanctionTypeOther}
                  onChange={onChangeCourtActionHandler}
                  onBlur={onBlurCourtActionHandler}
                  onFocus={() => setFocusedState(true)}
                />
                <div id="sanctionTypeOtherError" className="invalid-feedback">
                  Please enter a response
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <label
        htmlFor="monetaryPenaltyExplanation"
        className="event-status-date-exp"
        id="totalAmountLabelId"
      >
        Total Amount: *
      </label>
      <div
        className="input-group amount-paid-input-group"
        id="amount-paid-input-group-id"
        style={{ marginBottom: "24px" }}
      >
        <div
          className={`input-group-prepend ${
            totalAmountError ||
            (totalTotalPortionAmountFocus && totalAmountError)
              ? "amount-error"
              : ""
          }`}
          id="amount-paind-dollar-sign-wrapper"
        >
          <span
            className={`input-group-text input-group-text-pad-right ${
              totalTotalPortionAmountFocus && totalAmountError
                ? "is-invalid"
                : ""
            }`}
            id="amount-paid-dollar-sign-id"
          >
            $
          </span>
        </div>
        <input
          type="text"
          id="monetaryPenaltyExplanation"
          className={`input-pad-left ${
            totalAmountError ? "form-control is-invalid" : "form-control"
          }`}
          style={{ borderRightStyle: "inset", borderRadius: "0 .4rem .4rem 0" }}
          spellCheck="false"
          maxLength={11}
          autoComplete="off"
          onFocus={() => {
            setFocusedState(true);
            setTotalPortionAmountFocus(true);
          }}
          value={`${totalAmount}`.replaceAll("-", "").trim()}
          onBlur={(e) => {
            setTotalPortionAmountFocus(false);
            onTotalAmountBlurHandler(e);
          }}
          onChange={handleOnTotalAmountChange}
          onKeyPress={onlyNumeric}
          onPaste={pasteCommaDelimited}
        />
        <div id="totalAmountError" className="invalid-feedback">
          {totalAmountErrorMsg?.length
            ? totalAmountErrorMsg
            : "Please enter $ amount"}
        </div>
      </div>

      <label
        htmlFor="monetaryPenaltyExplanation"
        className="event-status-date-exp"
        id="portionLeviedLabelId"
      >
        Portion levied against you: *
      </label>
      <div
        className="input-group amount-paid-input-group"
        id="amount-paid-input-group-id"
        style={{ marginBottom: "24px" }}
      >
        <div
          className={`input-group-prepend ${
            portionLeviedError ||
            (portionPortionAmountFocus && portionLeviedError)
              ? "amount-error"
              : ""
          }`}
          id="amount-paind-dollar-sign-wrapper"
        >
          <span
            className={`input-group-text input-group-text-pad-right ${
              portionPortionAmountFocus && portionLeviedError
                ? "is-invalid"
                : ""
            }`}
            id="amount-paid-dollar-sign-id"
          >
            $
          </span>
        </div>
        <input
          type="text"
          id="monetaryPenaltyExplanation"
          className={`input-pad-left ${
            portionLeviedError ? "form-control is-invalid" : "form-control"
          }`}
          style={{ borderRightStyle: "inset", borderRadius: "0 .4rem .4rem 0" }}
          spellCheck="false"
          maxLength={11}
          autoComplete="off"
          onFocus={() => {
            setFocusedState(true);
            setPortionAmountFocus(true);
          }}
          value={`${portionLevied}`.replaceAll("-", "").trim()}
          onBlur={(e) => {
            setPortionAmountFocus(false);
            onPortionLeviedBlurHandler(e);
          }}
          onChange={handleOnPortionLeviedChange}
          onKeyPress={onlyNumeric}
          onPaste={pasteCommaDelimited}
        />
        <div id="portionLeviedError" className="invalid-feedback">
          {portionLeviedErrorMsg?.length
            ? portionLeviedErrorMsg
            : "Please enter $ amount"}
        </div>
      </div>

      <div
        className="col-md-12 event-status-date-wrapper"
        style={{ marginTop: 0 }}
      >
        <label id="datePaidLabelId" htmlFor="datePaidId">
          <strong id="datePaidText">Date Paid by You: *</strong>
        </label>
        <InputMask
          id="datePaidId"
          mask="99/99/9999"
          maskChar="_"
          className={`${
            datePaidError ? "form-control is-invalid" : "form-control"
          } event-status-date-input`}
          placeholder="MM/DD/YYYY"
          autoComplete="off"
          required
          value={datePaid}
          onChange={onDatePaidChangeHandler}
          onBlur={onDatePaidBlurHandler}
          onFocus={() => setFocusedState(true)}
        />
        <div id="datePaidError" className="invalid-feedback">
          {datePaidErrorMsg?.length
            ? datePaidErrorMsg
            : "Please enter a valid Date Paid"}
        </div>
      </div>

      <div className="row event-status-date-radio-group">
        <div className="custom-control">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-datePaidExactId"
                type="radio"
                id="datePaidExactId"
                className="custom-control-input"
                value={eventStatusDateType.EXACT}
                checked={datePaidRadio === eventStatusDateType.EXACT}
                onChange={handleChangeDatePaidChecked}
              />
              <label
                htmlFor="datePaidExactId"
                className="custom-control-label"
                id="datePaidExactLabelId"
              >
                {eventStatusDateType.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-datePaidExplanationId"
                type="radio"
                id="datePaidExplanationId"
                className="custom-control-input"
                value={eventStatusDateType.EXPLANATION}
                checked={datePaidRadio === eventStatusDateType.EXPLANATION}
                onChange={handleChangeDatePaidChecked}
              />
              <label
                htmlFor="datePaidExplanationId"
                className="custom-control-label"
                id="datePaidExplanationLabelId"
              >
                {eventStatusDateType.EXPLANATION}
              </label>
            </div>
            {datePaidRadio === eventStatusDateType.EXPLANATION ? (
              <div className="event-status-date-explanation-container">
                <label
                  htmlFor="datePaidExplanation"
                  className="event-status-date-exp"
                  id="datePaidExplanationTextLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="datePaidExplanation"
                  onChange={onDatePaidExplanationChange}
                  className={
                    datePaidExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurDatePaidExpHandler}
                  onFocus={() => setFocusedState(true)}
                  rows="3"
                  cols={500}
                  value={datePaidExp}
                />
                <div id="datePaidExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row" style={{ marginTop: "-34px" }}>
        <div className="col-md-12">
          <label className="inv-business" id="penaltyWaivedLabelId">
            Was any portion of penalty waived? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="portionPenaltyWaived"
                type="radio"
                id="portionPenaltyWaived_yes"
                className="custom-control-input"
                value={true}
                checked={portionPenaltyWaived === true}
                onChange={handleChangePortionPenaltyWaivedChecked}
              />
              <label
                htmlFor="portionPenaltyWaived_yes"
                className="custom-control-label"
                id="portionPenaltyWaived_yes_label"
              >
                Yes
              </label>
            </div>

            {portionPenaltyWaived === true ? (
              <div className="portion-of-penalty-amount-container">
                <label
                  htmlFor="monetaryPenaltyExplanation"
                  className="event-status-date-exp"
                  id="penaltyWaivedAmountLabelId"
                >
                  Amount:
                </label>
                <div
                  className="input-group amount-paid-input-group"
                  id="amount-paid-input-group-id"
                >
                  <div
                    className={`input-group-prepend ${
                      portionOfPenaltyAmountError ||
                      (amountFocus && portionOfPenaltyAmountError)
                        ? "amount-error"
                        : ""
                    }`}
                    id="amount-paind-dollar-sign-wrapper"
                  >
                    <span
                      className={`input-group-text input-group-text-pad-right ${
                        amountFocus && portionOfPenaltyAmountError
                          ? "is-invalid"
                          : ""
                      }`}
                      id="amount-paid-dollar-sign-id"
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    id="monetaryPenaltyExplanation"
                    className={`input-pad-left ${
                      portionOfPenaltyAmountError
                        ? "form-control is-invalid"
                        : "form-control"
                    }`}
                    style={{
                      borderRightStyle: "inset",
                      borderRadius: "0 .4rem .4rem 0",
                    }}
                    spellCheck="false"
                    maxLength={11}
                    autoComplete="off"
                    onFocus={() => {
                      setFocusedState(true);
                      setAmountFocus(true);
                    }}
                    value={`${portionOfPenaltyAmount}`
                      .replaceAll("-", "")
                      .trim()}
                    onBlur={(e) => {
                      setAmountFocus(false);
                      onPortionOfPenaltyAmountBlurHandler(e);
                    }}
                    onChange={handleOnPortionOfPenaltyAmountChange}
                    onKeyPress={onlyNumeric}
                    onPaste={pasteCommaDelimited}
                  />
                  <div
                    id="portionOfPenaltyAmountError"
                    className="invalid-feedback"
                  >
                    {portionOfPenaltyAmountErrorMsg?.length
                      ? portionOfPenaltyAmountErrorMsg
                      : "Please enter $ amount"}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="custom-control custom-radio">
              <input
                name="portionPenaltyWaived"
                type="radio"
                id="portionPenaltyWaived_no"
                className="custom-control-input"
                value={false}
                checked={portionPenaltyWaived === false}
                onChange={handleChangePortionPenaltyWaivedChecked}
              />
              <label
                htmlFor="portionPenaltyWaived_no"
                className="custom-control-label"
                id="portionPenaltyWaived_no_label"
              >
                No
              </label>
            </div>
            <div id="radio_error" className="invalid-feedback">
              Please choose a response.
            </div>
          </div>
        </div>
      </div>

      <div className="row drp-add-new-charge-btns">
        <div className="col-md-12 buttons">
          <span
            onClick={handleShowBackModal}
            style={{
              marginRight: "25px",
              color: "#3174af",
              cursor: "pointer",
            }}
            role="presentation"
          >
            <strong>Cancel</strong>
          </span>

          <Button
            className="addChargeButton"
            label={
              dataIndex !== null ? "Save" : "Add Monetary Related Sanction"
            }
            onClick={handleAddRequaification}
            disabled={isNextDisabled}
          />

          {false && <span className="mm-dots-loading"></span>}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default AddNewMonetaryRelatedSanctionContent;
