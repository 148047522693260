import React, { Component } from "react";
import { Button, FormCustomControl } from "react-blueprint-library";
import { withOktaAuth } from "@okta/okta-react";
import {
  updateContractStatus,
  saveBIGFICRADisclosure,
  saveContract,
  saveFastCashForm,
  saveFastCashFormBrokerCorp,
} from "../../../../api/makeAPIRequest";
import {
  CONTRACT_STATUS_ID,
  CONTRACT_TYPE_DESCRIPTIONS,
} from "../../../../constants/Contract";
import BackButtonModal from "../BackButtonModal";
import BlankContractModal from "../BlankContractModal";
import img from "../../../../assets/img/accept.gif";
import { FORM_ID } from "../../../../constants/Forms";
import ReviewSignHeader from "./ReviewSignForm1/ReviewSignHeader";
import { UtagTracking } from "../../../../utils/utag-tracking";

class ReviewSignForm2 extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleChecked = this.handleChecked.bind(this);
    this.handleReadContractClick = this.handleReadContractClick.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleShowBlankContractModal =
      this.handleShowBlankContractModal.bind(this);
    this.handleCloseBlankContractModal =
      this.handleCloseBlankContractModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.getVerbiage = this.getVerbiage.bind(this);
  }

  initialState() {
    return {
      checked: false,
      showBackModal: false,
      formHasChanged: false,
      is_submitted: false,
      contractData: "",
      pInfo: "",
      show_blank_contract_modal: false,
      blank_contract_blob: "",
      blank_disclosure_blob: "",
      verbiage: "",
      oktaSubId: this.props.authState.accessToken.claims.uid,
      candidate_email: this.props.authState.idToken.claims.email,
      is_screen_capture: false,
      app_contract_title: "",
    };
  }

  async handleChecked() {
    let currentCheck = this.state.checked;
    await this.setState({ checked: !currentCheck });
    this.props.hasCurrentFormChanged(true);
    this.setState({ formHasChanged: true });
  }

  handleReadContractClick() {
    this.showBlankContractModal();
  }

  async handleSubmitClick() {
    const {
      oktaSubId,
      candidate_email,
      contractData: { contractid },
      is_submitted: isSubmitted,
    } = this.state;
    const {
      corpInfo: { corpname },
      contracttypedesc,
    } = this.props;

    this.setState({ is_screen_capture: true, is_submitted: !isSubmitted });

    await updateContractStatus(
      oktaSubId,
      contractid,
      candidate_email,
      CONTRACT_STATUS_ID.APPLICATION_SUBMITTED
    );

    //save screenshot, ipaddress, user agent
    await this.props.saveESigData();

    if (contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP) {
      await saveContract(oktaSubId, contractid, corpname);
    }

    await saveBIGFICRADisclosure(oktaSubId, contractid);

    //fast cash
    if (contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP) {
      await saveFastCashFormBrokerCorp(oktaSubId, this.props.corpid);
    } else if (
      //MMLIS ONLY & MEMBER of CORP contract don't have direct deposit, so no fast cash
      contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.MMLIS &&
      contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP &&
      contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
    ) {
      await saveFastCashForm(oktaSubId);
    }

    //give a couple seconds for application to react
    //to upload and then go to confirmation page
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    await delay(750);

    window.location = `/applicationconfirmation?contract=${contractid}`;
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleCloseBlankContractModal() {
    this.setState({ show_blank_contract_modal: false });
  }

  handleShowBlankContractModal() {
    this.setState({ show_blank_contract_modal: true });
  }
  handleBackClick() {
    this.setState({ formHasChanged: false });
    this.handleCloseBackModal();
    this.props.toggleForm(FORM_ID.REVIEW_SIGN_1);
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      this.props.toggleForm(FORM_ID.REVIEW_SIGN_1);
    }
  }
  //Gets signature text
  async getVerbiage() {
    let verbiageState = await this.props.reviewSign2Verbiage;
    let verbiageText = verbiageState[0].sb_verbiage;
    await this.setState({
      verbiage: verbiageText,
    });
    document.getElementById("reviewSignInjectedText").innerHTML =
      this.state.verbiage;
  }

  async componentDidMount() {
    this.getVerbiage();
    await this.setState({
      contractData: this.props.dashboardInfo,
      pInfo: this.props.pInfo,
      blank_contract_blob: this.props.blankContractBlob,
      blank_disclosure_blob: this.props.blankDisclosureBlob,
    });
    if (
      this.state.contractData.contracttypedesc ===
      CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
    ) {
      this.setState({ app_contract_title: "SIGN APPLICATION" });
    } else {
      this.setState({ app_contract_title: "SIGN CONTRACT" });
    }
    UtagTracking.sendPageNameView(
      "reviewsignform2",
      this.props.candidateId,
      this.props.agencynumber,
      this.props.contracttypeid
    );
  }

  async componentDidUpdate() {
    if (
      this.props.blankContractBlob !== this.state.blank_contract_blob ||
      this.props.blankDisclosureBlob !== this.state.blank_disclosure_blob
    ) {
      await this.setState({
        blank_contract_blob: this.props.blankContractBlob,
        blank_disclosure_blob: this.props.blankDisclosureBlob,
      });
    }
  }

  render() {
    return (
      <div id="ReviewAndSign2">
        {this.state.contractData.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP ? (
          <ReviewSignHeader
            step="2"
            title="Review application"
          ></ReviewSignHeader>
        ) : (
          <ReviewSignHeader
            step="2"
            title={this.state.app_contract_title}
          ></ReviewSignHeader>
        )}

        <>
          <div
            className="row"
            style={{ marginTop: "35px", marginBottom: "15px" }}
          >
            {this.state.contractData.contracttypedesc ===
            CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP ? (
              <div className="col-md-12" id="agncy-name">
                <h2
                  id="rs-cnd-nme"
                  style={{ color: "black", marginBottom: "10px" }}
                >{`${this.state.pInfo.firstname} ${this.state.pInfo.lastname}`}</h2>
                {this.state.contractData.contracttypedesc.substring(
                  0,
                  this.state.contractData.contracttypedesc.length - 8
                )}
                {this.props.dashboardInfo.mmlis === true &&
                this.props.dashboardInfo.financingonly === true ? (
                  <span> + MMLIS, Financing</span>
                ) : this.props.dashboardInfo.mmlis === true &&
                  this.props.dashboardInfo.financingonly === false ? (
                  <span> + MMLIS</span>
                ) : this.props.dashboardInfo.mmlis === false &&
                  this.props.dashboardInfo.financingonly === true ? (
                  <span> + Financing</span>
                ) : null}{" "}
                |{` MassMutual Agency #${this.state.contractData.agencynumber}`}
              </div>
            ) : (
              <div className="col-md-12" id="agncy-name">
                <h2
                  id="rs-cnd-nme"
                  style={{ color: "black", marginBottom: "10px" }}
                >{`${this.state.pInfo.firstname} ${this.state.pInfo.lastname}`}</h2>
                {this.state.contractData.contracttypedesc}
                {this.props.dashboardInfo.mmlis === true &&
                this.props.dashboardInfo.financingonly === true ? (
                  <span> + MMLIS, Financing</span>
                ) : this.props.dashboardInfo.mmlis === true &&
                  this.props.dashboardInfo.financingonly === false ? (
                  <span> + MMLIS</span>
                ) : this.props.dashboardInfo.mmlis === false &&
                  this.props.dashboardInfo.financingonly === true ? (
                  <span> + Financing</span>
                ) : null}{" "}
                |{` MassMutual #${this.state.contractData.agencynumber}`}
              </div>
            )}
          </div>
          {this.state.contractData.contracttypedesc !==
            CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP && (
            <>
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ paddingTop: "25px", marginBottom: "15px" }}
                  id="rs-contract-heading"
                >
                  {this.state.contractData.contracttypedesc ===
                  CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY ? (
                    <h3 id="cntrt">Application</h3>
                  ) : (
                    <h3 id="cntrt">Contract</h3>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12" id="cntrct-txt">
                  <p>
                    Please review your proposed{" "}
                    {this.state.contractData.contracttypedesc ===
                    CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY ? (
                      <>application</>
                    ) : (
                      <>contract</>
                    )}{" "}
                    with MassMutual.{" "}
                    {this.state.contractData.contracttypedesc !==
                      CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY && (
                      <>
                        Please note that the contract will not become effective
                        until it is endorsed by MassMutual.
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12" id="ct-btton">
                  <Button
                    variant="secondary"
                    onClick={this.handleShowBlankContractModal}
                    id="read-contract"
                  >
                    {this.state.contractData.contracttypedesc ===
                    CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY ? (
                      <>READ APPLICATION</>
                    ) : (
                      <>READ CONTRACT</>
                    )}
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
        <div
          className="row"
          style={{ paddingTop: "40px", paddingBottom: "25px" }}
        >
          <div className="col-md-12">
            <h3 id="sgntre">Signature</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p id="reviewSignInjectedText"></p>
          </div>
        </div>

        {this.state.is_screen_capture ? (
          <div className="row">
            <div className="col-md-12" style={{ paddingTop: "20px" }}>
              <img alt="" width="90px" src={img} />
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className="col-md-12"
              style={{ paddingTop: "20px" }}
              id="iaccept"
            >
              <FormCustomControl
                id="accept"
                inputType="checkbox"
                label="I Accept"
                onChange={this.handleChecked}
                checked={this.state.checked}
              />
              <label
                className="required-field-text"
                style={{ paddingLeft: "0.5em" }}
              ></label>
            </div>
          </div>
        )}
        <div className="row" style={{ float: "right" }}>
          <div
            className="col-md-12"
            style={{
              textAlign: "right",
              paddingTop: "30px",
              display: "inline-flex",
            }}
          >
            <span
              onClick={() => {
                this.handleShowBackModal();
              }}
              style={{
                margin: "8px 25px 8px 0px",
                color: "#3174af",
                cursor: "pointer",
              }}
              id="back-button"
            >
              <strong> Back </strong>
            </span>
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => {
                this.handleSubmitClick();
              }}
              disabled={!this.state.checked || this.state.is_submitted}
              id="submit-button"
            >
              SUBMIT
            </Button>
            {this.state.is_submitted && (
              <div className="mm-dots-loading-lg"></div>
            )}
          </div>
        </div>

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />

        {this.state.blank_contract_blob !== "" &&
          this.state.blank_disclosure_blob !== "" && (
            <BlankContractModal
              isOpen={this.state.show_blank_contract_modal}
              closeBackModal={this.handleCloseBlankContractModal}
              blankContractBlob={this.state.blank_contract_blob}
              blankDisclosureBlob={this.state.blank_disclosure_blob}
            />
          )}
      </div>
    );
  }
}

export default withOktaAuth(ReviewSignForm2);
