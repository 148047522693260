/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { Dropdown, DropdownButton } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../../store/actions";
import BackButtonModal from "../../../../../Forms/BackButtonModal";

import "./CustomerComplaintForm.scss";

import {
  dateValidator,
  formatMoney,
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../common/Functions";
import { CCB_FORM_ID } from "../../constants";
import {
  custComplaintStatusOptions,
  customerComplaintActionType,
  exactExplanationOptions,
  yesNoOptions,
} from "../constants";

const CustomerComplaintAddDRPCustomerComplaintForm = ({
  updateCCBForm,
  backFormId,
  updateTempCustComplDRP,
  tempDRP,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);

  const [custComplaintIsOralComplaint, setCustComplaintIsOralComplaint] =
    useState(tempDRP?.custComplaintIsOralComplaint || "");
  const [
    custComplaintIsOralComplaintError,
    setCustComplaintIsOralComplaintError,
  ] = useState(false);
  const [custComplaintIsWrittenComplaint, setCustComplaintIsWrittenComplaint] =
    useState(tempDRP?.custComplaintIsWrittenComplaint || "");
  const [custComplaintIsWrittenComplaintError, setIsWrittenComplaintError] =
    useState(false);
  const [
    custComplaintIsArbitrationCivilLitigation,
    setIsArbitrationCivilLitigation,
  ] = useState(tempDRP?.custComplaintIsArbitrationCivilLitigation || "");
  const [
    custComplaintIsArbitrationCivilLitigationError,
    setIsArbitrationCivilLitigationError,
  ] = useState(false);
  const [custComplaintCourtName, setCourtName] = useState(
    tempDRP?.custComplaintCourtName || ""
  );
  const [custComplaintCourtNameError, setCourtNameError] = useState(false);
  const [custComplaintCourtLocation, setCourtLocation] = useState(
    tempDRP?.custComplaintCourtLocation || ""
  );
  const [custComplaintCourtLocationError, setCourtLocationError] =
    useState(false);
  const [custComplaintDocketOrCase, setDocketOrCase] = useState(
    tempDRP?.custComplaintDocketOrCase || ""
  );
  const [custComplaintDocketOrCaseError, setDocketOrCaseError] =
    useState(false);
  const [custComplaintFilingDate, setFilingDate] = useState(
    tempDRP?.custComplaintFilingDate || ""
  );
  const [custComplaintFilingDateError, setFilingDateError] = useState(false);
  const [custComplaintFilingDateErrorMsg, setFilingDateErrorMsg] = useState("");
  const [custComplaintDateReceived, setDateReceived] = useState(
    tempDRP?.custComplaintDateReceived || ""
  );
  const [custComplaintDateReceivedError, setDateReceivedError] =
    useState(false);
  const [custComplaintDateReceivedErrorMsg, setDateReceivedErrorMsg] =
    useState("");
  const [custComplaintDateReceivedRadio, setDateReceivedRadio] = useState(
    tempDRP?.custComplaintDateReceivedRadio || ""
  );
  const [custComplaintDateReceivedRadioError, setDateReceivedRadioError] =
    useState(false);
  const [custComplaintDateReceivedExplanation, setDateReceivedExplanation] =
    useState(tempDRP?.custComplaintDateReceivedExplanation || "");
  const [
    custComplaintDateReceivedExplanationError,
    setDateReceivedExplanationError,
  ] = useState(false);
  const [custComplaintIsComplaintPending, setIsComplaintPending] = useState(
    tempDRP?.custComplaintIsComplaintPending || ""
  );
  const [custComplaintIsComplaintPendingError, setIsComplaintPendingError] =
    useState(false);
  const [custComplaintStatusDate, setStatusDate] = useState(
    tempDRP?.custComplaintStatusDate || ""
  );
  const [custComplaintStatusDateError, setStatusDateError] = useState(false);
  const [custComplaintStatusDateErrorMsg, setStatusDateErrorMsg] = useState("");
  const [custComplaintStatusDateRadio, setStatusDateRadio] = useState(
    tempDRP?.custComplaintStatusDateRadio || ""
  );
  const [custComplaintStatusDateRadioError, setStatusDateRadioError] =
    useState(false);
  const [custComplaintStatusDateExplanation, setStatusDateExplanation] =
    useState(tempDRP?.custComplaintStatusDateExplanation || "");
  const [
    custComplaintStatusDateExplanationError,
    setStatusDateExplanationError,
  ] = useState(false);
  const [custComplaintStatusOption, setStatusOption] = useState(
    tempDRP?.custComplaintStatusOption || ""
  );
  const [custComplaintStatusOptionError, setStatusOptionError] =
    useState(false);
  const [custComplaintMonetaryComp, setMonetaryComp] = useState(
    tempDRP?.custComplaintMonetaryComp || ""
  );
  const [custComplaintMonetaryCompError, setMonetaryCompError] =
    useState(false);
  const [custComplaintMonetaryCompErrorMsg, setMonetaryCompErrorMsg] =
    useState("");
  const [custComplaintContribAmount, setContribAmount] = useState(
    tempDRP?.custComplaintContribAmount || ""
  );
  const [custComplaintContribAmountError, setContribAmountError] =
    useState(false);
  const [custComplaintContribAmountErrorMsg, setContribAmountErrorMsg] =
    useState("");

  const [isFocusedState, setFocusedState] = useState(false);

  const [isUpdates, setIsUpdates] = useState(false);

  const isError =
    custComplaintIsOralComplaintError ||
    custComplaintIsWrittenComplaintError ||
    custComplaintIsArbitrationCivilLitigationError ||
    custComplaintCourtNameError ||
    custComplaintCourtLocationError ||
    custComplaintDocketOrCaseError ||
    custComplaintFilingDateError ||
    custComplaintDateReceivedError ||
    custComplaintDateReceivedRadioError ||
    custComplaintDateReceivedExplanationError ||
    custComplaintStatusDateError ||
    custComplaintStatusDateRadioError ||
    custComplaintStatusDateExplanationError ||
    custComplaintStatusOptionError ||
    custComplaintContribAmountError ||
    custComplaintMonetaryCompError;

  const isNextBtnDisabled = !!(
    custComplaintIsOralComplaint === "" ||
    custComplaintIsWrittenComplaint === "" ||
    custComplaintIsArbitrationCivilLitigation === "" ||
    (custComplaintIsArbitrationCivilLitigation === yesNoOptions.YES &&
      (custComplaintCourtName.length < 2 ||
        custComplaintCourtLocation.length < 2 ||
        custComplaintDocketOrCase.length < 2 ||
        custComplaintFilingDate === "" ||
        custComplaintFilingDate.includes("_") ||
        dateValidator(custComplaintFilingDate).isInvalid)) ||
    custComplaintDateReceived === "" ||
    custComplaintDateReceived.includes("_") ||
    dateValidator(custComplaintDateReceived).isInvalid ||
    custComplaintDateReceivedRadio === "" ||
    (custComplaintDateReceivedRadio === exactExplanationOptions.EXPLANATION &&
      custComplaintDateReceivedExplanation?.length < 2) ||
    custComplaintIsComplaintPending === "" ||
    (custComplaintIsComplaintPending === yesNoOptions.NO &&
      (custComplaintStatusOption === "" ||
        ((custComplaintStatusOption === "Settled" ||
          custComplaintStatusOption.includes("Award")) &&
          (custComplaintContribAmount?.length < 1 ||
            custComplaintMonetaryComp?.length < 1)))) ||
    custComplaintStatusDate === "" ||
    custComplaintStatusDate.includes("_") ||
    dateValidator(custComplaintStatusDate).isInvalid ||
    custComplaintStatusDateRadio === "" ||
    (custComplaintStatusDateRadio === exactExplanationOptions.EXPLANATION &&
      custComplaintStatusDateExplanation?.length < 2) ||
    isError ||
    isFocusedState
  );

  const isOralComplaintChangeChecked = (e) => {
    setIsUpdates(true);
    const { value } = e.target;
    setCustComplaintIsOralComplaintError(false);
    setCustComplaintIsOralComplaint(value);
  };
  const isWrittenComplaintChangeChecked = (e) => {
    setIsUpdates(true);
    const { value } = e.target;
    setIsWrittenComplaintError(false);
    setCustComplaintIsWrittenComplaint(value);
  };
  const isArbitrationCivilLitigationChangeChecked = (e) => {
    setIsUpdates(true);
    const { value } = e.target;
    if (value === "No") {
      setCourtName("");
      setCourtNameError(false);
      setCourtLocation("");
      setCourtLocationError(false);
      setDocketOrCase("");
      setDocketOrCaseError(false);
      setFilingDate("");
      setFilingDateError(false);
    }
    setIsArbitrationCivilLitigationError(false);
    setIsArbitrationCivilLitigation(value);
  };
  const isComplaintPendingChangeChecked = (e) => {
    setIsUpdates(true);
    const { value } = e.target;
    if (value === "Yes") {
      setStatusOption("");
      setStatusOptionError(false);
      setContribAmount("");
      setContribAmountError(false);
      setMonetaryComp("");
      setMonetaryCompError(false);
    }
    setIsComplaintPendingError(false);
    setIsComplaintPending(value);
  };

  //onChange
  const onChangeTextInput = ({ target: { id, value } }) => {
    setIsUpdates(true);
    switch (id) {
      case "custComplaintCourtName":
        return setCourtName(value);
      case "custComplaintCourtLocation":
        return setCourtLocation(value);
      case "custComplaintDocketOrCase":
        return setDocketOrCase(value);
      case "custComplaintFilingDate":
        return setFilingDate(value);
      case "custComplaintDateReceived":
        return setDateReceived(value);
      case "custComplaintDateReceivedExplanation":
        return setDateReceivedExplanation(value);
      case "custComplaintStatusDate":
        return setStatusDate(value);
      case "custComplaintStatusDateExplanation":
        return setStatusDateExplanation(value);
      default:
        break;
    }
  };
  const onChangeMoneyInput = ({ target: { id, value } }) => {
    setIsUpdates(true);
    const moneyValue = formatMoney(value);
    switch (id) {
      case "custComplaintMonetaryComp":
        return setMonetaryComp(moneyValue);
      case "custComplaintContribAmount":
        return setContribAmount(moneyValue);
      default:
        break;
    }
  };
  const onBlurTextInput = ({ target: { id, value } }) => {
    setFocusedState(false);
    const isInvalid = value?.trim()?.length < 2;
    switch (id) {
      case "custComplaintCourtName":
        return setCourtNameError(isInvalid);
      case "custComplaintCourtLocation":
        return setCourtLocationError(isInvalid);
      case "custComplaintDocketOrCase":
        return setDocketOrCaseError(isInvalid);
      case "custComplaintDateReceivedExplanation":
        return setDateReceivedExplanationError(isInvalid);
      case "custComplaintStatusDateExplanation":
        return setStatusDateExplanationError(isInvalid);
      default:
        break;
    }
  };
  const onBlurDateInput = ({ target: { id, value } }) => {
    setFocusedState(false);
    const { isInvalid, errMessage } = dateValidator(value);
    switch (id) {
      case "custComplaintFilingDate":
        setFilingDateError(isInvalid);
        setFilingDateErrorMsg(errMessage);
        break;
      case "custComplaintDateReceived":
        setDateReceivedError(isInvalid);
        setDateReceivedErrorMsg(errMessage);
        break;
      case "custComplaintStatusDate":
        setStatusDateError(isInvalid);
        setStatusDateErrorMsg(errMessage);
        break;
      default:
        break;
    }
  };
  const onBlurMoneyInput = ({ target: { id, value } }) => {
    setFocusedState(false);
    const isMoneyError =
      parseInt(value?.replaceAll(",", "")) > 100000000 || value?.length < 1;

    let moneyErrMessage;
    switch (id) {
      case "custComplaintMonetaryComp":
        moneyErrMessage =
          parseInt(value?.replaceAll(",", "")) > 100000000
            ? "Please enter a valid number"
            : value?.length < 1
            ? "Please enter Award Amount"
            : "";
        setMonetaryCompError(isMoneyError);
        setMonetaryCompErrorMsg(moneyErrMessage);
        break;
      case "custComplaintContribAmount":
        moneyErrMessage =
          parseInt(value?.replaceAll(",", "")) > 100000000
            ? "Please enter a valid number"
            : value?.length < 1
            ? "Please enter your Contribution Amount"
            : "";
        setContribAmountError(isMoneyError);
        setContribAmountErrorMsg(moneyErrMessage);
        break;
      default:
        break;
    }
  };

  const handleDateReceivedRadioCheckChange = ({ target: { value } }) => {
    setIsUpdates(true);
    if (value === exactExplanationOptions.EXACT) {
      setDateReceivedExplanation("");
      setDateReceivedExplanationError(false);
    }
    setDateReceivedRadioError(false);
    setDateReceivedRadio(value);
  };
  const handleStatusDateRadioCheckChange = ({ target: { value } }) => {
    setIsUpdates(true);
    if (value === exactExplanationOptions.EXACT) {
      setStatusDateExplanation("");
      setStatusDateExplanationError(false);
    }
    setStatusDateRadioError(false);
    setStatusDateRadio(value);
  };
  const handleCloseBackModal = () => setShowBackModal(false);

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, []);

  const handleShowBackModal = () => {
    if (isUpdates) {
      setShowBackModal(true);
    } else {
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = () => {
    const { statusDetailsMatter, statusDetailsNamedPartyRadio } = tempDRP;
    let nextFormId;
    if (
      statusDetailsMatter === customerComplaintActionType.CUSTOMER_COMPLAINT ||
      statusDetailsNamedPartyRadio === "No"
    ) {
      for (const [key, value] of Object.entries(tempDRP)) {
        if (key.slice(0, 3) === "arb" || key.slice(0, 3) === "civ") {
          delete tempDRP[key];
        }
      }
      nextFormId = CCB_FORM_ID.ADD_DRP_COMMENTS;
    } else if (
      statusDetailsMatter === customerComplaintActionType.ARBITRATION_CFTC
    ) {
      for (const [key, value] of Object.entries(tempDRP)) {
        if (key.slice(0, 3) === "civ") {
          delete tempDRP[key];
        }
      }
      nextFormId = CCB_FORM_ID.ADD_DRP_ARBITRATION_CFTC;
    } else if (
      statusDetailsMatter === customerComplaintActionType.CIVIL_LITIGATION
    ) {
      for (const [key, value] of Object.entries(tempDRP)) {
        if (key.slice(0, 3) === "arb") {
          delete tempDRP[key];
        }
      }
      nextFormId = CCB_FORM_ID.ADD_DRP_CIVIL_LITIGATION;
    } else {
      nextFormId = CCB_FORM_ID.ADD_DRP_COMMENTS;
    }
    const submitData = {
      ...tempDRP,
      custComplaintIsOralComplaint,
      custComplaintIsWrittenComplaint,
      custComplaintIsArbitrationCivilLitigation,
      custComplaintCourtName,
      custComplaintCourtLocation,
      custComplaintDocketOrCase,
      custComplaintFilingDate,
      custComplaintDateReceived,
      custComplaintDateReceivedRadio,
      custComplaintDateReceivedExplanation,
      custComplaintIsComplaintPending,
      custComplaintStatusOption,
      custComplaintContribAmount,
      custComplaintMonetaryComp,
      custComplaintStatusDate,
      custComplaintStatusDateRadio,
      custComplaintStatusDateExplanation,
    };
    updateTempCustComplDRP(submitData);
    updateCCBForm(nextFormId);
  };

  const onChangeStatusOptionHandler = ({ target: { value } }) => {
    setIsUpdates(true);
    setStatusOption(value);
    setContribAmount("");
    setContribAmountError(false);
    setContribAmountErrorMsg("");
    setMonetaryComp("");
    setMonetaryCompError(false);
    setMonetaryCompErrorMsg("");
  };
  const onBlurCustomerStatusOptionHandler = ({ relatedTarget }) => {
    return custComplaintStatusOption === "" && relatedTarget === null
      ? setStatusOptionError(true)
      : setStatusOptionError(false);
  };

  const clickFunc = (event) => {
    event.target.focus();
  };
  return (
    <div className="customer-complaint-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label className="customer-complaint-label" id="cutomerOralText">
            Is this an oral complaint? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="isOralComplaintYes"
                type="radio"
                id="isOralComplaintYes"
                className="custom-control-input"
                value={yesNoOptions.YES}
                checked={custComplaintIsOralComplaint === yesNoOptions.YES}
                onChange={isOralComplaintChangeChecked}
              />
              <label
                htmlFor="isOralComplaintYes"
                className="custom-control-label"
                id="isOralComplaintYesLabelId"
              >
                {yesNoOptions.YES}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="isOralComplaintNo"
                type="radio"
                id="isOralComplaintNo"
                className="custom-control-input"
                value={yesNoOptions.NO}
                checked={custComplaintIsOralComplaint === yesNoOptions.NO}
                onChange={isOralComplaintChangeChecked}
              />
              <label
                htmlFor="isOralComplaintNo"
                className="custom-control-label"
                id="isOralComplaintNoLabelId"
              >
                {yesNoOptions.NO}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label className="customer-complaint-label" id="customerWrittenText">
            Is this a written complaint? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="isWrittenComplaintYes"
                type="radio"
                id="isWrittenComplaintYes"
                className="custom-control-input"
                value={yesNoOptions.YES}
                checked={custComplaintIsWrittenComplaint === yesNoOptions.YES}
                onChange={isWrittenComplaintChangeChecked}
              />
              <label
                htmlFor="isWrittenComplaintYes"
                className="custom-control-label"
                id="isWrittenComplaintYesLabelId"
              >
                {yesNoOptions.YES}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="isWrittenComplaintNo"
                type="radio"
                id="isWrittenComplaintNo"
                className="custom-control-input"
                value={yesNoOptions.NO}
                checked={custComplaintIsWrittenComplaint === yesNoOptions.NO}
                onChange={isWrittenComplaintChangeChecked}
              />
              <label
                htmlFor="isWrittenComplaintNo"
                className="custom-control-label"
                id="isWrittenComplaintNoLabelId"
              >
                {yesNoOptions.NO}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label
            className="customer-complaint-label"
            id="customerArbitrationText"
          >
            Is this an arbitration/CFTC reparation or civil litigation? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="isArbitrationCivilLitigationYes"
                type="radio"
                id="isArbitrationCivilLitigationYes"
                className="custom-control-input"
                value={yesNoOptions.YES}
                checked={
                  custComplaintIsArbitrationCivilLitigation === yesNoOptions.YES
                }
                onChange={isArbitrationCivilLitigationChangeChecked}
              />
              <label
                htmlFor="isArbitrationCivilLitigationYes"
                className="custom-control-label"
                id="isArbitrationCivilLitigationYesLabelId"
              >
                {yesNoOptions.YES}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="isArbitrationCivilLitigationNo"
                type="radio"
                id="isArbitrationCivilLitigationNo"
                className="custom-control-input"
                value={yesNoOptions.NO}
                checked={
                  custComplaintIsArbitrationCivilLitigation === yesNoOptions.NO
                }
                onChange={isArbitrationCivilLitigationChangeChecked}
              />
              <label
                htmlFor="isArbitrationCivilLitigationNo"
                className="custom-control-label"
                id="isArbitrationCivilLitigationNoLabelId"
              >
                {yesNoOptions.NO}
              </label>
            </div>
          </div>
        </div>
      </div>
      {custComplaintIsArbitrationCivilLitigation === yesNoOptions.YES && (
        <div className="arbitration-civil-litigation-wrapper">
          <div className="row">
            <div className="col-md-7">
              <label
                className="customer-complaint-label"
                htmlFor="custComplaintCourtName"
                id="courtNameLabelId"
              >
                Arbitration/reparation forum or court name: *
              </label>
              <input
                type="text"
                id="custComplaintCourtName"
                className={
                  custComplaintCourtNameError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                maxLength={500}
                minLength={2}
                autoComplete="off"
                value={custComplaintCourtName}
                onChange={onChangeTextInput}
                onBlur={onBlurTextInput}
                onFocus={() => setFocusedState(true)}
              />
              <div
                id="custComplaintCourtNameError"
                className="invalid-feedback"
              >
                Please enter a response
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <label
                className="customer-complaint-label"
                htmlFor="custComplaintCourtLocation"
                id="courtLocationLabelId"
              >
                Location of court (City or County and State or Country) *
              </label>
              <input
                type="text"
                id="custComplaintCourtLocation"
                className={
                  custComplaintCourtLocationError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                maxLength={100}
                minLength={2}
                autoComplete="off"
                value={custComplaintCourtLocation}
                onChange={onChangeTextInput}
                onBlur={onBlurTextInput}
                onFocus={() => setFocusedState(true)}
              />
              <div
                id="custComplaintCourtLocationError"
                className="invalid-feedback"
              >
                Please enter Location of court
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <label
                className="customer-complaint-label"
                htmlFor="custComplaintDocketOrCase"
                id="docketOrCaseLabelId"
              >
                Docket/Case #: *
              </label>
              <input
                type="text"
                id="custComplaintDocketOrCase"
                className={
                  custComplaintDocketOrCaseError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                maxLength={100}
                minLength={2}
                autoComplete="off"
                value={custComplaintDocketOrCase}
                onChange={onChangeTextInput}
                onBlur={onBlurTextInput}
                onFocus={() => setFocusedState(true)}
              />
              <div
                id="custComplaintDocketOrCaseError"
                className="invalid-feedback"
              >
                Please enter Docket/Case #
              </div>
            </div>
          </div>
          <div className="row date-wrapper">
            <div className="col-md-7">
              <label
                className="customer-complaint-label"
                htmlFor="custComplaintFilingDate"
                id="filingDateLabelId"
              >
                Filing date of arbitration/CFTC reparation or civil litigation:
                *
              </label>
              <InputMask
                id="custComplaintFilingDate"
                mask="99/99/9999"
                maskChar="_"
                className={
                  custComplaintFilingDateError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                placeholder="MM/DD/YYYY"
                autoComplete="off"
                value={custComplaintFilingDate}
                onChange={onChangeTextInput}
                onBlur={onBlurDateInput}
                onFocus={() => setFocusedState(true)}
              />
              <div
                id="custComplaintFilingDateError"
                className="invalid-feedback"
              >
                {custComplaintFilingDateErrorMsg}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row date-wrapper">
        <div className="col-md-7">
          <label
            className="customer-complaint-label"
            htmlFor="custComplaintDateReceived"
            id="dateReceivedLabelId"
          >
            Date received by/served on firm: *
          </label>
          <InputMask
            id="custComplaintDateReceived"
            mask="99/99/9999"
            maskChar="_"
            className={
              custComplaintDateReceivedError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={custComplaintDateReceived}
            onChange={onChangeTextInput}
            onBlur={onBlurDateInput}
            onFocus={() => setFocusedState(true)}
          />
          <div id="custComplaintDateReceivedError" className="invalid-feedback">
            {custComplaintDateReceivedErrorMsg}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="dateReceivedRadioExact"
                type="radio"
                id="dateReceivedRadioExact"
                className="custom-control-input"
                value={exactExplanationOptions.EXACT}
                checked={
                  custComplaintDateReceivedRadio ===
                  exactExplanationOptions.EXACT
                }
                onChange={handleDateReceivedRadioCheckChange}
              />
              <label
                htmlFor="dateReceivedRadioExact"
                className="custom-control-label"
                id="dateReceivedRadioExactLabelId"
              >
                {exactExplanationOptions.EXACT} date
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="dateReceivedRadioExplanation"
                type="radio"
                id="dateReceivedRadioExplanation"
                className="custom-control-input"
                value={exactExplanationOptions.EXPLANATION}
                checked={
                  custComplaintDateReceivedRadio ===
                  exactExplanationOptions.EXPLANATION
                }
                onChange={handleDateReceivedRadioCheckChange}
              />
              <label
                htmlFor="dateReceivedRadioExplanation"
                className="custom-control-label"
                id="dateReceivedRadioExplanationLabelId"
              >
                {exactExplanationOptions.EXPLANATION}
              </label>
            </div>
          </div>
        </div>
      </div>
      {custComplaintDateReceivedRadio ===
        exactExplanationOptions.EXPLANATION && (
        <div className="row" style={{ marginLeft: "10px" }}>
          <div className="col-md-10">
            <label
              className="customer-complaint-label"
              htmlFor="customerExplanation"
              id="dateReceivedExplanationLabelId"
            >
              If not exact, provide explanation *
            </label>
            <textarea
              type="text"
              id="custComplaintDateReceivedExplanation"
              className={
                custComplaintDateReceivedExplanationError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              autoComplete="off"
              value={custComplaintDateReceivedExplanation}
              onChange={onChangeTextInput}
              onBlur={onBlurTextInput}
              onFocus={() => setFocusedState(true)}
            />

            <div
              id="custComplaintDateReceivedExplanationError"
              className="invalid-feedback"
            >
              Please enter an Explanation
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <label className="customer-complaint-label" id="customerPendingText">
            Is the complaint, arbitration/CFTC reparation or civil litigation
            pending? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="isComplaintPendingYes"
                type="radio"
                id="isComplaintPendingYes"
                className="custom-control-input"
                value={yesNoOptions.YES}
                checked={custComplaintIsComplaintPending === yesNoOptions.YES}
                onChange={isComplaintPendingChangeChecked}
              />
              <label
                htmlFor="isComplaintPendingYes"
                className="custom-control-label"
                id="isComplaintPendingYesLabelId"
              >
                {yesNoOptions.YES}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="isComplaintPendingNo"
                type="radio"
                id="isComplaintPendingNo"
                className="custom-control-input"
                value={yesNoOptions.NO}
                checked={custComplaintIsComplaintPending === yesNoOptions.NO}
                onChange={isComplaintPendingChangeChecked}
              />
              <label
                htmlFor="isComplaintPendingNo"
                className="custom-control-label"
                id="isComplaintPendingNoLabelId"
              >
                {yesNoOptions.NO}
              </label>
            </div>
          </div>
        </div>
      </div>
      {custComplaintIsComplaintPending === yesNoOptions.NO && (
        <div className="not-pending-wrapper">
          <div className="row">
            <div className="col-md-10" style={{ minWidth: "430px" }}>
              <label
                className="customer-complaint-label"
                htmlFor="custComplaintStatusOption"
                id="statusOptionLabelId"
              >
                If the complaint, arbitration/CFTC reparation or civil
                litigation is not pending, provide status: *
              </label>
              <div>
                <DropdownButton
                  id="custComplaintStatusOption"
                  type="default"
                  variant="secondary"
                  title={
                    custComplaintStatusOption === ""
                      ? "Status"
                      : custComplaintStatusOption
                  }
                  className={
                    custComplaintStatusOptionError
                      ? `is-invalid`
                      : custComplaintStatusOption === ""
                      ? "status-placeholder"
                      : ""
                  }
                  selected={false}
                  autoComplete="new-address"
                  onSelect={(key, event) => {
                    event.target.id = "custComplaintStatusOption";
                    event.target.value = key;
                    onChangeStatusOptionHandler(event);
                  }}
                  onBlur={onBlurCustomerStatusOptionHandler}
                  onClick={clickFunc}
                >
                  {custComplaintStatusOptions.map(({ option }, key) => {
                    return (
                      <Dropdown.Item
                        key={key}
                        eventKey={option}
                        id={`custComplaintStatusOption${key}`}
                      >
                        {option}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
              <div
                id="custComplaintStatusOptionError"
                className={
                  custComplaintStatusOptionError
                    ? "show invalid-feedback"
                    : "invalid-feedback"
                }
              >
                Please provide a status
              </div>
            </div>
          </div>
          {(custComplaintStatusOption === "Settled" ||
            custComplaintStatusOption.includes("Award")) && (
            <>
              <div className="row">
                <div className="col-md-10">
                  <label
                    className="customer-complaint-label"
                    htmlFor="custComplaintMonetaryComp"
                    id="custComplaintMonetaryCompLabelId"
                  >
                    Settlement/Award/Monetary Judgment award *
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div
                    className={
                      custComplaintMonetaryCompError
                        ? "input-group is-invalid"
                        : "input-group"
                    }
                    id="alleged_damage_amount_input"
                  >
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-pad-right"
                        id="alleged_damage_amount_input_$"
                      >
                        $
                      </span>
                    </div>
                    <input
                      type="text"
                      id="custComplaintMonetaryComp"
                      className="form-control form-control-15"
                      maxLength={11}
                      value={custComplaintMonetaryComp}
                      onChange={onChangeMoneyInput}
                      onBlur={onBlurMoneyInput}
                      onFocus={() => setFocusedState(true)}
                      onKeyPress={onlyNumeric}
                      onPaste={pasteCommaDelimited}
                      autoComplete="off"
                    />
                  </div>
                  <div
                    id="custComplaintMonetaryCompError"
                    className={
                      custComplaintMonetaryCompError
                        ? "show invalid-feedback"
                        : "invalid-feedback"
                    }
                  >
                    {custComplaintMonetaryCompErrorMsg}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <label
                    className="customer-complaint-label"
                    htmlFor="custComplaintContribAmount"
                    id="custComplaintContribAmountLabelId"
                  >
                    Your Contribution Amount *
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div
                    className={
                      custComplaintContribAmountError
                        ? "input-group is-invalid"
                        : "input-group"
                    }
                    id="alleged_damage_amount_input"
                  >
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-pad-right"
                        id="alleged_damage_amount_input_$"
                      >
                        $
                      </span>
                    </div>
                    <input
                      type="text"
                      id="custComplaintContribAmount"
                      className="form-control form-control-15"
                      maxLength={11}
                      value={custComplaintContribAmount}
                      onChange={onChangeMoneyInput}
                      onBlur={onBlurMoneyInput}
                      onFocus={() => setFocusedState(true)}
                      onKeyPress={onlyNumeric}
                      onPaste={pasteCommaDelimited}
                      autoComplete="off"
                    />
                  </div>
                  <div
                    id="custComplaintContribAmountError"
                    className={
                      custComplaintContribAmountError
                        ? "show invalid-feedback"
                        : "invalid-feedback"
                    }
                  >
                    {custComplaintContribAmountErrorMsg}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <div className="row date-wrapper">
        <div className="col-md-7">
          <label
            className="customer-complaint-label"
            htmlFor="custComplaintStatusDate"
            id="statusDateLabelId"
          >
            Status Date: *
          </label>
          <InputMask
            id="custComplaintStatusDate"
            mask="99/99/9999"
            maskChar="_"
            className={
              custComplaintStatusDateError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={custComplaintStatusDate}
            onChange={onChangeTextInput}
            onBlur={onBlurDateInput}
            onFocus={() => setFocusedState(true)}
          />
          <div id="custComplaintStatusDateError" className="invalid-feedback">
            {custComplaintStatusDateErrorMsg}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="statusDateRadioExact"
                type="radio"
                id="statusDateRadioExact"
                className="custom-control-input"
                value={exactExplanationOptions.EXACT}
                checked={
                  custComplaintStatusDateRadio === exactExplanationOptions.EXACT
                }
                onChange={handleStatusDateRadioCheckChange}
              />
              <label
                htmlFor="statusDateRadioExact"
                className="custom-control-label"
                id="statusDateRadioExactLabelId"
              >
                {exactExplanationOptions.EXACT} date
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="statusDateRadioExplanation"
                type="radio"
                id="statusDateRadioExplanation"
                className="custom-control-input"
                value={exactExplanationOptions.EXPLANATION}
                checked={
                  custComplaintStatusDateRadio ===
                  exactExplanationOptions.EXPLANATION
                }
                onChange={handleStatusDateRadioCheckChange}
              />
              <label
                htmlFor="statusDateRadioExplanation"
                className="custom-control-label"
                id="statusDateRadioExplanationLabelId"
              >
                {exactExplanationOptions.EXPLANATION}
              </label>
            </div>
          </div>
        </div>
      </div>
      {custComplaintStatusDateRadio === exactExplanationOptions.EXPLANATION && (
        <div className="row" style={{ marginLeft: "10px" }}>
          <div className="col-md-10">
            <label
              className="customer-complaint-label"
              htmlFor="custComplaintStatusDateExplanation"
              id="statusDateExplanationLabelId"
            >
              If not exact, provide explanation *
            </label>
            <textarea
              type="text"
              id="custComplaintStatusDateExplanation"
              className={
                custComplaintStatusDateExplanationError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              autoComplete="off"
              value={custComplaintStatusDateExplanation}
              onChange={onChangeTextInput}
              onBlur={onBlurTextInput}
              onFocus={() => setFocusedState(true)}
            />

            <div
              id="custComplaintStatusDateExplanationError"
              className="invalid-feedback"
            >
              Please enter an Explanation
            </div>
          </div>
        </div>
      )}

      <div className="backbutton-drp-customer-complaint row">
        <div className="col-md-12 buttons">
          <span onClick={handleShowBackModal}>
            <strong id="crimDisclBack">Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CustomerComplaintAddDRPCustomerComplaintForm;
