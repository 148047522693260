import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import {
  CONTRACT_STATUS_DESC,
  CONTRACT_TYPE_DESCRIPTIONS,
} from "../../../constants/Contract";
import {
  getBIGFICRADisclosure,
  getContract,
  getMMLISAddOnContract,
} from "../../../api/makeAPIRequest";
import { useOktaAuth } from "@okta/okta-react";

const HomePagePriorApps = ({
  agencynumber,
  agency,
  applicationStatus,
  contract,
  contracttype,
  contractid,
  contracteffectivedate,
  prevSub,
  firstname,
  lastname,
  nyregs,
  phonenumber,
  mmlis,
  finance,
  cmid,
  index = 0,
  length = 0,
  list = [],
  handleChange,
  lastchangedate,
  mmlisregistrationdate,
  drpCheckList = [],
}) => {
  const {
    authState: {
      idToken: {
        claims: { sub, email },
      },
    },
  } = useOktaAuth();

  const [contractUrl, setContractURL] = useState("");
  const [disclosuresUrl, setDisclosuresURL] = useState("");
  const [mmlisAddOnUrl, setMMLISAddOnUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const ContractButtonLabel =
    applicationStatus === CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ||
    applicationStatus === CONTRACT_STATUS_DESC.ACKNOWLEDGED_APPROVED ||
    applicationStatus === CONTRACT_STATUS_DESC.MMLIS_Pending
      ? "Fully Endorsed Contract"
      : contracttype === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
      ? "Candidate Application"
      : "Candidate Endorsed Contract";

  const showArchiveDeclineMessage =
    applicationStatus === CONTRACT_STATUS_DESC.DECLINED_BY_HOME_OFFICE ||
    applicationStatus === CONTRACT_STATUS_DESC.ACKNOWLEDGED_DECLINED ||
    applicationStatus === CONTRACT_STATUS_DESC.ARCHIVE_DECLINED_BY_AGENCY ||
    applicationStatus === CONTRACT_STATUS_DESC.ARCHIVE_APPLICATION_WITHDRAWN;

  const addOns =
    mmlis === true && finance === true
      ? "+ MMLIS, Financing"
      : mmlis === true && finance === false
      ? "+ MMLIS"
      : mmlis === false && finance === true
      ? "+ Financing"
      : null;

  const openContract = () => {
    try {
      if (contractUrl !== "") window.open(contractUrl, "_blank");
    } catch (e) {
      console.log(e);
    }
  };
  const openDisclosure = () => {
    try {
      if (disclosuresUrl !== "") window.open(disclosuresUrl, "_blank");
    } catch (e) {
      console.log(e);
    }
  };
  const openMMLISAddOn = () => {
    try {
      if (mmlisAddOnUrl !== "") window.open(mmlisAddOnUrl, "_blank");
    } catch (e) {
      console.log(e);
    }
  };
  const openConsumerNotificationRights = async () => {
    try {
      window.open(
        window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL,
        "_blank"
      );
    } catch (e) {
      console.log(e);
    }
  };

  const downloadAll = async () => {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    var link = document.createElement("a");

    if (contractUrl !== "") {
      link.download = `${firstname}_${lastname}_${
        contracttype === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
          ? "Application"
          : "Endorsed_Contract"
      }.pdf`;
      link.href = contractUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    //Add Delay to Safari because Safari does not allow multiple file download
    if (isSafari) {
      await delay(750);
    }

    if (disclosuresUrl !== "") {
      link.download = `${firstname}_${lastname}_Endorsed_BIG_FICRA_Disclosure.pdf`;
      link.href = disclosuresUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    //Add Delay to Safari because Safari does not allow multiple file download
    if (isSafari) {
      await delay(750);
    }

    if (mmlis) {
      if (mmlisAddOnUrl !== "") {
        link.download = `${firstname}_${lastname}_MMLIS_Add_On_Contract.pdf`;
        link.href = mmlisAddOnUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      //Add Delay to Safari because Safari does not allow multiple file download
      if (isSafari) {
        await delay(750);
      }
    }
    if (
      window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL !== null &&
      typeof window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL !==
        "undefined" &&
      window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL !== ""
    ) {
      link.setAttribute("download", "");
      link.href = window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  useEffect(() => {
    const fetchContracts = async () => {
      setLoading(true);
      try {
        const contractList = mmlis
          ? await Promise.all([
              getContract(sub, contractid),
              getBIGFICRADisclosure(sub, contractid),
              getMMLISAddOnContract(sub, contractid),
            ])
          : await Promise.all([
              getContract(sub, contractid),
              getBIGFICRADisclosure(sub, contractid),
            ]);
        const contractPDF = contractList[0];
        const disclosurePDF = contractList[1];
        const mmlisAddOnPDF = contractList[2];

        if (typeof contractPDF !== "string" && contractPDF.byteLength > 10000) {
          const newContractURL = URL.createObjectURL(
            new Blob([contractPDF], { type: "application/pdf" })
          );
          setContractURL(newContractURL);
        }
        if (
          typeof disclosurePDF !== "string" &&
          disclosurePDF.byteLength > 10000
        ) {
          const newDisclosureURL = URL.createObjectURL(
            new Blob([disclosurePDF], { type: "application/pdf" })
          );
          setDisclosuresURL(newDisclosureURL);
        }

        if (
          mmlis &&
          typeof mmlisAddOnPDF !== "string" &&
          mmlisAddOnPDF.byteLength > 10000
        ) {
          const newMMLISAddOnURL = URL.createObjectURL(
            new Blob([mmlisAddOnPDF], { type: "application/pdf" })
          );
          setMMLISAddOnUrl(newMMLISAddOnURL);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    fetchContracts();
  }, [sub, contractid, mmlis]);

  const noFormsInd =
    contracttype === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
      ? disclosuresUrl !== ""
      : disclosuresUrl !== "" && contractUrl !== "";
  return (
    <div className="home-page-prior-apps">
      <div className="content" style={{ padding: "20px" }}>
        <div
          className="homepageText"
          style={{ display: "inline-block", width: "100%" }}
        >
          <div style={{ display: "inline-block", float: "left" }}>
            <h3
              style={{
                fontSize: "24px",
                fontWeight: "300px",
              }}
            >
              {" "}
              {showArchiveDeclineMessage && (
                <span className="review-dot"></span>
              )}
              <span className="badge badge-indicator"></span>
              {contracttype}
            </h3>
            {(mmlis === true || finance === true) && (
              <span style={{ display: "inline-block" }}>{addOns}</span>
            )}
          </div>

          {showArchiveDeclineMessage ? (
            <div
              style={{
                display: "inline-block",
                marginLeft: "50px",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  lineHeight: "2",
                }}
              >
                Your agency licensing coordinator will contact you with more
                information.
              </span>
              <br />
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                As of {moment(lastchangedate).utc().format("MMMM DD, YYYY")}
                {/* As of {moment(lastchangedate).utc().format("MM/DD/YYYY")} */}
              </span>
            </div>
          ) : (
            <div style={{ display: "inline-block", lineHeight: "2" }}>
              {contracttype !== CONTRACT_TYPE_DESCRIPTIONS.MMLIS ? (
                <div
                  style={{
                    display: "inline-block",
                    fontWeight: "300px",
                    marginLeft: "50px",
                  }}
                  //className="eyebrow"
                  id={`homeEyebrow${contractid}`}
                >
                  <span>Agency #{agencynumber}</span>
                </div>
              ) : (
                <div
                  style={{
                    display: "inline-block",
                    fontWeight: "300px",
                    marginLeft: "73px",
                  }}
                  //className="eyebrow"
                  id={`homeEyebrow${contractid}`}
                >
                  <span>Agency #{agencynumber}</span>
                </div>
              )}

              <div
                style={{
                  display: "inline-block",
                  fontWeight: "300px",
                  marginLeft: "50px",
                }}
                //className="eyebrow"
                // id={`homeEyebrow${contractid}`}
              >
                {contracttype !== CONTRACT_TYPE_DESCRIPTIONS.MMLIS ? (
                  <span>CMID {cmid !== null ? cmid : "##########"}</span>
                ) : (
                  <span style={{ paddingLeft: "94px" }}></span>
                )}
              </div>

              <div
                style={{
                  display: "inline-block",
                  fontWeight: "300px",
                  marginLeft: "50px",
                  paddingBottom: "10px",
                }}
              >
                {contracttype !== CONTRACT_TYPE_DESCRIPTIONS.MMLIS ? (
                  <span>
                    Effective{" "}
                    {moment(contracteffectivedate).format("MMMM DD, YYYY")}
                  </span>
                ) : (
                  <span>
                    Effective{" "}
                    {moment(mmlisregistrationdate).format("MMMM DD, YYYY")}
                  </span>
                )}
              </div>
              <br />
            </div>
          )}
          {loading ? (
            <div
              style={{
                margin: "5px 20px",
                float: "right",
              }}
            >
              <div className="mm-dots-loading" />
            </div>
          ) : (
            <>
              {noFormsInd && (
                <div>
                  <DropdownButton
                    className={
                      CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
                        ? "icon-quick-actions mc-quick-actions"
                        : "icon-quick-actions"
                    }
                    id={`quickActions${contractid}`}
                    style={{ float: "right" }}
                    title=""
                  >
                    {contracttype !==
                      CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP && (
                      <Dropdown.Item onClick={() => openContract()}>
                        {ContractButtonLabel}
                      </Dropdown.Item>
                    )}

                    <Dropdown.Item onClick={() => openDisclosure()}>
                      Background Disclosures
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => openConsumerNotificationRights()}
                    >
                      Consumer Notification and Rights
                    </Dropdown.Item>
                    {mmlis && (
                      <Dropdown.Item onClick={() => openMMLISAddOn()}>
                        {applicationStatus ===
                        CONTRACT_STATUS_DESC.ACKNOWLEDGED_APPROVED
                          ? "MMLIS Endorsed Contract"
                          : "MMLIS Contract"}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={() => downloadAll()}>
                      <span className="icon-download"></span>
                      <span id="dwnldall"> Download All</span>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              )}
            </>
          )}
        </div>

        <div style={{ display: "inline-block" }}>
          <div
            className="strike-through"
            style={{
              borderBottom: "solid 2px #D9D9D9",
              marginBottom: "-15px",
              display: "flex",
              marginLeft: "auto",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default HomePagePriorApps;
