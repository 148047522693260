/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../../store/actions";
import BackButtonModal from "../../../../../Forms/BackButtonModal";

import "./StatusDetailsForm.scss";

import {
  formatMoney,
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../common/Functions";
import { STATES } from "../../../../../../../constants/States";
import CheckboxList from "../../../common/CheckboxList";
import DRPCardNoButton from "../../../common/DRPCard/DRPCardNoButton";
import DRPSummaryListingCardCheck from "../../../common/DRPSummaryListingCard";
import { DRPProdCheckList } from "../../../constants";
import {
  customerComplaintActionType,
  exactExplanationOptions,
  yesNoOptions,
} from "../constants";

const CustomerComplaintAddDRPStatusDetailsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  data,
  updateTempCustComplDRP,
  tempDRP,
}) => {
  /** Initilialization */
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);
  const [drpSelectedCheckList, setDRPSelectedCheckList] = useState(
    tempDRP?.drpSelectedCheckList ? tempDRP?.drpSelectedCheckList : []
  );
  const [statusDetailsOtherProdText, setStatusDetailsOtherProdText] = useState(
    tempDRP?.statusDetailsOtherProdText || ""
  );
  const [selectedProdValues, setSelectedProdValues] = useState(
    tempDRP?.selectedProdValues || []
  );
  const [statusDetailsOtherProdTextError, setStatusDetailsOtherProdTextError] =
    useState(false);
  const selectedProdValueCount = selectedProdValues?.filter(
    (x) => typeof x === "string"
  )?.length;
  const isOtherSelected = selectedProdValues?.includes("Other");

  const [statusDetailsMatter, setStatusDetailsMatter] = useState(
    tempDRP?.statusDetailsMatter || ""
  );
  const [statusDetailsMatterError, setMatterError] = useState(false);
  const [statusDetailsNamedPartyRadio, setStatusDetailsNamedPartyRadio] =
    useState(tempDRP?.statusDetailsNamedPartyRadio || "");
  const [statusDetailsCustomerNames, setStatusDetailsCustomerNames] = useState(
    tempDRP?.statusDetailsCustomerNames || ""
  );
  const [statusDetailsCustomerNamesError, setStatusDetailsCustomerNamesError] =
    useState(false);
  const [statusDetailsCustomerState, setStatusDetailsCustomerState] = useState(
    tempDRP?.statusDetailsCustomerState || ""
  );
  const [statusDetailsCustomerStateError, setStatusDetailsCustomerStateError] =
    useState(false);
  const [
    statusDetailsCustomerOtherStates,
    setStatusDetailsCustomerOtherStates,
  ] = useState(tempDRP?.statusDetailsCustomerOtherStates || "");
  const [
    statusDetailsCustomerEmployingFirm,
    setStatusDetailsCustomerEmployingFirm,
  ] = useState(tempDRP?.statusDetailsCustomerEmployingFirm || "");
  const [
    statusDetailsCustomerEmployingFirmError,
    setStatusDetailsCustomerEmployingFirmError,
  ] = useState(false);
  const [
    statusDetailsCustomerAllegations,
    setStatusDetailsCustomerAllegations,
  ] = useState(tempDRP?.statusDetailsCustomerAllegations || "");
  const [
    statusDetailsCustomerAllegationsError,
    setStatusDetailsCustomerAllegationsError,
  ] = useState(false);
  const [
    statusDetailsCustomerDamageAmount,
    setStatusDetailsCustomerDamageAmount,
  ] = useState(tempDRP?.statusDetailsCustomerDamageAmount || "");
  const [
    statusDetailsCustomerDamageAmountError,
    setStatusDetailsCustomerDamageAmountError,
  ] = useState(false);
  const [
    statusDetailsCustomerDamageAmountErrorMsg,
    setStatusDetailsCustomerDamageAmountErrorMsg,
  ] = useState("");
  const [
    statusDetailsAllegedDamageAmountRadio,
    setStatusDetailsAllegedDamageAmountRadio,
  ] = useState(tempDRP?.statusDetailsAllegedDamageAmountRadio || "");
  const [
    statusDetailsAllegedDamageAmountRadioError,
    setStatusDetailsAllegedDamageAmountRadioError,
  ] = useState(false);
  const [statusDetailsCustomerExplanation, setStatusDetailsExplanation] =
    useState(tempDRP?.statusDetailsCustomerExplanation || "");
  const [
    statusDetailsCustomerExplanationError,
    setStatusDetailsExplanationError,
  ] = useState(false);

  const [isFocusedState, setFocusedState] = useState(false);

  const [isUpdates, setIsUpdates] = useState(false);

  /** is There an Error */

  const isError =
    statusDetailsMatterError ||
    statusDetailsCustomerNamesError ||
    statusDetailsCustomerStateError ||
    statusDetailsCustomerEmployingFirmError ||
    statusDetailsCustomerAllegationsError ||
    statusDetailsCustomerDamageAmountError ||
    statusDetailsAllegedDamageAmountRadioError ||
    statusDetailsOtherProdTextError ||
    statusDetailsCustomerExplanationError;

  /** is There an Error */
  const isNextBtnDisabled = !!(
    !(drpSelectedCheckList.length !== 0) ||
    !(selectedProdValueCount !== 0) ||
    !statusDetailsMatter?.length ||
    ((statusDetailsMatter === customerComplaintActionType.ARBITRATION_CFTC ||
      statusDetailsMatter === customerComplaintActionType.CIVIL_LITIGATION) &&
      !statusDetailsNamedPartyRadio?.length) ||
    (isOtherSelected &&
      (!statusDetailsOtherProdText?.length ||
        (statusDetailsOtherProdText?.length &&
          statusDetailsOtherProdText?.length < 2))) ||
    !statusDetailsCustomerNames?.length ||
    (statusDetailsCustomerNames?.length &&
      statusDetailsCustomerNames?.length < 1) ||
    !statusDetailsCustomerState?.length ||
    (statusDetailsCustomerState?.length &&
      statusDetailsCustomerState?.length < 2) ||
    !statusDetailsCustomerEmployingFirm?.length ||
    (statusDetailsCustomerEmployingFirm?.length &&
      statusDetailsCustomerEmployingFirm?.length < 2) ||
    !statusDetailsCustomerAllegations?.length ||
    (statusDetailsCustomerAllegations?.length &&
      statusDetailsCustomerAllegations?.length < 2) ||
    !statusDetailsCustomerDamageAmount?.length ||
    !statusDetailsAllegedDamageAmountRadio?.length ||
    (statusDetailsAllegedDamageAmountRadio ===
      exactExplanationOptions.EXPLANATION &&
      (!statusDetailsCustomerExplanation?.length ||
        (statusDetailsCustomerExplanation?.length &&
          statusDetailsCustomerExplanation?.length < 2))) ||
    isError ||
    isFocusedState
  );

  const handleMatterChangedChecked = ({ target: { value } }) => {
    setIsUpdates(true);
    if (value === customerComplaintActionType.CUSTOMER_COMPLAINT) {
      setStatusDetailsNamedPartyRadio("");
    }
    setMatterError(false);
    setStatusDetailsMatter(value);
  };

  const handleNamedPartyYesNoChangedChecked = ({ target: { value } }) => {
    setIsUpdates(true);
    setStatusDetailsNamedPartyRadio(value);
  };

  const handleAllegedCompDamageAmtChangedChecked = ({ target: { value } }) => {
    setIsUpdates(true);
    if (value === exactExplanationOptions.EXACT) {
      setStatusDetailsExplanation("");
      setStatusDetailsExplanationError(false);
    }
    setStatusDetailsAllegedDamageAmountRadioError(false);
    setStatusDetailsAllegedDamageAmountRadio(value);
  };

  const onChangeTextInput = ({ target: { id, value } }) => {
    setIsUpdates(true);
    switch (id) {
      case "statusDetailsCustomerNames":
        setStatusDetailsCustomerNames(value);
        break;
      case "statusDetailsCustomerOtherStates":
        setStatusDetailsCustomerOtherStates(value);
        break;
      case "statusDetailsCustomerEmployingFirm":
        setStatusDetailsCustomerEmployingFirm(value);
        break;
      case "statusDetailsCustomerAllegations":
        setStatusDetailsCustomerAllegations(value);
        break;
      case "statusDetailsCustomerDamageAmount":
        setStatusDetailsCustomerDamageAmount(formatMoney(value));
        break;
      case "statusDetailsCustomerExplanation":
        setStatusDetailsExplanation(value);
        break;
      default:
        break;
    }
  };
  const onBlurTextInput = ({ target: { id, value } }) => {
    setFocusedState(false);
    switch (id) {
      case "statusDetailsCustomerNames":
        setStatusDetailsCustomerNamesError(value?.trim()?.length < 1);
        break;
      case "statusDetailsCustomerEmployingFirm":
        setStatusDetailsCustomerEmployingFirmError(value?.trim()?.length < 2);
        break;
      case "statusDetailsCustomerAllegations":
        setStatusDetailsCustomerAllegationsError(value?.trim()?.length < 2);
        break;
      case "statusDetailsCustomerDamageAmount":
        const isMoneyError =
          parseInt(value?.replaceAll(",", "")) > 100000000 || value?.length < 1;
        const moneyErrMessage =
          parseInt(value?.replaceAll(",", "")) > 100000000
            ? "Please enter a valid number"
            : value?.length < 1
            ? "Please enter Damage Amount"
            : "";
        setStatusDetailsCustomerDamageAmountError(isMoneyError);
        setStatusDetailsCustomerDamageAmountErrorMsg(moneyErrMessage);
        break;
      case "statusDetailsCustomerExplanation":
        setStatusDetailsExplanationError(value?.trim()?.length < 2);
        break;
      default:
        break;
    }
  };
  const onChangeCustomerStateHandler = ({ target: { value } }) => {
    setIsUpdates(true);
    setStatusDetailsCustomerState(value);
  };
  const onBlurCustomerStateHandler = ({ relatedTarget }) => {
    setFocusedState(false);
    return statusDetailsCustomerState === "" && relatedTarget === null
      ? setStatusDetailsCustomerStateError(true)
      : setStatusDetailsCustomerStateError(false);
  };

  const onChangeCheckedProd = ({ target: { value, checked } }) => {
    setIsUpdates(true);

    if (value === "Other") {
      setStatusDetailsOtherProdText("");
      setStatusDetailsOtherProdTextError(false);
    }

    if (checked) {
      setSelectedProdValues((prev) => [...prev, value]);
    } else {
      setSelectedProdValues((prev) => [
        ...prev.filter((data) => data !== value),
      ]);
    }
  };
  const onOtherProdChangeHandler = ({ target: { value } }) => {
    setIsUpdates(true);
    setStatusDetailsOtherProdText(value);
  };
  const onBlurProdOtherHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2
      ? setStatusDetailsOtherProdTextError(true)
      : setStatusDetailsOtherProdTextError(false);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  useEffect(() => {
    if (isUpdates || isError) {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    } else {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    }
  }, [isUpdates, isError, dispatch]);

  useEffect(() => {
    if (data?.drpCheckList && data?.drpCheckList?.length === 1) {
      setDRPSelectedCheckList([data?.drpCheckList[0].key]);
    }
  }, []);

  const handleShowBackModal = () => {
    if (isUpdates || isError) {
      setShowBackModal(true);
    } else {
      updateTempCustComplDRP({});
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateTempCustComplDRP({});
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = () => {
    const submitData = {
      ...tempDRP,
      drpSelectedCheckList,
      statusDetailsMatter,
      statusDetailsNamedPartyRadio,
      statusDetailsCustomerNames,
      statusDetailsCustomerState,
      statusDetailsCustomerOtherStates,
      statusDetailsCustomerEmployingFirm,
      statusDetailsCustomerAllegations,
      selectedProdValues,
      statusDetailsCustomerDamageAmount,
      statusDetailsAllegedDamageAmountRadio,
      statusDetailsCustomerExplanation,
      statusDetailsOtherProdText,
    };
    updateTempCustComplDRP(submitData);
    updateCCBForm(nextFormId);
  };

  const handleChange = ({ target: { checked, value } }) => {
    setIsUpdates(true);
    const list = [];

    if (checked) {
      list.push(value);
      setDRPSelectedCheckList((drp) => [...new Set([...drp, ...list])]);
    }
    if (!checked) {
      setDRPSelectedCheckList((drp) => [
        ...new Set(drp?.filter((item) => item !== value)),
      ]);
    }
  };

  return (
    <div className="cust-compl-disclosure-status-details-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label className="status-details-instructions">
            One matter may result in more than one affirmative answer to the
            below items. Use a single DRP to report details relating to a
            particular matter (i.e., a customer complaint/arbitration/CFTC
            reparation/civil litigation). Use a separate DRP for each matter.
          </label>
        </div>
      </div>
      <div
        className="cust-compl-discl-drp-summary"
        style={{ paddingTop: "20px" }}
      >
        {data?.drpCheckList.length && data?.drpCheckList.length > 1 ? (
          <DRPCardNoButton
            header={"Check the question(s) you are responding to:"}
          >
            {data?.drpCheckList.map(
              ({ key, mainQuestion, question }, index) => (
                <DRPSummaryListingCardCheck
                  key={`${index}_${key}`}
                  questionKey={key}
                  mainQuestion={mainQuestion}
                  question={question}
                  index={index}
                  length={data?.drpCheckList.length}
                  handleChange={handleChange}
                  isCheckbox
                  drpCheckList={[
                    ...data?.drpCheckList,
                    ...drpSelectedCheckList,
                  ]}
                />
              )
            )}
          </DRPCardNoButton>
        ) : null}
      </div>

      <div className="row">
        <div className="col-md-12">
          <label className="cust-complaint-action">
            What type of matter are you reporting? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="customerComplaint"
                type="radio"
                id="customerComplaint"
                className="custom-control-input"
                value={customerComplaintActionType.CUSTOMER_COMPLAINT}
                checked={
                  statusDetailsMatter ===
                  customerComplaintActionType.CUSTOMER_COMPLAINT
                }
                onChange={handleMatterChangedChecked}
              />
              <label
                htmlFor="customerComplaint"
                className="custom-control-label"
                id="customerComplaintLabelId"
              >
                {customerComplaintActionType.CUSTOMER_COMPLAINT}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="arbitrationCFTC"
                type="radio"
                id="arbitrationCFTCId"
                className="custom-control-input"
                value={customerComplaintActionType.ARBITRATION_CFTC}
                checked={
                  statusDetailsMatter ===
                  customerComplaintActionType.ARBITRATION_CFTC
                }
                onChange={handleMatterChangedChecked}
              />
              <label
                htmlFor="arbitrationCFTCId"
                className="custom-control-label"
                id="arbitrationCFTCLabelId"
              >
                {customerComplaintActionType.ARBITRATION_CFTC}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="civilLitigation"
                type="radio"
                id="civilLitigationId"
                className="custom-control-input"
                value={customerComplaintActionType.CIVIL_LITIGATION}
                checked={
                  statusDetailsMatter ===
                  customerComplaintActionType.CIVIL_LITIGATION
                }
                onChange={handleMatterChangedChecked}
              />
              <label
                htmlFor="civilLitigationId"
                className="custom-control-label"
                id="civilLitigationLabelId"
              >
                {customerComplaintActionType.CIVIL_LITIGATION}
              </label>
            </div>
          </div>
        </div>
      </div>
      {(statusDetailsMatter === customerComplaintActionType.ARBITRATION_CFTC ||
        statusDetailsMatter ===
          customerComplaintActionType.CIVIL_LITIGATION) && (
        <div style={{ marginLeft: "40px" }}>
          <div className="row">
            <div className="col-md-12">
              <label className="cust-complaint-action">
                Are you named party/respondent? *
              </label>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-4 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="namedPartyYes"
                    type="radio"
                    id="namedPartyYes"
                    className="custom-control-input"
                    value={yesNoOptions.YES}
                    checked={statusDetailsNamedPartyRadio === yesNoOptions.YES}
                    onChange={handleNamedPartyYesNoChangedChecked}
                  />
                  <label
                    htmlFor="namedPartyYes"
                    className="custom-control-label"
                    id="namedPartyYesLabelId"
                  >
                    {yesNoOptions.YES}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="namedPartyNo"
                    type="radio"
                    id="namedPartyNo"
                    className="custom-control-input"
                    value={yesNoOptions.NO}
                    checked={statusDetailsNamedPartyRadio === yesNoOptions.NO}
                    onChange={handleNamedPartyYesNoChangedChecked}
                  />
                  <label
                    htmlFor="namedPartyNo"
                    className="custom-control-label"
                    id="namedPartyNoLabelId"
                  >
                    {yesNoOptions.NO}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <hr />
      <div className="row">
        <div className="col-md-10">
          <label
            className="customer-names"
            htmlFor="statusDetailsCustomerNames"
            id="customerNamesLabelId"
          >
            Customer Name(s) *
          </label>
          <textarea
            type="text"
            id="statusDetailsCustomerNames"
            className={
              statusDetailsCustomerNamesError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={200}
            minLength={2}
            autoComplete="off"
            value={statusDetailsCustomerNames}
            onChange={onChangeTextInput}
            onBlur={onBlurTextInput}
            onFocus={() => setFocusedState(true)}
          />
          <div
            id="statusDetailsCustomerNamesError"
            className="invalid-feedback"
          >
            Please enter valid Name(s)
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10" style={{ minWidth: "430px" }}>
          <label
            className="customer-state"
            htmlFor="customerComplaintState"
            id="customerComplaintStateLabelId"
          >
            Customer(s) state of residence (choose "not on list" if foreign
            address) *
          </label>
          <div>
            <DropdownButton
              id={"customerComplaintState"}
              type="default"
              variant="secondary"
              title={
                statusDetailsCustomerState === ""
                  ? "Select State"
                  : statusDetailsCustomerState
              }
              className={
                statusDetailsCustomerStateError
                  ? `is-invalid`
                  : statusDetailsCustomerState === ""
                  ? "state-placeholder"
                  : ""
              }
              selected={false}
              autoComplete="new-address"
              onSelect={(key, event) => {
                event.target.id = "customerComplaintState";
                event.target.value = key;
                onChangeCustomerStateHandler(event);
              }}
              onBlur={onBlurCustomerStateHandler}
              onClick={({ target }) => {
                target.focus();
              }}
            >
              {STATES.map(({ code, name }, key) => (
                <Dropdown.Item
                  key={key}
                  eventKey={code}
                  id={`state_option_${code}`}
                >
                  {name}
                </Dropdown.Item>
              ))}
              <Dropdown.Item
                key={"Not on list"}
                eventKey={"Not on list"}
                id={`state_option_not_on_list`}
              >
                Not on list
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div
            id="statusDetailsCustomerStateError"
            className={
              statusDetailsCustomerStateError
                ? "show invalid-feedback"
                : "invalid-feedback"
            }
          >
            Please select a valid State
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <label
            className="customer-other-states"
            htmlFor="statusDetailsCustomerOtherStates"
            id="customerOtherStatesLabelId"
          >
            Other state(s) of residence/detail (optional)
          </label>
          <textarea
            type="text"
            id="statusDetailsCustomerOtherStates"
            className="form-control form-control-15"
            maxLength={200}
            minLength={2}
            autoComplete="off"
            value={statusDetailsCustomerOtherStates}
            onChange={onChangeTextInput}
            onBlur={() => setFocusedState(false)}
            onFocus={() => setFocusedState(true)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <label
            className="customer-employing-firm"
            htmlFor="statusDetailsCustomerEmployingFirm"
            id="customerEmployingFirmLabelId"
          >
            Employing Firm when activities occurred which led to the customer
            complaint, arbitration, CFTC reparation or civil litigation *
          </label>
          <textarea
            type="text"
            id="statusDetailsCustomerEmployingFirm"
            className={
              statusDetailsCustomerEmployingFirmError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={500}
            minLength={2}
            autoComplete="off"
            value={statusDetailsCustomerEmployingFirm}
            onChange={onChangeTextInput}
            onBlur={onBlurTextInput}
            onFocus={() => setFocusedState(true)}
          />
          <div
            id="statusDetailsCustomerEmployingFirmError"
            className="invalid-feedback"
          >
            Please provide a Firm name
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <label
            className="customer-allegations"
            htmlFor="statusDetailsCustomerAllegations"
            id="customerAllegationsLabelId"
          >
            Allegation(s) and a brief summary of events related to the
            allegation(s) including dates when activities leading to the
            allegation(s) occurred *
          </label>
          <textarea
            type="text"
            id="statusDetailsCustomerAllegations"
            className={
              statusDetailsCustomerAllegationsError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={500}
            minLength={2}
            autoComplete="off"
            value={statusDetailsCustomerAllegations}
            onChange={onChangeTextInput}
            onBlur={onBlurTextInput}
            onFocus={() => setFocusedState(true)}
          />
          <div
            id="statusDetailsCustomerAllegationsError"
            className="invalid-feedback"
          >
            Please enter a response
          </div>
        </div>
      </div>
      <div>
        <div>
          <CheckboxList
            selectedItems={selectedProdValues}
            list={DRPProdCheckList}
            header="Product Type(s) (Select all that apply): *"
            onChangeChecked={onChangeCheckedProd}
            onOtherChangeHandler={onOtherProdChangeHandler}
            onBlurOtherHandler={onBlurProdOtherHandler}
            otherTextError={statusDetailsOtherProdTextError}
            otherText={statusDetailsOtherProdText}
            onFocusHandler={() => setFocusedState(true)}
          />
        </div>
      </div>

      <div className="row">
        <div
          className="col-md-5"
          style={{ marginBottom: ".8rem", minWidth: "380px" }}
        >
          <div style={{ float: "left" }}>
            <label
              className="customer-damage-amount"
              htmlFor="statusDetailsCustomerDamageAmount"
              id="customerDamageAmountLabelId"
            >
              Alleged Compensatory Damage Amount *
            </label>
            <div
              className={
                statusDetailsCustomerDamageAmountError
                  ? "input-group is-invalid"
                  : "input-group"
              }
              id="alleged_damage_amount_input"
            >
              <div className="input-group-prepend">
                <span
                  className="input-group-text input-group-text-pad-right"
                  id="alleged_damage_amount_input_$"
                >
                  $
                </span>
              </div>
              <input
                type="text"
                id="statusDetailsCustomerDamageAmount"
                className="form-control form-control-15"
                maxLength={11}
                value={statusDetailsCustomerDamageAmount}
                onChange={onChangeTextInput}
                onBlur={onBlurTextInput}
                onFocus={() => setFocusedState(true)}
                onKeyPress={onlyNumeric}
                onPaste={pasteCommaDelimited}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="customer-damage-amount-tooltip-wrapper tooltipContainer">
            <span className="icon-info"></span>
            <span className="tooltiptext">
              If no damage amount is alleged, the complaint must be reported
              unless the firm has made a good faith determination that the
              damages from the alleged conduct would be less than $5,000
            </span>
          </div>
          <div
            id="statusDetailsCustomerDamageAmountError"
            className={`invalid-feedback ${
              statusDetailsCustomerDamageAmountError ? "show" : ""
            }`}
            style={{ float: "left" }}
          >
            {statusDetailsCustomerDamageAmountErrorMsg}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="exact"
                type="radio"
                id="exact"
                className="custom-control-input"
                value={exactExplanationOptions.EXACT}
                checked={
                  statusDetailsAllegedDamageAmountRadio ===
                  exactExplanationOptions.EXACT
                }
                onChange={handleAllegedCompDamageAmtChangedChecked}
              />
              <label
                htmlFor="exact"
                className="custom-control-label"
                id="exactLabelId"
              >
                {exactExplanationOptions.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="explanation"
                type="radio"
                id="explanation"
                className="custom-control-input"
                value={exactExplanationOptions.EXPLANATION}
                checked={
                  statusDetailsAllegedDamageAmountRadio ===
                  exactExplanationOptions.EXPLANATION
                }
                onChange={handleAllegedCompDamageAmtChangedChecked}
              />
              <label
                htmlFor="explanation"
                className="custom-control-label"
                id="explanationLabelId"
              >
                {exactExplanationOptions.EXPLANATION}
              </label>
            </div>
          </div>
        </div>
      </div>
      {statusDetailsAllegedDamageAmountRadio ===
        exactExplanationOptions.EXPLANATION && (
        <div className="row" style={{ marginLeft: "10px" }}>
          <div className="col-md-10">
            <label
              className="customer-explanation"
              htmlFor="statusDetailsCustomerExplanation"
              id="customerExplanationLabelId"
            >
              If not exact, provide explanation *
            </label>
            <textarea
              type="text"
              id="statusDetailsCustomerExplanation"
              className={
                statusDetailsCustomerExplanationError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              autoComplete="off"
              value={statusDetailsCustomerExplanation}
              onChange={onChangeTextInput}
              onBlur={onBlurTextInput}
              onFocus={() => setFocusedState(true)}
            />

            <div
              id="statusDetailsCustomerExplanationError"
              className="invalid-feedback"
            >
              Please enter an Explanation
            </div>
          </div>
        </div>
      )}

      <div className="backbutton-drp-cust-compl row">
        <div className="col-md-12 buttons">
          <span onClick={handleShowBackModal}>
            <strong id="crimDisclBack">Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CustomerComplaintAddDRPStatusDetailsForm;
