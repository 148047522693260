import React, { useEffect, useState } from "react";
import ReviewPersonalInfoFinOnly from "./ReviewSignForm1/ReviewPersonalInfoFinOnly";
import { Button, FormCustomControl } from "react-blueprint-library";
import BackButtonModal from "../BackButtonModal";
import { FORM_ID } from "../../../../constants/Forms";
import { updateContractStatus } from "../../../../api/makeAPIRequest";
import { CONTRACT_STATUS_ID } from "../../../../constants/Contract";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../store/actions";

const ACCEPT_LABEL = `I represent that all the information I have provided is complete and true to the best of my knowledge and belief.`;

const cardStyle = {
  marginTop: "20px",
  marginBottom: "40px",
  background: "white",
  borderRadius: "8px",
  border: "1px #d9d9d6 solid",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "16px",
  display: "inline-flex",
  width: "100%",
};

const ReviewAndSignFinOnly = (props) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleShowBackModal = () => setShowBackModal(true);
  const handleCloseBackModal = () => setShowBackModal(false);

  const handleBackClick = () => {
    setIsUpdate(false);
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    handleCloseBackModal();
    props.toggleForm(FORM_ID.PERSONAL_INFO);
  };

  const handleChecked = () => {
    setChecked((prevState) => !prevState);
    setIsUpdate(true);
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  };

  const handleSubmitClick = async () => {
    setIsSubmitted(true);

    await updateContractStatus(
      props.subId,
      props.contractid,
      props.personalInformationData.emailaddress,
      CONTRACT_STATUS_ID.APPLICATION_SUBMITTED
    );

    window.location = `/applicationconfirmation?contract=${props.contractid}`;
  };

  return (
    <>
      <div id={`reviewsignheader`}>
        <div className="row">
          <div className="col-md-12" id="main-hdng">
            <h1>Review and Sign</h1>
          </div>
        </div>
      </div>

      <ReviewPersonalInfoFinOnly
        isUpdate={isUpdate}
        personalInformation={props.personalInformationData}
        toggleForm={props.toggleForm}
        candidatePresentationMode={props.candidatePresentationMode}
      />

      <div className="row">
        <div style={{ ...cardStyle }}>
          <div className="drp-container">
            <div
              className="col-md-12"
              style={{
                position: "relative",
                top: "1.8rem",
                paddingRight: "45px",
              }}
              id="iaccept"
            >
              <FormCustomControl
                id="accept"
                inputType="checkbox"
                label={ACCEPT_LABEL}
                onChange={handleChecked}
                checked={checked}
              />
              <label
                htmlFor="accept"
                className="required-field-text"
                style={{ paddingLeft: "0.5em" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ float: "right" }}>
        <div
          className="col-md-12"
          style={{
            textAlign: "right",
            paddingTop: "30px",
            display: "inline-flex",
          }}
        >
          <span
            role="presentation"
            onClick={() => {
              if (checked || isUpdate) {
                handleShowBackModal();
              } else {
                props.toggleForm(FORM_ID.PERSONAL_INFO);
              }
            }}
            style={{
              margin: "8px 25px 8px 0px",
              color: "#3174af",
              cursor: "pointer",
            }}
            id="back-button"
          >
            <strong> Back </strong>
          </span>

          <Button
            style={{ marginRight: "10px" }}
            onClick={handleSubmitClick}
            disabled={!checked || isSubmitted}
            id="submit-button"
          >
            SUBMIT
          </Button>

          {isSubmitted && <div className="mm-dots-loading-lg"></div>}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </>
  );
};

export default ReviewAndSignFinOnly;
