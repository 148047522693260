import { useOktaAuth } from "@okta/okta-react";
import moment from "moment";
import { Button } from "react-blueprint-library";
import { updateContractStatus } from "../../../api/makeAPIRequest";
import {
  CONTRACT_STATUS_DESC,
  CONTRACT_STATUS_ID,
  isBackgroundCheck,
  isPending,
  isSubmitted,
} from "../../../constants/Contract";
import PDFForm from "./PDFForm";

const HomePageActiveApps = ({
  agencynumber,
  applicationStatus,
  contract,
  contractid,
  mmlis,
  finance,
  lastchangedate,
  showGetStartedFor,
}) => {
  const {
    authState: {
      idToken: {
        claims: { sub, email },
      },
    },
  } = useOktaAuth();

  const getStarted = async () => {
    if (applicationStatus === CONTRACT_STATUS_DESC.INVITATION_SENT) {
      showGetStartedFor(contractid);
    } else {
      const shouldUpdateStatus =
        applicationStatus === CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE ||
        applicationStatus === CONTRACT_STATUS_DESC.NEEDS_REVISION;
      if (shouldUpdateStatus) {
        await updateContractStatus(
          sub,
          contractid,
          email,
          CONTRACT_STATUS_ID.APPLICATION_IN_PROGRESS
        );
      }
      // For some reason, the page changes location before the updateContractStatus Completes.
      // Set a timeout to fix this
      setTimeout(
        () => {
          window.location = `/startapplication?contract=${contractid}`;
        },
        shouldUpdateStatus ? 250 : 0
      );
    }
  };

  return (
    <div className="home-page-active-apps">
      <div className="content" style={{ padding: "20px" }}>
        <div className="homepageText" style={{ display: "inline-block" }}>
          <div className="eyebrow" id={`homeEyebrow${contractid}`}>
            {applicationStatus === CONTRACT_STATUS_DESC.INVITATION_SENT ? (
              <span
                id="new_bubble"
                className="badge badge-pill badge-secondary"
                style={{
                  backgroundColor: "#002E6B",
                  textTransform: "none",
                  fontWeight: "500",
                  color: "white",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                New
              </span>
            ) : null}

            <span id="finetext"> Agency #{agencynumber}</span>
          </div>

          <div
            id={`homePageContractAndAddons${contractid}`}
            style={{ display: "inline-block" }}
          >
            <h2 style={{ display: "inline-block" }}>{contract}</h2>
            {mmlis === true && finance === true ? (
              <span style={{ display: "inline-block", paddingLeft: "20px" }}>
                + MMLIS, Financing
              </span>
            ) : mmlis === true && finance === false ? (
              <span style={{ display: "inline-block", paddingLeft: "20px" }}>
                + MMLIS
              </span>
            ) : mmlis === false && finance === true ? (
              <span style={{ display: "inline-block", paddingLeft: "20px" }}>
                + Financing
              </span>
            ) : null}
          </div>

          <div id="homepageAppStatusText" style={{ paddingBottom: "20px" }}>
            {applicationStatus === CONTRACT_STATUS_DESC.INVITATION_SENT ? (
              <span>
                Invitation sent on{" "}
                {moment(lastchangedate).utc().format("MMMM DD, YYYY")}
              </span>
            ) : (
              <span>
                Application Status as of{" "}
                {moment(lastchangedate).utc().format("MMMM DD, YYYY")}
              </span>
            )}
          </div>

          {applicationStatus !== CONTRACT_STATUS_DESC.INVITATION_SENT && (
            <div style={{ display: "inline-block" }}>
              <div
                className="strike-through"
                style={{
                  borderBottom: "solid 2px #D9D9D9",
                  marginBottom: "-15px",
                  display: "flex",
                  marginRight: "60px",
                }}
              />

              {isBackgroundCheck(applicationStatus) ||
              isPending(applicationStatus) ||
              isSubmitted(applicationStatus) ? (
                <span className="badge badge-primary">
                  Application Submitted
                </span>
              ) : applicationStatus ===
                  CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE ||
                applicationStatus === CONTRACT_STATUS_DESC.NEEDS_REVISION ? (
                <span className="badge badge-primary-error">
                  Application Returned
                </span>
              ) : applicationStatus ===
                  CONTRACT_STATUS_DESC.APPLICATION_IN_PROGRESS ||
                applicationStatus ===
                  CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ? (
                <span className="badge badge-primary-current">
                  Application in Progress
                </span>
              ) : (
                <span className="badge badge-primary">
                  Application in Progress
                </span>
              )}
              {applicationStatus === CONTRACT_STATUS_DESC.AGENCY_REVIEW ||
              applicationStatus ===
                CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ||
              applicationStatus === CONTRACT_STATUS_DESC.RETURNED_TO_AGENCY ||
              applicationStatus ===
                CONTRACT_STATUS_DESC.APPLICATION_SUBMITTED ||
              isBackgroundCheck(applicationStatus) ? (
                <span className="badge badge-primary-current">
                  Agency Review
                </span>
              ) : (
                <span className="badge badge-primary">Agency Review</span>
              )}
              {applicationStatus === CONTRACT_STATUS_DESC.HOME_OFFICE_REVIEW ||
              applicationStatus ===
                CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ||
              applicationStatus ===
                CONTRACT_STATUS_DESC.SUBMITTED_TO_HOME_OFFICE ||
              applicationStatus === CONTRACT_STATUS_DESC.MMLIS_Pending ||
              isPending(applicationStatus) ? (
                <span className="badge badge-primary-current">
                  MassMutual Review
                </span>
              ) : (
                <span className="badge badge-primary">MassMutual Review</span>
              )}
            </div>
          )}
        </div>

        {applicationStatus === CONTRACT_STATUS_DESC.APPLICATION_IN_PROGRESS ||
        applicationStatus === CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE ||
        applicationStatus === CONTRACT_STATUS_DESC.NEEDS_REVISION ||
        applicationStatus === CONTRACT_STATUS_DESC.INVITATION_SENT ? (
          <p
            className="buttons"
            style={{
              padding: "50px 0px 20px 0px",
              marginLeft: "auto",
              display: "flex",
              float: "right",
            }}
          >
            <Button
              onClick={() => getStarted()}
              id={`continueApplicationButton${contractid}`}
              className="continueButton"
            >
              {applicationStatus ===
                CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE ||
              applicationStatus === CONTRACT_STATUS_DESC.NEEDS_REVISION ? (
                <>Revise Application</>
              ) : applicationStatus === CONTRACT_STATUS_DESC.INVITATION_SENT ? (
                <>Begin Application</>
              ) : (
                <>Continue Application</>
              )}
              <span style={{ marginLeft: "10px" }} className="icon-caret" />
            </Button>
          </p>
        ) : (
          <>
            <div
              id={`contract-forms-${contractid}`}
              style={{
                marginLeft: "150px",
                display: "flex",
                minHeight: "100px",
                float: "right",
                alignItems: "center",
              }}
            >
              <PDFForm
                contractid={contractid}
                mmlis={mmlis}
                applicationStatus={applicationStatus}
                contract={contract}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HomePageActiveApps;
